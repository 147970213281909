import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CmFeedbackProvidedRoutingModule } from './cm-feedback-provided-routing.module';
import { CmFeedbackProvidedComponent } from './cm-feedback-provided.component';
import { CmFeedbackListModule } from 'src/app/shared/modules/cm-feedback-list/cm-feedback-list.module';
import { FeedbackFilterCriteriaFormModule } from 'src/app/shared/modules/feedback-filter-criteria-form/feedback-filter-criteria-form.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';

@NgModule({
  declarations: [CmFeedbackProvidedComponent],
  imports: [
    CommonModule,
    CmFeedbackProvidedRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,

    FeedbackFilterCriteriaFormModule,
    CmFeedbackListModule,
  ],
  exports: [CmFeedbackProvidedComponent],
})
export class CmFeedbackProvidedModule {}
