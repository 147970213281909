import {
  CdkDragDrop,
  CdkDragStart,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/common.service';
import {
  FeedbackType,
  QuestionGroup,
} from 'src/app/core/services/feedback.service';
import { QuestionsConfigService } from 'src/app/core/services/questions-config.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {
  FeedbackTypeDialogComponent,
  FeedbackTypeDialogData,
} from '../feedback-type-dialog/feedback-type-dialog.component';

@Component({
  selector: 'app-feedback-type-detail',
  templateUrl: './feedback-type-detail.component.html',
  styleUrls: [
    './feedback-type-detail.component.scss',
    '../questions-config.component.scss',
  ],
})
export class FeedbackTypeDetailComponent implements OnInit {
  @Input()
  selectedForm!: FeedbackType;
  @Output() selectedQuestionGroup = new EventEmitter<{
    form: FeedbackType;
    title: string;
    questionGroup: QuestionGroup;
  }>();

  dragDisabled = true;
  formTypes!: FeedbackType[];
  isLoading = true;
  isLoadingQuestionGroups = true;
  questionGroups!: QuestionGroup[];
  questionGroupColumns: string[] = ['dragHandle', 'active', 'questionGroup'];
  activeFormGroups!: FormGroup[];
  previousIndex!: number;
  orderChanged = false;

  dragHandleTooltip = 'Drag to reorder';

  constructor(
    private commonService: CommonService,
    private snackBarService: SnackBarService,
    private questionConfigService: QuestionsConfigService,
    private formBuilder: FormBuilder,
    private feedbackTypeDialog: MatDialog
  ) {}

  ngOnInit() {
    this.isLoading = true;

    this.commonService.getActiveFeedbackTypes().subscribe(
      (data: FeedbackType[]) => {
        this.formTypes = data;
        if (this.selectedForm) {
          this.selectedForm =
            this.formTypes.find(
              form => form.feedbackTypeId === this.selectedForm.feedbackTypeId
            ) || this.selectedForm;
          this.formSelected();
        }
        this.isLoading = false;
      },
      (error: string) => {
        this.snackBarService.error(error);
        this.isLoading = false;
      }
    );
  }

  formSelected() {
    this.isLoadingQuestionGroups = true;
    this.questionConfigService
      .getAllQuestionGroupsFromFeedbackType(this.selectedForm?.feedbackTypeId)
      .subscribe(
        (data: QuestionGroup[]) => {
          this.questionGroups = data;
          this.isLoadingQuestionGroups = false;

          this.activeFormGroups = this.questionGroups.map(questionGroup =>
            this.formBuilder.group({
              activeControl: [questionGroup.isActive],
            })
          );
        },
        (error: string) => {
          this.snackBarService.error(error);
          this.isLoadingQuestionGroups = false;
        }
      );
  }

  editQuestionGroup(whichQuestionGroup: QuestionGroup) {
    this.selectedQuestionGroup.emit({
      form: this.selectedForm,
      title: 'Edit Question Group',
      questionGroup: whichQuestionGroup,
    });
  }

  addQuestionGroup() {
    const newQuestionGroup = new QuestionGroup();
    newQuestionGroup.type = this.selectedForm.feedbackTypeId;
    newQuestionGroup.isActive = true;
    newQuestionGroup.sortOrder = this.questionGroups.length;
    this.selectedQuestionGroup.emit({
      form: this.selectedForm,
      title: 'Add Question Group',
      questionGroup: newQuestionGroup,
    });
  }

  editFeedbackType() {
    const editFeedbackTypeData = new FeedbackTypeDialogData();
    editFeedbackTypeData.title = 'Edit Feedback Type';
    editFeedbackTypeData.typeDescr = this.selectedForm.typeDescr;

    const optionDialogRef: MatDialogRef<FeedbackTypeDialogComponent, string> =
      this.feedbackTypeDialog.open<
        FeedbackTypeDialogComponent,
        FeedbackTypeDialogData
      >(FeedbackTypeDialogComponent, {
        width: '500px',
        height: '230px',
        data: editFeedbackTypeData,
      });

    optionDialogRef.afterClosed().subscribe(data => {
      if (data) {
        const updatedFeedback = { ...this.selectedForm, typeDescr: data };

        this.questionConfigService
          .updateFeedbackType(updatedFeedback)
          .subscribe(
            (feedbackType: FeedbackType) => {
              this.formTypes = this.formTypes.map(form => {
                if (form.feedbackTypeId === feedbackType.feedbackTypeId) {
                  return feedbackType;
                } else {
                  return form;
                }
              });
              this.selectedForm =
                this.formTypes.find(
                  form => form.feedbackTypeId === feedbackType.feedbackTypeId
                ) ?? this.selectedForm;
              this.snackBarService.message('Feedback Type Saved');
            },
            (error: string) => {
              this.snackBarService.error(error);
            }
          );
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    const updatedQuestionGroups = [...this.questionGroups];
    const updatedFormGroups = [...this.activeFormGroups];

    moveItemInArray(
      updatedQuestionGroups,
      this.previousIndex,
      event.currentIndex
    );
    moveItemInArray(updatedFormGroups, this.previousIndex, event.currentIndex);

    updatedQuestionGroups.forEach((question, index) => {
      question.sortOrder = index;
    });

    this.activeFormGroups = updatedFormGroups;
    this.questionGroups = updatedQuestionGroups;
    this.orderChanged = true;
  }

  start(event: CdkDragStart<string[]>, toIndex: number) {
    this.previousIndex = toIndex;
  }

  get isSaveDisabled(): boolean {
    return (
      this.selectedForm === null ||
      (this.activeFormGroups.every(formGroup => formGroup.pristine) &&
        !this.orderChanged)
    );
  }

  get hasUnsavedChanges(): boolean {
    return (
      !!this.selectedForm &&
      (this.activeFormGroups.some(formGroup => !formGroup.pristine) ||
        this.orderChanged)
    );
  }

  get hasNoActive(): boolean {
    return (
      this.questionGroups.length > 0 &&
      this.activeFormGroups.every(formGroup => !formGroup.value.activeControl)
    );
  }

  save() {
    if (this.questionGroups.length > 0) {
      this.isLoadingQuestionGroups = true;

      this.questionGroups.map((questionGroup, index) => {
        questionGroup.isActive =
          this.activeFormGroups[index].value.activeControl;
        return questionGroup;
      });

      this.questionGroups.forEach(questionGroup => {
        this.questionConfigService.updateQuestionGroup(questionGroup).subscribe(
          () => {
            this.activeFormGroups.forEach(formGroup => {
              formGroup.markAsPristine();
            });
            this.orderChanged = false;
            this.isLoadingQuestionGroups = false;
          },
          (error: string) => {
            this.snackBarService.error(error);
            this.orderChanged = false;
            this.isLoadingQuestionGroups = false;
          }
        );
      });
    }
  }
}
