import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SavedAssessment } from 'src/app/core/services/assessment.service';

@Component({
  selector: 'app-feedback-list',
  templateUrl: './feedback-list.component.html',
  styleUrls: ['./feedback-list.component.scss'],
})
export class FeedbackListComponent implements OnInit, OnChanges {
  @ViewChild(MatSort, { static: true })
  sort!: MatSort;

  @Input()
  canReview!: boolean;

  @Input() set feedbacks(feedbck: SavedAssessment[]) {
    this.dataSource = new MatTableDataSource<SavedAssessment>(feedbck);
    if (feedbck) {
      this.isLoading = false;
    } else {
      this.dataSource = new MatTableDataSource<SavedAssessment>();
    }
  }

  isLoading = true;
  selected = [];
  dataSource!: MatTableDataSource<SavedAssessment>;

  resultColumnHeaders: string[] = [
    'employeeName',
    'submittedByName',
    'submissionType',
    'requestedDate',
    'submittedDate',
    'currentStatus',
    'careerMentorName',
    'clientName',
  ];

  constructor(private router: Router) {}

  ngOnInit() {
    return;
  }

  ngOnChanges() {
    this.dataSource.sort = this.sort;
  }

  onSelect(selected: SavedAssessment) {
    const feedbackTypeId = selected.feedbackTypeId
      ? selected.feedbackTypeId
      : 0;
    const routeToNavigate = this.canReview ? 'reviewFeedback' : 'viewFeedback';
    this.router.navigate([routeToNavigate, selected.id, feedbackTypeId]);
  }
}
