import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RequestRemoteWorkWorkflowRoutingModule } from './request-remote-work-workflow-routing.module';
import { RequestRemoteWorkWorkflowComponent } from './request-remote-work-workflow.component';
import { RequestRemoteWorkWorkflowFormComponent } from './request-remote-work-workflow-form/request-remote-work-workflow-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';

@NgModule({
  declarations: [
    RequestRemoteWorkWorkflowComponent,

    RequestRemoteWorkWorkflowFormComponent,
  ],
  imports: [
    CommonModule,
    RequestRemoteWorkWorkflowRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    AngularMaterialModule,

    FormsModule,
    ReactiveFormsModule,
  ],
})
export class RequestRemoteWorkWorkflowModule {}
