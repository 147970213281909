import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LayoutService } from 'src/@vex/services/layout.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Client } from 'src/app/core/services/common.service';
import {
  Question,
  QuestionOption,
} from 'src/app/core/services/feedback.service';
import { Employee } from 'src/app/core/services/onliner.service';
import {
  FbFeedbacksSelfAssessment,
  FbSelfAssessment,
  FbSelfAssessmentAnswer,
  FbSelfAssessmentFeedback,
  FbSelfAssessmentSchedule,
  FocalReviewBaseCamp,
  FocalReviewDetails,
  FocalReviewRevenue,
  FocalSickTime,
  FocalUtilization,
  FocalWorkHistory,
  OnlinerGoals,
  PDBudgetSummary,
  PDDeductionSummary,
  SelfAssessmentService,
} from 'src/app/core/services/self-assessment.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import {
  FeedbackStatusEnum,
  FeedbackTypeEnum,
} from 'src/app/shared/enums/enums.model';
import * as formValidators from 'src/app/shared/validators/form-validator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-self-assessment',
  templateUrl: './self-assessment.component.html',
  styleUrls: ['./self-assessment.component.scss'],
})
export class SelfAssessmentComponent implements OnInit, OnChanges {
  @ViewChild('autosize')
  autosize!: CdkTextareaAutosize;
  @Input()
  reportId!: number | null;
  @Input()
  reportForm!: UntypedFormGroup;
  @Input() selectedFormId: number = FeedbackTypeEnum.SelfAssessment;
  @Input()
  selectedTypeLoaded!: boolean;
  @Input() set questionGroupIDs(qGroupIds: number[]) {
    if (qGroupIds !== null) {
      if (qGroupIds.length === 1) {
        if (
          !this._questionGroupIds ||
          this._questionGroupIds[0] === qGroupIds[0]
        ) {
          this.reloadRequired = false;
        } else {
          this.reloadRequired = true;
        }
      } else if (qGroupIds.length > 1) {
        if (!this._questionGroupIds) {
          this.reloadRequired = false;
        } else {
          this.reloadRequired = !this._questionGroupIds.every(qg =>
            qGroupIds.every(question => question === qg)
          );
        }
      }

      this._questionGroupIds = [...qGroupIds];
    }
  }
  @Input()
  questionOptions!: QuestionOption[];
  @Input()
  questionOptionGroupIDs!: number[];
  @Input()
  questions!: Question[];
  @Input()
  role!: string;

  @Output() draftCheck: EventEmitter<boolean> = new EventEmitter<boolean>();
  isCollapsed = true;
  onliners!: Employee[];
  clients!: Client[];
  onlinerCM = '';
  onlinerCMUserId = '';
  tooltipItems!: string[];
  toolTip!: string;
  onlinerAssigned = false;
  clientAssigned = false;
  dismissed = false;
  questionsForThisForm: Question[] = [];
  mainQuestionsForThisForm!: Question[];
  subQuestionsForThisForm!: Question[];
  isDraft = false;
  reloadRequired = false;
  report!: FbSelfAssessment | null;
  reports!: FbSelfAssessment | null;
  formattedFirstDay!: string;
  formattedLastDay!: string;
  budgetYearCurrent!: string;
  budgetYearPrevious!: string;

  // feedback card variables
  feedbacks: FbSelfAssessmentFeedback[] = [];
  submitterName!: string;
  feedbackType: string[] = [];
  feedbackQuestions: string[] = [];
  feedbackAnswers: string[] = [];
  feedbackId: number[] = [];
  feedbackQuestionType: string[] = [];
  uniqueFeedbacks: FbFeedbacksSelfAssessment[] = [];
  feedbackTypeDescr!: string;

  defaultColor = 'rgb(156, 30, 54)';
  disabledColor = 'rgba(0,0,0,.38)';

  unsolicitedFormId: number = FeedbackTypeEnum.AdhocFeedback;
  clientreportFormId: number = FeedbackTypeEnum.ClientFeedback;
  ciaFormId: number = FeedbackTypeEnum.CultureInAction;
  autoSaveSubscription!: Subscription;

  filteredOnliners!: Observable<Employee[]>;

  isLoading!: boolean;
  isSaving = false;
  isSubmitting = false;
  isFeedbackAdmin!: boolean;
  feedbackTypeIndicator!: number;
  isNew!: boolean;
  isFeedbackRequest!: boolean;
  submittedForThisYear = false;
  minDate: Date = new Date();
  vacationSelected = false;

  onlinerGoals!: OnlinerGoals[];
  hasOnlinerGoals = false;
  resumeUpdateDate!: string;
  countLateTimeSheetSubmission!: string;
  countWeeklyHealthSubmission!: string;
  fbRequestCountProvidedForOnliners!: number;
  fbRequestCountRequestedByOnliners!: number;
  fbRequestCountRequestedFromClients!: number;
  fbRequestCountRequestedFromOnliners!: number;
  PDBudgetSummaryCurrent!: PDBudgetSummary;
  PDBudgetSummaryPrevious!: PDBudgetSummary;
  PDDeductionSummaryUnitsCurrent: PDDeductionSummary[] = [];
  PDDeductionSummaryUnitsPrevious: PDDeductionSummary[] = [];
  PDDeductionSummaryHoursCurrent: PDDeductionSummary[] = [];
  PDDeductionSummaryHoursPrevious: PDDeductionSummary[] = [];
  focalReviewDetails!: FocalReviewDetails;
  focalReviewBaseCamp!: FocalReviewBaseCamp;
  focalReviewRevenue!: FocalReviewRevenue;
  focalSickTime!: FocalSickTime;
  focalUtilization: FocalUtilization[] = [];
  focalWorkHistory: FocalWorkHistory[] = [];
  previousHours!: number;
  currentHours!: number;
  previousBillableHours!: number;
  currentBillableHours!: number;
  previousSickHours!: number;
  currentSickHours!: number;
  deliveryDisclaimerMonthYear!: string;
  feedbackCount = 0;
  submitterNames: string[] = [];
  latestScheduled!: FbSelfAssessmentSchedule;
  currentFiscalYear!: number;
  previousFiscalYear!: number;

  errorMatcher = new formValidators.CompControlErrorStateMatcher();

  questionEnumToGroupTitle = new Map<string, string>([
    ['CoreValueSA', 'CORE VALUES'],
    ['CorpCitizenshipSA', 'CORPORATE CITIZENSHIP'],
    ['GrowthDevSA', 'GROWTH & DEVELOPMENT'],
    ['DeliverySA', 'Delivery'],
  ]);

  theCheckbox = false;
  private _questionGroupIds!: number[];

  constructor(
    private selfAssessmentService: SelfAssessmentService,
    private snackBarService: SnackBarService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private layoutService: LayoutService
  ) {}

  ngOnInit() {
    if (this._questionGroupIds !== null) {
      const groupedQuestions = this.questions?.reduce(
        (acc: { [key: string]: Question }, question: Question) => {
          const { questionEnum, groupId } = question;
          if (!acc[questionEnum] && this._questionGroupIds.includes(groupId)) {
            acc[questionEnum] = question;
          }
          return acc;
        },
        {} as { [key: string]: Question }
      );
      // Any new question enum has to be added to the questionEnumToGroupTitle map.
      const questionEnums = Array.from(this.questionEnumToGroupTitle.keys());
      this.mainQuestionsForThisForm = groupedQuestions
        ? questionEnums
            .map(questionEnum => groupedQuestions[questionEnum])
            .filter(question => question !== null)
        : [];
      this.subQuestionsForThisForm = this.questions?.filter(
        question => !this.mainQuestionsForThisForm.includes(question)
      );
      this.questionsForThisForm = this.questions?.filter(
        q => this._questionGroupIds.includes(q.groupId) && !q.isOptional
      );
    }

    this.setupPage();
    this.layoutService.sidenavCollapsed$.subscribe(collapsed => {
      this.isCollapsed = collapsed;
    });
  }

  ngOnChanges() {
    if (this.reloadRequired) {
      if (this._questionGroupIds !== null) {
        this.questionsForThisForm = [];
        this._questionGroupIds.forEach(qgId => {
          const questionsToFill = this.questions.filter(
            q => q.groupId === qgId
          );
          this.questionsForThisForm = [
            ...this.questionsForThisForm,
            ...questionsToFill,
          ];
        });
      }

      if (!this.autoSaveSubscription) {
        this.setupAutoSave();
      }

      this.setupPage();
    }
  }

  isDraftCheck() {
    this.draftCheck.emit(this.isDraft);
  }
  get onlinerFilterControl() {
    return this.reportForm.controls['onliner'];
  }

  get onlinerAndClientValidation() {
    return this.reportForm.controls['onliner'].valid;
  }

  get isSaved() {
    return this.reports && this.reports.id > 0;
  }
  showError = (controlName: string) => {
    const control = this.reportForm.controls[controlName];

    if (control && control.hasError('required') && control.touched) {
      return true;
    }
    return false;
  };

  closeAlert() {
    this.dismissed = true;
  }

  checkUtilizationAvailability(yearString: string) {
    let utilization: FocalUtilization[] = [];
    if (yearString === 'previous year') {
      utilization = this.focalUtilization.filter(
        u => u.fiscalYear === this.previousFiscalYear
      );
    } else if (yearString === 'current year') {
      utilization = this.focalUtilization.filter(
        u => u.fiscalYear === this.currentFiscalYear
      );
    }
    return utilization.length === 0 ? false : true;
  }

  getUtilization(yearString: string): number {
    let utilization: FocalUtilization[] = [];
    if (yearString === 'previous year') {
      utilization = this.focalUtilization.filter(
        u => u.fiscalYear === this.previousFiscalYear
      );
    } else if (yearString === 'current year') {
      utilization = this.focalUtilization.filter(
        u => u.fiscalYear === this.currentFiscalYear
      );
    }
    return utilization[0].utilization;
  }

  sumOfDeductionValues(deductions: PDDeductionSummary[]): number {
    let sum = 0;
    deductions.forEach(deduction => {
      sum = sum + deduction.deductionValue;
    });
    return sum;
  }

  goalStatus(status: number) {
    switch (status) {
      case 0:
        return 'Active';

      case 1:
        return 'On Hold';

      case 2:
        return 'Achieved';

      case 3:
        return 'Cancelled';

      default:
        return undefined;
    }
  }

  dateFormating(date: Date) {
    return this.datepipe.transform(date, 'MM/dd/yyyy');
  }

  fiscalYearRange(): string {
    this.formattedLastDay =
      this.datepipe.transform(this.latestScheduled.endDate, 'MMMM dd, yyyy') ||
      '';
    this.formattedFirstDay =
      this.datepipe.transform(
        this.latestScheduled.startDate,
        'MMMM dd, yyyy'
      ) || '';
    return '(' + this.formattedFirstDay + ' to ' + this.formattedLastDay + ')';
  }

  htmlDecode(input: string) {
    const txt = document.createElement('textarea');
    txt.innerHTML = input;
    return txt.value;
  }

  displayQuestion(question: Question): string {
    return question.question;
  }

  getID(questionID: number): string {
    const idString = '' + questionID + '';
    return idString;
  }

  getRadioTextQuestionId(questionId: number): string {
    const idString = '' + questionId + '_text';
    return idString;
  }

  radioOptionValueDisplay(option?: QuestionOption): number | undefined {
    const value = option?.score;
    return value;
  }

  filterSortThisQuestionOption(question: Question): QuestionOption[] {
    return this.questionOptions
      .filter(qo => qo.groupId === question.optionGroupId && qo.id > 1000)
      .sort((a, b) => a.score - b.score);
  }

  validQuestionOptions(question: Question) {
    return this.questionOptions.filter(
      qo => qo.groupId === question.optionGroupId
    );
  }

  optionDisplay = (option?: QuestionOption): string | undefined => {
    const text = option?.value;
    return text;
  };

  radioTextChange(optionId: number) {
    const option = this.questionOptions.find(qo => qo.id === optionId);
    if (option !== null && option !== undefined) {
      const question = this.questions.find(
        q => q.optionGroupId === option.groupId
      );
      if (option.score === -1) {
        this.reportForm.controls[`${question?.id}_text`].setValidators([
          Validators.required,
          Validators.minLength(1),
        ]);
        if (question) {
          this.commentRequired(question.id, optionId);
        }
      } else {
        this.reportForm.controls[`${question?.id}_text`].clearValidators();
      }

      this.reportForm.controls[`${question?.id}_text`].updateValueAndValidity();
    }
  }

  resetReportForm() {
    this.reportForm.reset();
    this.report = null;
    this.reportId = null;
    if (this.selectedFormId !== FeedbackTypeEnum.CultureInAction) {
      // not cia-feedback
      this.isFeedbackRequest = false;
      this.isDraft = true;
    }
  }

  updateReportForm() {
    // default onliner to logged in user
    this.reportForm.controls['onliner'].setValue(
      this.authService.getUserId().toLowerCase()
    );
    this.onlinerAssigned = true;
    const txt = document.createElement('textarea');
    let length = this.questionsForThisForm.length;
    let q: Question | null = null;
    let datePlusTimezoneOffset = new Date();

    if (this.isDraft) {
      // this.noEventOnlinerSelected(this.feedback.employee);
      // this.onlinerCMUserId = this.feedback.employee.careerManager.toLowerCase();
      this.isLoading = false;
    }

    // default client to Online
    // this.reportForm.controls['client'].setValue(this.getClientFromDropdown(this.feedback.client.clientId));
    this.clientAssigned = true;

    if (!this.submittedForThisYear) {
      // fill in answers to the questions, if there are any...
      for (let i = 0; i < length; i++) {
        q = this.questionsForThisForm[i];

        if (this.reports && this.reports.answers) {
          this.reports.answers.forEach(a => {
            if (q && q.id === a.questionId) {
              if (q.type.toString() === 'Text') {
                txt.innerHTML = a.stringValue;
                this.reportForm.controls[`${q.id}`].setValue(txt.value);
              } else if (q.type.toString() === 'Scale') {
                this.reportForm.controls[`${q.id}`].setValue(a.intValue);
              } else if (q.type.toString() === 'Date_Input') {
                datePlusTimezoneOffset = new Date(a.stringValue);
                datePlusTimezoneOffset.setMinutes(
                  datePlusTimezoneOffset.getMinutes() +
                    datePlusTimezoneOffset.getTimezoneOffset()
                );
                this.reportForm.controls[`${q.id}`].setValue(
                  datePlusTimezoneOffset.toISOString()
                );
              } else {
                if (q.type.toString() === 'Radio') {
                  this.reportForm.controls[`${q.id}`].setValue(a.optionId);
                } else if (this.reportForm.controls[`${q.id}`].value !== '') {
                  this.reportForm.controls[`${q.id}`].setValue(a.optionId);
                }

                if (q.type.toString() === 'Radio_Text') {
                  this.reportForm.controls[`${q.id}`].setValue(a.optionId);
                  this.reportForm.controls[`${q.id}_text`].setValue(
                    a.stringValue
                  );
                  if (a.optionId) {
                    this.radioTextChange(a.optionId);
                  }
                }
              }
            }
          });
        }
        length = this.questionsForThisForm.length;
      }
    }

    this.feedbackTypeIndicator = this.selectedFormId;
    this.reportForm.updateValueAndValidity();
    // this.reportForm.markAsDirty();
    this.changeDetectorRef.detectChanges();
  }

  saveFeedback() {
    this.isSaving = true;
    this.updateReportFromForm();
    let message = '';

    if (this.reports) {
      this.selfAssessmentService.saveReport(this.reports).subscribe(
        savedReport => {
          this.report = savedReport;

          message = 'Your Self Assessment has been saved.';

          this.snackBarService.message(message);
          this.isSaving = false;
          this.isFeedbackRequest = true;
          this.isDraft = true;
          this.reportForm.markAsPristine();
          this.setupPage();
        },
        error => {
          this.isSaving = false;
          this.snackBarService.error(error);
        }
      );
    }
  }

  enableFeedbackFields(): void {
    this.questionsForThisForm.forEach(q => {
      this.reportForm.controls[`${q.id}`].enable();
    });
  }

  displayPreSubmissionConfirmation() {
    const confirmDialog = new ConfirmDialog();

    confirmDialog.message =
      'Are you sure you want to submit your Self Assessment for this year?';
    confirmDialog.title = 'Submission confirmation';
    confirmDialog.okButtonTitle = 'Yes';
    confirmDialog.cancelButtonTitle = 'No';

    const dialogRef: MatDialogRef<ConfirmationDialogComponent, string> =
      this.dialog.open<ConfirmationDialogComponent, ConfirmDialog>(
        ConfirmationDialogComponent,
        {
          width: '400px',
          data: confirmDialog,
          disableClose: true,
        }
      );

    dialogRef.afterClosed().subscribe(choice => {
      if (choice === 'ok') {
        this.submitFeedback();
        // this.reportForm.markAsPristine();
      }
    });
  }

  submitFeedback() {
    this.isSubmitting = true;
    this.reportForm.disable();
    this.updateReportFromForm();
    let message = '';

    if (this.reports) {
      this.selfAssessmentService.submitReport(this.reports).subscribe(
        () => {
          message = 'Your Self Assessment form has been submitted.';

          this.snackBarService.message(message);
          this.isSubmitting = false;
          this.submittedForThisYear = true;
          this.isSaving = false;
          // this.enableFeedbackFields();
          // this.postSubmissionProcess();
          // this.resetReportForm();
          // this.onlinerAssigned = false;
          // this.clientAssigned = false;
          // this.reportForm.disable();
          this.reportForm.markAsPristine();

          // if the user has already submitted a form, disable the form and display a message indicating that they've alredy submitted a form
          this.navigateToFeedback();
        },
        error => {
          this.isSubmitting = false;
          this.enableFeedbackFields();
          this.snackBarService.error(error);
        }
      );
    }
  }

  optionsHaveScore(question: Question) {
    const qOptions = this.validQuestionOptions(question);
    let haveScore = false;

    qOptions.forEach(qo => {
      haveScore = haveScore || qo.score !== null;
    });

    return haveScore;
  }

  navigateToFeedback() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/home']));
  }

  isSaveButtonDisabled(): boolean {
    const isDisabled =
      this.isSaving ||
      this.isSubmitting ||
      this.reportForm.get('onliner')?.errors !== null;
    return isDisabled;
  }

  getQuestionGroupTitle(questionEnum: string) {
    return this.questionEnumToGroupTitle.get(questionEnum);
  }

  get hasQuestions(): boolean {
    return this.questionsForThisForm.length > 0;
  }

  private GetFocalReviewDetails() {
    this.selfAssessmentService
      .GetFocalReviewDetails(
        new Date().getFullYear(),
        false,
        this.authService.getUserId()
      )
      .subscribe(data => {
        this.focalReviewDetails = data;
        if (data) {
          this.focalReviewBaseCamp = data.focalReviewBaseCamp;
          this.focalReviewRevenue = data.focalReviewRevenue;
          this.focalUtilization = data.focalUtilizations.slice(0, 2);
          this.focalSickTime = data.focalSickTime;
          this.focalWorkHistory = data.focalWorkHistory;
          this.previousHours = this.focalReviewBaseCamp.previousHours;
          this.currentHours = this.focalReviewBaseCamp.currentHours;
          this.previousBillableHours =
            this.focalReviewRevenue.previousBillableHours;
          this.currentBillableHours =
            this.focalReviewRevenue.currentBillableHours;
          this.previousSickHours = this.focalSickTime.previousSickHours;
          this.currentSickHours = this.focalSickTime.currentSickHours;
          if (this.focalUtilization.length > 0) {
            this.deliveryDisclaimerMonthYear =
              this.focalUtilization[0].monthYear === ''
                ? 'NA'
                : this.focalUtilization[0].monthYear;
          }
        }
      });
  }

  private GetPDHistory() {
    this.selfAssessmentService
      .GetPDHistory(
        new Date().getFullYear(),
        false,
        false,
        this.authService.getUserId()
      )
      .subscribe(data => {
        this.PDBudgetSummaryCurrent = data;
        if (
          this.PDBudgetSummaryCurrent !== null &&
          this.PDBudgetSummaryCurrent.pdDeductionSummary !== null
        ) {
          this.PDDeductionSummaryHoursCurrent =
            this.PDBudgetSummaryCurrent.pdDeductionSummary.filter(
              deduction => deduction.deductionTypeId === 'H'
            );
          this.budgetYearCurrent = this.PDBudgetSummaryCurrent.budgetYear;

          this.PDDeductionSummaryUnitsCurrent =
            this.PDBudgetSummaryCurrent.pdDeductionSummary.filter(
              deduction => deduction.deductionTypeId === 'U'
            );
        }
      });

    this.selfAssessmentService
      .GetPDHistory(
        new Date().getFullYear(),
        false,
        true,
        this.authService.getUserId()
      )
      .subscribe(data => {
        this.PDBudgetSummaryPrevious = data;
        if (
          this.PDBudgetSummaryPrevious !== null &&
          this.PDBudgetSummaryPrevious.pdDeductionSummary !== null
        ) {
          this.PDDeductionSummaryHoursPrevious =
            this.PDBudgetSummaryPrevious.pdDeductionSummary.filter(
              deduction => deduction.deductionTypeId === 'H'
            );
          this.budgetYearPrevious = this.PDBudgetSummaryPrevious.budgetYear;

          this.PDDeductionSummaryUnitsPrevious =
            this.PDBudgetSummaryPrevious.pdDeductionSummary.filter(
              deduction => deduction.deductionTypeId === 'U'
            );
        }
      });
  }

  private GetFeedbackData() {
    this.selfAssessmentService
      .GetFeedbackData(
        new Date().getFullYear(),
        false,
        this.authService.getUserId()
      )
      .subscribe(data => {
        if (data && data.length !== 0) {
          this.feedbacks = data;
          let index = 0;
          let currentAssessment: number;
          if (this.feedbacks.length !== 0) {
            currentAssessment = this.feedbacks[0].id; // the first assessments id
          }
          this.feedbacks.forEach(a => {
            if (a.id !== currentAssessment) {
              // move to the next assessment (it's not reading the last assessment)
              const assessment = new FbFeedbacksSelfAssessment();
              assessment.answers = this.feedbackAnswers;
              assessment.questions = this.feedbackQuestions;
              assessment.submitterName = this.submitterName;
              assessment.questionType = this.feedbackType;
              assessment.typeDescr = this.feedbackTypeDescr;
              this.uniqueFeedbacks[index] = assessment;

              index++; // next index/corresponding assessment
              currentAssessment = a.id;

              // reset the values and go again
              this.feedbackAnswers = [];
              this.feedbackQuestions = [];
              this.feedbackType = [];
              this.submitterName = '';
            }
            this.feedbackType.push(a.type);
            this.submitterName = a.submitterId;
            this.feedbackTypeDescr = a.typeDescr;
            this.feedbackQuestions.push(a.question);
            this.feedbackId.push(a.id);
            this.feedbackAnswers.push(this.htmlDecode(a.answer));
          });
          // the last assessment
          const lastAssessment = new FbFeedbacksSelfAssessment();
          lastAssessment.answers = this.feedbackAnswers;
          lastAssessment.questions = this.feedbackQuestions;
          lastAssessment.questionType = this.feedbackType;
          lastAssessment.typeDescr = this.feedbackTypeDescr;
          lastAssessment.submitterName = this.submitterName;
          this.uniqueFeedbacks[index] = lastAssessment;
        }
      });
  }

  private GetCountOfFeedbacks() {
    this.selfAssessmentService
      .GetCountOfFeedbacks(
        new Date().getFullYear(),
        false,
        this.authService.getUserId()
      )
      .subscribe(data => {
        this.fbRequestCountProvidedForOnliners = data[0];
        this.fbRequestCountRequestedByOnliners = data[1];
        this.fbRequestCountRequestedFromClients = data[2];
        this.fbRequestCountRequestedFromOnliners = data[3];
      });
  }

  private setupPage() {
    this.feedbackTypeIndicator = this.selectedFormId;
    this.isLoading = true;
    this.getLatestScheduled();
    this.selfAssessmentService.CheckUserAssessment().subscribe(
      data => {
        this.reports = data;
        if (
          this.reports &&
          this.reports.scheduleId === this.latestScheduled.id
        ) {
          // if the latest form we retrieved is for the current fiscal year, then open the forms saved data or if it's submitted then show the dialog and don't open
          this.getReport();
        } else {
          // if there was no previous form (null) or the latest form was for a different fiscal cycle.
          this.reports = null; // if there was a previous form from a different fiscal year, this ensures that we are working on a new form not the one that was retrieved
          this.isLoading = false;
          this.isDraftCheck();
        }
        this.setupAutoSave();
      },
      error => {
        this.isLoading = false;
        this.snackBarService.error(error);
      }
    );

    this.GetOnlinerGoals();
    this.getCorporateCitizenship();
    this.GetFeedbackData();
    this.GetCountOfFeedbacks();
    this.GetPDHistory();
    this.GetFocalReviewDetails();
    this.isDraftCheck();
    this.reportForm?.markAsPristine();
  }
  private getCorporateCitizenship() {
    this.selfAssessmentService
      .getCorporateCitizenship(
        new Date().getFullYear(),
        false,
        this.authService.getUserId()
      )
      .subscribe(data => {
        this.resumeUpdateDate =
          data[2] === 'January 01, 0001' ? 'Not Available' : data[2];
        this.countLateTimeSheetSubmission = data[0];
        this.countWeeklyHealthSubmission = data[1];
      });
  }

  private GetOnlinerGoals() {
    this.selfAssessmentService
      .GetOnlinerGoals(
        new Date().getFullYear(),
        false,
        this.authService.getUserId()
      )
      .subscribe(data => {
        this.onlinerGoals = data;
        this.hasOnlinerGoals = true;
      });
  }

  private getLatestScheduled() {
    this.selfAssessmentService
      .GetLatestSelfAssessmentSchedule()
      .subscribe(data => {
        this.latestScheduled = data;
        this.currentFiscalYear = this.latestScheduled.fiscalYear;
        this.previousFiscalYear = this.latestScheduled.fiscalYear - 1;
      });
  }

  private commentRequired(questionId: number, optionId: number): boolean {
    const option = this.questionOptions.find(qo => qo.id === optionId);
    let required = false;

    if (option !== null && option !== undefined) {
      if (option.score === -1) {
        this.reportForm.controls[`${questionId}_text`].markAsTouched();
        required = true;
      }
    }

    return required;
  }

  private getReport() {
    if (
      this.reports?.log &&
      this.reports.log.findIndex(l => l.statusId === FeedbackStatusEnum.Draft) >
        -1
    ) {
      this.isDraft = true;
    } else {
      this.isDraft = false;
    }

    if (
      this.reports?.log &&
      this.reports.log.findIndex(
        l => l.statusId === FeedbackStatusEnum.Posted
      ) > -1
    ) {
      this.submittedForThisYear = true;
      this.isDraft = false;
      // this.showAlreadySubmittedDialog();
      if (this.reports?.id) {
        this.reports.id = -1;
      }
    } else {
      this.submittedForThisYear = false;
    }

    if (!this.submittedForThisYear) {
      this.isDraftCheck();
      this.updateReportForm();
    }

    this.isLoading = false;
  }

  private showAlreadySubmittedDialog() {
    const confirmDialog = new ConfirmDialog();
    confirmDialog.title = 'Form Submitted For This Year';
    confirmDialog.message =
      '<p> You have already submitted a form for this year.</p>';
    confirmDialog.okButtonTitle = 'Continue';
    this.dialog.open<ConfirmationDialogComponent, ConfirmDialog>(
      ConfirmationDialogComponent,
      {
        width: '600px',
        data: confirmDialog,
        disableClose: true,
      }
    );
  }

  private setupAutoSave() {
    this.autoSaveSubscription = this.reportForm.valueChanges
      .pipe(debounceTime(environment.autoSaveInterval))
      .subscribe(() => {
        if (this.onlinerAndClientValidation && this.reportForm.dirty) {
          this.saveFeedback();
        }
      });
  }

  private updateReportFromForm() {
    let answers: FbSelfAssessmentAnswer[] = [];

    if (!this.reports) {
      this.reports = new FbSelfAssessment();
      this.reports.id = -1;
      this.reports.fiscalYear = this.currentFiscalYear;
    } else {
      if (this.reports.answers) {
        answers = [...this.reports.answers];
        this.reports.answers = [];
      }
    }

    this.questionsForThisForm.forEach(q => {
      if (this.reports && !this.reports.answers) {
        this.reports.answers = [];
      }

      const options = this.questionOptions.filter(
        qo => qo.groupId === q.optionGroupId
      );

      let stringValue: string | null = '';
      let intValue: number | null = 0;
      let optionID: number | null = 0;

      if (q.type.toString() === 'Scale') {
        intValue = this.reportForm.controls[q.id].value;
        optionID =
          this.questionOptions.find(
            qo =>
              qo.score === intValue &&
              qo.groupId ===
                this.questionOptionGroupIDs.find(
                  qoid => qoid === q.optionGroupId
                )
          )?.id || null; // this is slightly better
      } else {
        if (
          q.type.toString() === 'Text' ||
          q.type.toString() === 'Date_Input'
        ) {
          stringValue = this.reportForm.value[q.id];
          intValue = null;
          optionID = null;
        } else {
          if (q.type.toString() === 'Radio_Text') {
            stringValue = this.reportForm.value[`${q.id}_text`];
          } else {
            stringValue = !this.reportForm.value[q.id]
              ? null
              : options.find(sv => sv.id === this.reportForm.value[q.id])
                  ?.value || null;
          }
          intValue = !this.reportForm.value[q.id]
            ? null
            : options.find(sv => sv.id === this.reportForm.value[q.id])
                ?.score || null;
          optionID = !this.reportForm.value[q.id]
            ? null
            : options.find(sv => sv.id === this.reportForm.value[q.id])?.id ||
              null;
        }
      }

      const index = answers.findIndex(a => a.questionId === q.id);

      if (index > -1) {
        this.reports?.answers.push({
          id: answers[index].id,
          questionId: answers[index].questionId,
          question: answers[index].question,
          stringValue: stringValue || '',
          intValue:
            q.type.toString() === 'Text' ? answers[index].intValue : intValue,
          optionId: optionID,
          option: answers[index].option,
        });
      } else {
        this.reports?.answers.push({
          id: -1,
          questionId: q.id,
          question: q,
          stringValue: stringValue || '',
          intValue: q.type.toString() === 'Text' ? null : intValue,
          optionId: q.type.toString() === 'Text' ? null : optionID,
          option: null,
        });
      }
    });
  }
}
