<form *ngIf="!isLoading && feedbackForm" [formGroup]="feedbackForm" class="mb-5 feedback-form" novalidate>
  <div *ngIf="isSaved && !feedbackId && !dismissed" class="row form-group p-3">
    <mat-card appearance="outlined" class="bg-success text-white">
      <mat-card-content class="flex">
        <button type="button" class="close" aria-label="Close"
          (click)="closeAlert()">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="ml-4">
          <span><i class="far fa-check-circle"></i></span> You've successfully
          saved a feedback draft. <a (click)="navigateToFeedback()" (keyup.enter)="navigateToFeedback()" [tabindex]="0" class="alert-link"> Create another?</a>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

      <div class="row form-group col-md-12 col-sm-6 col-xs-12" [ngClass]="{'has-error': feedbackForm.get('onliner')?.errors && feedbackForm.get('onliner')?.touched}">
        <div class="input-pad">
          <div *ngIf="!(isSaved || isFeedbackRequest); else displayReadOnly">
            <mat-form-field appearance="outline" class="onliner-width" [ngClass]="{'editable': !(isSaved || isFeedbackRequest)}">
              <mat-label class="required-label">Choose Onliner</mat-label>
              <input class="onliner-width"
                matInput placeholder="Choose Onliner"
                aria-label="Onliner" name="onliner"
                [matAutocomplete]="onlinerauto" formControlName="onliner"
                [errorStateMatcher]="errorMatcher"
                [style.color]="isDraft ? disabledColor: defaultColor"/>
              <mat-autocomplete #onlinerauto="matAutocomplete" [displayWith]="onlinerDisplay" (optionSelected)="onlinerSelected($event)" >
                <mat-option class="options" *ngFor="let option of filteredOnliners | async" [value]="option">
                  {{ onlinerDisplay(option) }}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="showError('onliner')">Please enter the name of the Onliner</mat-error>
            </mat-form-field>
          </div>
          <ng-template #displayReadOnly>
            <mat-form-field appearance="outline"  class="onliner-width" [ngClass]="{'editable': !(isSaved || isFeedbackRequest)}">
              <mat-label class="required-label">Choose Onliner</mat-label>
              <input class="onliner-width" matInput type="text" placeholder="Choose Onliner" aria-label="Onliner" [disabled]="true" [value]=getOnliner() />
            </mat-form-field>
          </ng-template>
        </div>
        <div *ngIf="onlinerAssigned && !(feedbackForm.get('onliner')?.errors)">
          <mat-form-field appearance="outline" class="onliner-width" >
            <mat-label>Career Mentor</mat-label>
            <input class="onliner-width mat-test" matInput type="text" placeholder="Career Mentor" aria-label="Career Mentor" [disabled]="true" [value]=getOnlinerCM() />
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="selectedFormId === unsolicitedFormId || selectedFormId === thirdPartyId">
        <div class="row form-group col-md-12 col-sm-6 col-xs-12">
          <div class="mb-2">Choose "Other" if the client is not on the list.<br>Choose "Online Business Systems" if the Onliner is in a non-billable role.</div>
        </div>
        <div class="row form-group col-md-12 col-sm-6 col-xs-12">
          <mat-form-field *ngIf="!isFeedbackRequest; else displayReadOnlyClient" appearance="outline"  class="client-group client-width" [ngClass]="{'has-error': feedbackForm.get('client')?.errors && feedbackForm.get('client')?.touched, 'editable': !isFeedbackRequest}">
            <mat-label class="required-label">Choose Client</mat-label>
            <input class="client-width"
              matInput placeholder="Choose Client"
              aria-label="Client" name="client"
              [matAutocomplete]="clientauto" formControlName="client"
              [style.color]="isDraft ? disabledColor: defaultColor">
            <mat-autocomplete #clientauto="matAutocomplete" [displayWith]="clientDisplay" (optionSelected)="clientSelected($event)">
              <mat-option class="options" *ngFor="let option of filteredClients | async" [value]="option">
                {{ clientDisplay(option) }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="feedbackForm.get('client')?.hasError('required') && feedbackForm.get('client')?.touched">Please enter the name of the Client.</mat-error>
          </mat-form-field>
          <ng-template #displayReadOnlyClient>
            <mat-form-field appearance="outline"  class="client-group client-width" [ngClass]="{'has-error': feedbackForm.get('client')?.errors && feedbackForm.get('client')?.touched, 'editable': !isFeedbackRequest}">
              <mat-label class="required-label">Choose Client</mat-label>
              <input class="client-width" matInput type="text" placeholder="Choose Client" aria-label="Client" [disabled]="true" [value]=getClient() />
            </mat-form-field>
          </ng-template>
        </div>

        <div class="row form-group col-md-12 col-sm-6 col-xs-12" *ngIf="feedbackForm && feedbackForm.value.client  && feedbackForm.value.client.clientName === 'Other'">
          <mat-form-field appearance="outline" class="client-group client-width" [ngClass]="{'has-error': feedbackForm.get('otherClient')?.errors && feedbackForm.get('otherClient')?.touched, 'editable': true}">
            <mat-label>Other Client</mat-label>
            <div class="col-md-10 pl-0">
                <input matInput type="text" placeholder="Other Client" aria-label="Other Client" id="otherClient" formControlName="otherClient">
            </div>
            <mat-error *ngIf="feedbackForm.get('otherClient')?.hasError('required') && feedbackForm.get('otherClient')?.touched">Please enter the name of the Other Client.</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="selectedFormId !== ciaFormId">
        <div class="label-header reduce-label-spacing" *ngIf="(feedbackForm && feedbackForm.get('goals')?.value) && (onlinerAssigned && !(feedbackForm.get('onliner')?.errors))"> Goals </div>
        <mat-card *ngIf="feedbackForm && feedbackForm.get('goals')?.value && (onlinerAssigned && !(feedbackForm.get('onliner')?.errors))" id="goals" class="col-md-12 col-sm-10 col-xs-12">
          <mat-card-content>
            <mat-form-field appearance="outline" class="row form-group col-md-12 col-sm-6 col-xs-12 m-0 mt-2">
              <textarea matInput
                        [readonly]="true"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="5"
                        style="overflow:hidden"
                        type="text"
                        id="goals"
                        white-space="pre"
                        formControlName="goals"> </textarea>
            </mat-form-field>
          </mat-card-content>
        </mat-card>
      </div>

      <div *ngIf="selectedFormId === ciaFormId || selectedFormId === unsolicitedFormId || selectedFormId === thirdPartyId">
        <div *ngFor="let question of questionsForThisForm">
          <div class="mb-2 reduce-label-spacing" [ngClass]="{'required-label' : !question.isOptional }">{{ displayQuestion(question) }}</div>

          <div *ngIf="question.id === 43" class="onliner-width">
              <mat-card class="col-md-12 col-sm-10 col-xs-12">
                  <mat-card-content>
                    <div class="row form-group col-md-12 col-sm-10 col-xs-12" [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
                      <input
                      matInput
                      id="{{getID(question.id)}}"
                      formControlName="{{getID(question.id)}}"
                    />
                    </div>
                  </mat-card-content>
              </mat-card>
          </div>

          <div *ngIf="question.id !== 43">
            <mat-card class="col-md-12 col-sm-10 col-xs-12">
              <mat-card-content>
                <div *ngIf="question.type.toString() === 'Radio'">
                  <div class="row form-group col-md-12 col-sm-10 col-xs-12" [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
                    <mat-radio-group formControlName="{{question.id}}">
                      <mat-radio-button *ngFor="let option of validQuestionOptions(question)" [value]="option.id">
                        <span class="radio-rap">{{ optionDisplay(option) }}</span>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
                <div *ngIf="question.type.toString() === 'Scale'">
                  <div class="row form-group col-md-12 col-sm-10 col-xs-12" [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
                    <div class="row col-md-12 col-sm-10 col-xs-12">
                      <span >{{ getIntervalTextValue(question, 0) }}</span>
                      <span class="far-right-text" >{{ getIntervalTextValue(question, 1) }}</span>
                    </div>
                <mat-slider
                  color="secondary"
                  showTickMarks
                  [max]="getIntervalMax(question)"
                  [min]="getIntervalMin(question)"
                  [step]="getSliderTickInterval(question)"
                  >
                  <input
                    matSliderThumb
                    formControlName="{{question.id}}" />
                </mat-slider>
                  </div>
                </div>
                <div *ngIf="question.type.toString() === 'Select'">
                  <div class="row form-group col-md-12 col-sm-10 col-xs-12" [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
                    <mat-select
                      #selectInput
                      id="{{question.id}}"
                      formControlName="{{question.id}}"
                      (input)="onSearchChange(selectInput.value)">
                      <mat-option *ngFor="let option of validQuestionOptions(question)"
                          [value]="option.id">
                          {{option.value}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="feedbackForm.get(getID(question.id))?.errors">Please enter an answer</mat-error>
                  </div>
                </div>
                <div *ngIf="question.type.toString() === 'Text'">
                  <mat-form-field appearance="outline" class="row form-group col-md-12 col-sm-10 col-xs-12" [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
                    <textarea type="text"
                              matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize"
                              cdkAutosizeMinRows="5"
                              style="overflow:hidden"
                              id="{{getID(question.id)}}"
                              formControlName="{{getID(question.id)}}"> </textarea>
                  </mat-form-field>
                </div>
                <div *ngIf="question.type.toString() === 'Radio_Text'">
                  <div class="row form-group col-md-12 col-sm-10 col-xs-12" [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
                    <mat-radio-group formControlName="{{question.id}}">
                      <mat-radio-button *ngFor="let option of validQuestionOptions(question)" [value]="option.id" (change)="radioTextChange($event.value, question.id)">
                        <span class="radio-rap">{{ optionDisplay(option) }}</span>
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <mat-form-field appearance="outline" class="row form-group col-md-12 col-sm-10 col-xs-12" [ngClass]="{'has-error': feedbackForm.get(getRadioTextQuestionId(question.id))?.errors && feedbackForm.get(getRadioTextQuestionId(question.id))?.touched }">
                    <textarea type="text"
                              matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize"
                              cdkAutosizeMinRows="5"
                              style="overflow:hidden"
                              id="{{getRadioTextQuestionId(question.id)}}"
                              formControlName="{{getRadioTextQuestionId(question.id)}}"
                              placeholder="Enter comments here..."></textarea>
                  </mat-form-field>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
      <div [ngClass]="isCollapsed ? 'fixed-above-footer fixed-collapsed' : 'fixed-above-footer fixed-expanded'">
          <button *ngIf="!isThirdParty" type="button" mat-stroked-button class="save-btn" color="basic" (click)="saveFeedback()" [disabled]="!hasQuestions || isSaveButtonDisabled() || feedbackForm.pristine">
              <span *ngIf="isSaving"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>{{ isSaved ? 'UPDATE' : 'SAVE' }}
          </button>
          <button type="button" mat-stroked-button class="submit-btn" (click)="submitFeedback()" [disabled]="!hasQuestions || !feedbackForm.valid || isSubmitting || isSaving">
              <span *ngIf="isSubmitting"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span> SUBMIT
          </button>
      </div>
  </form>

<div *ngIf="isLoading" class="grid place-items-center inset-0">
  <mat-spinner></mat-spinner>
</div>
