import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {
  FbWeeklyReport,
  WeeklyReportService,
} from 'src/app/core/services/weekly-report-service';
import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-view-weekly-health-delivery-report',
  templateUrl: './view-weekly-health-delivery-report.component.html',
  styleUrls: ['./view-weekly-health-delivery-report.component.scss'],
})
export class ViewWeeklyHealthDeliveryReportComponent implements OnInit {
  weeklyReportId!: number;
  weeklyReport!: FbWeeklyReport;
  operationInProgress: string | null = null;
  showActionPanel = true;
  isLoading = true;
  currentUserId = this.authService.getUserId().toLowerCase();

  constructor(
    private weeklyReportService: WeeklyReportService,
    private snackBarService: SnackBarService,
    private authService: AuthService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.weeklyReportId = +params['id'];

      this.operationInProgress = 'loading';

      this.getWeeklyReportDetail(this.weeklyReportId);
    });
  }

  private getWeeklyReportDetail(weeklyReportId: number) {
    this.weeklyReportService
      .getWeeklyReportDetail(weeklyReportId.toString())
      .subscribe(
        data => {
          if (data.submittedBy === this.currentUserId) {
            this.weeklyReport = data;
            this.operationInProgress = null;
          } else {
            this.showConfirmationDialog();
          }
          this.isLoading = false;
        },
        error => {
          this.snackBarService.error(error);
          this.operationInProgress = null;
        }
      );
  }

  private showConfirmationDialog() {
    const confirmDialog = new ConfirmDialog();
    confirmDialog.title = 'Access Error';
    confirmDialog.message =
      "<p> You don't have access to this feedback using this section of the app.</p>";
    confirmDialog.okButtonTitle = 'Continue';
    // confirmDialog.cancelButtonTitle = "Cancel";
    this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: confirmDialog,
      disableClose: true,
    });
  }
}
