<vex-secondary-toolbar current="Review">
  <vex-breadcrumbs [crumbs]="['Review', 'CM Feedback']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="survey-page component-container survey">
  <div class="row mb-3">
    <h3 class="page-title col-md-9">CM Feedback Dashboard</h3>
    <mat-form-field appearance="outline" class="year-width editable col-md-3">
      <mat-label>Year</mat-label>
      <mat-select [(ngModel)]="selectedYear" name="year" (selectionChange)="getCMSurvey($event.value)">
        <mat-option *ngFor="let year of surveyYears" [value]="year">
          {{ year }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-list *ngIf="questions" class="mat-elevation-z8 mb-5">
    <mat-list-item mat-list-item *ngFor="let q of questions; let ix = index">
      <span>{{ix+1}}. {{ q.question }}</span>
    </mat-list-item>
  </mat-list>

  <table *ngIf="displayedColumns" mat-table [dataSource]="displayRows" class="mat-elevation-z8" style="width:100%">

    <ng-container matColumnDef="mentor">
      <mat-header-cell *matHeaderCellDef>Onliner</mat-header-cell>
      <mat-cell [ngClass]="row.isCMRow ? 'mentor-row' : 'mentee-row'" *matCellDef="let row">{{ row.name }}</mat-cell>
    </ng-container>

    <!--Dynamic Columns-->
    <ng-container *ngFor="let column of dynamicColumns; let ix = index" [matColumnDef]="column.matColumnDef">
      <mat-header-cell *matHeaderCellDef [matTooltip]="getColumnHeaderTip(ix)" matTooltipPosition="above" class="center-header">{{ column.header }}</mat-header-cell>
      <mat-cell [ngClass]="row.isCMRow ? 'mentor-score-row' : ''" *matCellDef="let row" class="center-cell">{{ column.cell(row,ix) }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row matRipple *matRowDef="let row; columns: displayedColumns" [ngClass]="row.isCMRow ? 'mat-row-clickable' : ''" (click)="rowClicked(row.name)" [matRippleDisabled]="!row.isCMRow"></mat-row>
  </table>
</div>

<div *ngIf="displayRows && !questions" class="component-container">No records found</div>
