import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { AcceptedData } from 'export-to-csv/output/lib/types';
import { decode } from 'html-entities';
import {
  ClientFeedbackOnOnline,
  FbAnswer,
} from 'src/app/core/services/assessment.service';
import { CommonService } from 'src/app/core/services/common.service';
import {
  Question,
  QuestionOption,
} from 'src/app/core/services/feedback.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-client-feedback-online-list',
  templateUrl: './client-feedback-online-list.component.html',
  styleUrls: ['./client-feedback-online-list.component.scss'],
})
export class ClientFeedbackOnlineListComponent implements OnInit, OnChanges {
  @Input()
  feedbacks!: ClientFeedbackOnOnline[];

  @ViewChild(MatSort, { static: true })
  sort!: MatSort;

  dataSource!: MatTableDataSource<ClientFeedbackOnOnline>;
  isLoading = true;
  displayedQuestions!: DisplayedQuestion[];
  displayedColumns!: string[];
  questionOptions!: QuestionOption[];

  constructor(
    private datePipe: DatePipe,
    private commonSerive: CommonService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit() {
    this.commonSerive.getQuestionOptions().subscribe(
      data => {
        this.questionOptions = data;
        this.setUpPage();
      },
      error => {
        this.snackBarService.error(error);
      }
    );
  }

  ngOnChanges() {
    this.setUpPage();
  }

  htmlDecode(input: string) {
    const txt = document.createElement('textarea');
    txt.innerHTML = input;
    return txt.value;
  }

  displayAnswer(answers: FbAnswer[], questionId: number) {
    const answer = answers.find(a => a.questionId === questionId);
    let result;
    if (
      !answer ||
      !(answer.stringValue || answer.optionId || answer.intValue)
    ) {
      result = 'N/A';
    } else {
      if (
        answer.question.type.toString() === 'Radio' ||
        answer.question.type.toString() === 'Scale'
      ) {
        result = `${answer.intValue}`;
      } else {
        result = answer.stringValue;
      }
    }
    return this.htmlDecode(result || '');
  }

  downloadCSV(filterDateDisplay: string) {
    const generateCSV = () => {
      const headers = this.displayedColumns.map((c, idx) => {
        let header = c;
        if (idx === 0) {
          header = 'Client';
        }
        if (idx === 2) {
          header = 'Submitted Date';
        }
        return `${header}`;
      });
      const data = this.dataSource.data.map(f => {
        const tableData: { [key: string]: unknown } = {};
        tableData['Client'] = f.clientName?.trim();
        tableData['Contact Name'] =
          f.answers.find(a => a.questionId === 45)?.stringValue?.trim() ||
          'N/A';
        tableData['Submitted Date'] = this.datePipe.transform(
          f.submittedDate,
          'MM/dd/yyyy'
        );
        this.displayedQuestions
          .filter(x => x.id !== 45)
          .forEach(x => {
            const answer = f.answers.find(a => a.questionId === x.id);
            tableData[`${x.question}`] =
              !answer ||
              !(answer.stringValue || answer.optionId || answer.intValue)
                ? 'N/A'
                : answer.question.type.toString() === 'Radio' ||
                    answer.question.type.toString() === 'Scale'
                  ? answer.intValue
                  : `${decode(answer.stringValue)}`;
          });
        return tableData;
      });
      return { headers, data };
    };

    const generated = generateCSV();
    const now = this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a');
    const csvConfig = mkConfig({
      fieldSeparator: ',',
      filename: `Client Feedback - Online Report ${filterDateDisplay} Created on ${now}`,
      title: `Client Feedback - Online Report ${filterDateDisplay}\n\n`,
      quoteCharacter: '"',
      decimalSeparator: '.',
      columnHeaders: generated.headers,
      showTitle: true,
      useBom: true,
    });

    const file = generateCsv(csvConfig)(
      generated.data as {
        [k: string]: AcceptedData;
        [k: number]: AcceptedData;
      }[]
    );
    download(csvConfig)(file);
  }

  getColumnHeaderTip(question: Question): string {
    const displayTip = !this.isTooltipHidden(question);
    let minScore: number | undefined, maxScore: number | undefined;
    if (displayTip && question.optionGroupId && this.questionOptions) {
      maxScore = Math.max(
        ...this.questionOptions
          .filter(qo => qo.groupId === question.optionGroupId)
          .map(o => o.score)
      );
      minScore = Math.min(
        ...this.questionOptions
          .filter(qo => qo.groupId === question.optionGroupId)
          .map(o => o.score)
      );
    }
    return displayTip ? `Score range: ${minScore} - ${maxScore}` : '';
  }

  isTooltipHidden(q: Question): boolean {
    return !(q.type.toString() === 'Radio' || q.type.toString() === 'Scale');
  }

  private setUpPage() {
    if (this.feedbacks) {
      this.isLoading = true;
      this.sortFeedbacksBySubmissionDate();
      this.getDynamicColumns();
      this.dataSource = new MatTableDataSource<ClientFeedbackOnOnline>(
        this.feedbacks
      );
      // TODO: accessor return data type: expecting a single value, got an array in some cases.
      // this.dataSource.sortingDataAccessor = (
      //   item: ClientFeedbackOnOnline,
      //   property: string
      // ) => {
      //   switch (property) {
      //     case 'Contact Name':
      //       return item.answers.map(a => a.stringValue);
      //     default:
      //       return (item as never)[property] || '';
      //   }
      // };
      this.dataSource.sort = this.sort;
      this.isLoading = false;
    }
  }

  private sortFeedbacksBySubmissionDate() {
    this.feedbacks.sort((a, b) =>
      a.submittedDate < b.submittedDate
        ? 1
        : b.submittedDate < a.submittedDate
          ? -1
          : 0
    );
  }

  private getDynamicColumns() {
    this.displayedQuestions = [];
    this.feedbacks.forEach(f => {
      f.answers.forEach(a => {
        if (
          this.displayedQuestions.findIndex(q => q.id === a.questionId) === -1
        ) {
          this.displayedQuestions.push({
            id: a.questionId,
            question: a.question.question,
            tooltip: this.getColumnHeaderTip(a.question),
            isTooltipHidden: this.isTooltipHidden(a.question),
          });
        }
      });
    });

    // This makes the app retrocompatible. It adds the Contact Column for forms filled before the Contact question was implemented
    if (!this.displayedQuestions.find(q => q.id === 45)) {
      const contactQuestion: DisplayedQuestion = new DisplayedQuestion();
      contactQuestion.id = 45;
      contactQuestion.question = 'Contact Name';
      this.displayedQuestions.push(contactQuestion);
    }
    // --
    this.displayedColumns = [
      'clientName',
      this.displayedQuestions.find(q => q.id === 45)?.question ||
        'Contact Name',
      'submittedDate',
      ...this.displayedQuestions.filter(q => q.id !== 45).map(q => q.question),
    ];
  }
}

export class DisplayedQuestion {
  id!: number;
  question!: string;
  tooltip!: string;
  isTooltipHidden!: boolean;
}
