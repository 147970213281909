import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LayoutService } from 'src/@vex/services/layout.service';
import {
  AssessmentService,
  SavedAssessment,
} from 'src/app/core/services/assessment.service';
import { FeedbackReviewService } from 'src/app/core/services/feedback-review.service';
import { FeedbackService } from 'src/app/core/services/feedback.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {
  CommentDialog,
  CommentDialogComponent,
} from 'src/app/shared/components/comment-dialog/comment-dialog.component';
import {
  FeedbackStatusEnum,
  FeedbackTypeEnum,
} from 'src/app/shared/enums/enums.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-review-cmfeedback',
  templateUrl: './review-cmfeedback.component.html',
  styleUrls: ['./review-cmfeedback.component.scss'],
})
export class ReviewCmfeedbackComponent implements OnInit {
  feedbackId!: number;
  feedbackTypeId = FeedbackTypeEnum.CareerMentorFeedback;
  feedback!: SavedAssessment;
  operationInProgress: string | null = null;
  showActionPanel = true;
  pcRole: string = environment.roles.FeedbackPeopleCare;
  isCollapsed = true;
  pendingStatusId: number = FeedbackStatusEnum.Pending;
  postedStatusId: number = FeedbackStatusEnum.Posted;

  constructor(
    private assessmentService: AssessmentService,
    private feedbackReviewService: FeedbackReviewService,
    private feedbackService: FeedbackService,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private layoutService: LayoutService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.feedbackId = +params['id'];
      this.operationInProgress = 'loading';
      this.getFeedbackDetail(this.feedbackId);
    });
    this.layoutService.sidenavCollapsed$.subscribe(collapsed => {
      this.isCollapsed = collapsed;
    });
  }

  postFeedback() {
    const gatherCommentsDialog = new CommentDialog();

    gatherCommentsDialog.title = 'Post Feedback';
    gatherCommentsDialog.message = 'People Care Comments (optional)';
    gatherCommentsDialog.isEmptyInputAllowed = true;
    gatherCommentsDialog.okButtonTitle = 'Submit';
    gatherCommentsDialog.cancelButtonTitle = 'Cancel';

    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: '350px',
      height: '350px',
      data: gatherCommentsDialog,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.performFeedbackPosting(data.reasons);
      }
    });
  }

  reviseFeedback() {
    const gatherCommentsDialog = new CommentDialog();

    gatherCommentsDialog.title = 'Revise Feedback';
    gatherCommentsDialog.message =
      'Please enter notes explaining what needs to be revised.';
    gatherCommentsDialog.okButtonTitle = 'Submit';
    gatherCommentsDialog.cancelButtonTitle = 'Cancel';

    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: '400px',
      height: '380px',
      data: gatherCommentsDialog,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.performFeedbackRevision(data.reasons);
      }
    });
  }

  unpostFeedback() {
    this.operationInProgress = 'unposting';
    this.feedbackReviewService.unpostFeedback(this.feedbackId).subscribe(
      () => {
        this.feedback.currentStatusId = FeedbackStatusEnum.Pending;
        this.feedback.currentStatus =
          FeedbackStatusEnum[this.feedback.currentStatusId];
        this.feedbackService.updateReviewableCmFeedbackStatus(
          this.feedback.id,
          FeedbackStatusEnum.Pending
        );
        this.operationInProgress = null;
        this.snackBarService.message(
          `Career Mentor feedback from ${this.feedback.submittedByName} has been successfully unposted.`
        );
      },
      error => {
        this.operationInProgress = null;
        this.snackBarService.error(error);
      }
    );
  }

  private getFeedbackDetail(feedbackId: number) {
    this.assessmentService.getFeedbackDetail(feedbackId).subscribe(
      data => {
        this.feedback = data;
        this.operationInProgress = null;
      },
      error => {
        this.snackBarService.error(error);
        this.operationInProgress = null;
      }
    );
  }

  private performFeedbackPosting(peopleCareComments?: string) {
    this.operationInProgress = 'posting';
    this.feedbackReviewService
      .postFeedback(this.feedbackId, peopleCareComments)
      .subscribe(
        () => {
          this.feedback.currentStatusId = FeedbackStatusEnum.Posted;
          this.feedback.currentStatus =
            FeedbackStatusEnum[this.feedback.currentStatusId];
          this.getFeedbackDetail(this.feedbackId);
          this.feedbackService.updateReviewableCmFeedbackStatus(
            this.feedback.id,
            FeedbackStatusEnum.Posted
          );
          this.operationInProgress = null;
          this.snackBarService.message(
            `Career Mentor feedback from ${this.feedback.submittedByName} has been successfully posted and an email was sent to ${this.feedback.employeeName}. `
          );
        },
        error => {
          this.operationInProgress = null;
          this.snackBarService.error(error);
        }
      );
  }

  private performFeedbackRevision(reasons: string) {
    this.operationInProgress = 'revising';
    this.feedbackReviewService
      .reviseFeedback(this.feedbackId, reasons)
      .subscribe(
        () => {
          this.feedback.currentStatusId = FeedbackStatusEnum.Draft;
          this.feedbackService.removeSavedFromReviewableCmFeedback(
            this.feedback.id
          );
          this.snackBarService.message(
            `Career Mentor feedback for ${this.feedback.employeeName} from ${this.feedback.submittedByName} has been sent back for revision. An email was sent to ${this.feedback.submittedByName}. `
          );
          this.operationInProgress = null;
        },
        error => {
          this.operationInProgress = null;
          this.snackBarService.error(error);
        }
      );
  }
}
