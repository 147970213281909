import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { ViewWeeklyHealthDeliveryDetailComponent } from './view-weekly-health-delivery-detail/view-weekly-health-delivery-detail.component';
import { ViewWeeklyHealthDeliveryReportRoutingModule } from './view-weekly-health-delivery-report-routing.module';
import { ViewWeeklyHealthDeliveryReportComponent } from './view-weekly-health-delivery-report.component';

@NgModule({
  declarations: [
    ViewWeeklyHealthDeliveryReportComponent,
    ViewWeeklyHealthDeliveryDetailComponent,
  ],
  imports: [
    CommonModule,
    ViewWeeklyHealthDeliveryReportRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    AngularMaterialModule,
  ],
  providers: [DatePipe],
})
export class ViewWeeklyHealthDeliveryReportModule {}
