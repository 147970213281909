import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { CareerMentorService } from 'src/app/core/services/career-mentor.service';
import { environment } from 'src/environments/environment';
import * as formValidator from '../../../shared/validators/form-validator';

@Component({
  selector: 'app-request-onliner-feedback',
  templateUrl: './request-onliner-feedback.component.html',
  styleUrls: ['./request-onliner-feedback.component.scss'],
})
export class RequestOnlinerFeedbackComponent implements OnInit {
  isCMRequest = false;
  isCM!: boolean;
  isDelegate!: boolean;
  requestFeedbackForm: UntypedFormGroup = this.createRequestFeedbackForm();
  subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private careerMentorService: CareerMentorService
  ) {}

  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    return !this.requestFeedbackForm.dirty;
  }

  ngOnInit() {
    this.isCM = this.authService.doesUserHaveRole([
      environment.roles.FeedbackCm,
    ]);
    this.subscriptions.push(
      this.careerMentorService.isDelegated$.subscribe(
        delegated => (this.isDelegate = delegated)
      )
    );
  }

  private createRequestFeedbackForm(): UntypedFormGroup {
    return this.formBuilder.group({
      careerMentor: ['', formValidator.validateOnliner()],
      onliner: ['', formValidator.validateOnliner()],
      client: [''],
      otherClient: [''],
      message: ['', formValidator.requiredAndNoWhiteSpaceValidator],
    });
  }
}
