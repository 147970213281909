import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Error403RoutingModule } from './error-403-routing.module';
import { Error403Component } from './error-403.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [Error403Component],
  imports: [CommonModule, Error403RoutingModule, MatIconModule],
})
export class Error403Module {}
