// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {

  dropdownOpen: boolean;

  public userProfile: any;
  public profilePhotoData: string;
  private readonly _destroying$ = new Subject<void>();
  isAuthenticated : boolean = false;
  userName: string;
  isImageLoading: boolean = true;
  userRoles: string;

  constructor(private popover: PopoverService,
              private cd: ChangeDetectorRef,

              private authService: AuthService,
              private msalBroadcastService: MsalBroadcastService,
              private userService: UserService,
              ) {
              }

  ngOnInit() {

    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.isAuthenticated = this.authService.isAuthenticated();
      if(this.isAuthenticated) {
        this.userName = this.authService.getUserProfile()?.name || '';

        this.userService.getMyProfilePhoto()
          .subscribe(data => {
            if (data !== null) {
              this.cd.markForCheck();
                this.profilePhotoData = `data:image/png;base64,${data}`;
            } else {
              this.profilePhotoData = null;
            }
            this.isImageLoading = false;
          }, error => {
            this.isImageLoading = false;
          });

        this.userRoles = this.roles();
      }
    })
  }

  roles() : string {

    let retRoles: string = '';

    let allRoles: Array<string> = this.authService.getUserRoles();

    if(allRoles.includes(environment.roles.FeedbackUser)) {
      retRoles += 'User'
    }

    if(allRoles.includes(environment.roles.FeedbackCm)) {
      retRoles += ', Career Mentor';
    }

    if(allRoles.includes(environment.roles.FeedbackAdmin)) {
      retRoles += ', Admin';
    }

    if(allRoles.includes(environment.roles.FeedbackCFRAdmin)) {
      retRoles += ', CFRAdmin';
    }

    if (allRoles.includes(environment.roles.FeedbackPeopleCare)) {
      retRoles += ', People Care';
    }
    if (allRoles.includes(environment.roles.FeedbackMarketing)) {
      retRoles += ', Marketing';
    }

    if (allRoles.includes(environment.roles.FeedbackLead)) {
      retRoles += ', Practice Lead';
    }

    if (allRoles.includes(environment.roles.FeedbackCEO)) {
      retRoles += ', CEO';
    }

    if (allRoles.includes(environment.roles.FeedbackReviewer)) {
      retRoles += ', Reviewer';
    }

    return 'Roles: ' + retRoles;
  }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}
