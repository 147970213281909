import { Component, Input, OnInit } from '@angular/core';
import { LegacyFeedbackComment } from 'src/app/core/services/feedback.service';
import { CommentTypeEnum } from 'src/app/shared/enums/enums.model';
import { commentTypeDetail } from 'src/app/shared/models/constants.model';

@Component({
  selector: 'app-legacy-feedback-detail',
  templateUrl: './legacy-feedback-detail.component.html',
  styleUrls: ['./legacy-feedback-detail.component.scss'],
})
export class LegacyFeedbackDetailComponent implements OnInit {
  @Input() legacyFeedbackComment!: LegacyFeedbackComment;
  commentTypeDetail!: string;

  ngOnInit() {
    this.commentTypeDetail = this.getCommentTypeDescription() || '';
  }

  getCommentTypeDescription(): string | null {
    if (this.legacyFeedbackComment) {
      switch (this.legacyFeedbackComment.commentType.commentTypeId) {
        case CommentTypeEnum.Technical:
          return commentTypeDetail.Technical;
        case CommentTypeEnum.Business:
          return commentTypeDetail.Business;
        case CommentTypeEnum.Management:
          return commentTypeDetail.Management;
        case CommentTypeEnum.CoreConsultantCompetencies:
          return commentTypeDetail.CoreConsultantCompetencies;
        case CommentTypeEnum.AnnualSelfEvaluationGRT:
          return commentTypeDetail.AnnualSelfEvaluationGRT;
        case CommentTypeEnum.CorporateCitizenship:
          return commentTypeDetail.CorporateCitizenship;
        default:
          return null;
      }
    }
    return null;
  }
}
