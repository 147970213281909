import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { RemoteWorkRequestTypeEnum } from 'src/app/shared/enums/enums.model';
import { ApiErrorData } from 'src/app/shared/interfaces/api-error.interface';
import { ChangeRequestStatusResult } from 'src/app/shared/interfaces/remote-work.interface';
import { MatchType } from 'src/app/shared/shared.module';
import { environment } from 'src/environments/environment';
import { Question, QuestionOption } from '../services/feedback.service';
import {
  RemoteWorkConfig,
  RemoteWorkConfigService,
} from '../services/remote-work-config.service';
import { AuthService } from './auth.service';
import { AuthorizedHttp } from './authorized-http.service';
import { Employee } from './onliner.service';
export const defaultRemoteWorkRequestType: RemoteWorkRequestType = {
  id: -1,
  description: 'All',
};
export const defaultRemoteWorkRequestStatus: RemoteWorkRequestStatus = {
  id: -1,
  description: 'All',
};
export const defaultPendingRemoteWorkRequestStatus: RemoteWorkRequestStatus = {
  id: 5,
  description: 'Pending',
};
export const defaultCountry: Country = {
  countryId: 'CAN',
  countryDesc: 'Canada',
  alpha2CodeId: 'CA',
};

@Injectable()
export class RemoteWorkRequestService {
  public _myRemoteWorkRequests!: RemoteWorkRequests[];
  public _remoteWorkRequests!: RemoteWorkRequests[];
  public _employeeDetails!: EmployeeDetails;
  public _remoteWorkRequest!: RemoteWorkRequests;
  private _apiEndpoint = `${environment.apiEndpoint}/api/remoteworkrequest`;
  private readonly _CEORole: string = environment.roles.FeedbackCEO;

  constructor(
    private http: AuthorizedHttp,
    private remoteWorkConfigService: RemoteWorkConfigService,
    private authService: AuthService
  ) {}

  public getCurrentRemoteWorkRequests(
    remoteWorkRequestId: number,
    isReview: boolean
  ): Observable<RemoteWorkRequests> {
    return this.http
      .get<RemoteWorkRequests>(
        `${this._apiEndpoint}/${remoteWorkRequestId}/${isReview}`
      )
      .pipe(
        map(
          this.mapRemoteWorkRequest(
            result => (this._remoteWorkRequest = result)
          )
        ),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getDraftRemoteWorkRequests(): Observable<RemoteWorkRequests[]> {
    return this.http
      .get<RemoteWorkRequests[]>(
        `${this._apiEndpoint}/myDraftRemoteWorkRequests`
      )
      .pipe(
        map(
          this.mapRemoteWorkRequests(
            results => (this._myRemoteWorkRequests = results)
          )
        ),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getActiveRemoteWorkRequest(): Observable<RemoteWorkRequests> {
    return this.http
      .get<RemoteWorkRequests>(`${this._apiEndpoint}/myActiveRemoteWorkRequest`)
      .pipe(
        map(
          this.mapRemoteWorkRequest(
            result => (this._remoteWorkRequest = result)
          )
        ),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getRemoteWorkRequests(
    employeeId = ''
  ): Observable<RemoteWorkRequests[]> {
    return this.http
      .get<RemoteWorkRequests[]>(`${this._apiEndpoint}/onliner/${employeeId}`)
      .pipe(
        map(
          this.mapRemoteWorkRequests(
            results => (this._remoteWorkRequests = results)
          )
        ),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getRequestsForRequestList(
    isReview: boolean
  ): Observable<RemoteWorkRequests[]> {
    return this.http
      .get<RemoteWorkRequests[]>(
        `${this._apiEndpoint}/requestsForList/${isReview}`
      )
      .pipe(
        map(
          this.mapRemoteWorkRequests(
            results => (this._remoteWorkRequests = results)
          )
        ),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public saveRequest(
    request: RemoteWorkRequests
  ): Observable<ChangeRequestStatusResult> {
    return this.http
      .post<ChangeRequestStatusResult>(
        `${this._apiEndpoint}/saveRemoteWorkRequest`,
        request
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public updateRequest(
    data: ExtendRemoteWorkRequest
  ): Observable<ExtendRemoteWorkRequest> {
    return this.http
      .post<ExtendRemoteWorkRequest>(`${this._apiEndpoint}/extend`, data)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public submitRequest(
    requestToSubmit: RemoteWorkRequests
  ): Observable<ChangeRequestStatusResult> {
    return this.http
      .post<ChangeRequestStatusResult>(
        `${this._apiEndpoint}/submitRemoteWorkRequest`,
        requestToSubmit
      )
      .pipe(
        map(request => request),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getUserDetailsForRequest(remoteRequestId: number) {
    return this.http
      .get<EmployeeDetails>(
        `${this._apiEndpoint}/onlinerDetails/${remoteRequestId}`
      )
      .pipe(
        map(
          this.mapEmployeeDetails(result => (this._employeeDetails = result))
        ),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getCurrentUserDetails() {
    return this.http
      .get<EmployeeDetails>(`${this._apiEndpoint}/onlinerDetails/0`)
      .pipe(
        map(
          this.mapEmployeeDetails(result => (this._employeeDetails = result))
        ),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public approveRemoteWorkRequest(
    requestId: number
  ): Observable<ChangeRequestStatusResult> {
    return this.http
      .post<ChangeRequestStatusResult>(`${this._apiEndpoint}/approve`, {
        requestId: requestId,
      })
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public rejectRemoteWorkRequest(
    requestId: number
  ): Observable<ChangeRequestStatusResult> {
    return this.http
      .post<ChangeRequestStatusResult>(`${this._apiEndpoint}/reject`, {
        requestId: requestId,
      })
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public deleteRemoteWorkRequest(id: number): Observable<number> {
    return this.http
      .post<number>(`${this._apiEndpoint}/deleteRequest`, id)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public userCanChangeStatus(userId: string): Observable<boolean> {
    return this.getUsersRemoteWorkConfig().pipe(
      map(
        configs =>
          this.isInRemoteWorkRequestConfig(configs, userId) || this.isUserCEO()
      ),
      first()
    );
  }

  public isUserCEO(): boolean {
    return this.authService.doesUserHaveRole([this._CEORole]);
  }

  public getUsersRemoteWorkConfig(): Observable<RemoteWorkConfig[]> {
    return this.remoteWorkConfigService
      .getRemoteWorkConfigs()
      .pipe(
        catchError(() =>
          throwError(
            'No email configuration found relocation requests reviewers.'
          )
        )
      );
  }

  private mapRemoteWorkRequests(
    assignResults: (remoteWorkRequests: RemoteWorkRequests[]) => void
  ) {
    return (data: RemoteWorkRequests[]) => {
      const results: RemoteWorkRequests[] = MatchType.MatchDataArray(
        data,
        RemoteWorkRequests
      );
      if (results) {
        results.forEach((request: RemoteWorkRequests) => {
          request.FormatData();
        });
      }
      assignResults(results);
      return results;
    };
  }

  private mapRemoteWorkRequest(
    assignResults: (remoteWorkRequests: RemoteWorkRequests) => void
  ) {
    return (data: RemoteWorkRequests) => {
      const results: RemoteWorkRequests = MatchType.MatchData(
        data,
        RemoteWorkRequests
      );
      if (results?.FormatData) {
        results.FormatData();
        assignResults(results);
      }
      return results;
    };
  }

  private mapEmployeeDetails(
    assignResults: (details: EmployeeDetails) => void
  ) {
    return (data: EmployeeDetails) => {
      const results: EmployeeDetails = MatchType.MatchData(
        data,
        EmployeeDetails
      );
      assignResults(results);
      return results;
    };
  }

  private mapConfigUser() {
    return (data: RemoteWorkConfig[]) => {
      const results: RemoteWorkConfig[] = MatchType.MatchDataArray(
        data,
        RemoteWorkConfig
      );
      return results;
    };
  }
  private isInRemoteWorkRequestConfig(
    configs: RemoteWorkConfig[],
    userId: string
  ): boolean {
    return configs.some(
      config =>
        (config.ccEmail &&
          config.ccEmail.toLowerCase() === userId.toLowerCase()) ||
        (config.primaryEmail === userId &&
          config.primaryEmail.toLowerCase() === userId.toLowerCase())
    );
  }
}

export class EmployeeDetails {
  name!: string;
  country!: string;
  practice!: string;
  ceoUserReviewRequest!: string;
  primaryEmailReviewRequest!: string;
  ccEmailReviewRequest!: string;
  countryAlpha2Code!: string;
  userId!: string;
}

export class RemoteWorkRequests {
  id!: number;
  requestedBy!: string;
  requestedByName!: string;
  requestStatusId!: number;
  expectedTravelDate!: Date | null;
  expectedReturnDate!: Date | null;
  requestedDate!: Date;
  requestTypeId!: number;
  submittedDate!: Date;
  country!: string;
  city!: string;
  comment!: string;
  requestType!: string;
  requestTypeName!: string;
  laptopUsageTypeId!: number;
  clientApproval!: boolean;
  approvedDate!: Date;
  approvedBy!: string;
  requestStatusDescription!: string;
  approvedByName!: string;
  log: RemoteWorkRequestAudit[] = [];
  answers!: FbRemoteWorkRequestAnswer[];
  FormatData(): void {
    this.requestType = RemoteWorkRequestTypeEnum[this.requestTypeId];
    this.requestTypeName = this.getRequestTypeDescription(this.requestTypeName);
    this.expectedReturnDate =
      this.expectedReturnDate &&
      new Date(this.expectedReturnDate).getFullYear() === 1
        ? null
        : this.expectedReturnDate;
  }

  private getRequestTypeDescription(questionText: string): string {
    if (
      questionText.toLowerCase() === 'temporary relocation' ||
      questionText.toLowerCase() === 'working remotely (temporarily)'
    ) {
      return 'Temporary';
    } else if (questionText.toLowerCase() === 'permanent relocation') {
      return 'Permanent';
    }
    return '';
  }
}

export class RemoteWorkRequestType {
  id!: number;
  description!: string;
}

export class RemoteWorkRequestStatus {
  id!: number;
  description!: string;
}

export class RemoteWorkRequestAudit {
  id!: number;
  statusId!: number;
  changedByEmployee!: Employee;
  changedBy!: string;
  changedDate!: Date;
  description!: string;
}

export class FbRemoteWorkRequestAnswer {
  id!: number;
  questionId!: number;
  question!: Question;
  intValue!: number | null;
  stringValue!: string;
  optionId!: number | null;
  option!: QuestionOption | null;
}

export class Country {
  countryId!: string;
  countryDesc!: string;
  alpha2CodeId!: string;
}

export class ExtendRemoteWorkRequest {
  requestId!: number;
  isReturning!: boolean;
  endDate?: Date;
}
