import { Injectable } from '@angular/core';

import { AbstractControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { CareerMentor } from './career-mentor.service';
import { Client, SelectListItem } from './common.service';
import {
  AnonymousOption,
  CompetencyDto,
  FeedbackStatus,
  FeedbackType,
  defaultAnonymousOption,
  defaultClient,
  defaultCompetency,
} from './feedback.service';
import { Employee, defaultOnliner } from './onliner.service';
import {
  Country,
  RemoteWorkRequestStatus,
  RemoteWorkRequestType,
  defaultCountry,
  defaultRemoteWorkRequestType,
} from './remote-work-request.service';
import {
  FbSelfAssessmentSchedule,
  PracticeType,
  defaultPracticeType,
} from './self-assessment.service';

@Injectable()
export class AutoSuggestService {
  careerMentorDisplay = (option?: CareerMentor): string =>
    option ? `${option.firstName} ${option.lastName}`.trim() : '';
  submissionTypeDisplay = (option?: SelectListItem): string =>
    option ? option.name.trim() : '';
  fiscalYearDisplay = (option?: FbSelfAssessmentSchedule): string =>
    option ? option.fiscalYear.toString() : '';
  onlinerDisplay = (option?: Employee | CareerMentor): string =>
    option ? `${option.firstName} ${option.lastName}`.trim() : '';
  competencyDisplay = (option?: CompetencyDto): string =>
    option ? `${option.name}`.trim() : '';
  practiceDisplay = (option?: string): string =>
    option ? `${option}`.trim() : '';
  practiceTypeDisplay = (option?: PracticeType): string =>
    option ? `${option.practice}`.trim() : '';
  clientDisplay = (option?: Client): string =>
    option ? option.clientName.trim() : '';
  feedbackTypeDisplay = (option?: FeedbackType): string =>
    option ? option.typeDescr.trim() : '';
  feedbackStatusDisplay = (option?: FeedbackStatus): string =>
    option ? option.statusDescription.trim() : '';
  anonymousDisplay = (option?: AnonymousOption): string =>
    option ? option.anonymousDesc.trim() : '';
  remoteWorkRequestStatusDisplay = (
    option?: RemoteWorkRequestStatus
  ): string => (option ? option.description.trim() : '');
  remoteWorkRequestTypeDisplay = (option?: RemoteWorkRequestType): string =>
    option ? option.description.trim() : '';
  countryDisplay = (option?: Country): string =>
    option && option.countryDesc ? option.countryDesc.trim() : '';

  setOnlinerFilter(
    onlinerControl: AbstractControl<
      string | Employee | CareerMentor,
      string | Employee | CareerMentor
    >,
    onliners: Employee[] | CareerMentor[],
    includeAll = false,
    onFilter = false
  ) {
    onliners = onliners.sort((a, b) => (a.firstName < b.firstName ? -1 : 1));

    if (!includeAll) {
      const defaultIndex = onliners.findIndex(
        o => o.firstName === defaultOnliner.firstName
      );
      if (defaultIndex > -1) {
        onliners.splice(defaultIndex, 1);
      }
    }

    if (onFilter) {
      onliners.unshift(defaultOnliner);
    }

    function isEmployeeOrCareerMentor(
      value: string | Employee | CareerMentor
    ): value is Employee | CareerMentor {
      const isEmployee =
        !!(<Employee>value)?.firstName || !!(<Employee>value)?.lastName;
      const isCareerMentor =
        !!(<CareerMentor>value)?.firstName || !!(<CareerMentor>value)?.lastName;
      return isEmployee || isCareerMentor;
    }

    return onlinerControl.valueChanges.pipe(
      startWith<string | Employee | CareerMentor>(null),
      map(value =>
        value && isEmployeeOrCareerMentor(value)
          ? null //`${value?.firstName} ${value?.lastName}`.trim()
          : <string>value
      ),
      map(description =>
        description
          ? this.onlinerFilter(description.toString(), onliners)
          : onliners.slice()
      )
    );
  }

  setCompetencyFilter(
    competencyControl: AbstractControl<
      string | CompetencyDto,
      string | CompetencyDto
    >,
    competencies: CompetencyDto[],
    includeAll = false,
    onFilter = false
  ) {
    if (!includeAll) {
      const defaultIndex = competencies.findIndex(
        o => o.name === defaultCompetency.name
      );
      if (defaultIndex > -1) {
        competencies.splice(defaultIndex, 1);
      }
    }

    if (onFilter) {
      competencies.unshift(defaultCompetency);
    }

    return competencyControl.valueChanges.pipe(
      startWith<string | CompetencyDto>(null),
      map(
        value => (typeof value === typeof '' ? <string>value : null) //(<CompetencyDto>value || {}).name
      ),
      map(description =>
        description
          ? this.competencyFilter(description.toString(), competencies)
          : competencies.slice()
      )
    );
  }

  setPracticeFilter(
    practiceControl: AbstractControl<
      string | PracticeType,
      string | PracticeType
    >,
    practices: string[],
    includeAll = false,
    onFilter = false
  ) {
    if (!includeAll) {
      const defaultIndex = practices.findIndex(o => o.trim() === 'All');
      if (defaultIndex > -1) {
        practices.splice(defaultIndex, 1);
      }
    }

    if (onFilter) {
      practices.unshift('All');
    }

    return practiceControl.valueChanges.pipe(
      startWith<string | PracticeType>(null),
      map(
        value => (typeof value === typeof '' ? <string>value : null) //(<PracticeType>value || {}).practice
      ),
      map(description =>
        description
          ? this.practiceFilter(description.toString(), practices)
          : practices.slice()
      )
    );
  }

  setPracticeTypeFilter(
    practiceControl: AbstractControl<
      string | PracticeType,
      string | PracticeType
    >,
    practiceTypes: PracticeType[],
    includeAll = false,
    onFilter = false
  ) {
    if (!includeAll) {
      const defaultIndex = practiceTypes.findIndex(
        o => o.practice.trim() === 'All'
      );
      if (defaultIndex > -1) {
        practiceTypes.splice(defaultIndex, 1);
      }
    }

    if (onFilter) {
      practiceTypes.unshift(defaultPracticeType);
    }

    return practiceControl.valueChanges.pipe(
      startWith<string | PracticeType>(null),
      map(
        value => (typeof value === typeof '' ? <string>value : null) //(<PracticeType>value || {}).practice
      ),
      map(description =>
        description
          ? this.practiceTypeFilter(description.toString(), practiceTypes)
          : practiceTypes.slice()
      )
    );
  }

  setCareerMentorFilter(
    cmControl: AbstractControl<
      string | Employee | CareerMentor,
      string | Employee | CareerMentor
    >,
    careerMentors: Employee[] | CareerMentor[]
  ) {
    careerMentors = careerMentors.sort((a, b) =>
      a.firstName < b.firstName ? -1 : 1
    );
    return cmControl.valueChanges.pipe(
      startWith<string | Employee | CareerMentor>(null),
      map(
        value => (typeof value === typeof '' ? <string>value : null) //(<CareerMentor>value || {}).firstName
      ),
      map(description =>
        description
          ? this.onlinerFilter(description.toString(), careerMentors)
          : careerMentors.slice()
      )
    );
  }

  setClientFilter(
    clientControl: AbstractControl<string | Client, string | Client>,
    clients: Client[],
    includeAll = false,
    onFilter = false
  ) {
    clients = (clients || []).sort((a, b) =>
      a.clientName < b.clientName ? -1 : 1
    );

    if (!includeAll) {
      const defaultIndex = clients.findIndex(
        o => o.clientName === defaultClient.clientName
      );
      if (defaultIndex > -1) {
        clients.splice(defaultIndex, 1);
      }
    }

    if (onFilter) {
      clients.unshift(defaultClient);
    }

    return clientControl?.valueChanges.pipe(
      startWith<string | Client>(null),
      map(
        value => (typeof value === typeof '' ? <string>value : null) //(<Client>value || {}).clientName
      ),
      map(description =>
        description
          ? this.clientFilter(description.toString(), clients)
          : clients.slice()
      )
    );
  }

  setFeedbackTypeFilter(
    feedbackTypeControl: AbstractControl<
      string | FeedbackType,
      string | FeedbackType
    >,
    feedbackTypes: FeedbackType[]
  ) {
    feedbackTypes = feedbackTypes.sort((a, b) =>
      a.typeDescr > b.typeDescr ? -1 : 1
    );
    return feedbackTypeControl.valueChanges.pipe(
      startWith<string | FeedbackType>(null),
      map(
        value => (typeof value === typeof '' ? <string>value : null) //(<FeedbackType>value || {}).typeDescr
      ),
      map(description =>
        description
          ? this.feedbackTypeFilter(description.toString(), feedbackTypes)
          : feedbackTypes.slice()
      )
    );
  }

  setFeedbackStatusFilter(
    feedbackStatusControl: AbstractControl<
      string | FeedbackStatus,
      string | FeedbackStatus
    >,
    feedbackStatuses: FeedbackStatus[]
  ) {
    feedbackStatuses = feedbackStatuses.sort((a, b) =>
      a.statusDescription < b.statusDescription ? -1 : 1
    );
    return feedbackStatusControl.valueChanges.pipe(
      startWith<string | FeedbackStatus>(null),
      map(
        value => (typeof value === typeof '' ? <string>value : null) //(<FeedbackStatus>value || {}).statusDescription
      ),
      map(description =>
        description
          ? this.feedbackStatusFilter(description.toString(), feedbackStatuses)
          : feedbackStatuses.slice()
      )
    );
  }

  setSelectListFilter(
    control: AbstractControl<string | SelectListItem, string | SelectListItem>,
    selectListItem: SelectListItem[]
  ) {
    selectListItem = selectListItem.sort((a, b) => (a.name < b.name ? -1 : 1));
    return control.valueChanges.pipe(
      startWith<string | SelectListItem>(null),
      map(
        value => (typeof value === typeof '' ? <string>value : null) //(<SelectListItem>value || {}).name
      ),
      map(description =>
        description
          ? this.submissionTypeFilter(description.toString(), selectListItem)
          : selectListItem.slice()
      )
    );
  }

  setAnonymousFilter(
    control: AbstractControl<
      string | AnonymousOption,
      string | AnonymousOption
    >,
    anonymousOption: AnonymousOption[]
  ) {
    anonymousOption = anonymousOption.sort((a, b) =>
      a.anonymousDesc < b.anonymousDesc ? -1 : 1
    );

    anonymousOption.unshift(defaultAnonymousOption);

    return control.valueChanges.pipe(
      startWith<string | AnonymousOption>(null),
      map(
        value => (typeof value === typeof '' ? <string>value : null) //(<AnonymousOption>value || {}).anonymousDesc
      ),
      map(description =>
        description
          ? this.anonymousFilter(description.toString(), anonymousOption)
          : anonymousOption.slice()
      )
    );
  }

  setRemoteWorkRequestStatusFilter(
    remoteWorkRequestStatusControl: AbstractControl<
      string | RemoteWorkRequestStatus,
      string | RemoteWorkRequestStatus
    >,
    remoteWorkRequestStatuses: RemoteWorkRequestStatus[]
  ) {
    remoteWorkRequestStatuses = remoteWorkRequestStatuses.sort((a, b) =>
      a.description < b.description ? -1 : 1
    );
    return remoteWorkRequestStatusControl.valueChanges.pipe(
      startWith<string | RemoteWorkRequestStatus>(null),
      map(
        value => (typeof value === typeof '' ? <string>value : null) //(<RemoteWorkRequestStatus>value || {}).description
      ),
      map(description =>
        description
          ? this.remoteWorkRequestStatusFilter(
              description.toString(),
              remoteWorkRequestStatuses
            )
          : remoteWorkRequestStatuses.slice()
      )
    );
  }

  setRemoteWorkRequestTypesFilter(
    remoteWorkRequestTypesControl: AbstractControl<
      string | RemoteWorkRequestType,
      string | RemoteWorkRequestType
    >,
    remoteWorkRequestTypes: RemoteWorkRequestType[],
    includeAll = false,
    onFilter = false
  ) {
    if (!includeAll) {
      const defaultIndex = remoteWorkRequestTypes.findIndex(
        r => r.description === defaultRemoteWorkRequestType.description
      );
      if (defaultIndex > -1) {
        remoteWorkRequestTypes.splice(defaultIndex, 1);
      }
    }

    if (onFilter) {
      remoteWorkRequestTypes.unshift(defaultRemoteWorkRequestType);
    }

    return remoteWorkRequestTypesControl.valueChanges.pipe(
      startWith<string | RemoteWorkRequestType>(null),
      map(
        value => (typeof value === typeof '' ? <string>value : null) //(<RemoteWorkRequestType>value || {}).description
      ),
      map(description =>
        description
          ? this.remoteWorkRequestTypesFilter(
              description.toString(),
              remoteWorkRequestTypes
            )
          : remoteWorkRequestTypes.slice()
      )
    );
  }

  setCountriesFilter(
    countriesControl: AbstractControl<string | Country, string | Country>,
    countries: Country[],
    includeAll = false,
    onFilter = false
  ) {
    const filtered = [...countries];
    if (!includeAll) {
      const defaultIndex = countries.findIndex(
        r => r.countryDesc === defaultCountry.countryDesc
      );
      if (defaultIndex > -1) {
        filtered.splice(defaultIndex, 1);
      }
    }
    if (onFilter) {
      filtered.unshift(defaultCountry);
    }
    return countriesControl.valueChanges.pipe(
      startWith<string | Country>(null),
      map(
        value => (typeof value === typeof '' ? <string>value : null) //(<Country>value || {}).countryDesc
      ),
      map(description =>
        description
          ? this.countriesFilter(description.toString(), filtered)
          : filtered.slice()
      )
    );
  }

  private onlinerFilter(
    val: string,
    onliners: Employee[] | CareerMentor[]
  ): Employee[] | CareerMentor[] {
    interface EmployeeCompare {
      firstName: string;
      lastName: string;
    }
    const filterFn = (onliner: EmployeeCompare) =>
      `${onliner.firstName.toLowerCase()} ${onliner.lastName.toLowerCase()}`.includes(
        val.toLowerCase()
      );
    return (onliners as EmployeeCompare[]).filter(filterFn) as typeof onliners;
  }

  private competencyFilter(
    val: string,
    competencies: CompetencyDto[]
  ): CompetencyDto[] {
    interface CompetencyCompare {
      competencyId: number;
      name: string;
    }
    const filterFn = (competency: CompetencyCompare) =>
      `${competency.name.toLowerCase()}`.includes(val.toLowerCase());
    return (competencies as CompetencyCompare[]).filter(
      filterFn
    ) as typeof competencies;
  }

  private practiceFilter(val: string, practices: string[]): string[] {
    return practices.filter(x =>
      `${x.toLowerCase()}`.includes(val.toLowerCase())
    );
  }

  private practiceTypeFilter(
    val: string,
    practiceTypes: PracticeType[]
  ): PracticeType[] {
    interface PracticeCompare {
      practice: string;
    }
    const filterFn = (practice: PracticeCompare) =>
      `${practice.practice.toLowerCase()}`.includes(val.toLowerCase());
    return (practiceTypes as PracticeCompare[]).filter(
      filterFn
    ) as typeof practiceTypes;
  }

  private careerMentorFilter(
    val: string,
    careerMentors: Employee[] | CareerMentor[]
  ): Employee[] | CareerMentor[] {
    interface EmployeeCompare {
      firstName: string;
      lastName: string;
    }
    const filterFn = (onliner: EmployeeCompare) =>
      `${onliner.firstName.toLowerCase()} ${onliner.lastName.toLowerCase()}`.includes(
        val.toLowerCase()
      );
    return (careerMentors as EmployeeCompare[]).filter(
      filterFn
    ) as typeof careerMentors;
  }

  private clientFilter(val: string, clients: Client[]): Client[] {
    return clients.filter(client =>
      `${client.clientName.toLowerCase()}`.includes(val.toLowerCase())
    );
  }

  private feedbackTypeFilter(
    val: string,
    feedbackTypes: FeedbackType[]
  ): FeedbackType[] {
    return feedbackTypes.filter(feedbackType =>
      `${feedbackType.typeDescr.toLowerCase()}`.includes(val.toLowerCase())
    );
  }

  private feedbackStatusFilter(
    val: string,
    feedbackStatuses: FeedbackStatus[]
  ): FeedbackStatus[] {
    return feedbackStatuses.filter(feedbackStatus =>
      `${feedbackStatus.statusDescription.toLowerCase()}`.includes(
        val.toLowerCase()
      )
    );
  }

  private submissionTypeFilter(
    val: string,
    selectListItem: SelectListItem[]
  ): SelectListItem[] {
    return selectListItem.filter(x =>
      `${x.name.toLowerCase()}`.includes(val.toLowerCase())
    );
  }

  private anonymousFilter(
    val: string,
    anonymous: AnonymousOption[]
  ): AnonymousOption[] {
    return anonymous.filter(x =>
      `${x.anonymousDesc.toLowerCase()}`.includes(val.toLowerCase())
    );
  }

  private remoteWorkRequestStatusFilter(
    val: string,
    remoteWorkRequestStatuses: RemoteWorkRequestStatus[]
  ): RemoteWorkRequestStatus[] {
    return remoteWorkRequestStatuses;
  }

  private remoteWorkRequestTypesFilter(
    val: string,
    remoteWorkRequestTypes: RemoteWorkRequestType[]
  ): RemoteWorkRequestType[] {
    return remoteWorkRequestTypes;
  }

  private countriesFilter(val: string, countries: Country[]): Country[] {
    return countries.filter(c =>
      `${c.countryDesc.toLowerCase()}`.includes(val.toLowerCase())
    );
  }
}
