<vex-secondary-toolbar current="Self Assessments">
  <vex-breadcrumbs [crumbs]="['My Feedback', 'Self Assessments']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="component-container">
  <h3 *ngIf="!isLoading && nameReady" class="page-title col-md-12">
    {{ year }} - {{ user.firstName }} {{ user.lastName }}
  </h3>

  <div class="row m-0 mb-4">
    <div *ngIf="(!isUser || isAdmin) && !isLoading" class="col-lg-12 col-md-12 clearfix mb-3">
      <div class="pull-right-on-lg-screen">
        <button type="button" mat-stroked-button class="submit-btn"
          (click)="goBack()">
          <i class="fas fa-angle-double-left"></i> Back
        </button>
        <button
          type="button"
          mat-stroked-button
          class="unsubmit-btn-right"
          [hidden]="!isAdmin || isReviewedForThisYear || isAssessmentFromPreviousYear"
          (click)="displayUnSubmissionConfirmation()">
          <span *ngIf="isSubmitting"><i class="fa fa-spinner fa-spin"
              aria-hidden="true"></i></span>
          UNSUBMIT
        </button>
      </div>
    </div>
    <div *ngIf="isEmpty" class="component-container">No records found</div>
    <div class="col feedback-form-div print-width">
      <div *ngIf="isUser && !isLoading">
        <div class="form-group row no-print">
          <div class="col-md-4">
            <span class="label-header">Fiscal Year:</span>
          </div>
          <div class="col-md-8">
            <mat-form-field appearance="outline" class="col-md-5">
              <mat-select [(ngModel)]="selectedYear" (selectionChange)="
                  getSelfAssessmentReportDetailsByYear($event.value)
                ">
                <mat-option *ngFor="let years of submissionYearsList"
                  [value]="years">
                  {{ years }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="!isLoading && nameReady">
        <div>
          <mat-card appearance="outlined">
            <mat-card-content class="pt-2 pb-2">
              <table class="text-left">
                <tr>
                  <td class="col-md-6 font-weight-bold">
                    <span>Onliner</span>
                  </td>
                  <td>
                    <span>{{ user.firstName }} {{ user.lastName }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="col-md-6 font-weight-bold">
                    <span>Start Date</span>
                  </td>
                  <td>
                    <span>{{ startDate }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="col-md-6 font-weight-bold">
                    <span>Primary Competency</span>
                  </td>
                  <td>
                    <span>{{ user.primaryCompetency }} </span>
                  </td>
                </tr>
                <tr>
                  <td class="col-md-6 font-weight-bold">
                    <span>Consultant Competency</span>
                  </td>
                  <td>
                    <span>{{ user.consultantCompetency }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="col-md-6 font-weight-bold">
                    <span>Practice</span>
                  </td>
                  <td>
                    <span>{{ user.practice }}</span>
                  </td>
                </tr>
              </table>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div *ngIf="selectedYear > 2022">
        <form *ngIf="!isLoading && reportForm && nameReady"
          [formGroup]="reportForm" novalidate>
          <div *ngFor="let question of mainQuestionsForThisForm">
            <mat-card
              appearance="outlined"
              class="p-3 col-md-12 col-sm-12 col-xs-12"
              *ngIf="question && question.questionEnum !== 'ConsultantSA' || user.role === 'Consultant'">
              <mat-card-content style="padding: 10px">
                <h4
                  [ngClass]="{ 'required-label': question && !question.isOptional }">
                  {{getQuestionGroupTitle(question && question.questionEnum)}}
                </h4>

                <div *ngIf="question && question.questionEnum === 'CoreValueSA'">
                  <div class="mt-2">
                    <ul class="core-values-list">
                      <li>CARING & EMPATHY - We look out for each other</li>
                      <li>PASSION - We are energized by what we do</li>
                      <li>INTEGRITY - We do what's right</li>
                      <li>FORWARD THINKING - We are always evolving</li>
                      <li>COMMITMENT & PERSEVERANCE - We finish what we start</li>
                    </ul>
                  </div>
                </div>

                <div *ngIf="question && question.questionEnum === 'CorpCitizenshipSA'">
                  <p>Corporate Citizenship at Online includes being responsive
                    to internal requests, sharing your knowledge/experience with
                    others, providing feedback for team members, and taking
                    responsibility to know what's happening within your
                    practice/business unit/company and how you can contribute.</p>
                  <p>Here is your annual synopsis</p>
                  <mat-card appearance="outlined">
                    <mat-card-header>
                      <mat-card-title>Feedback</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <div class="mt-3 pb-2">
                        <div class="row">
                          <div class="col-lg-4 col-md-4">
                            <b># Requested From Onliners</b>
                          </div>
                          <div class="col-lg-3 col-md-3">
                            {{ fbRequestCountRequestedFromOnliners }}
                          </div>
                          <div class="col-lg-3 col-md-4">
                            <b># Requested By Onliners</b>
                          </div>
                          <div class="col-lg-2 col-md-1">
                            {{ fbRequestCountRequestedByOnliners }}
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-4 col-md-4">
                            <b># Requested From Clients</b>
                          </div>
                          <div class="col-lg-3 col-md-3">
                            {{ fbRequestCountRequestedFromClients }}
                          </div>
                          <div class="col-lg-3 col-md-4">
                            <b># Provided For Onliners</b>
                          </div>
                          <div class="col-lg-2 col-md-1">
                            {{ fbRequestCountProvidedForOnliners }}
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-4 col-md-4">
                            <b>Resume Updated Date</b>
                          </div>
                          <div class="col-lg-3 col-md-3">
                            {{ resumeUpdateDate }}
                          </div>
                          <div class="col-lg-3 col-md-4">
                            <b># Late Time Sheet Submissions</b>
                          </div>
                          <div class="col-lg-2 col-md-1">
                            {{ countLateTimeSheetSubmission }}
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-lg-4 col-md-4">
                            <b>Weekly PulseCheck Submissions</b>
                          </div>
                          <div class="col-lg-3 col-md-3">
                            {{ countWeeklyHealthSubmission }}
                          </div>
                          <div class="col-lg-3 col-md-4">

                          </div>
                          <div class="col-lg-2 col-md-1">

                          </div>
                        </div>
                        <hr />
                        <div
                          *ngIf="uniqueFeedbacks && uniqueFeedbacks.length > 0">
                          <div
                            *ngFor="let feedback of uniqueFeedbacks; let i = index">
                            <div class="col-3">
                              <b>{{ uniqueFeedbacks[i].submitterName }}</b>
                            </div>
                            <div class="col-3">
                              <span>({{ uniqueFeedbacks[i].typeDescr }})</span>
                            </div>

                            <div
                              *ngFor="let options of uniqueFeedbacks[i].questions; let j = index">
                              <div
                                *ngIf="uniqueFeedbacks[i].answers[j].length !== null && uniqueFeedbacks[i].answers[j].length !== 0">
                                <div class="row pb-1"
                                  *ngIf="uniqueFeedbacks[i].questionType[j] === 'Radio'">
                                  <div class="col-2"></div>
                                  <div class="col-8"><b>{{
                                      uniqueFeedbacks[i].questions[j] }}</b></div>
                                  <div class="col-2">{{
                                    uniqueFeedbacks[i].answers[j] }}</div>
                                </div>

                                <div class="row pt-2 pb-2"
                                  *ngIf="uniqueFeedbacks[i].questionType[j] === 'Text'">
                                  <div class="col-2"></div>
                                  <div class="col-8"> <b>{{
                                      uniqueFeedbacks[i].questions[j] }}</b></div>
                                  <div class="col-2"></div>
                                  <div class="col-2"></div>
                                  <div class="col-9">{{
                                    uniqueFeedbacks[i].answers[j] }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>

                <div *ngIf="question && question.questionEnum === 'GrowthDevSA'">
                  <mat-card appearance="outlined">
                    <mat-card-header>
                      <mat-card-title>PD History</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                      <div class="col-2"><b>Units CAD</b></div>
                      <div class="text-right"
                        *ngIf="PDDeductionSummaryUnitsPrevious.length === 0 && PDDeductionSummaryUnitsCurrent.length === 0">
                        <b class="pr-4">{{0 |currency}}</b>
                      </div>
                      <div *ngIf="PDDeductionSummaryUnitsPrevious.length !== 0">
                        <div class="col-1">
                          {{ budgetYearPrevious }}
                        </div>
                        <div
                          class="row"
                          *ngFor="let option2 of PDDeductionSummaryUnitsPrevious">
                          <div class="col-1"></div>
                          <div class="col-3">
                            {{ option2.deductionDesc }}
                          </div>
                          <div class="col-6">
                            {{ option2.comment }}
                          </div>
                          <div class="col-2 text-right">
                            <span class="pr-4">{{
                              option2.deductionValue | currency
                              }}</span>
                          </div>
                        </div>
                        <div class="text-right">
                          <b class="pr-4">{{
                            sumOfDeductionValues(PDDeductionSummaryUnitsPrevious)
                            | currency
                            }}</b>
                        </div>
                      </div>

                      <div *ngIf="PDDeductionSummaryUnitsCurrent.length !== 0">
                        <div class="col-1">
                          {{ budgetYearCurrent }}
                        </div>
                        <div
                          class="row"
                          *ngFor="let option of PDDeductionSummaryUnitsCurrent">
                          <div class="col-1"></div>
                          <div class="col-3">
                            {{ option.deductionDesc }}
                          </div>
                          <div class="col-6">
                            {{ option.comment }}
                          </div>
                          <div class="col-2 text-right">
                            <span class="pr-4">{{
                              option.deductionValue | currency
                              }}</span>
                          </div>
                        </div>
                        <div class="text-right">
                          <b class="pr-4">{{
                            sumOfDeductionValues(PDDeductionSummaryUnitsCurrent)
                            | currency
                            }}</b>
                        </div>
                      </div>

                      <div class="col-2"><b>Hours</b></div>
                      <div class="text-right"
                        *ngIf="PDDeductionSummaryHoursPrevious.length === 0 && PDDeductionSummaryHoursCurrent.length === 0">
                        <b class="pr-4">0</b>
                      </div>
                      <div *ngIf="PDDeductionSummaryHoursPrevious.length !== 0">
                        <div class="col-2">
                          {{ budgetYearPrevious }}
                        </div>
                        <div
                          class="row"
                          *ngFor="let option2 of PDDeductionSummaryHoursPrevious">
                          <div class="col-1"></div>
                          <div class="col-3">
                            {{ option2.deductionDesc }}
                          </div>
                          <div class="col-6">
                            {{ option2.comment }}
                          </div>
                          <div class="col-2 text-right">
                            <span class="pr-4">{{
                              option2.deductionValue
                              }}</span>
                          </div>
                        </div>
                        <div class="text-right">
                          <b class="pr-4">{{
                            sumOfDeductionValues(PDDeductionSummaryHoursPrevious)
                            }}</b>
                        </div>
                      </div>

                      <div *ngIf="PDDeductionSummaryHoursCurrent.length !== 0">
                        <div class="col-2">
                          {{ budgetYearCurrent }}
                        </div>
                        <div
                          class="row"
                          *ngFor="let option of PDDeductionSummaryHoursCurrent">
                          <div class="col-1"></div>
                          <div class="col-3">
                            {{ option.deductionDesc }}
                          </div>
                          <div class="col-6">
                            {{ option.comment }}
                          </div>
                          <div class="col-2 text-right">
                            <span class="pr-4">{{
                              option.deductionValue
                              }}</span>
                          </div>
                        </div>
                        <div class="text-right">
                          <b class="pr-4">{{
                            sumOfDeductionValues(PDDeductionSummaryHoursCurrent)
                            }}</b>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>

                <div *ngIf="question && question.questionEnum === 'DeliverySA'">
                  <mat-card appearance="outlined">
                    <mat-card-header>
                      <mat-card-title>Data in this section is accurate as of
                        the end of {{ deliveryDisclaimerMonthYear }}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="pt-3 pb-3">
                      <div class="row">
                        <div class="col-3"></div>
                        <div class="col-2 text-right pr-4">
                          <b>{{ fiscalYearForFRDetails(false) }}</b>
                        </div>
                        <div class="col-2 text-right pr-4">
                          <b>{{ fiscalYearForFRDetails(true) }}</b>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3"><b>Base Camp Hours</b></div>
                        <div class="col-2 text-right">
                          {{ previousHours }}
                        </div>
                        <div class="col-2 text-right">
                          {{ currentHours }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3"><b>Total Billable Hours</b></div>
                        <div class="col-2 text-right">
                          {{ previousBillableHours }}
                        </div>
                        <div class="col-2 text-right">
                          {{ currentBillableHours }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3"><b>Utilization %</b></div>
                        <div
                          class="col-2 text-right"
                          *ngIf="checkUtilizationAvailability('previous year')">
                          {{ getUtilization("previous year") | percent: "1.2"
                          }}
                        </div>
                        <div
                          class="col-2 text-right"
                          *ngIf="!checkUtilizationAvailability('previous year')">
                          Not Available
                        </div>
                        <div
                          class="col-2 text-right"
                          *ngIf="checkUtilizationAvailability('current year')">
                          {{ getUtilization("current year") | percent: "1.2"
                          }}
                        </div>
                        <div
                          class="col-2 text-right"
                          *ngIf="!checkUtilizationAvailability('current year')">
                          Not Available
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3"><b>Wellness Hours Taken</b></div>
                        <div class="col-2 text-right">
                          {{ previousSickHours }}
                        </div>
                        <div class="col-2 text-right">
                          {{ currentSickHours }}
                        </div>
                      </div>
                    </mat-card-content>
                    <mat-card-header>
                      <mat-card-title>Work History (YTD)</mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="pl-3 pt-3 pb-3">
                      <div *ngFor="let option of focalWorkHistory">
                        {{ option.companyName }}
                      </div>
                      <div *ngIf="focalWorkHistory.length === 0">
                        Not Available
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>

                <div *ngIf="question.type.toString() === 'Text'">
                  <div class="form-row">
                    <app-self-assessment-question
                      class="self-assessment-question form-group {{ (isReviewer || isEditorReviewer) ? 'col-6' : 'col-12'}}"
                      [question]="question"
                      [reportForm]="reportForm"
                      [control]="asFormControl(reportForm.controls[question.id])">
                    </app-self-assessment-question>
                    <app-self-assessment-question
                      *ngIf="question.reviewerQuestion && (isReviewer || isEditorReviewer)"
                      class="self-assessment-question form-group col-6 {{isReviewedForThisYear ? '' : 'reviewer-question'}}"
                      customLabel="Reviewer Evaluation"
                      [isReviewedForThisYear]="isReviewedForThisYear"
                      [isEditorReviewer]="isEditorReviewer"
                      [question]="question.reviewerQuestion"
                      [reportForm]="reportForm"
                      [control]="asFormControl(reportForm.controls['review-'+question.id])">
                    </app-self-assessment-question>
                  </div>
                </div>
                <div *ngIf="question.type.toString() === 'Radio'">
                  <span class="label-header reduce-label-spacing"
                    [innerHTML]="question.question"></span>
                  <div
                    [ngClass]="{ 'required-label': question && !question.isOptional }"
                    class="font-weight-bold {{isReviewer ? 'col-6' : 'col-12'}}">
                    Please evaluate yourself based on the above expectation
                    description and provide comments to support
                  </div>
                  <div class="form-row">
                    <app-self-assessment-question
                      class="self-assessment-question form-group {{isReviewer ? 'col-6' : 'col-12'}}"
                      [question]="question"
                      [reportForm]="reportForm"
                      [control]="asFormControl(reportForm.controls[question.id])"
                      [questionOptions]="questionOptions">
                    </app-self-assessment-question>
                    <app-self-assessment-question
                      *ngIf="question.reviewerQuestion && isReviewer"
                      class="self-assessment-question form-group col-6 {{isReviewedForThisYear ? '' : 'reviewer-question'}}"
                      [isReviewedForThisYear]="isReviewedForThisYear"
                      [question]="question"
                      [reportForm]="reportForm"
                      [control]="asFormControl(reportForm.controls['review-'+question.id])"
                      [questionOptions]="questionOptions">
                    </app-self-assessment-question>
                  </div>
                </div>

                <div *ngFor="let subQuestion of subQuestionsForThisForm">
                  <div
                    *ngIf="subQuestion.questionEnum === question.questionEnum"
                    class="w-100">
                    <div *ngIf="subQuestion.type.toString() === 'Text'">
                      <div class="form-row">
                        <app-self-assessment-question
                          class="self-assessment-question form-group {{(isReviewer || isEditorReviewer) ? 'col-6' : 'col-12'}}"
                          [question]="subQuestion"
                          [reportForm]="reportForm"
                          [control]="asFormControl(reportForm.controls[subQuestion.id])">
                        </app-self-assessment-question>
                        <app-self-assessment-question
                          *ngIf="subQuestion.reviewerQuestion && isReviewer"
                          class="self-assessment-question form-group col-6 {{isReviewedForThisYear ? '' : 'reviewer-question'}}"
                          customLabel="Reviewer Evaluation"
                          [isReviewedForThisYear]="isReviewedForThisYear"
                          [isEditorReviewer]="isEditorReviewer"
                          [question]="subQuestion.reviewerQuestion"
                          [reportForm]="reportForm"
                          [control]="asFormControl(reportForm.controls['review-'+subQuestion.reviewerQuestion.questionId])">
                        </app-self-assessment-question>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
            <p *ngIf="question && question.questionEnum === 'CoreValueSA'">
              <strong>Developing</strong> - Not currently achieving consistent
              results
              but in the process of developing/working towards a more consistent
              outcome.<br>
              <strong>Meeting Expectations</strong> - Consistently meeting
              expectations, possibly exceeding expectations at times.<br>
              <strong>Exceeding Expectations</strong> - Consistently exceeding
              expectations.<br>
            </p>
          </div>

          <div *ngIf="(isReviewer || isEditorReviewer)" [ngClass]="isCollapsed ? 'fixed-above-footer fixed-collapsed' : 'fixed-above-footer fixed-expanded'">
            <button
              type="button"
              mat-stroked-button
              class="save-btn ml-2"
              color="basic"
              (click)="saveFeedback()"
              [disabled]="reportForm.pristine || isSubmitting || isSaving || submittedForThisYear || ( isReviewedForThisYear && !isEditorReviewer)">
              <span *ngIf="isSaving"><i class="fa fa-spinner fa-spin"
                  aria-hidden="true"></i></span>
              {{ isSaved ? "UPDATE" : "SAVE" }}
            </button>
            <button
              type="button"
              mat-stroked-button
              class="submit-btn"
              color="primary"
              (click)="displayPreSubmissionConfirmation()"
              [disabled]="reportForm.invalid || isSubmitting || isSaving || submittedForThisYear || (isReviewedForThisYear  && !isEditorReviewer) ">
              <span *ngIf="isSubmitting"><i class="fa fa-spinner fa-spin"
                  aria-hidden="true"></i></span>
              SUBMIT
            </button>
          </div>
        </form>
      </div>

      <div *ngIf="selectedYear <= 2022">
        <form *ngIf="!isLoading && reportForm && nameReady"
          [formGroup]="reportForm" novalidate>
          <fieldset disabled>
            <div *ngFor="let question of mainQuestionsForThisForm">
              <div *ngIf="question.type.toString() !== 'Date_Input'">
                <span *ngIf="question.type.toString() === 'Text'"
                  class="reduce-label-spacing text-label"
                  [ngClass]="{ 'required-label': question && !question.isOptional }">{{
                  displayQuestion(question) }}</span>
                <mat-card appearance="outlined"
                  class="col-md-12 col-sm-10 col-xs-12"
                  *ngIf="question && question.questionEnum !== 'ConsultantSA' || user.role === 'Consultant'">
                  <mat-card-content style="padding: 10px">
                    <div *ngIf="question.type.toString() === 'Radio'">
                      <div class="row">
                        <div class="form-group col-md-12">
                          <h4 *ngIf="question && question.questionEnum === 'GrowthDevSA'">
                            GROWTH & DEVELOPMENT
                          </h4>
                          <mat-card appearance="outlined"
                          *ngIf="question && question.questionEnum === 'GrowthDevSA' && isDisplay">
                            <mat-card-header>
                              <mat-card-title>Goals - active and completed
                                during fiscal year
                              </mat-card-title>
                            </mat-card-header>
                            <mat-card-content class="mt-3 pb-3">
                              <div class="ml-3" *ngIf="hasOnlinerGoals">
                                <div class="row">
                                  <div class="col-1"><b>Status</b></div>
                                  <div class="col-7"></div>
                                  <div class="col-2"><b>Created</b></div>
                                  <div class="col-1"><b>Modified</b></div>
                                </div>
                                <div class="row"
                                  *ngFor="let goal of onlinerGoals">
                                  <div class="col-1">
                                    <span>{{ goalStatus(goal.goalStatus) }}</span>
                                  </div>
                                  <div class="col-7">
                                    <span>{{ htmlDecode(goal.goal) }}</span>
                                  </div>
                                  <div class="col-2">
                                    <span>{{
                                      dateFormating(goal.createdDate)
                                      }}</span>
                                  </div>
                                  <div class="col-1">
                                    <span>{{
                                      dateFormating(goal.lastModifiedDate)
                                      }}</span>
                                  </div>
                                </div>
                              </div>
                              <b class="ml-3" *ngIf="!hasOnlinerGoals">No goals
                                available</b>
                            </mat-card-content>
                          </mat-card>
                          <mat-card appearance="outlined" *ngIf="question && question.questionEnum === 'GrowthDevSA' && isDisplay">
                            <mat-card-header>
                              <mat-card-title>Feedback</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                              <div class="mt-3 pb-2">
                                <div class="row print-row">
                                  <div class="col-lg-3">
                                    <b># Requested From Other Onliners</b>
                                  </div>
                                  <div class="col-sm-1">
                                    {{ fbRequestCountRequestedFromOnliners }}
                                  </div>
                                  <div class="col-lg-3">
                                    <b># Requested By Onliners</b>
                                  </div>
                                  <div class="col-sm-1">
                                    {{ fbRequestCountRequestedByOnliners }}
                                  </div>
                                </div>
                                <div class="row mb-3">
                                  <div class="col-lg-3">
                                    <b># Requested From Clients</b>
                                  </div>
                                  <div class="col-sm-1">
                                    {{ fbRequestCountRequestedFromClients }}
                                  </div>
                                  <div class="col-lg-3">
                                    <b># Provided For Onliners</b>
                                  </div>
                                  <div class="col-sm-1">
                                    {{ fbRequestCountProvidedForOnliners }}
                                  </div>
                                </div>
                                <div
                                  *ngIf="uniqueFeedbacks && uniqueFeedbacks.length > 0">
                                  <div
                                    *ngFor="let feedback of uniqueFeedbacks; let i = index">
                                    <div class="col-3">
                                      <b>{{ uniqueFeedbacks[i].submitterName }}</b>
                                    </div>
                                    <div class="col-3">
                                      <span>({{ uniqueFeedbacks[i].typeDescr }})</span>
                                    </div>

                                    <div
                                      *ngFor="let options of uniqueFeedbacks[i].questions; let j = index">
                                      <div
                                        *ngIf="uniqueFeedbacks[i].answers[j].length !== null && uniqueFeedbacks[i].answers[j].length !== 0">
                                        <div class="row pb-1"
                                          *ngIf="uniqueFeedbacks[i].questionType[j] === 'Radio'">
                                          <div class="col-2"></div>
                                          <div class="col-8"><b>{{
                                              uniqueFeedbacks[i].questions[j] }}</b></div>
                                          <div class="col-2">{{
                                            uniqueFeedbacks[i].answers[j] }}</div>
                                        </div>

                                        <div class="row pt-2 pb-2"
                                          *ngIf="uniqueFeedbacks[i].questionType[j] === 'Text'">
                                          <div class="col-2"></div>
                                          <div class="col-8"> <b>{{
                                              uniqueFeedbacks[i].questions[j] }}</b></div>
                                          <div class="col-2"></div>
                                          <div class="col-2"></div>
                                          <div class="col-9">{{
                                            uniqueFeedbacks[i].answers[j] }}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>

                          <div
                            *ngIf="question && question.questionEnum === 'GrowthDevSA' && isDisplay">
                            <b>Please rate yourself for Growth & Development</b>
                          </div>
                          <div class="page-break"></div>
                          <h4 *ngIf="question && question.questionEnum === 'CoreValueSA'">
                            CORE VALUES
                          </h4>
                          <div class="mt-2"
                            *ngIf="question && question.questionEnum === 'CoreValueSA' && isDisplay">
                            <b>Please rate yourself for Core Values</b>
                          </div>
                          <h4
                            *ngIf="question && question.questionEnum === 'CorpCitizenshipSA'">
                            CORPORATE CITIZENSHIP
                          </h4>
                          <mat-card appearance="outlined"
                          *ngIf="question && question.questionEnum === 'CorpCitizenshipSA' && isDisplay">
                            <mat-card-header></mat-card-header>
                            <mat-card-content>
                              <div class="mt-3 pb-2">
                                <div class="row">
                                  <div class="col-lg-3">
                                    <b>Resume Update Date</b>
                                  </div>
                                  <div class="col-sm-2">
                                    {{ resumeUpdateDate }}
                                  </div>
                                  <div class="col-lg-3">
                                    <b>#Late Time Sheet Submission</b>
                                  </div>
                                  <div class="col-sm-2">
                                    {{ countLateTimeSheetSubmission }}
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-lg-3">
                                    <b>Weekly PulseCheck Submission</b>
                                  </div>
                                  <div class="col-sm-2">
                                    {{ countWeeklyHealthSubmission }}
                                  </div>
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                          <b class="mt-2"
                            *ngIf="question && question.questionEnum === 'CorpCitizenshipSA' && isDisplay">
                            Please rate yourself for Corporate Citizenship</b>
                          <div class="page-break"></div>
                          <h4 *ngIf="question && question.questionEnum === 'ProficiencySA'">
                            <span
                              *ngIf="user.role === 'Consultant'; else business">PRIMARY
                              COMPETENCY</span>
                            <ng-template #business>ROLE</ng-template>
                            PROFICIENCY
                          </h4>
                          <!-- Budget Summary -->
                          <mat-card
                            appearance="outlined"
                            *ngIf="question && question.questionEnum === 'ProficiencySA' && isDisplay"
                            class="pt-3 pb-3">
                            <mat-card-header>
                              <mat-card-title>PD History</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                              <div class="col-2"><b>Units CAD</b></div>
                              <div class="text-right"
                                *ngIf="PDDeductionSummaryUnitsPrevious.length === 0 && PDDeductionSummaryUnitsCurrent.length === 0">
                                <b class="pr-4">{{0 |currency}}</b>
                              </div>
                              <div
                                *ngIf="PDDeductionSummaryUnitsPrevious.length !== 0">
                                <div class="col-1">
                                  {{ budgetYearPrevious }}
                                </div>
                                <div class="row"
                                  *ngFor="let option2 of PDDeductionSummaryUnitsPrevious">
                                  <div class="col-1"></div>
                                  <div class="col-3">
                                    {{ option2.deductionDesc }}
                                  </div>
                                  <div class="col-6">
                                    {{ option2.comment }}
                                  </div>
                                  <div class="col-2 text-right">
                                    <span class="pr-4">{{
                                      option2.deductionValue | currency
                                      }}</span>
                                  </div>
                                </div>
                                <div class="text-right">
                                  <b class="pr-4">{{
                                    sumOfDeductionValues(PDDeductionSummaryUnitsPrevious)
                                    | currency
                                    }}</b>
                                </div>
                              </div>

                              <div
                                *ngIf="PDDeductionSummaryUnitsCurrent.length !== 0">
                                <div class="col-1">
                                  {{ budgetYearCurrent }}
                                </div>
                                <div class="row"
                                  *ngFor="let option of PDDeductionSummaryUnitsCurrent">
                                  <div class="col-1"></div>
                                  <div class="col-3">
                                    {{ option.deductionDesc }}
                                  </div>
                                  <div class="col-6">
                                    {{ option.comment }}
                                  </div>
                                  <div class="col-2 text-right">
                                    <span class="pr-4">{{
                                      option.deductionValue | currency
                                      }}</span>
                                  </div>
                                </div>
                                <div class="text-right">
                                  <b class="pr-4">{{
                                    sumOfDeductionValues(PDDeductionSummaryUnitsCurrent)
                                    | currency
                                    }}</b>
                                </div>
                              </div>

                              <div class="col-2"><b>Hours</b></div>
                              <div class="text-right"
                                *ngIf="PDDeductionSummaryHoursPrevious.length === 0 && PDDeductionSummaryHoursCurrent.length === 0">
                                <b class="pr-4">0</b>
                              </div>
                              <div
                                *ngIf="PDDeductionSummaryHoursPrevious.length !== 0">
                                <div class="col-2">
                                  {{ budgetYearPrevious }}
                                </div>
                                <div class="row"
                                  *ngFor="let option2 of PDDeductionSummaryHoursPrevious">
                                  <div class="col-1"></div>
                                  <div class="col-3">
                                    {{ option2.deductionDesc }}
                                  </div>
                                  <div class="col-6">
                                    {{ option2.comment }}
                                  </div>
                                  <div class="col-2 text-right">
                                    <span class="pr-4">{{
                                      option2.deductionValue
                                      }}</span>
                                  </div>
                                </div>
                                <div class="text-right">
                                  <b class="pr-4">{{
                                    sumOfDeductionValues(PDDeductionSummaryHoursPrevious)
                                    }}</b>
                                </div>
                              </div>

                              <div
                                *ngIf="PDDeductionSummaryHoursCurrent.length !== 0">
                                <div class="col-2">
                                  {{ budgetYearCurrent }}
                                </div>
                                <div class="row"
                                  *ngFor="let option of PDDeductionSummaryHoursCurrent">
                                  <div class="col-1"></div>
                                  <div class="col-3">
                                    {{ option.deductionDesc }}
                                  </div>
                                  <div class="col-6">
                                    {{ option.comment }}
                                  </div>
                                  <div class="col-2 text-right">
                                    <span class="pr-4">{{
                                      option.deductionValue
                                      }}</span>
                                  </div>
                                </div>
                                <div class="text-right">
                                  <b class="pr-4">{{
                                    sumOfDeductionValues(PDDeductionSummaryHoursCurrent)
                                    }}</b>
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                          <div class="mt-2"
                          *ngIf="question && question.questionEnum === 'ProficiencySA' && isDisplay">
                            <b>Please rate yourself for Role Proficiency</b>
                          </div>
                          <div class="page-break"></div>
                          <h4 *ngIf="question && question.questionEnum === 'DeliverySA'">
                            DELIVERY
                          </h4>
                          <!-- DeliverySA -->
                          <mat-card appearance="outlined"
                          *ngIf="question && question.questionEnum === 'DeliverySA' && isDisplay">
                            <mat-card-header>
                              <mat-card-title>Data in this section is accurate
                                as of the end of {{
                                deliveryDisclaimerMonthYear }}</mat-card-title>
                            </mat-card-header>
                            <mat-card-content class="pt-3 pb-3">
                              <div class="row">
                                <div class="col-3"></div>
                                <div class="col-2 text-right pr-4">
                                  <b>{{ fiscalYearForFRDetails(false) }}</b>
                                </div>
                                <div class="col-2 text-right pr-4">
                                  <b>{{ fiscalYearForFRDetails(true) }}</b>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-3"><b>Base Camp Hours</b></div>
                                <div class="col-2 text-right">
                                  {{ previousHours }}

                                </div>
                                <div class="col-2 text-right">
                                  {{ currentHours }}
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-3">
                                  <b>Total Billable Hours</b>
                                </div>
                                <div class="col-2 text-right">
                                  {{ previousBillableHours }}
                                </div>
                                <div class="col-2 text-right">
                                  {{ currentBillableHours }}
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-3"><b>Utilization %</b></div>
                                <div class="col-2 text-right"
                                  *ngIf="checkUtilizationAvailability('previous year')">
                                  {{
                                  getUtilization('previous year')
                                  | percent: "1.2"
                                  }}
                                </div>
                                <div class="col-2 text-right"
                                  *ngIf="!checkUtilizationAvailability('previous year')">
                                  Not Available
                                </div>
                                <div class="col-2 text-right"
                                  *ngIf="checkUtilizationAvailability('current year')">
                                  {{
                                  getUtilization('current year')
                                  | percent: "1.2"
                                  }}
                                </div>
                                <div class="col-2 text-right"
                                  *ngIf="!checkUtilizationAvailability('current year')">
                                  Not Available
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-3">
                                  <b>Wellness Hours Taken</b>
                                </div>
                                <div class="col-2 text-right">
                                  {{ previousSickHours }}
                                </div>
                                <div class="col-2 text-right">
                                  {{ currentSickHours }}
                                </div>
                              </div>
                            </mat-card-content>
                            <mat-card-header>
                              <mat-card-title>Work History (YTD)</mat-card-title>
                            </mat-card-header>
                            <mat-card-content class="pl-3 pt-3 pb-3">
                              <div *ngFor="let option of focalWorkHistory">
                                {{ option.companyName }}
                              </div>
                              <div *ngIf="focalWorkHistory.length === 0">
                                Not Available
                              </div>
                            </mat-card-content>
                          </mat-card>

                          <div class="mt-2"
                          *ngIf="question && question.questionEnum === 'DeliverySA' && isDisplay">
                            <b>Please rate yourself for Delivery</b>
                          </div>

                          <h4 *ngIf="question && question.questionEnum === 'ConsultantSA' && user.role === 'Consultant'">
             =               CONSULTING SKILLS
                          </h4>
                          <span class="label-header reduce-label-spacing">{{
                            displayQuestion(question)
                            }}</span>
                          <span *ngIf="question && question.questionEnum === 'ConsultantSA'"
                            class="label-header reduce-label-spacing">
                            Associate Consultants: Meets expectations -
                            Demonstrates proficiency in initiative,
                            adaptability,
                            communication, and managing expectations.
                            Acknowledges
                            where there is room for improvement and works to
                            address.<br /><br />
                            Consultants: Meets expectations - Demonstrates
                            proficiency in initiatives, adaptability,
                            communication, managing expectations, mentorship
                            (formal and informal), and leadership. Acknowledges
                            where there is room for improvement and works to
                            address.<br /><br />
                            Senior Consultants: Meets expectations -
                            Demonstrates
                            proficiency in initiatives, adaptability,
                            communication, managing expectations, mentorship
                            (formal and informal), leadership, salability (self
                            and others), and thought leadership. Acknowledges
                            where there is room for improvement and works to
                            address.<br /><br />
                            Principal Consultant: Meets expectations -
                            Demonstrates proficiency in initiatives,
                            adaptability,
                            communication, managing expectations, mentorship
                            (formal and informal), leadership, salability (self
                            and others), pre-sales, and opportunity
                            identification. Acknowledges where there is room for
                            improvement and works to address.
                          </span>

                          <mat-grid-list
                            cols="{{ validQuestionOptions(question).length }}"
                            rowHeight="60px">
                            <mat-grid-tile id="mat-grid-option-display"
                              *ngFor="
                              let option of validQuestionOptions(question)
                            "><span
                                class="font-weight-bold displaySpan centerText">{{
                                optionDisplay(option)
                                }}</span></mat-grid-tile>
                          </mat-grid-list>

                          <div *ngIf="optionsHaveScore(question)">
                            <mat-grid-list
                              cols="{{ validQuestionOptions(question).length }}"
                              rowHeight="60px">
                              <mat-grid-tile id="mat-grid-option-display"
                                *ngFor="
                                let option of validQuestionOptions(question)
                              "><span
                                  class="font-weight-bold displaySpan centerText">{{
                                  radioOptionValueDisplay(option) }}</span></mat-grid-tile>
                            </mat-grid-list>
                          </div>

                          <mat-grid-list
                            cols="{{ validQuestionOptions(question).length }}"
                            rowHeight="60px">
                            <mat-radio-group formControlName="{{ question.id }}">
                              <mat-grid-tile *ngFor="
                                let option of validQuestionOptions(question)
                              ">
                                <mat-radio-button class="question-radio-button"
                                  disabled [value]="option.id">
                                </mat-radio-button>
                              </mat-grid-tile>
                            </mat-radio-group>
                          </mat-grid-list>
                        </div>
                        <!-- sub Question for Growth & Development -->
                        <div
                          *ngIf="question && question.questionEnum === 'GrowthDevSA' && this.isDisplay"
                          class="w-100 p-1">
                          <div
                            *ngFor="let subQuestion of subQuestionsForThisForm">
                            <b *ngIf="subQuestion.type.toString() === 'Text' && subQuestion.questionEnum === 'GrowthDevSA'"
                            class="ml-3 reduce-label-spacing text-label">{{
                              displayQuestion(subQuestion) }}</b>
                            <div *ngIf="subQuestion.type.toString() === 'Text' && subQuestion.questionEnum === 'GrowthDevSA'">
                              <mat-form-field appearance="outline"
                                class="no-padding row form-group col-md-12 col-sm-10 col-xs-12 no-border">
                                <textarea type="text" matInput
                                  cdkTextareaAutosize [disabled]="true"
                                  [readonly]="true"
                                  #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="5"
                                  [value]="reportForm.get(getID(subQuestion.id))?.value">
                                </textarea>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                        <!-- sub Question for Core Values -->
                        <div
                          *ngIf="question && question.questionEnum === 'CoreValueSA' && this.isDisplay"
                          class="w-100 p-1">
                          <div
                            *ngFor="let subQuestion of subQuestionsForThisForm">
                            <b
                            *ngIf="subQuestion.type.toString() === 'Text' && subQuestion.questionEnum === 'CoreValueSA'"
                            class="ml-3 reduce-label-spacing text-label">
                              {{ displayQuestion(subQuestion) }}
                            </b>
                            <div
                            *ngIf="subQuestion.type.toString() === 'Text' && subQuestion.questionEnum === 'CoreValueSA'">
                              <mat-form-field appearance="outline"
                                class="no-padding row form-group col-md-12 col-sm-10 col-xs-12 no-border">
                                <textarea type="text" matInput
                                  cdkTextareaAutosize [disabled]="true"
                                  [readonly]="true"
                                  #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="5"
                                  [value]="reportForm.get(getID(subQuestion.id))?.value">
                                </textarea>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                        <!-- sub Question for Corporate Citizenship -->
                        <div
                          *ngIf="question && question.questionEnum === 'CorpCitizenshipSA' && this.isDisplay"
                          class="w-100 p-1">
                          <div
                            *ngFor="let subQuestion of subQuestionsForThisForm">
                            <b *ngIf="subQuestion.type.toString() === 'Text' && subQuestion.questionEnum === 'CorpCitizenshipSA'" class="ml-3 reduce-label-spacing text-label">{{
                              displayQuestion(subQuestion) }}</b>
                            <div *ngIf="subQuestion.type.toString() === 'Text' && subQuestion.questionEnum === 'CorpCitizenshipSA'">
                              <mat-form-field appearance="outline"
                                class="no-padding row form-group col-md-12 col-sm-10 col-xs-12 no-border">
                                <textarea type="text" matInput
                                  cdkTextareaAutosize [disabled]="true"
                                  [readonly]="true"
                                  #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="5"
                                  [value]="reportForm.get(getID(subQuestion.id))?.value">
                                </textarea>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                        <!-- sub Question for Role Proficiency -->
                        <div
                          *ngIf="question && question.questionEnum === 'ProficiencySA' && this.isDisplay"
                          class="w-100 p-1">
                          <div
                            *ngFor="let subQuestion of subQuestionsForThisForm">
                            <b *ngIf="subQuestion.type.toString() === 'Text' && subQuestion.questionEnum === 'ProficiencySA'" class="ml-3 reduce-label-spacing text-label">
                              {{ displayQuestion(subQuestion) }}</b>
                            <div *ngIf="subQuestion.type.toString() === 'Text' && subQuestion.questionEnum === 'ProficiencySA'">
                              <mat-form-field appearance="outline"
                                class="no-padding row form-group col-md-12 col-sm-10 col-xs-12 no-border">
                                <textarea type="text" matInput
                                  cdkTextareaAutosize [disabled]="true"
                                  [readonly]="true"
                                  #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="5"
                                  [value]="reportForm.get(getID(subQuestion.id))?.value">
                                </textarea>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                        <!-- sub Question for Delivery -->
                        <div
                          *ngIf="question && question.questionEnum === 'DeliverySA'  && this.isDisplay"
                          class="w-100 p-1">
                          <div
                            *ngFor="let subQuestion of subQuestionsForThisForm">
                            <b *ngIf="
                              subQuestion.type.toString() === 'Text' && subQuestion.questionEnum === 'DeliverySA'" class="ml-3 reduce-label-spacing text-label">
                              {{ displayQuestion(subQuestion) }}</b>
                            <div *ngIf="
                              subQuestion.type.toString() === 'Text' && subQuestion.questionEnum === 'DeliverySA'">
                              <mat-form-field appearance="outline"
                                class="no-padding row form-group col-md-12 col-sm-10 col-xs-12 no-border">
                                <textarea type="text" matInput
                                  cdkTextareaAutosize [disabled]="true"
                                  [readonly]="true"
                                  #autosize="cdkTextareaAutosize"
                                  cdkAutosizeMinRows="5"
                                  [value]="reportForm.get(getID(subQuestion.id))?.value">
                                </textarea>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="question.type.toString() === 'Radio_Text'">
                      <div class="row form-group col-md-12 col-sm-10 col-xs-12">
                        <mat-radio-group formControlName="{{ question.id }}">
                          <mat-radio-button
                            *ngFor="let option of validQuestionOptions(question)"
                            [value]="option.id"
                            (change)="radioTextChange($event.value)">
                            <span class="radio-rap">{{
                              optionDisplay(option)
                              }}</span>
                          </mat-radio-button>
                        </mat-radio-group>
                      </div>
                      <mat-form-field appearance="outline"
                        class="row form-group col-md-12 col-sm-10 col-xs-12 no-border">
                        <textarea type="text" matInput cdkTextareaAutosize
                          disabled="true" readonly="true"
                          #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                          [value]="reportForm.get(getID(question.id))?.value"></textarea>
                      </mat-form-field>
                    </div>
                    <!-- <div *ngIf="question.type.toString() == 'Scale'">
                      <div class="row form-group col-md-12 col-sm-10 col-xs-12">
                        <div class="row col-md-12 col-sm-10 col-xs-12">
                          <span>{{ getIntervalTextValue(question, 0) }}</span>
                          <span class="far-right-text">{{
                            getIntervalTextValue(question, 1)
                            }}</span>
                        </div>
                        <mat-slider
                          color="secondary"
                          showTickMarks
                          [disabled]="disabled"
                          [max]="getIntervalMax(question)"
                          [min]="getIntervalMin(question)"
                          [step]="getSliderTickInterval(question)"
                          [thumbLabel]="true">
                          <input
                            matSliderThumb
                            formControlName="{{ question.id }}" />
                        </mat-slider>
                      </div>
                    </div> -->
                    <div *ngIf="question.type.toString() === 'Select'">
                      <div class="row form-group col-md-12 col-sm-10 col-xs-12">
                        <mat-select id="{{ question.id }}"
                          formControlName="{{ question.id }}"
                          >
                          <mat-option
                            *ngFor="let option of validQuestionOptions(question)"
                            [value]="option.id">
                            {{ option.value }}
                          </mat-option>
                        </mat-select>
                        <mat-error
                          *ngIf="reportForm.get(getID(question.id))?.errors">Please
                          enter an answer</mat-error>
                      </div>
                    </div>
                    <div *ngIf="question.type.toString() === 'Text'">
                      <mat-form-field appearance="outline"
                        class="no-padding row form-group col-md-12 col-sm-10 col-xs-12 no-border">
                        <textarea type="text" matInput cdkTextareaAutosize
                          [disabled]="true" [readonly]="true"
                          #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                          [value]="reportForm.get(getID(question.id))?.value">
                        </textarea>
                      </mat-form-field>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
              <span *ngIf="question.type.toString() === 'Date_Input'"
                class="label-header reduce-label-spacing">{{
                displayQuestion(question) }}</span>
              <mat-card appearance="outlined" *ngIf="question.id === 47"
                class="col-md-4 col-sm-10 col-xs-12">
                <mat-card-content>
                  <mat-form-field class="col-md-12 search">
                    <div>
                      <input matInput [readonly]="true"
                        formControlName="{{ question.id }}"
                        [matDatepicker]="startDatePicker"
                        placeholder="MM/DD/YYYY" maxDateRangeValidator="48"
                        (click)="startDatePicker.open()"
                        (focus)="startDatePicker.open()" [min]="minDate" />
                      <mat-datepicker #startDatePicker></mat-datepicker>
                    </div>
                    <span class="error-message" *ngIf="
                      reportForm.get(getID(question.id))?.hasError('datePatternError') &&
                      reportForm.get(getID(question.id))?.dirty
                    ">
                      Must be in Date format
                    </span>
                    <span class="error-message" *ngIf="
                      reportForm.get(getID(question.id))?.hasError('invalidStartDate') &&
                      reportForm.get(getID(question.id))?.touched
                    ">
                      Start Date must be a date and before the End Date.
                    </span>
                  </mat-form-field>
                </mat-card-content>
              </mat-card>
              <mat-card appearance="outlined" *ngIf="question.id === 48"
                class="col-md-4 col-sm-10 col-xs-12">
                <mat-card-content>
                  <mat-form-field class="col-md-12 search">
                    <div>
                      <input matInput [readonly]="true"
                        formControlName="{{ question.id }}"
                        [matDatepicker]="endDatePicker" placeholder="MM/DD/YYYY"
                        minDateRangeValidator="47"
                        (click)="endDatePicker.open()"
                        (focus)="endDatePicker.open()" [min]="minDate" />
                      <mat-datepicker #endDatePicker></mat-datepicker>
                    </div>
                    <span class="error-message" *ngIf="
                      reportForm.get(getID(question.id))?.hasError('datePatternError') &&
                      reportForm.get(getID(question.id))?.dirty
                    ">
                      Must be in Date format
                    </span>
                    <span class="error-message" *ngIf="
                      reportForm.get(getID(question.id))?.hasError('invalidEndDate') &&
                      reportForm.get(getID(question.id))?.touched
                    ">
                      End Date must be a date and greater than Start Date.
                    </span>
                  </mat-form-field>
                </mat-card-content>
              </mat-card>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>
