// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: '##production##',
  apiEndpoint: '##apiEndpoint##',
  goalTrackerLink: '##GoalTrackerLink##',
  postLogoutRedirect: '##postLogoutRedirect##/',
  webApiClientId: '##webApiClientId##',
  cloudInstanceId: '##cloudInstanceId##/',
  tenant: '##tenant##',
  CookiesPolicyLink: '##CookiesPolicyLink##',
  HomePageLink: '##HomePageLink##',
  roles: {
    FeedbackAdmin: '##FeedbackAdmin##',
    FeedbackUser: '##FeedbackUser##',
    FeedbackCm: '##FeedbackCM##',
    FeedbackReviewer: '##FeedbackReviewer##',
    FeedbackCmDelegate: '##FeedbackCMDelegate##',
    FeedbackCFRAdmin: '##FeedbackCFRAdmin##',
    FeedbackPeopleCare: '##FeedbackPeopleCare##',
    FeedbackMarketing: '##FeedbackMarketing##',
    FeedbackLead: '##FeedbackLead##',
    FeedbackCEO: '##FeedbackCEO##',
  },
  autoSaveInterval: 300000, // 5 minutes
  assessmentFiscalYear: {
    StartDay: '##assessmentFiscalStartDay##',
    StartMonth: '##assessmentFiscalStartMonth##',
    EndDay: '##assessmentFiscalEndDay##',
    EndMonth: '##assessmentFiscalEndMonth##',
  },
  buildNumber: '##buildNumber##',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
