import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  FbWeeklyReport,
  FbWeeklyReportSubmissionData,
} from 'src/app/core/services/weekly-report-service';

@Component({
  selector: 'app-weekly-health-delivery-history-list',
  templateUrl: './weekly-health-delivery-history-list.component.html',
  styleUrls: ['./weekly-health-delivery-history-list.component.scss'],
})
export class WeeklyHealthDeliveryHistoryListComponent implements OnChanges {
  @Input() set feedbacks(feedbck: FbWeeklyReport[]) {
    this.initialList = feedbck;
    if (feedbck) {
      this.isLoading = false;
      this.getMyWeeklyReportListByYear(this.currentYear);
    } else {
      this.dataSource = new MatTableDataSource<FbWeeklyReport>();
    }
  }

  @Input()
  year!: number;
  @Input()
  surveyYears!: number[];

  @ViewChild(MatSort, { static: true })
  sort!: MatSort;

  isLoading = true;
  selected = [];
  dataSource!: MatTableDataSource<FbWeeklyReport>;
  currentYear = new Date().getFullYear();
  selectedYear = this.currentYear;
  initialList!: FbWeeklyReport[];
  filteredSubmissionList!: FbWeeklyReport[];
  weeklySubmissionDates!: FbWeeklyReportSubmissionData[];

  resultColumnHeaders: string[] = ['submittedDate'];

  constructor(private router: Router) {}

  targetWeekMonday(targetWeek: Date) {
    const endDate = new Date(targetWeek);
    const prevMonday = new Date(targetWeek).setDate(endDate.getDate() - 4);
    return prevMonday;
  }

  ngOnChanges() {
    this.dataSource.sort = this.sort;
  }

  onSelect(selected: FbWeeklyReport) {
    const weeklyReportId = selected.id ? selected.id : 0;
    if (weeklyReportId > 0) {
      const routeToNavigate = 'viewWeeklyReport';
      this.router.navigate([routeToNavigate, weeklyReportId]);
    }
  }

  getMyWeeklyReportListByYear = (year: number) => {
    const todaysDate = new Date();
    if (year > todaysDate.getFullYear()) {
      this.dataSource = new MatTableDataSource<FbWeeklyReport>();
    } else {
      this.filteredSubmissionList = this.initialList.filter(
        wr => new Date(wr['submittedDate']).getFullYear() === year
      );
      this.dataSource = new MatTableDataSource<FbWeeklyReport>(
        this.filteredSubmissionList
      );
      if (!this.filteredSubmissionList) {
        this.dataSource = new MatTableDataSource<FbWeeklyReport>();
      }
    }
  };

  getIsSubmitted(id: number) {
    if (id === 0) {
      return ' - Unsubmitted';
    }
    return '';
  }
}
