import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-session-expired-dialog',
  templateUrl: './session-expired-dialog.component.html',
  styleUrls: ['./session-expired-dialog.component.scss'],
})
export class SessionExpiredDialogComponent implements OnInit, OnDestroy {
  errorMessage!: string | null;
  isRenewingSession = false;
  isLoggingOut = false;
  sub?: Subscription;

  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<SessionExpiredDialogComponent>,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit() {
    return;
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  renewSession() {
    const renewSuccessHandler = () => {
      this.errorMessage = null;
      this.isRenewingSession = false;
      this.dialogRef.close();
      this.snackBarService.message('Session succesfully renewed.');
    };

    this.isRenewingSession = true;
    this.sub?.unsubscribe();

    this.sub = this.authService.acquireToken('popup', 1).subscribe({
      next: renewSuccessHandler,
      error: () => {
        const childSub = this.authService.acquireToken('redirect').subscribe({
          next: renewSuccessHandler,
          error: error => {
            this.errorMessage = error;
            this.isRenewingSession = false;
            this.snackBarService.error('Unable to renew session.');
          },
        });

        this.sub?.add(childSub);
      },
    });
  }

  logout() {
    this.isLoggingOut = true;
    this.sub?.unsubscribe();
    this.authService.logout();
  }
}
