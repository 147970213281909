import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Observable, forkJoin } from 'rxjs';
import { AutoSuggestService } from 'src/app/core/services/auto-suggest.service';
import {
  CareerMentor,
  CareerMentorService,
  SavedCMDelegate,
} from 'src/app/core/services/career-mentor.service';
import {
  Employee,
  OnlinerService,
} from 'src/app/core/services/onliner.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import * as formValidator from 'src/app/shared/validators/form-validator';

@Component({
  selector: 'app-cm-delegation',
  templateUrl: './cm-delegation.component.html',
  styleUrls: ['./cm-delegation.component.scss'],
})
export class CmDelegationComponent implements OnInit {
  cmDelegateForm: UntypedFormGroup = this.createForm();

  careerMentors!: CareerMentor[];
  filteredCareerMentors!: Observable<CareerMentor[]>;
  selectedCareerMentor!: CareerMentor;
  get careerMentorControl() {
    return this.cmDelegateForm.controls['careerMentor'];
  }

  currentDelegates!: SavedCMDelegate[];

  onliners!: Employee[];
  filteredOnliners!: Observable<Employee[]> | Observable<CareerMentor[]>;
  get onlinerControl() {
    return this.cmDelegateForm.controls['onliner'];
  }

  get grantAccessButton() {
    return this.cmDelegateForm.controls['grantAccessButton'];
  }

  isLoading!: boolean;
  isSubmitting!: boolean;

  constructor(
    private careerMentorService: CareerMentorService,
    private onlinerService: OnlinerService,
    private snackBarService: SnackBarService,
    private autoSuggestService: AutoSuggestService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.setupPage();
    this.setupOnlinerDropdownDisabling();
  }

  careerMentorDisplay = (option?: CareerMentor): string =>
    this.autoSuggestService.careerMentorDisplay(option);

  onlinerDisplay = (option?: Employee | CareerMentor): string =>
    this.autoSuggestService.onlinerDisplay(option);

  careerMentorSelected(e: MatAutocompleteSelectedEvent) {
    const value = e.option.value;

    if ((e.source && value) || value === '') {
      this.getOnlinerDelegates(value.userId);
    }
  }

  onlinerSelected(e: MatAutocompleteSelectedEvent) {
    const value = e.option.value;
    if ((e.source && value) || value === '') {
      this.grantAccessButton.enable();
    } else {
      this.grantAccessButton.disable();
    }
  }

  grantAccess() {
    const careerMentor = this.careerMentorControl.value as CareerMentor;
    const onliner = this.onlinerControl.value as Employee;

    if (careerMentor && onliner) {
      this.isSubmitting = true;
      const newDelegate = new SavedCMDelegate(
        careerMentor.userId,
        onliner.userId,
        `${careerMentor.firstName} ${careerMentor.lastName}`,
        `${onliner.firstName} ${onliner.lastName}`
      );

      this.careerMentorService.addDelegate(newDelegate).subscribe(
        () => {
          this.getOnlinerDelegates(careerMentor.userId);
          this.onlinerControl.reset();
          this.isSubmitting = false;
          if (!onliner.isCMDelegate) {
            this.sendNewCMDelegateEmail(newDelegate);
            onliner.isCMDelegate = true;
          }
          this.snackBarService.message('CM Delegate assigned successfully!');
        },
        error => {
          this.isSubmitting = false;
          this.snackBarService.error(error);
        }
      );
    }
  }

  getOnlinerDelegates(userId: string) {
    this.careerMentorService.getDelegatesFor(userId).subscribe(
      data => {
        this.currentDelegates = data.sort((a, b) =>
          a.delegateName.localeCompare(b.delegateName)
        );
        this.fillOnlinersDropdown(userId);
      },
      error => this.snackBarService.error(error)
    );
  }

  fillOnlinersDropdown(currentCMId: string) {
    if (this.onliners) {
      this.onlinerControl.reset();
      const onlinersExceptCMAndExisted = this.onliners.filter(
        f =>
          f.userId !== currentCMId &&
          !this.currentDelegates.some(x => x.delegateId === f.userId)
      );
      this.filteredOnliners = this.autoSuggestService.setOnlinerFilter(
        this.onlinerControl,
        onlinersExceptCMAndExisted
      );
    }
  }

  removeDelegate(d: SavedCMDelegate) {
    this.careerMentorService
      .removeDelegate(d.careerManagerId, d.delegateId)
      .subscribe(
        () => {
          this.getOnlinerDelegates(d.careerManagerId);
          this.evaluateIsDelegate(d);
        },
        error => this.snackBarService.error(error)
      );
  }

  get isLargeWindow() {
    const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    return windowWidth >= 768;
  }

  private setupPage() {
    this.isLoading = true;
    forkJoin([
      this.onlinerService.getOnliners(),
      this.careerMentorService.getCareerMentors(),
    ]).subscribe(
      data => {
        this.onliners = data[0].sort((a, b) =>
          `${a.firstName} ${a.lastName}`.localeCompare(
            `${b.firstName} ${b.lastName}`
          )
        );
        this.careerMentors = data[1].sort((a, b) =>
          `${a.firstName} ${a.lastName}`.localeCompare(
            `${b.firstName} ${b.lastName}`
          )
        );
        this.filteredCareerMentors = this.autoSuggestService.setOnlinerFilter(
          this.careerMentorControl,
          this.careerMentors
        );
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        this.snackBarService.error(error);
      }
    );
  }

  private setupOnlinerDropdownDisabling() {
    this.onlinerControl.disable();

    this.careerMentorControl.statusChanges.subscribe(value => {
      this.onlinerControl.reset();

      if (value === 'VALID') {
        this.onlinerControl.enable();
      } else {
        this.onlinerControl.disable();
        this.currentDelegates = [];
      }
    });
  }

  private createForm() {
    return this.formBuilder.group({
      careerMentor: ['', formValidator.validateOnliner()],
      onliner: ['', formValidator.validateOnliner()],
      grantAccessButton: [''],
    });
  }

  private displayComfirmationMessage(
    isAssignment: boolean,
    delegateInfo: SavedCMDelegate
  ) {
    const confirmDialog = new ConfirmDialog();
    const message = isAssignment
      ? delegateInfo.delegateName +
        ' was not a CM Delegate to any CM team before. An email has been sent to Helpdesk to request that ' +
        delegateInfo.delegateName +
        ' be added to the Feedback CM AD group.'
      : delegateInfo.delegateName +
        ' is no longer a CM Delegate to any CM team. An email has been sent to Helpdesk to request that ' +
        delegateInfo.delegateName +
        ' be removed from the Feedback CM AD group.';
    confirmDialog.message = message;
    confirmDialog.title = 'CM Delegation Helpdesk Email';
    confirmDialog.okButtonTitle = 'OK';

    this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: confirmDialog,
      disableClose: true,
    });
  }

  private sendNewCMDelegateEmail(delegateInfo: SavedCMDelegate) {
    this.careerMentorService
      .sendHelpdeskAddCMDelegationEmail(delegateInfo)
      .subscribe(
        () => {
          this.displayComfirmationMessage(true, delegateInfo);
        },
        error => {
          this.snackBarService.error(error);
        }
      );
  }

  private sendDeleteCMDelegateEmail(delegateInfo: SavedCMDelegate) {
    this.careerMentorService
      .sendHelpdeskDeleteCMDelegationEmail(delegateInfo)
      .subscribe(
        () => {
          this.displayComfirmationMessage(false, delegateInfo);
        },
        error => {
          this.snackBarService.error(error);
        }
      );
  }

  private evaluateIsDelegate(delegateInfo: SavedCMDelegate) {
    this.careerMentorService.isDelegate(delegateInfo.delegateId).subscribe(
      data => {
        if (!data) {
          const onliner = this.onliners.find(
            e => e.userId === delegateInfo.delegateId
          );
          if (onliner) {
            onliner.isCMDelegate = false;
          }
          this.sendDeleteCMDelegateEmail(delegateInfo);
        }
        this.snackBarService.message('CM Delegate removed successfully!');
      },
      error => this.snackBarService.error(error)
    );
  }
}
