import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SavedFbConfidential } from 'src/app/core/services/assessment.service';
import { ConfidentialFeedbackFilterService } from 'src/app/core/services/confidential-feedback-filter.service';
import { FeedbackService } from 'src/app/core/services/feedback.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-confidential-feedback',
  templateUrl: './confidential-feedback.component.html',
  styleUrls: ['./confidential-feedback.component.scss'],
})
export class ConfidentialFeedbackComponent implements OnInit {
  conFeedbacks!: SavedFbConfidential[];
  filteredConFeedbacks!: SavedFbConfidential[];
  filterCriteriaForm!: UntypedFormGroup;
  filterDateDisplay!: string;
  filterOnlinerValue!: string;
  filterPracticeValue!: string;
  filterSubmittedByValue!: string;
  filterStartDateValue!: string;
  filterEndDateValue!: string;
  filtersValue!: string;
  endDate!: Date;
  startDate!: Date;
  isLoading = true;

  constructor(
    private feedbackService: FeedbackService,
    private snackBarService: SnackBarService,
    private feedbackFilterService: ConfidentialFeedbackFilterService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.filterCriteriaForm =
      this.feedbackFilterService.getFilterCriteriaForm();
    this.getConfidentialFeedbacks();
  }

  filterConFeedbacks() {
    const searchCriteria = this.feedbackFilterService.getFilterCriteriaModel();
    this.filterDateDisplay =
      this.feedbackFilterService.getFilterDateRangeDisplay(searchCriteria);
    if (this.conFeedbacks !== null) {
      this.filteredConFeedbacks = this.conFeedbacks.filter(
        f =>
          this.feedbackFilterService.filterOnliner(f, searchCriteria) &&
          this.feedbackFilterService.filterStartDate(f, searchCriteria) &&
          this.feedbackFilterService.filterEndDate(f, searchCriteria) &&
          this.feedbackFilterService.filterPractice(f, searchCriteria) &&
          this.feedbackFilterService.filterSubmitter(f, searchCriteria)
      );
    }
    this.getFilterFormValues();
  }

  getFilterFormValues() {
    this.filterOnlinerValue = `${this.filterCriteriaForm.controls.onliner.value['firstName']} ${this.filterCriteriaForm.controls.onliner.value['lastName']}`;
    this.filterOnlinerValue = this.filterOnlinerValue.trim();
    this.filterPracticeValue = this.filterCriteriaForm.controls.practice.value;
    this.filterSubmittedByValue = `${this.filterCriteriaForm.controls.submittedBy.value['firstName']} ${this.filterCriteriaForm.controls.submittedBy.value['lastName']}`;
    this.filterStartDateValue = this.filterCriteriaForm.controls.startDate.value
      ? `${this.formatDate(this.filterCriteriaForm.controls.startDate.value)}`
      : '';
    this.filterEndDateValue = this.filterCriteriaForm.controls.endDate.value
      ? `${this.formatDate(this.filterCriteriaForm.controls.endDate.value)}`
      : '';
    this.filtersValue = `${this.filterOnlinerValue}, ${this.filterPracticeValue}, ${this.filterSubmittedByValue}, ${this.filterStartDateValue}, ${this.filterEndDateValue}`;
  }

  formatDate(date: Date) {
    const result = date.toLocaleString('default', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
    return result;
  }

  filterDates() {
    this.isLoading = true;
    const searchCriteria = this.feedbackFilterService.getFilterCriteriaModel();
    this.filterDateDisplay =
      this.feedbackFilterService.getFilterDateRangeDisplay(searchCriteria);
    if (
      searchCriteria.startDate >= this.startDate &&
      searchCriteria.endDate <= this.endDate
    ) {
      this.filterConFeedbacks();
      this.isLoading = false;
    } else {
      this.startDate = searchCriteria.startDate;
      this.endDate = searchCriteria.endDate;
      this.getConfidentialFeedbacks();
    }
  }

  private getConfidentialFeedbacks() {
    const searchCriteria = this.feedbackFilterService.getFilterCriteriaModel();
    this.startDate = searchCriteria.startDate;
    this.endDate = searchCriteria.endDate;
    const startDateString =
      this.datePipe.transform(this.startDate, 'longDate') || '';
    const endDateString =
      this.datePipe.transform(this.endDate, 'longDate') || '';
    this.feedbackService
      .getAllConfidentialFeedback(startDateString, endDateString)
      .subscribe(
        data => {
          this.conFeedbacks = data;
          this.isLoading = false;
          this.filterConFeedbacks();
        },
        error => {
          this.snackBarService.error(error);
        }
      );
  }
}
