export const commentTypeDetail = {
  Technical:
    '<span> - depth and breadth of technical knowledge or proficiency </span>',
  Business:
    '<span> - Industry knowledge and understanding of business processes as it relates to problem domain </span>',
  Management:
    '<span> - <ol> <li> Consultants - Self-management </li> <li> Managers - Self-management; management of others (providing and documenting feedback); management of client expectations. </li> </ol> </span>',
  CoreConsultantCompetencies:
    '<span> - All consultants will be measured on certain core attributes, as they are critical to their overall success. These skills are necessary when dealing with business clients, IT clients, and vendors, as well as within the Online culture. (I.e. Communication Skills, Interpersonal/Adaptability Skills, Professionalism, Conflict Stress Management, Teambuilding Skills).</span>',
  ProjectOverview: '<span></span>',
  CareerManager: '<span></span>',
  AnnualSelfEvaluation: '<span></span>',
  AnnualReviewSummaryEmployeeComments: '<span></span>',
  _3MonthReviewSummaryEmployeeComments: '<span></span>',
  Work: '<span></span>',
  Personal: '<span></span>',
  Interpersonal: '<span></span>',
  Leadership: '<span></span>',
  AnnualSelfEvaluationGRT:
    '<span> - Communication, Interpersonal/Adaptability, Professionalism, Managing Expectations, Group Facilitation, Opportunity Identification Skills/Client Relationship Skills, Conflict Stress Management, Team Building Skills/Leadership, Methodology Skills, Time Management, Online Advocate Skills.</span>',
  CorporateCitizenship:
    '<span> - Consider your contributions and areas of development in the Corporate Citizenship competencies as defined in the Consultant Competencies.</span>',
  _2006NonGRTSelfEval: '<span></span>',
};
