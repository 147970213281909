import { environment } from 'src/environments/environment';

export interface Role {
  admin: string;
  user: string;
  cm: string;
  reviewer: string;
  cmdelegate: string;
  cfradmin: string;
  peoplecare: string;
  marketing: string;
  lead: string;
  ceo: string;
}

export const roles: Role = {
  admin: environment.roles.FeedbackAdmin,
  user: environment.roles.FeedbackUser,
  cm: environment.roles.FeedbackCm,
  reviewer: environment.roles.FeedbackReviewer,
  cmdelegate: environment.roles.FeedbackCmDelegate,
  cfradmin: environment.roles.FeedbackCFRAdmin,
  peoplecare: environment.roles.FeedbackPeopleCare,
  marketing: environment.roles.FeedbackMarketing,
  lead: environment.roles.FeedbackLead,
  ceo: environment.roles.FeedbackCEO,
};
