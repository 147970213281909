import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { distinctUntilChanged, filter } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { RemoteWorkRequestService } from 'src/app/core/services/remote-work-request.service';

@Directive({
  selector: '[ifRoleOrReviewer]',
})
export class IfRoleOrReviewerDirective implements OnInit {
  @Input('ifRoleOrReviewer') roleName!: string | string[];

  constructor(
    private cd: ChangeDetectorRef,
    private templateRef: TemplateRef<Element>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
    private remoteWorkRequestService: RemoteWorkRequestService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  ngOnInit() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        distinctUntilChanged()
      )
      .subscribe({
        next: () => this.createView(),
      });
  }

  createView(): void {
    if (!this.authService.isAuthenticated()) {
      this.viewContainer.clear();
      return;
    }

    const roles =
      typeof this.roleName === 'string'
        ? this.roleName.split(',')
        : this.roleName;
    const userId = this.authService.getUserId();
    this.remoteWorkRequestService
      .userCanChangeStatus(userId)
      .subscribe(result => {
        if (this.authService.doesUserHaveRole(roles) || result === true) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
        this.cd.markForCheck();
      });
  }
}
