<vex-secondary-toolbar current="My Feedback">
  <vex-breadcrumbs *ngIf="feedback" [crumbs]="['My Feedback', feedback.clientName , (feedback.submittedDate | date:'MM/dd/yyyy') ?? '']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="row m-0">
  <div class="component-container">
      <!-- <app-feedback-detail [feedback]="feedback" [feedbackTypeIdParam]="feedbackTypeId" [isLegacyFeedback]="isLegacyFeedback" [operationInProgress]="operationInProgress"></app-feedback-detail> -->
      <app-feedback-detail [feedback]="feedback" [feedbackTypeIdParam]="feedbackTypeId" [operationInProgress]="operationInProgress"></app-feedback-detail>
  </div>
</div>
