import { Component, Input, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

export class UpdateDialog {
  title1!: string;
  title2!: string;
  question1!: string;
  question2A!: string;
  question2B!: string;
  question3!: string;
  returning!: boolean;
  extending!: boolean;
  returnDate!: Date;
  expctdStartDate!: Date | null;
  expctdReturnDate!: Date | null;
  okButtonTitle!: string;
  cancelButtonTitle!: string;
  theme!: string;
}

@Component({
  selector: 'app-update-dialog',
  templateUrl: './update-dialog.component.html',
  styleUrls: ['./update-dialog.component.scss'],
})
export class UpdateDialogComponent {
  @Input() updateForm: UntypedFormGroup;
  minDate!: Date;
  maxDate: Date | null;
  currDate: Date = new Date();
  endDate: Date | null = new Date();
  expctdReturn: Date = new Date();
  expctdStart!: Date;
  displayStart!: string;
  displayReturn!: string;
  dateSelected = true;

  isReturning = true;
  isExtending = true;
  public data: UpdateDialog = inject(MAT_DIALOG_DATA);

  constructor(
    private fb: UntypedFormBuilder,
    private updateRef: MatDialogRef<UpdateDialogComponent>
  ) {
    const data = this.data;
    data.returning = this.isReturning;
    data.extending = this.isExtending;

    if (data?.expctdStartDate) {
      this.expctdStart = new Date(data.expctdStartDate);
      this.displayStart = this.formatDate(this.expctdStart);
      this.minDate = this.expctdStart;
    }
    if (data?.expctdReturnDate) {
      this.expctdReturn = new Date(data.expctdReturnDate);
      this.displayReturn = this.formatDate(this.expctdReturn);
    }
    this.maxDate = this.currDate;

    this.updateForm = fb.group({
      returning: [data.returning],
      extending: [data.extending],
      returnDate: [this.endDate],
    });
  }

  public onChange(event: MatSelectChange) {
    if (event.value === true) {
      this.isExtending = false;
      this.dateSelected = false;
      this.minDate = this.expctdStart;
      this.maxDate = this.currDate;
    } else if (event.value === false) {
      this.isExtending = true;
      this.dateSelected = false;
      this.endDate = null;
      this.minDate = new Date(this.expctdReturn);
      this.minDate.setDate(this.minDate.getDate() + 1);
      this.maxDate = this.endDate;
    }
  }

  public onExtending(event: MatSelectChange) {
    if (event.value) {
      this.isExtending = true;
    } else if (!event.value) {
      this.isExtending = false;
      this.updateRef.close();
    }
  }

  public onDateSelect(event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    if (this.endDate) {
      this.dateSelected = true;
    } else {
      this.dateSelected = false;
    }
  }

  public onClose() {
    this.isReturning = false;
    this.isExtending = false;
  }

  submit() {
    this.updateRef.close(this.updateForm.value);
  }

  close() {
    this.updateRef.close();
  }

  private formatDate(date: Date): string {
    let result = '';
    if (!date) {
      return '';
    }

    result += date.getMonth() + 1;
    result += '/';
    result += date.getDate();
    result += '/';
    result += date.getFullYear();

    return result;
  }
}
