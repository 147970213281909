// @ts-nocheck
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {
  goalTrackerLink:string = environment.goalTrackerLink;

  items = [
  ];

  trackById = trackById;
  userName: string;
  public profilePhotoData: string;
  private readonly _destroying$ = new Subject<void>();
  isAuthenticated : boolean = false;

  constructor(private cd: ChangeDetectorRef,
              private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
              private msalBroadcastService: MsalBroadcastService,
              private authService: AuthService) { }

  ngOnInit() {
    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.isAuthenticated = this.authService.isAuthenticated();
      if(this.isAuthenticated) {
        const allRoles: Array<string> = this.authService.getUserRoles();
        if(allRoles.includes(environment.roles.FeedbackAdmin)) {
          this.items = [{
            id: '2',
            label: 'Relocation Config',
            route: '/remoteworkconfig'
          },
          {
            id: '3',
            label: 'CM Delegation Config',
            route: '/cmdelegation'
          },
          {
            id: '4',
            label: 'CM Delegate Report',
            route: '/cmdelegationreport'
          },
          {
            id: '5',
            label: 'Questions Config',
            route: '/questionsConfig'
          }]
          this.cd.detectChanges();
        }
      }
    })
  }

  close() {
    this.popoverRef.close();
  }

  logout(){
    this.close();
    this.authService.logout();
  }
}
