import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { distinctUntilChanged, filter } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { OnlinerService } from 'src/app/core/services/onliner.service';

@Directive({
  selector: '[ifCountry]',
})
export class IfCountryDirective implements OnInit {
  @Input('ifCountry') countryAlpha2Codes!: string[] | null;

  constructor(
    private cd: ChangeDetectorRef,
    private templateRef: TemplateRef<Element>,
    private viewContainer: ViewContainerRef,
    private onlinerService: OnlinerService,
    private authService: AuthService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  ngOnInit() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        distinctUntilChanged()
      )
      .subscribe({
        next: () => this.createView(),
      });
  }

  createView(): void {
    if (!this.countryAlpha2Codes || this.countryAlpha2Codes.length === 0) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      if (!this.authService.isAuthenticated()) {
        this.viewContainer.clear();
        return;
      }

      this.onlinerService.getOnlinerDetails().subscribe({
        next: (data: { countryAlpha2Code: string }) => {
          if (this.countryAlpha2Codes?.includes(data.countryAlpha2Code)) {
            // for non USA users
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainer.clear();
          }
          this.cd.markForCheck();
        },
        error: () => this.viewContainer.clear(),
      });
    }
  }
}
