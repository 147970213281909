import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RemoteWorkConfigComponent } from './remote-work-config.component';

const routes: Routes = [
  {
    path: '',
    component: RemoteWorkConfigComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RemoteWorkConfigRoutingModule {}
