import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { download, generateCsv } from 'export-to-csv';
import { ColumnHeader } from 'export-to-csv/output/lib/types';
import { SavedCMDelegate } from 'src/app/core/services/career-mentor.service';

@Component({
  selector: 'app-cm-delegation-list',
  templateUrl: './cm-delegation-list.component.html',
  styleUrls: ['./cm-delegation-list.component.scss'],
})
export class CmDelegationListComponent {
  delegations!: SavedCMDelegate[];
  delegationViewModel!: MatTableDataSource<CMDelegateVM>;
  isLoading = true;

  @Input() set cmDelegates(cmDelegates: CMDelegateVM[]) {
    // this.delegations = cmDelegates;
    this.delegationViewModel = new MatTableDataSource<CMDelegateVM>(
      cmDelegates
    );
    if (cmDelegates) {
      this.isLoading = false;
    } else {
      this.delegationViewModel = new MatTableDataSource<CMDelegateVM>();
    }
  }

  resultColumnHeaders: string[] = ['careerMentor', 'delegate'];

  constructor(private datePipe: DatePipe) {}

  downloadCSV() {
    const generateCSV = () => {
      const data = this.delegationViewModel.data.map(x => ({
        careerManagerName: x.visibleCareerMentor,
        delegateName: x.delegate,
      }));

      // let headers = ["Career Mentor", "Delegate"];
      const headerByKey: ColumnHeader[] = [
        {
          key: 'careerManagerName',
          displayLabel: 'Career Mentor',
        },
        {
          key: 'delegateName',
          displayLabel: 'Delegate',
        },
      ];
      return { headerByKey, data };
    };
    const generated = generateCSV();
    const now = this.datePipe.transform(new Date(), 'MM/dd/yyyy hh:mm a');
    const csvConfig = {
      fieldSeparator: ',',
      filename: `CM Delegates Report on ${now}`,
      title: `CM Delegates Report on ${now}\n\n`,
      quoteCharacter: '"',
      decimalSeparator: '.',
      columnHeaders: generated.headerByKey,
      showTitle: true,
      useBom: true,
    };
    const file = generateCsv(csvConfig)(generated.data);
    download(csvConfig)(file);
  }
}

class CMDelegateVM {
  constructor(
    public visibleCareerMentor: string,
    public delegate: string,
    public cssClass: string
  ) {}
}
