import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PersonalSelfAssessmentRoutingModule } from './personal-self-assessment-routing.module';
import { PersonalSelfAssessmentComponent } from './personal-self-assessment.component';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';

@NgModule({
  declarations: [PersonalSelfAssessmentComponent],
  imports: [
    CommonModule,
    PersonalSelfAssessmentRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    AngularMaterialModule,
  ],
})
export class PersonalSelfAssessmentModule {}
