<vex-secondary-toolbar current="Relocation Configuration">
  <vex-breadcrumbs [crumbs]="['Relocation Configuration']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="row m-0 component-container">
  <div class="mb-5">
    <h3 class="page-title">Relocation Configuration</h3>
  </div>

  <div class="row m-0">
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 p-0 pr-5 feedback-form-div">
      <form *ngIf="!isLoading" [formGroup]="rwConfigForm" novalidate [style.fontSize.px]="18">

        <div >
          <mat-form-field appearance="outline" class="region-width" [ngClass]="{'editable': true}">
            <mat-label>Region</mat-label>
            <mat-select (click)="validSave()" (selectionChange)="onChange($event)" [(value)]="selected">
              <mat-option [disabled]="changesMade" *ngFor="let rwConfig of rwConfigs" [value]="rwConfig">{{rwConfig.regionDesc}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline" class="onliner-width" [ngClass]="{'editable': true}">
            <mat-label>Choose Approver</mat-label>
            <input name="approver" matInput placeholder="Choose Approver" aria-label="Approver" [matAutocomplete]="approverAuto" formControlName="approver" />
            <mat-autocomplete #approverAuto="matAutocomplete" [displayWith]="onlinerDisplay" (optionSelected)="approverSelected($event)">
              <mat-option class="options" *ngFor="let option of filteredApprovers | async" [value]="option">
                {{onlinerDisplay(option)}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="rwConfigForm.get('approver')?.errors && rwConfigForm.get('approver')?.touched">Please enter the name of a valid Onliner</mat-error>
          </mat-form-field>
        </div>

        <div >
          <mat-form-field appearance="outline" class="onliner-width" [ngClass]="{'editable': true}">
            <mat-label>Choose Alternative Approver</mat-label>
            <input name="secApprover" matInput placeholder="Choose Alternate Approver" aria-label="Alternate Approver" [matAutocomplete]="approver2Auto" formControlName="secApprover" />
            <mat-autocomplete #approver2Auto="matAutocomplete" [displayWith]="onlinerDisplay" (optionSelected)="approverTwoSelected($event)">
              <mat-option class="options" *ngFor="let option of filteredSecApprovers | async" [value]="option">
                {{onlinerDisplay(option)}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="rwConfigForm.get('secApprover')?.errors && rwConfigForm.get('secApprover')?.touched">Please enter the name of a valid Onliner</mat-error>
          </mat-form-field>
        </div>

        <div class="row-btn">
            <button type="button" mat-stroked-button (click)="setDropDownValues()" class="cancel-btn" name="cancelChangesButton" [disabled]="!rwConfigSelected">
                <span *ngIf="changesMade"><i aria-hidden="true"></i></span>CANCEL
            </button>
            <button type="button" mat-stroked-button color="primary" (click)="grantAccess()" class="save-btn" name="grantAccessButton" [disabled]="!rwConfigForm.valid || isSubmitting || !changesMade">
                <span *ngIf="isSubmitting"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>SAVE
            </button>
        </div>

      </form>
    </div>
  </div>
</div>
