import { Location } from '@angular/common';
import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  FbConfidentialAnswer,
  SavedFbConfidential,
} from 'src/app/core/services/assessment.service';
import { ConfidentialQuestionIds } from 'src/app/shared/enums/enums.model';

@Component({
  selector: 'app-confidential-feedback-detail',
  templateUrl: './confidential-feedback-detail.component.html',
  styleUrls: ['./confidential-feedback-detail.component.scss'],
})
export class ConfidentialFeedbackDetailComponent {
  @Input() feedback!: SavedFbConfidential;
  @Input() operationInProgress!: string | null;
  questionIds: typeof ConfidentialQuestionIds = ConfidentialQuestionIds;

  constructor(
    private location: Location,
    public dialog: MatDialog,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {}

  formatDateTimeToDate(dateTime: Date): string {
    const dateObj = new Date(dateTime);
    const formattedDate = dateObj.toLocaleDateString('en-US', {
      timeZone: 'UTC',
    });

    return formattedDate;
  }

  goBack() {
    this.location.back();
  }

  getStringValue(answer: FbConfidentialAnswer) {
    return this.htmlDecode(answer.stringValue);
  }

  getOptionValue(answer: FbConfidentialAnswer) {
    return this.htmlDecode(answer.option?.value || '');
  }

  htmlDecode(input: string) {
    const txt = document.createElement('textarea');
    txt.innerHTML = input;
    return txt.value;
  }
}
