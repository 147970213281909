
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" [hidden]="isLoading || !(dataSource && dataSource.data.length > 0)">

  <ng-container matColumnDef="requestTo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Request To</th>
      <td mat-cell *matCellDef="let value" class="leftAlignCell">
        {{ value.requestTo }}
      </td>
  </ng-container>

  <ng-container matColumnDef="requestDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Request Date</th>
      <td mat-cell *matCellDef="let value" >
        {{ value.requestDate | date:'MM/dd/yyyy' }}
      </td>
  </ng-container>

  <ng-container matColumnDef="submittedDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Submitted Date</th>
      <td mat-cell *matCellDef="let value" >
        {{ value.submittedDate | date:'MM/dd/yyyy'}}
      </td>
  </ng-container>

  <ng-container matColumnDef="daysToSubmission">
      <th mat-header-cell *matHeaderCellDef mat-sort-header >Days to Submission</th>
      <td mat-cell *matCellDef="let value" >
        {{ value.daysToSubmission }}
      </td>
  </ng-container>

  <ng-container matColumnDef="requestType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Request Type</th>
      <td mat-cell *matCellDef="let value" >
        {{ value.requestType }}
      </td>
  </ng-container>

  <ng-container matColumnDef="status" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell  *matCellDef="let value">
        {{ value.status }}
      </td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let value" class="text-center">
      <div *ngIf="isDeletable(value)"><button class="btn btn-link control" (click)="openDeleteConfirmation(value)"><i class="fas fa-trash-alt"></i></button></div>
    </td>
  </ng-container>

<tr mat-header-row *matHeaderRowDef="resultColumnHeaders"></tr>
<tr mat-row class="mat-row-clickable" *matRowDef="let row; columns: resultColumnHeaders"></tr>
</table>
<div *ngIf="dataSource && dataSource.data.length === 0 && !isLoading">No pending feedbacks</div>

<div *ngIf="isLoading" class="grid place-items-center inset-0">
  <mat-spinner></mat-spinner>
</div>
