<form *ngIf="!isLoading && feedbackForm" [formGroup]="feedbackForm" class="mb-5 cmfeedback-form" novalidate>
  <div *ngIf="isSaved && !feedbackId && !dismissed" class="row form-group p-3">
    <mat-card appearance="outlined" class="bg-success text-white">
      <mat-card-content class="flex">
        <button type="button" class="close" aria-label="Close"
          (click)="closeAlert()">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="ml-4">
          <span><i class="far fa-check-circle"></i></span> You've successfully
          saved a feedback draft. <a (click)="resetFeedbackForm()" (keyup.enter)="resetFeedbackForm()" [tabindex]="0" class="alert-link">Create another?</a>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

    <div *ngIf="careerMentor">
    <mat-form-field appearance="outline" class="onliner-width" >
      <mat-label>Career Mentor</mat-label>
        <input class="onliner-width mat-test" matInput type="text" placeholder="Career Mentor"  [disabled]="true" [value]=getOnlinerCM() />
    </mat-form-field>
  </div>

  <div *ngFor="let question of questionsForThisForm">
    <div class="label-header reduce-label-spacing"
      [ngClass]="{'required-label' : !question.isOptional }">{{
      displayQuestion(question) }}</div>
    <mat-card appearance="outlined" class="col-md-12 col-sm-10 col-xs-12">
      <mat-card-content>
        <div *ngIf="question.type.toString() === 'Radio'">
          <div class="form-group col-md-12 col-sm-10 col-xs-12"
            [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
            <mat-radio-group formControlName="{{question.id}}">
              <mat-radio-button
                *ngFor="let option of validQuestionOptions(question)"
                [value]="option.id" (change)="questionFilled()">
                <span class="radio-rap">{{ optionDisplay(option) }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div *ngIf="question.type.toString() === 'Scale'">
          <div class="form-group col-md-12 col-sm-12 col-xs-12"
            [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
            <mat-slider
              color="secondary"
              showTickMarks
              [max]="getIntervalMax(question)"
              [min]="getIntervalMin(question)"
              [step]="getSliderTickInterval(question)"
              #ngSlider>
              <input
                matSliderThumb
                (change)="questionFilled()"
                #ngSliderThumb="matSliderThumb"
                formControlName="{{question.id}}"
                (input)="updateDisplayOption(question, {source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" />
            </mat-slider>
          </div>
          <div class="flex-display">
            <span style="font-size:large"><strong>{{scaleQuestions[question.id]}}</strong></span>
          </div>
        </div>
        <div *ngIf="question.type.toString() === 'Select'">
          <div class="form-group col-md-12 col-sm-10 col-xs-12"
            [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
            <mat-select
              #selectInput
              id="{{question.id}}"
              formControlName="{{question.id}}"
              (input)="onSearchChange(selectInput.value)"
              (change)="questionFilled()">
              <mat-option *ngFor="let option of validQuestionOptions(question)"
                [value]="option.id">
                {{option.value}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="feedbackForm.get(getID(question.id))?.errors">Please enter an answer</mat-error>
          </div>
        </div>
        <div *ngIf="question.type.toString() === 'Text'">
          <mat-form-field appearance="outline"
            class="form-group col-md-12 col-sm-10 col-xs-12"
            [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
            <textarea #textInput type="text"
              matInput
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="5"
              style="overflow:hidden"
              id="{{getID(question.id)}}"
              formControlName="{{getID(question.id)}}"
              (keyup)="textQuestionFilled(textInput.value, question.id)">
            </textarea>
          </mat-form-field>
        </div>
        <div *ngIf="question.type.toString() === 'Radio_Text'">
          <div class="form-group col-md-12 col-sm-10 col-xs-12"
            [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
            <mat-radio-group formControlName="{{question.id}}">
              <mat-radio-button
                *ngFor="let option of validQuestionOptions(question)"
                [value]="option.id" (change)="radioTextChange($event.value, question.id)">
                <span class="radio-rap">{{ optionDisplay(option) }}</span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-form-field appearance="outline"
            class="form-group col-md-12 col-sm-10 col-xs-12"
            [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
            <textarea #textInput type="text"
              matInput
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="5"
              style="overflow:hidden"
              id="{{getRadioTextQuestionId(question.id)}}"
              formControlName="{{getRadioTextQuestionId(question.id)}}"
              (keyup)="textQuestionFilled(textInput.value, question.id)"
              placeholder="Enter comments here...">
            </textarea>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div [ngClass]="isCollapsed ? 'fixed-above-footer fixed-collapsed' : 'fixed-above-footer fixed-expanded'">
      <button type="button" mat-stroked-button class="save-btn"  color="basic" (click)="saveFeedback()" [disabled]="!(isQuestionFilled || isTextQuestionFilled) || isSaving || isSubmitting || feedbackForm.pristine || !hasQuestions">
          <span *ngIf="isSaving"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>{{ isSaved ? 'UPDATE' : 'SAVE' }}
      </button>
      <button type="button" mat-stroked-button class="submit-btn" (click)="submitFeedback()" [disabled]="!feedbackForm.valid || isSubmitting || isSaving || !hasQuestions">
          <span *ngIf="isSubmitting"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span> SUBMIT
      </button>
  </div>

</form>

<div *ngIf="isLoading" class="grid place-items-center inset-0">
  <mat-spinner></mat-spinner>
</div>

