<vex-secondary-toolbar current="Provide Feedback">
  <vex-breadcrumbs [crumbs]="['Client', 'Provide Feedback']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="component-container">
  <h3 class="page-title col-md-12 col-sm-12 col-xs-12"> Provide Feedback - {{ OnlinerName }}</h3>

  <p class="mandatory-text">* All questions marked with an asterisk are mandatory. They will need to be answered before the feedback can be submitted.</p>
  <div class="responsive row m-0">
    <div *ngIf="questionGroupIDs" class="col-md-8 col-sm-12 col-xs-12 feedback-form-div">
      <app-client-feedback-form
        [assessment]="assessment" [feedbackForm]="feedbackForm" [questions]="questions"
        [questionOptions]="questionOptions" [questionOptionGroups]="questionOptionGroups"
        [feedbackId]="feedbackId" [questionOptionGroupIDs]="questionOptionGroupIDs"
        [questionGroupIDs]="questionGroupIDs" [questionGroups]="questionGroups"
        [contactName]="contactName"
        ></app-client-feedback-form>
    </div>

    <div *ngIf="!isLoading" class="col-md-4 col-sm-12 col-xs-12">
        <mat-expansion-panel class="tips no-border-radius" [expanded]="isLargeWindow">
            <mat-expansion-panel-header>
                <mat-panel-title>
                        <h4><i class="fas fa-info-circle" title="Tips for Feedback"></i> Tips for Feedback</h4>
                  </mat-panel-title>
            </mat-expansion-panel-header>
          <p>Online encourages Onliners to solicit feedback on a regular basis throughout the year. We believe feedback is a valuable tool in an Onliner's development,
            benefiting the Onliner, Online, and ultimately our Clients.</p>
        </mat-expansion-panel>
    </div>
  </div>
</div>
