<div class="p-3 dialog-header text-center">
  <span *ngIf="isReturning" class="dialog-title d-inline">{{data.title1}}</span>
  <span *ngIf="!isReturning" class="dialog-title d-inline">{{data.title2}}</span>
  <button mat-dialog-close="cancel" type="button" class="btn btn-link close pull-right text-white no-underline" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="comment-dialog-body">
  <form *ngIf="updateForm" [formGroup]="updateForm">
    <div class="row1">
      <span class="message">Current request start and end date: </span>
      <p class="dateRange">{{displayStart}} - {{displayReturn}}</p>
    </div>
    <div class="row2">
      <span *ngIf="isReturning" class="q1">{{data.question1}}</span>
      <span *ngIf="!isReturning" class="q1">{{data.question3}}</span>
      <mat-form-field *ngIf="isReturning" appearance="outline" class="input1">
        <mat-select [(value)]="isReturning" (selectionChange)="onChange($event)" formControlName="returning">
          <mat-option [value]="true">Yes</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="!isReturning" appearance="outline" class="input1B">
        <mat-select [(value)]="isExtending" (selectionChange)="onExtending($event)" formControlName="extending">
          <mat-option [value]="true">Yes</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row3">
    <span *ngIf="isReturning" class="q2B">{{data.question2B}}</span>
    <span *ngIf="!isReturning && isExtending" class="q2A">{{data.question2A}}</span>
      <mat-form-field appearance="outline" class="input2">
        <input
          matInput
          formControlName="returnDate"
          [matDatepicker]="endDatePicker"
          placeholder="MM/DD/YYYY"
          name="dateEnd"
          (click)="endDatePicker.open()"
          (dateInput)="onDateSelect($event)"
          (focus)="endDatePicker.open()"
          [min]="minDate"
          [max]="maxDate"
          [value]="endDate">
          <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
</div>

<mat-dialog-actions class="submission">
  <div class="row3">
    <button type="button" class="btn btn-action-dialog success-button ml-1" [disabled]="!updateForm.valid || (isExtending && !dateSelected)"
      (click)="submit()">{{data.okButtonTitle}}</button>
  </div>
</mat-dialog-actions>
