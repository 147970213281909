<vex-secondary-toolbar current="Career Mentor Delegation Config">
  <vex-breadcrumbs [crumbs]="['Career Mentor Delegation Config']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="row m-0 component-container">
  <div class="mb-5">
    <h3 class="page-title">Career Mentor Delegation Config</h3>
  </div>

  <div class="responsive row m-0">
    <div
    class="col-xl-3 col-lg-4 col-md-6 col-xs-12 p-0 pr-5 feedback-form-div">

      <form [formGroup]="cmDelegateForm" novalidate [style.fontSize.px]="18">

        <div class="row form-group m-0 mt-2 pl-2"
          [ngClass]="{'has-error': cmDelegateForm.get('careerMentor')?.errors && cmDelegateForm.get('careerMentor')?.touched}">
          <mat-form-field appearance="outline" class="onliner-width"
            [ngClass]="{'editable': true}">
            <mat-label>Choose Career Mentor</mat-label>
            <input name="careerMentor" matInput
              placeholder="Choose Career Mentor" aria-label="Career Mentor"
              [matAutocomplete]="careerMentorAuto"
              formControlName="careerMentor" />
            <mat-autocomplete #careerMentorAuto="matAutocomplete"
              [displayWith]="careerMentorDisplay"
              (optionSelected)="careerMentorSelected($event)">
              <mat-option class="options"
                *ngFor="let option of filteredCareerMentors | async"
                [value]="option">
                {{ careerMentorDisplay(option) }}
              </mat-option>
            </mat-autocomplete>
            <mat-error
              *ngIf="cmDelegateForm.get('careerMentor')?.errors && cmDelegateForm.get('careerMentor')?.touched">Please
              enter the name of the Career Mentor</mat-error>
          </mat-form-field>
        </div>

        <div class="row form-group m-0 mt-2 pl-2">
          <mat-form-field appearance="outline" class="onliner-width"
            [ngClass]="{'editable': true}">
            <mat-label>Choose Onliner</mat-label>
            <input name="onliner" matInput placeholder="Choose Onliner"
              aria-label="Onliner" [matAutocomplete]="onlinerAuto"
              formControlName="onliner" />
            <mat-autocomplete #onlinerAuto="matAutocomplete"
              [displayWith]="onlinerDisplay"
              (optionSelected)="onlinerSelected($event)">
              <mat-option class="options"
                *ngFor="let option of filteredOnliners | async" [value]="option">
                {{ onlinerDisplay(option) }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="row form-group m-0 mt-2">
          <div class="col-md-12 button-max-width text-right">
            <button type="button" mat-stroked-button
              class="save-btn"
              color="primary"
              (click)="grantAccess()" name="grantAccessButton"
              [disabled]="!cmDelegateForm.valid || isSubmitting">
              <span *ngIf="isSubmitting"><i class="fa fa-spinner fa-spin"
                  aria-hidden="true"></i></span>ASSIGN
            </button>
          </div>
        </div>

        <span
          *ngIf="cmDelegateForm && cmDelegateForm.value.careerMentor && cmDelegateForm.get('careerMentor')?.valid"
          class="label-header m-0 mt-2 pl-2">Delegates </span>
        <div class="m-0 mb-2 pl-2">
          <mat-card appearance="outlined"
            *ngIf="cmDelegateForm && cmDelegateForm.value.careerMentor && cmDelegateForm.get('careerMentor')?.valid">
            <mat-card-content>
              <div class="col-md-10 p-0">
                <span *ngFor="let d of currentDelegates">
                  <div class="delegateBox">{{d.delegateName}}<i
                      (click)="removeDelegate(d)" (keyup.enter)="removeDelegate(d)" [tabindex]="0"
                      class="far fa-times-circle fa-lg delete"
                      title="Remove Delegate"></i></div>
                </span>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </form>
    </div>

    <div
      class="col-xl-4 col-lg-3 col-md-6 col-xs-12  fit-content-height">
      <mat-expansion-panel class="tips no-border-radius" [expanded]="isLargeWindow">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h4><i class="fas fa-info-circle" title="Tips for Feedback"></i>
              Tips for CM Delegation</h4>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <p>When assigning/removing a delegate, please send a corresponding email
          to Helpdesk (helpdesk@obsglobal.com) and request that the delegate be
          added/removed from the FeedbackCM AD group.</p>
      </mat-expansion-panel>
    </div>
  </div>

</div>
