<vex-secondary-toolbar current="My CM Feedback">
  <vex-breadcrumbs [crumbs]="['My CM Feedback', 'Received']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="component-container">
  <div class="row col-md-8">
    <h3 class="page-title col-md-5 pl-0 ">My Received CM Feedback</h3>
    <mat-form-field appearance="outline" class="col-md-3 pr-0">
      <mat-label>Year</mat-label>
      <mat-select [(ngModel)]="selectedYear" name="year"
        (selectionChange)="getMyReceivedCmFeedbacks($event.value)">
        <mat-option *ngFor="let year of surveyYears" [value]="year">
          {{ year }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="quantitativeData">
    <div class="row col-md-8" *ngFor="let q of quantitativeData; let ix = index">
      <mat-card appearance="outlined" class="mb-1 col-md-7 question-card flex-row">
        <mat-card-content><div>{{ix+1}}. {{ q.question }} ({{ q.maxGroupScore
            }})</div></mat-card-content>
      </mat-card>
      <mat-card appearance="outlined" class="mb-1 col-md-1 score-card"
        matTooltip="The average score for this question would only show up once there are at least 2 valid responses."
        [matTooltipDisabled]="isTooltipDisabled(q.numScores, q.averageScore)"
        matTooltipPosition="right">
        <mat-card-content><div class="score-display"><strong>{{displayScore(q.numScores,
              q.averageScore)}}</strong></div></mat-card-content>
      </mat-card>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" matSort
    class="mat-elevation-z8 mt-5 col-md-9"
    [hidden]="isLoading || !(dataSource && dataSource.data.length > 0)">

    <ng-container matColumnDef="submittedByName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Onliner</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.submittedByName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="submittedDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Date Submitted</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.submittedDate | date:'MM/dd/yyyy'
        }}</mat-cell>
    </ng-container>

    <ng-container *ngFor="let q of qualitativeQuestions"
      [matColumnDef]="q.id.toString()">
      <mat-header-cell *matHeaderCellDef class="p-3">{{ q.question }}</mat-header-cell>
      <mat-cell *matCellDef="let row" class="p-3">{{ displayAnswer(row.answers,
        q.id) }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"
      style="cursor: pointer" class="mat-row-clickable"
      (click)="onSelect(row)"></mat-row>
  </table>
</div>

<div *ngIf="!isLoading && dataSource.data.length === 0"
  class="component-container">No records found</div>

<!-- <div *ngIf="isLoading" class="loading m-auto"></div> -->
