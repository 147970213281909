<div class="w-full p-gutter text-center">
  <img alt="unauthorized" class="select-none pointer-events-none filter grayscale w-full max-w-lg mx-auto mb-6" src="assets/img/illustrations/it_support.svg">

  <h1 class="display-3 font-medium m-0">403</h1>
  <h2 class="headline m-0">Authorization Error.</h2>
  <div class="row">&nbsp;</div>
  <p class="text-center">You are not authorized to access this page.</p>
  <p class="text-center">Please contact your system administrator.</p>
  <!-- <div class="bg-foreground rounded-full overflow-hidden relative ltr:pl-5 rtl:pr-5 h-12 max-w-md w-full shadow-xl mx-auto mt-6 flex items-center">
    <mat-icon class="text-secondary flex-none" svgIcon="mat:search"></mat-icon>
    <input class="border-0 h-12 outline-none ltr:pl-4 rtl:pr-4 placeholder:text-secondary bg-foreground flex-auto"
           placeholder="Search for other pages ..."
           type="text">
  </div> -->
  <div *ngIf="timedOut">
    <div class="row">&nbsp;</div>
    <p class="text-center">You have been logged out, click Okay</p>
    <div align="center">
      <button
        type="button"
        class="bg-transparent hover:bg-obs-success text-obs-success font-semibold hover:text-white py-2 px-4 border border-obs-success hover:border-transparent rounded"
        (click)="logout()"
      >
        Okay
      </button>
    </div>
 </div>
</div>
