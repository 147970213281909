import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Renderer2,
  inject,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  MatIconRegistry,
  SafeResourceUrlWithIconOptions,
} from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { Settings } from 'luxon';
import { Subject, Subscription, filter, takeUntil, timer } from 'rxjs';
import { LayoutService } from 'src/@vex/services/layout.service';
import { NavigationService } from '../@vex/services/navigation.service';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { AuthService } from './core/services/auth.service';
import { CommonService } from './core/services/common.service';
import { SessionExpiredDialogComponent } from './shared/components/session-expired-dialog/session-expired-dialog.component';
import { sideMenu } from './shared/models/side-menu.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isAuthenticated = false;
  showWHDForm = false;

  mobileQuery!: MediaQueryList;
  dialogRef!: MatDialogRef<SessionExpiredDialogComponent>;

  sub!: Subscription;
  childSub!: Subscription;
  sessionExpiredDialogIsOpened = false;

  private readonly _destroying$ = new Subject<void>();
  private _mobileQueryListener!: () => void;

  private document: Document = inject(DOCUMENT);
  private localeId: string = inject(LOCALE_ID);

  constructor(
    private renderer: Renderer2,
    private platform: Platform,

    private layoutService: LayoutService,
    // private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,

    private authService: AuthService,
    private dialog: MatDialog,
    private msalAuthService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private commonService: CommonService
  ) {
    this.validateRequiredServices();

    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );
          default:
            return null;
        }
      }
    );

    /**
     * Add your own routes here
     */
    this.navigationService.items = sideMenu;
  }

  async ngOnInit(): Promise<void> {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (message: EventMessage) =>
            message.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((message: EventMessage) => {
        const authResult = message.payload as AuthenticationResult;
        // console.info('LOGIN_SUCCESS', message);
        this.msalAuthService.instance.setActiveAccount(authResult.account);
        this.isAuthenticated = true;

        this.commonService.getWeeklyReportActiveFlag().subscribe(result => {
          this.showWHDForm = result;
          this.navigationService.itemVisibility = {
            '/weeklyDelivery': this.showWHDForm,
            '/weeklyDeliveryHistory': this.showWHDForm,
          };
        });
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (message: EventMessage) =>
            message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((message: EventMessage) => {
        this.isAuthenticated = true;

        const authResult = message.payload as AuthenticationResult;
        this.msalAuthService.instance.setActiveAccount(authResult.account);
        if (authResult.expiresOn) {
          const countdown =
            Math.trunc(authResult.expiresOn.getTime() / 1000) -
            Math.trunc(Date.now() / 1000);
          const countdownOffset = 5 * 60; // show dialog 5 mins before token expire

          // console.info('ACQUIRE_TOKEN_SUCCESS')
          // console.info({
          //   expiresOn: authResult.expiresOn,
          //   now: new Date(),
          //   remaining: countdown
          // })

          this.sub?.unsubscribe();
          this.sub = timer(1000 * (countdown - countdownOffset)).subscribe(
            () => {
              if (!this.sessionExpiredDialogIsOpened) {
                this.showSessionExpiredDialog(countdownOffset);
              } else {
                this.authService.logout();
              }
            }
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.mobileQuery?.removeListener(this._mobileQueryListener);
    this.sub?.unsubscribe();
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  showSessionExpiredDialog(countdownOffset: number) {
    this.sessionExpiredDialogIsOpened = true;
    this.dialogRef = this.dialog.open(SessionExpiredDialogComponent, {
      width: '400px',
      disableClose: true,
    });

    this.dialogRef.afterClosed().subscribe(() => {
      this.sessionExpiredDialogIsOpened = false;
      this.childSub?.unsubscribe();
    });

    this.dialogRef.afterOpened().subscribe(() => {
      this.childSub = timer(1000 * countdownOffset).subscribe(() => {
        if (this.sessionExpiredDialogIsOpened) {
          this.authService.logout();
        }
      });
    });
  }

  /*
      Vex template requires layoutService and splashScreenScreen being injected in the layout, although none of the functions are being executed in the component.
    */
  private validateRequiredServices() {
    if (!this.layoutService || !(this.layoutService instanceof LayoutService)) {
      throw new Error(
        'layoutService is missing or not a LayoutService instance.'
      );
    }
    if (
      !this.splashScreenService ||
      !(this.splashScreenService instanceof SplashScreenService)
    ) {
      throw new Error(
        'splashScreenService is missing or not a SplashScreenService instance.'
      );
    }
  }
}
