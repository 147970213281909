import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PersonalSelfAssessmentComponent } from './personal-self-assessment.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: PersonalSelfAssessmentComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PersonalSelfAssessmentRoutingModule {}
