import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiErrorData } from 'src/app/shared/interfaces/api-error.interface';
import { environment } from 'src/environments/environment';
import { AuthorizedHttp } from './authorized-http.service';

@Injectable({
  providedIn: 'root',
})
export class RemoteWorkConfigService {
  public rwConfigs!: RemoteWorkConfig[];
  private apiEndpoint = `${environment.apiEndpoint}/api/remoteworkconfig`;

  constructor(private http: AuthorizedHttp) {}

  public getRemoteWorkConfigs(): Observable<RemoteWorkConfig[]> {
    return this.rwConfigs
      ? of(this.rwConfigs)
      : this.http
          .get<RemoteWorkConfig[]>(
            `${this.apiEndpoint}/getRemoteWorkEmailConfig`
          )
          .pipe(
            map(results => {
              this.rwConfigs = results;
              return this.rwConfigs;
            }),
            catchError((errorResponse: ApiErrorData) =>
              throwError(
                errorResponse?.error?.exceptionMessage || 'Server error'
              )
            )
          );
  }

  updateRemoteWorkConfig(newConfig: RemoteWorkConfig): Observable<void> {
    return this.http
      .post<void>(`${this.apiEndpoint}/updateRemoteWorkEmail`, newConfig)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }
}

export class RemoteWorkConfig {
  id!: number;
  regionDesc!: string;
  primaryEmail!: string;
  ccEmail!: string | null;
}
