<div class="no-print group-break">
  <button type="button" mat-stroked-button class="submit-btn" routerLink="/cmdelegation" routerLinkActive="$router_link-active$" [routerLinkActiveOptions]="{ exact: true }">Add / Remove CM Delegate</button>
  <button type="button" mat-stroked-button class="ml-2 submit-btn" (click)="downloadCSV()">Download CSV</button>
</div>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading">
  <table mat-table [dataSource]="delegationViewModel" class="mat-elevation-z8" >

      <ng-container matColumnDef="careerMentor">
          <th mat-header-cell *matHeaderCellDef >Career Mentor</th>
          <td mat-cell *matCellDef="let value" class="leftAlignCell">
            {{ value.visibleCareerMentor }}
          </td>
      </ng-container>

      <ng-container matColumnDef="delegate">
          <th mat-header-cell *matHeaderCellDef>
            <span class="pl-1">Delegates</span>
        </th>
          <td mat-cell *matCellDef="let value" class="leftAlignCell">
            <span class="pl-1">{{ value.delegate }}</span>
          </td>
      </ng-container>

    <tr mat-header-row *matHeaderRowDef="resultColumnHeaders"></tr>
    <tr mat-row *matRowDef="let row; columns: resultColumnHeaders"></tr>
  </table>
</div>
