import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfidentialFeedbackRoutingModule } from './confidential-feedback-routing.module';
import { ConfidentialFeedbackComponent } from './confidential-feedback.component';
import { FeedbackFilterCriteriaFormModule } from 'src/app/shared/modules/feedback-filter-criteria-form/feedback-filter-criteria-form.module';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { ConfidentialFeedbackListModule } from './confidential-feedback-list/confidential-feedback-list.module';

@NgModule({
  declarations: [ConfidentialFeedbackComponent],
  imports: [
    CommonModule,
    ConfidentialFeedbackRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    AngularMaterialModule,

    FeedbackFilterCriteriaFormModule,
    ConfidentialFeedbackListModule,
  ],
})
export class ConfidentialFeedbackModule {}
