<vex-secondary-toolbar current="My Feedback">
  <vex-breadcrumbs [crumbs]="['My Feedback', 'Feedback Requests']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="row m-0">
  <div class="component-container">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <i class="fas fa-filter fa-lg"></i>
            <span>Filter Requests ({{filtersValue}})</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <app-feedback-filter-criteria-form
          [searchCriteriaForm]="filterCriteriaForm"
          [isOnlinerFilterVisible]="false"
          [isSubmittedByFilterVisible]="false"
          [isOnRequestsPage]="true"
          (filterDatesTriggered)="filterFeedbacks()"
          (filterFeedbacksTriggered)="filterFeedbacks()">
        </app-feedback-filter-criteria-form>
    </mat-expansion-panel>
<br>
    <h3 class="page-title">My Feedback Requests</h3>
    <app-my-feedback-requests-list [feedbacks]="filteredFeedbacks"></app-my-feedback-requests-list>
  </div>
</div>

