import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { SelfAssessmentPdfBuilderService } from 'src/app/core/services/self-assessement-pdf-builder.service';
import { TeamSurveyFilterCriteriaFormModule } from './team-survey-filter-criteria-form/team-survey-filter-criteria-form.module';
import { TeamSurveyListModule } from './team-survey-list/team-survey-list.module';
import { TeamSurveyRoutingModule } from './team-survey-routing.module';
import { TeamSurveyComponent } from './team-survey.component';

@NgModule({
  declarations: [TeamSurveyComponent],
  imports: [
    CommonModule,
    TeamSurveyRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,

    TeamSurveyFilterCriteriaFormModule,
    TeamSurveyListModule,
  ],
  providers: [SelfAssessmentPdfBuilderService],
})
export class TeamSurveyModule {}
