import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { SavedAssessment } from 'src/app/core/services/assessment.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { BaseFeedbackFilterService } from 'src/app/core/services/base-feedback-filter.service';
import {
  CareerMentor,
  CareerMentorService,
} from 'src/app/core/services/career-mentor.service';
import { FeedbackService } from 'src/app/core/services/feedback.service';
import { MyTeamFeedbackFilterService } from 'src/app/core/services/my-team-feedback-filter.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cm-group-feedback',
  templateUrl: './cm-group-feedback.component.html',
  styleUrls: ['./cm-group-feedback.component.scss'],
  providers: [
    {
      provide: BaseFeedbackFilterService,
      useExisting: MyTeamFeedbackFilterService,
    },
  ],
})
export class CmGroupFeedbackComponent implements OnInit {
  feedbacks!: SavedAssessment[];
  filteredFeedbacks!: SavedAssessment[];
  filterCriteriaForm!: UntypedFormGroup;
  filterDateDisplay!: string;
  isAdmin!: boolean;
  isCareerMentor!: boolean;
  currentCareerMentor?: CareerMentor;
  filterRecieverValue!: string;
  filterCMValue!: string;
  filterSubmissionTypeValue!: string;
  filterClientValue!: string;
  filterFeedbackStatusValue!: string;
  filtersValue!: string;
  isCMDelegate = false;
  isLoading = true;

  constructor(
    private feedbackService: FeedbackService,
    private snackBarService: SnackBarService,
    private feedbackFilterService: MyTeamFeedbackFilterService,
    private authService: AuthService,
    private careerMentorService: CareerMentorService
  ) {}

  ngOnInit() {
    this.isCareerMentor = this.authService.doesUserHaveRole([
      environment.roles.FeedbackCm,
    ]);
    this.isAdmin = this.authService.doesUserHaveRole([
      environment.roles.FeedbackAdmin,
    ]);
    this.setUpPage();
  }

  public getFeedbacks() {
    this.isLoading = true;
    if (this.isAdmin) {
      this.getAllReviewableFeedbacks();
    } else {
      this.getMyTeamFeedbacks();
    }
  }

  public getAllReviewableFeedbacks() {
    const filterCriteriaModel =
      this.feedbackFilterService.getFilterCriteriaModel();
    this.filterDateDisplay =
      this.feedbackFilterService.getFilterDateRangeDisplay(filterCriteriaModel);
    const startDate = this.feedbackFilterService.getDateFilterCriteria(
      filterCriteriaModel.startDate
    );
    const endDate = this.feedbackFilterService.getDateFilterCriteria(
      filterCriteriaModel.endDate
    );
    this.feedbackService
      .getAllReviewableFeedbacks(startDate, endDate)
      .subscribe(
        data => {
          this.feedbacks = data;
          this.filterFeedbacks(true);
          this.isLoading = false;
        },
        error => {
          this.snackBarService.error(error);
        }
      );
  }

  filterFeedbacks(receiverFilter: boolean) {
    const filterCriteriaModel =
      this.feedbackFilterService.getFilterCriteriaModel();

    this.filterDateDisplay =
      this.feedbackFilterService.getFilterDateRangeDisplay(filterCriteriaModel);
    this.filteredFeedbacks = this.feedbacks.filter(
      f =>
        this.feedbackFilterService.filterReceiver(f, filterCriteriaModel) &&
        this.feedbackFilterService.filterClient(f, filterCriteriaModel) &&
        this.feedbackFilterService.filterCareerMentor(f, filterCriteriaModel) &&
        this.feedbackFilterService.filterSubmissionType(
          f,
          filterCriteriaModel
        ) &&
        this.feedbackFilterService.filterFeedbackType(f, filterCriteriaModel) &&
        this.feedbackFilterService.filterFeedbackStatus(f, filterCriteriaModel)
    );

    if (receiverFilter) {
      this.filteredFeedbacks = this.filteredFeedbacks.filter(f =>
        this.feedbackFilterService.filterReceiver(f, filterCriteriaModel)
      );
    }

    this.getFilterFormValues(receiverFilter);
  }

  getFilterFormValues(receiverFilter: boolean) {
    this.filterClientValue = `${this.filterCriteriaForm.controls.client.value['clientName']}`;
    this.filterCMValue = `${this.filterCriteriaForm.controls.careerMentor.value['firstName']} ${this.filterCriteriaForm.controls.careerMentor.value['lastName']}`;
    this.filterCMValue = this.filterCMValue.trim();
    this.filterFeedbackStatusValue = `${this.filterCriteriaForm.controls.status.value['statusDescription']}`;
    this.filterSubmissionTypeValue = `${this.filterCriteriaForm.controls.submissionType.value['name']}`;

    if (receiverFilter) {
      this.filterRecieverValue = `${this.filterCriteriaForm.controls.onliner.value['firstName']} ${this.filterCriteriaForm.controls.onliner.value['lastName']}`;
      this.filterRecieverValue = this.filterRecieverValue.trim();
      this.filtersValue = `${this.filterClientValue}, ${this.filterRecieverValue}, ${this.filterSubmissionTypeValue}, ${this.filterFeedbackStatusValue}, ${this.filterCMValue}`;
    } else {
      this.filtersValue = `${this.filterClientValue}, ${this.filterSubmissionTypeValue}, ${this.filterFeedbackStatusValue}, ${this.filterCMValue}`;
    }
  }

  private setUpPage() {
    forkJoin(
      this.careerMentorService.getCareerMentors(),
      this.careerMentorService.isDelegate(
        this.authService.getUserId().toLowerCase()
      )
    ).subscribe(
      ([careerMentors, isDelegate]) => {
        this.currentCareerMentor = careerMentors.find(
          cm => cm.userId === this.authService.getUserId().toLowerCase()
        );
        this.filterCriteriaForm =
          this.feedbackFilterService.getFilterCriteriaForm(
            this.currentCareerMentor,
            true
          );
        this.isCMDelegate = isDelegate;

        if (this.isAdmin) {
          this.getAllReviewableFeedbacks();
        } else {
          this.getMyTeamFeedbacks();
        }
      },
      error => {
        this.snackBarService.error(error);
      }
    );
  }

  private getMyTeamFeedbacks() {
    const filterCriteriaModel =
      this.feedbackFilterService.getFilterCriteriaModel();
    this.filterDateDisplay =
      this.feedbackFilterService.getFilterDateRangeDisplay(filterCriteriaModel);
    const startDate = this.feedbackFilterService.getDateFilterCriteria(
      filterCriteriaModel.startDate
    );
    const endDate = this.feedbackFilterService.getDateFilterCriteria(
      filterCriteriaModel.endDate
    );

    this.feedbackService.getMyTeamFeedbacks(startDate, endDate).subscribe(
      data => {
        this.feedbacks = data;
        this.filterFeedbacks(false);
        this.isLoading = false;
      },
      error => {
        this.snackBarService.error(error);
      }
    );
  }
}
