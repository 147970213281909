import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TeamSurveyFilterCriteriaFormComponent } from './team-survey-filter-criteria-form.component';

@NgModule({
  declarations: [TeamSurveyFilterCriteriaFormComponent],
  imports: [
    CommonModule,

    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [TeamSurveyFilterCriteriaFormComponent],
})
export class TeamSurveyFilterCriteriaFormModule {}
