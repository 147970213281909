export class QuestionGroup {
  id!: number;
  sortOrder!: boolean;
  name!: string;
  questions!: Question[];
}

export enum QuestionType {
  Text = 'Text',
  Select = 'Select',
  Radio = 'Radio',
  Scale = 'Scale',
}

export class Option {
  id!: number;
  value!: string;
  sortOrder!: number;
}

export class OptionGroup {
  label!: string;
  options!: Option[];
}

export class Question {
  id!: number;
  question!: string;
  isOptional!: boolean;
  sortOrder!: number;
  type!: QuestionType;
  optionGroup!: OptionGroup;
}
