import { Injectable } from '@angular/core';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiErrorData } from 'src/app/shared/interfaces/api-error.interface';
import { environment } from 'src/environments/environment';
import { AuthorizedHttp } from './authorized-http.service';

@Injectable({
  providedIn: 'root',
})
export class CareerMentorService {
  public _careerMentors!: CareerMentor[];

  private _apiEndpoint = `${environment.apiEndpoint}/api/careerManager`;

  private isDelegatedSource = new Subject<boolean>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public isDelegated$ = this.isDelegatedSource.asObservable();

  constructor(private http: AuthorizedHttp) {}
  public isDelegated = (delegated: boolean) =>
    this.isDelegatedSource.next(delegated);

  public getCareerMentors(): Observable<CareerMentor[]> {
    return this._careerMentors
      ? of(this._careerMentors)
      : this.http.get<CareerMentor[]>(`${this._apiEndpoint}`).pipe(
          map(results => {
            this._careerMentors = results;
            return this._careerMentors;
          }),
          catchError((errorResponse: ApiErrorData) =>
            throwError(errorResponse?.error?.exceptionMessage || 'Server error')
          )
        );
  }

  public getCareerMentorsIncludingInactive(): Observable<CareerMentor[]> {
    return this.http
      .get<CareerMentor[]>(`${this._apiEndpoint}/allCareerMentor`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getDelegates(): Observable<SavedCMDelegate[]> {
    return this.http
      .get<SavedCMDelegate[]>(`${this._apiEndpoint}/delegate`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getDelegatesFor(careerMentor: string): Observable<SavedCMDelegate[]> {
    return this.http
      .get<SavedCMDelegate[]>(`${this._apiEndpoint}/delegate/${careerMentor}`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public addDelegate(newDelegate: SavedCMDelegate): Observable<void> {
    return this.http
      .post<void>(
        `${this._apiEndpoint}/delegate?careerManagerId=${newDelegate.careerManagerId}`,
        `['${newDelegate.delegateId}']`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public removeDelegate(
    careerMentorId: string,
    delegateId: string
  ): Observable<void> {
    return this.http
      .delete<void>(
        `${this._apiEndpoint}/delegate?careerManagerId=${careerMentorId}`,
        `['${delegateId}']`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public isDelegate(onlinerId: string): Observable<boolean> {
    return this.http
      .get<boolean>(`${this._apiEndpoint}/isDelegate/${onlinerId}`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public sendHelpdeskAddCMDelegationEmail(
    delegateInfo: SavedCMDelegate
  ): Observable<void> {
    return this.http
      .post<void>(
        `${this._apiEndpoint}/sendNewDelegateEmail?careerManagerId=${delegateInfo.careerManagerId}`,
        `['${delegateInfo.delegateId}']`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public sendHelpdeskDeleteCMDelegationEmail(
    delegateInfo: SavedCMDelegate
  ): Observable<void> {
    return this.http
      .post<void>(
        `${this._apiEndpoint}/sendDeleteDelegateEmail?careerManagerId=${delegateInfo.careerManagerId}`,
        `['${delegateInfo.delegateId}']`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }
}

export class SavedCMDelegate {
  constructor(
    public careerManagerId: string,
    public delegateId: string,
    public careerManagerName: string,
    public delegateName: string
  ) {}
}

export class CareerMentor {
  userId!: string;
  firstName!: string;
  lastName!: string;
}

export class DeleteCMDelegateRequest {
  constructor(
    public careerManagerId: string,
    public delegates: string[]
  ) {}
}
