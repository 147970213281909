<div *ngIf="!loading">
    <div class="detail-title">{{title}}</div>

    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 questions-config-body mat-elevation-z3">
        <span>
            <mat-form-field class="text-input" appearance="outline" [formGroup]="questionForm">
                <mat-label>Question</mat-label>
                <input matInput formControlName="questionControl" placeholder="Question">
            </mat-form-field>
        </span>

        <div class="row d-flex justify-content-between">
            <div class="col">
                <mat-form-field class="question-type-select" appearance="outline" [formGroup]="questionForm">
                    <mat-label>Question Type</mat-label>
                    <mat-select formControlName="typeControl" (selectionChange)="onNewTypeSelect($event)">
                        <mat-option *ngFor="let questionType of questionTypes" [value]="questionType">{{ questionType }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-auto d-flex justify-content-end pt-2">
                <form [formGroup]="questionForm">
                    <div class="d-flex justify-content-end">
                        <span class="toggle-label">Active</span>
                        <mat-slide-toggle formControlName="activeControl"></mat-slide-toggle>
                    </div>
                    <div class="d-flex justify-content-end">
                        <span class="toggle-label">Optional</span>
                        <mat-slide-toggle formControlName="optionalControl"></mat-slide-toggle>
                    </div>
                </form>
            </div>
        </div>
        <div *ngIf="usesOptionGroups()" class="row d-flex align-items-center mb-3">
            <mat-form-field class="col-6" appearance="outline" [formGroup]="questionForm">
                <mat-label>Option Group</mat-label>
                <mat-select formControlName="optionGroupControl" (selectionChange)="onNewSelect($event)">
                    <mat-option *ngFor="let group of questionOptionGroups" [value]="group">{{ group.label }}</mat-option>
                </mat-select>
            </mat-form-field>
            <span matTooltip="{{addOptionGroupTooltip}}" [matTooltipPosition]="'below'">
                <button mat-stroked-button (click)="addOptionGroup()" class="option-group-config-btn">
                    <i class="fas fa-plus" ></i>
                </button>
            </span>
            <span matTooltip="{{editOptionGroupTooltip}}" [matTooltipPosition]="'below'">
                <button mat-stroked-button [disabled]="questionForm.get('optionGroupControl')?.invalid" (click)="editOptionGroup()" class="option-group-config-btn">
                    <i class="fas fa-edit" ></i>
                </button>
            </span>
            <div class="scale-error-message" *ngIf="isInvalidScale()">Please use an Option Group where options all have an assigned Score</div>
        </div>

        <div *ngIf="!loadingOptions">
            <div class="table-header">Available Options</div>
            <div class="row table-container mat-elevation-z3">
                <table mat-table [dataSource]="options" cdkDropList>
                    <ng-container matColumnDef="option">
                        <mat-header-cell *matHeaderCellDef>Option</mat-header-cell>
                        <mat-cell *matCellDef="let answer">
                            {{answer.value}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="score">
                        <mat-header-cell *matHeaderCellDef>Score</mat-header-cell>
                        <mat-cell *matCellDef="let answer">
                            {{answer.score}}
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="answerColumns"></mat-header-row>
                    <mat-row
                        class="mat-row"
                        *matRowDef="let row; let i = index; columns: answerColumns;">
                    </mat-row>
                </table>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-start mt-4">
        <button mat-stroked-button class="cancel-btn" (click)="close()">CANCEL</button>
        <button mat-stroked-button class="save-btn ml-4" (click)="save()" [disabled]="isSaveDisabled">SAVE</button>
    </div>
</div>
