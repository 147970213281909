import { Location } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SavedAssessment } from 'src/app/core/services/assessment.service';
import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import {
  FeedbackStatusEnum,
  FeedbackTypeEnum,
} from 'src/app/shared/enums/enums.model';

@Component({
  selector: 'app-feedback-detail',
  templateUrl: './feedback-detail.component.html',
  styleUrls: ['./feedback-detail.component.scss'],
})
export class FeedbackDetailComponent implements OnInit, OnChanges {
  @Input() feedback!: SavedAssessment;
  @Input() feedbackTypeIdParam!: number;
  @Input() operationInProgress!: string | null;
  @Input() isLegacyFeedback!: boolean;

  cmComments!: string;
  mismatchedIDs = false;

  constructor(
    private location: Location,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    return;
  }

  ngOnChanges() {
    if (this.feedback) {
      this.mismatchedIDs =
        this.feedbackTypeIdParam !== this.feedback.feedbackTypeId;

      if (this.mismatchedIDs) {
        this.showConfirmationDialog();
      } else {
        this.getCmComments();
        if (
          this.feedback &&
          this.feedback.answers.find(c => c.optionId !== null)
        ) {
          this.feedback.answers.forEach(value => {
            if (
              !value.stringValue &&
              value.option !== null &&
              value.question.type.toString() !== 'Radio_Text'
            ) {
              value.stringValue = value.option?.value || '';
            }
          });
        }
      }
    }
  }

  goBack() {
    this.location.back();
  }

  getFeedbackTypeEnum(enumCode: number): string {
    return FeedbackTypeEnum[enumCode];
  }

  getFeedbackStatusEnum(enumCode: number): string {
    return FeedbackStatusEnum[enumCode];
  }

  htmlDecode(input: string) {
    const txt = document.createElement('textarea');
    txt.innerHTML = input;
    return txt.value;
  }

  private showConfirmationDialog() {
    const confirmDialog = new ConfirmDialog();
    confirmDialog.title = 'Access Error';
    confirmDialog.message =
      "<p> You don't have access to this feedback using this section of the app.</p>";
    confirmDialog.okButtonTitle = 'Continue';
    // confirmDialog.cancelButtonTitle = "Cancel";
    this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: confirmDialog,
      disableClose: true,
    });
  }

  private getCmComments() {
    this.feedback.log.sort((a, b) =>
      a.changedDate < b.changedDate ? 1 : b.changedDate < a.changedDate ? -1 : 0
    );

    this.cmComments = '';
    for (const audit of this.feedback.log) {
      if (audit.statusId === FeedbackStatusEnum.Posted && audit.description) {
        this.cmComments +=
          'From: ' +
          audit.changedByEmployee.firstName +
          ' ' +
          audit.changedByEmployee.lastName +
          '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
          'Date: ' +
          new Date(audit.changedDate).toLocaleDateString('en-US') +
          '\n' +
          this.htmlDecode(audit.description) +
          '\n\n';
      }
    }
  }
}
