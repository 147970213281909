import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiErrorData } from 'src/app/shared/interfaces/api-error.interface';
import { environment } from 'src/environments/environment';
import { AuthorizedHttp } from './authorized-http.service';
import {
  FeedbackStatus,
  FeedbackType,
  Question,
  QuestionGroup,
  QuestionOption,
  QuestionOptionGroup,
} from './feedback.service';
import { Employee } from './onliner.service';
import {
  Country,
  RemoteWorkRequestStatus,
  RemoteWorkRequestType,
  defaultRemoteWorkRequestStatus,
  defaultRemoteWorkRequestType,
} from './remote-work-request.service';
import { FbSelfAssessmentSchedule } from './self-assessment.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/common`;

  private _allEmployees!: Employee[];
  private _clients!: Client[];
  private _clientsExcludingDefault!: Client[];
  private _submissionTypes!: SelectListItem[];
  private _questions!: Question[] | null;
  private _questionGroup!: QuestionGroup[] | null;
  private _questionOptions!: QuestionOption[] | null;
  private _questionOptionGroups!: QuestionOptionGroup[] | null;
  private _feedbackStatuses!: FeedbackStatus[];
  private _showWHDForm!: boolean;
  private _submissionDates!: SubmissionDate[];
  private _remoteWorkRequestTypes!: RemoteWorkRequestType[];
  private _remoteWorkRequestStatuses!: RemoteWorkRequestStatus[];
  private _countries!: Country[];
  private _years!: Int32List[];

  constructor(private http: AuthorizedHttp) {}

  public getClientsExcludingDefault(): Observable<Client[]> {
    return this._clientsExcludingDefault
      ? of(this._clientsExcludingDefault)
      : this.getClients().pipe(
          map(clients => {
            this._clientsExcludingDefault = clients.filter(
              c => c.clientId !== defaultClient.clientId
            );
            return this._clientsExcludingDefault;
          })
        );
  }

  public getClients(): Observable<Client[]> {
    return this._clients
      ? of(this._clients)
      : this.http.get<Client[]>(`${this._apiEndpoint}/clients`).pipe(
          map(result => {
            this._clients = result;
            return result;
          }),
          catchError((errorResponse: ApiErrorData) =>
            throwError(errorResponse?.error?.exceptionMessage || 'Server error')
          )
        );
  }

  public getEmployeesIncludingInactive(): Observable<Employee[]> {
    return this._allEmployees
      ? of(this._allEmployees)
      : this.http.get<Employee[]>(`${this._apiEndpoint}/employees`).pipe(
          map(result => {
            this._allEmployees = result;
            return result;
          }),
          catchError((errorResponse: ApiErrorData) =>
            throwError(errorResponse?.error?.exceptionMessage || 'Server error')
          )
        );
  }

  public getSubmissionTypes(): Observable<SelectListItem[]> {
    return this._submissionTypes
      ? of(this._submissionTypes)
      : this.http
          .get<SelectListItem[]>(`${this._apiEndpoint}/submissionTypes`)
          .pipe(
            map(result => {
              this._submissionTypes = result;
              return this._submissionTypes;
            }),
            catchError((errorResponse: ApiErrorData) =>
              throwError(
                errorResponse?.error?.exceptionMessage || 'Server error'
              )
            )
          );
  }

  public getQuestionsForCurrentYear(): Observable<Question[]> {
    const currentYear = new Date().getFullYear();
    return this.getQuestionsForYear(currentYear);
  }

  public getQuestionsForYear(fiscalYear: number): Observable<Question[]> {
    return this.http
      .get<Question[]>(`${this._apiEndpoint}/questions/${fiscalYear}`)
      .pipe(
        map(result => {
          this._questions = result;
          return this._questions;
        }),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getQuestionsForSchedule(scheduleId: number): Observable<Question[]> {
    return this.http
      .get<Question[]>(`${this._apiEndpoint}/questions/${scheduleId}`)
      .pipe(
        map(result => {
          this._questions = result;
          return this._questions;
        }),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getQuestions(): Observable<Question[]> {
    return this.http.get<Question[]>(`${this._apiEndpoint}/questions`).pipe(
      map(result => {
        this._questions = result;
        return this._questions;
      }),
      catchError((errorResponse: ApiErrorData) =>
        throwError(errorResponse?.error?.exceptionMessage || 'Server error')
      )
    );
  }

  public getQuestionGroups(): Observable<QuestionGroup[]> {
    return this._questionGroup
      ? of(this._questionGroup)
      : this.http
          .get<QuestionGroup[]>(`${this._apiEndpoint}/questionGroups`)
          .pipe(
            map(result => {
              this._questionGroup = result;
              return this._questionGroup;
            }),
            catchError((errorResponse: ApiErrorData) =>
              throwError(
                errorResponse?.error?.exceptionMessage || 'Server error'
              )
            )
          );
  }

  public getQuestionOptions(): Observable<QuestionOption[]> {
    return this._questionOptions
      ? of(this._questionOptions)
      : this.http
          .get<QuestionOption[]>(`${this._apiEndpoint}/questionOptions`)
          .pipe(
            map(result => {
              this._questionOptions = result;
              return this._questionOptions;
            }),
            catchError((errorResponse: ApiErrorData) =>
              throwError(
                errorResponse?.error?.exceptionMessage || 'Server error'
              )
            )
          );
  }

  public getQuestionOptionGroups(): Observable<QuestionOptionGroup[]> {
    return this._questionOptionGroups
      ? of(this._questionOptionGroups)
      : this.http
          .get<QuestionOptionGroup[]>(
            `${this._apiEndpoint}/questionOptionGroups`
          )
          .pipe(
            map(result => {
              this._questionOptionGroups = result;
              return this._questionOptionGroups;
            }),
            catchError((errorResponse: ApiErrorData) =>
              throwError(
                errorResponse?.error?.exceptionMessage || 'Server error'
              )
            )
          );
  }

  public getActiveFeedbackTypes(): Observable<FeedbackType[]> {
    return this.http
      .get<FeedbackType[]>(`${this._apiEndpoint}/activeFeedbackTypes`)
      .pipe(
        map(result => result),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getFeedbackStatuses(): Observable<FeedbackStatus[]> {
    return this._feedbackStatuses
      ? of(this._feedbackStatuses)
      : this.http
          .get<FeedbackStatus[]>(`${this._apiEndpoint}/feedbackStatuses`)
          .pipe(
            map(result => {
              this._feedbackStatuses = result;
              return this._feedbackStatuses;
            }),
            catchError((errorResponse: ApiErrorData) =>
              throwError(
                errorResponse?.error?.exceptionMessage || 'Server error'
              )
            )
          );
  }

  public getWeeklyReportActiveFlag(): Observable<boolean> {
    return this.http
      .get<boolean>(`${this._apiEndpoint}/weeklyReportActiveFlag`)
      .pipe(
        map(result => {
          this._showWHDForm = result;
          return this._showWHDForm;
        }),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'ServerError')
        )
      );
  }

  public getSubmissionDates(): Observable<SubmissionDate[]> {
    return this.http
      .get<SubmissionDate[]>(`${this._apiEndpoint}/submissionDates`)
      .pipe(
        map(result => {
          this._submissionDates = result;
          return this._submissionDates;
        }),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'ServerError')
        )
      );
  }

  public getRemoteWorkRequestTypes(): Observable<RemoteWorkRequestType[]> {
    return this._remoteWorkRequestTypes
      ? of(this._remoteWorkRequestTypes)
      : this.http
          .get<RemoteWorkRequestType[]>(
            `${this._apiEndpoint}/remoteWorkRequestTypes`
          )
          .pipe(
            map(result => {
              this._remoteWorkRequestTypes = result;
              this._remoteWorkRequestTypes.unshift(
                defaultRemoteWorkRequestType
              );
              return this._remoteWorkRequestTypes;
            }),
            catchError((e: ApiErrorData) =>
              throwError(e.error.exceptionMessage || 'Server error')
            )
          );
  }

  public getRemoteWorkRequestStatuses(): Observable<RemoteWorkRequestStatus[]> {
    return this._remoteWorkRequestStatuses
      ? of(this._remoteWorkRequestStatuses)
      : this.http
          .get<RemoteWorkRequestStatus[]>(
            `${this._apiEndpoint}/remoteWorkRequestStatuses`
          )
          .pipe(
            map(result => {
              this._remoteWorkRequestStatuses = result;
              this._remoteWorkRequestStatuses.unshift(
                defaultRemoteWorkRequestStatus
              );
              return this._remoteWorkRequestStatuses;
            }),
            catchError((e: ApiErrorData) =>
              throwError(e.error.exceptionMessage || 'Server error')
            )
          );
  }

  public getCountries(): Observable<Country[]> {
    return this._countries
      ? of(this._countries)
      : this.http.get<Country[]>(`${this._apiEndpoint}/countries`).pipe(
          map(result => {
            this._countries = result;
            return this._countries;
          }),
          catchError((e: ApiErrorData) =>
            throwError(e.error.exceptionMessage || 'Server error')
          )
        );
  }

  public getFiscalYear(): Observable<FbSelfAssessmentSchedule[]> {
    return this.http
      .get<FbSelfAssessmentSchedule[]>(`${this._apiEndpoint}/years`)
      .pipe(
        catchError((e: ApiErrorData) =>
          throwError(e.error.exceptionMessage || 'Server error')
        )
      );
  }

  public getPractices(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this._apiEndpoint}/practices`)
      .pipe(
        catchError((e: ApiErrorData) =>
          throwError(e.error.exceptionMessage || 'Server error')
        )
      );
  }

  public clearQuestionsCache() {
    this._questions = null;
    this._questionGroup = null;
    this._questionOptions = null;
    this._questionOptionGroups = null;
  }
}

export class Client {
  clientId!: number;
  clientName!: string;
}

export class SelectListItem {
  id!: number;
  name!: string;
}

export class SubmissionDate {
  id!: number;
  targetDate!: Date;
  cutoffDate!: Date;
}

export const defaultClient: Client = { clientId: -1, clientName: 'All' };
