import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SelfAssessmentService } from 'src/app/core/services/self-assessment.service';

@Component({
  selector: 'app-personal-self-assessment',
  templateUrl: './personal-self-assessment.component.html',
  styleUrls: ['./personal-self-assessment.component.scss'],
})
export class PersonalSelfAssessmentComponent implements OnInit {
  isLoading = true;

  constructor(
    private router: Router,
    private selfAssessmentService: SelfAssessmentService
  ) {}

  ngOnInit() {
    this.selfAssessmentService.getRecentSelfAssessment().subscribe(data => {
      if (data === null) {
        this.isLoading = false;
      } else {
        this.router.navigate([
          'viewYearlyAssessmentReport/',
          data.scheduleId,
          data.id,
        ]);
      }
    });
  }
}
