import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CmDelegationReportRoutingModule } from './cm-delegation-report-routing.module';
import { CmDelegationReportComponent } from './cm-delegation-report.component';
import { CmDelegationListComponent } from './cm-delegation-list/cm-delegation-list.component';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';

@NgModule({
  declarations: [CmDelegationReportComponent, CmDelegationListComponent],
  imports: [
    CommonModule,
    CmDelegationReportRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    AngularMaterialModule,
  ],
})
export class CmDelegationReportModule {}
