import { Component, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as formValidator from 'src/app/shared/validators/form-validator';

export class CommentDialog {
  title!: string;
  message!: string;
  hint!: string;
  reasons!: string;
  okButtonTitle!: string;
  cancelButtonTitle!: string;
  theme!: string;
  isEmptyInputAllowed!: boolean;
}

@Component({
    selector: 'app-comment-dialog',
    templateUrl: './comment-dialog.component.html'
})
export class CommentDialogComponent {
  dialogForm: UntypedFormGroup;

  isEmptyInputAllowed = false;
  public data: CommentDialog = inject(MAT_DIALOG_DATA);
  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CommentDialogComponent>
  ) {
    const data = this.data;
    this.isEmptyInputAllowed = data.isEmptyInputAllowed;
    this.dialogForm = fb.group({
      reasons: [data.reasons, formValidator.requiredAndNoWhiteSpaceValidator],
    });
  }

  submit() {
    this.dialogRef.close(this.dialogForm.value);
  }

  close() {
    this.dialogRef.close();
  }
}
