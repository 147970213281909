<div class="col-lg-12 col-md-12 clearfix mb-3">
  <div class="pull-right-on-lg-screen">
    <button type="button" mat-stroked-button class="submit-btn"
      (click)="goBack()"><i class="fas fa-angle-double-left"></i> Back
    </button>
  </div>
</div>

<div *ngIf="operationInProgress !== 'loading' && feedback">
  <div class="feedback-detail-container col-sm-12 col-xs-12 pb-1">
    <div class="form-group row">
      <div class="col-md-8">
        <span class="label-header">Submission Week:</span>
        <span class="col-md-8">{{targetWeekMonday(feedback.submittedDate) |
          date:'MM/dd/yyyy' }} to {{feedback.submittedDate | date:'MM/dd/yyyy'}}</span>
      </div>
    </div>
    <div *ngFor="let answer of feedback.answers">
      <div *ngIf="answer">
        <div *ngIf="answer.question">
          <span class="label-header"> {{answer.question.question}}
          </span>
          <div *ngIf="answer.option">
            <mat-card appearance="outlined">
              <mat-card-content>
                <div class="row form-group m-0 mt-2">
                  <span style="white-space: pre-line">
                    {{htmlDecode(answer.option.value)}} </span>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div *ngIf="answer.stringValue && answer.questionId !== 37">
            <br>
            <span class="label-header-normal">Comments</span>
            <mat-card appearance="outlined">
              <mat-card-content>
                <div class="row form-group m-0 mt-2">
                  <span style="white-space: pre-line">
                    {{htmlDecode(answer.stringValue)}} </span>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
      <div style="height: 30px;"></div>
    </div>
  </div>
</div>

<div *ngIf="operationInProgress === 'loading'" class="grid place-items-center inset-0">
  <mat-spinner></mat-spinner>
</div>

