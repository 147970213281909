<vex-secondary-toolbar current="Client Feedback - Online">
  <vex-breadcrumbs [crumbs]="['Review', 'Client Feedback - Online']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div class="component-container">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <i class="fas fa-filter fa-lg"></i>
          <span>Filter Submissions ({{filtersValue}})</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-feedback-filter-criteria-form
        [searchCriteriaForm]="filterCriteriaForm"
        [isOnlinerFilterVisible]="false"
        [isSubmittedByFilterVisible]="false"
        [isOnClientFeedbackOnlinePage]="true"
        (filterDatesTriggered)="filterFeedbacks()"
        (filterFeedbacksTriggered)="filterFeedbacks()">
      </app-feedback-filter-criteria-form>
    </mat-expansion-panel>
    <br>
    <div class="row m-0">
      <h3 class="page-title">Client Feedback - Online {{filterDateDisplay}}</h3>
      <button type="button" mat-stroked-button class="ml-auto submit-btn float-right"  (click)="downloadCSV()" [disabled]="!filteredFeedbacks || filteredFeedbacks.length === 0">Download CSV</button>
    </div>
    <app-client-feedback-online-list [feedbacks]="filteredFeedbacks"></app-client-feedback-online-list>
  </div>
