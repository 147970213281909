<div class="col-lg-12 col-md-12 clearfix mb-3">
    <div class="pull-right-on-lg-screen">
        <button type="button" mat-stroked-button class="submit-btn"
            (click)="goBack()"><i
                class="fas fa-angle-double-left"></i> Back
        </button>
    </div>
</div>

<div *ngIf="operationInProgress !== 'loading' && feedback">
    <div class="feedback-detail-container col-sm-12 col-xs-12 pb-1">
        <div class="form-group row">
            <div class="col-md-6">
                <span class="label-header">Onliner</span>
                <span class="col-md-8">{{htmlDecode(feedback.employeeName)}}</span>
            </div>
            <div class="col-md-6">
                <span class="label-header">Practice</span>
                <span class="col-md-8">{{feedback.practice}}</span>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-md-6">
                <span class="label-header">Submitted By</span>
                <span class="col-md-8">{{htmlDecode(feedback.submittedByName)}}</span>
            </div>
            <div class="col-md-6">
                <span class="label-header">Submitted On</span>
                <span class="col-md-8">{{formatDateTimeToDate(feedback.submittedDate)}}</span>
            </div>
        </div>

        <div *ngFor="let answer of feedback.answers">
            <div *ngIf="answer">
                <span class="label-header">
                    {{answer.question.question}} </span>
                <mat-card
                    class="label-header reduce-label-spacing" class="col-md-12 col-sm-10 col-xs-12">
                    <mat-card-content>
                        <div *ngIf="answer.question.type.toString() === 'Radio'">
                            <div
                                class="row form-group col-md-12 col-sm-10 col-xs-12 mb-1">
                                <span> {{getOptionValue(answer)}} </span>
                            </div>
                        </div>
                        <div *ngIf="answer.question.type.toString() === 'Radio_Text'">
                            <div class="form-group col-md-12 col-sm-10 col-xs-12 mb-1">
                                <span> {{getOptionValue(answer)}} </span>
                            </div>
                            <mat-form-field appearance="outline" *ngIf="getStringValue(answer)"
                                class="row form-group col-md-12 col-sm-10 col-xs-12 mb-0">
                                <textarea class="text" type="text" matInput [disabled]="true" [value]="getStringValue(answer)"
                                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                    style="overflow:hidden"></textarea>
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                    <div
                        *ngIf="answer.question.type.toString() === 'Text'">
                        <mat-form-field appearance="outline"
                            class="form-group col-md-12 col-sm-10 col-xs-12 mb-0">
                            <textarea class="text" type="text" matInput
                                [disabled]="true"
                                [value]="getStringValue(answer)"
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="5"
                                style="overflow:hidden"> </textarea>
                        </mat-form-field>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>

</div>

<div *ngIf="operationInProgress === 'loading'" class="grid place-items-center inset-0">
  <mat-spinner></mat-spinner>
</div>

