import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmFeedbackListComponent } from './cm-feedback-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';

@NgModule({
  declarations: [CmFeedbackListComponent],
  imports: [
    CommonModule,

    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [CmFeedbackListComponent],
})
export class CmFeedbackListModule {}
