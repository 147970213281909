
<form *ngIf="!isLoading && reportForm" [formGroup]="reportForm" novalidate class="selfassessment-form">
  <div *ngFor="let question of mainQuestionsForThisForm">
    <div *ngIf="question">
      <div *ngIf="question.type.toString() !== 'Date_Input'">
        <mat-card
          appearance="outlined"
          class="col-md-12 col-sm-12 col-xs-12"
          *ngIf="question.questionEnum !== 'ConsultantSA' || role === 'Consultant'">
          <mat-card-content style="padding: 10px">
            <h4
              [ngClass]="{ 'required-label': !question.isOptional }">
              {{getQuestionGroupTitle(question.questionEnum)}}
            </h4>
            <div *ngIf="question.questionEnum === 'CoreValueSA'">
              <div class="mt-2">
                <ul class="core-values-list">
                  <li>CARING & EMPATHY - We look out for each other</li>
                  <li>PASSION - We are energized by what we do</li>
                  <li>INTEGRITY - We do what's right</li>
                  <li>FORWARD THINKING - We are always evolving</li>
                  <li>COMMITMENT & PERSEVERANCE - We finish what we start</li>
                </ul>
              </div>
            </div>

            <div *ngIf="question.questionEnum === 'CorpCitizenshipSA'">
              <p>Corporate Citizenship at Online includes being responsive to
                internal requests, sharing your knowledge/experience with others,
                providing feedback for team members, and taking responsibility to
                know what's happening within your practice/business unit/company
                and how you can contribute.</p>
              <p>Here is your annual synopsis</p>
              <mat-card appearance="outlined">
                <mat-card-header>
                  <mat-card-title>Feedback</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="mt-3 pb-2">
                    <div class="row">
                      <div class="col-lg-4 col-md-4">
                        <b># Requested From Onliners</b>
                      </div>
                      <div class="col-lg-3 col-md-3">
                        {{ fbRequestCountRequestedFromOnliners }}
                      </div>
                      <div class="col-lg-3 col-md-4">
                        <b># Requested By Onliners</b>
                      </div>
                      <div class="col-lg-2 col-md-1">
                        {{ fbRequestCountRequestedByOnliners }}
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-4 col-md-4">
                        <b># Requested From Clients</b>
                      </div>
                      <div class="col-lg-3 col-md-3">
                        {{ fbRequestCountRequestedFromClients }}
                      </div>
                      <div class="col-lg-3 col-md-4">
                        <b># Provided For Onliners</b>
                      </div>
                      <div class="col-lg-2 col-md-1">
                        {{ fbRequestCountProvidedForOnliners }}
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-4 col-md-4">
                        <b>Resume Updated Date</b>
                      </div>
                      <div class="col-lg-3 col-md-3">
                        {{ resumeUpdateDate }}
                      </div>
                      <div class="col-lg-3 col-md-4">
                        <b># Late Time Sheet Submissions</b>
                      </div>
                      <div class="col-lg-2 col-md-1">
                        {{ countLateTimeSheetSubmission }}
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-4 col-md-4">
                        <b>Weekly PulseCheck Submissions</b>
                      </div>
                      <div class="col-lg-3 col-md-3">
                        {{ countWeeklyHealthSubmission }}
                      </div>
                      <div class="col-lg-3 col-md-4">

                      </div>
                      <div class="col-lg-2 col-md-1">

                      </div>
                    </div>
                    <div *ngIf="uniqueFeedbacks && uniqueFeedbacks.length > 0">
                      <hr />
                      <div
                        *ngFor="let feedback of uniqueFeedbacks; let i = index">
                        <div class="col-3">
                          <b>{{ uniqueFeedbacks[i].submitterName }}</b>
                        </div>
                        <div class="col-3">
                          <span>({{ uniqueFeedbacks[i].typeDescr }})</span>
                        </div>

                        <div
                          *ngFor="let options of uniqueFeedbacks[i].questions; let j = index">
                          <div
                            *ngIf="uniqueFeedbacks[i].answers[j].length !== null && uniqueFeedbacks[i].answers[j].length !== 0">
                            <div class="row pb-1"
                              *ngIf="uniqueFeedbacks[i].questionType[j] === 'Radio'">
                              <div class="col-2"></div>
                              <div class="col-8"><b>{{
                                  uniqueFeedbacks[i].questions[j] }}</b></div>
                              <div class="col-2">{{
                                uniqueFeedbacks[i].answers[j] }}</div>
                            </div>

                            <div class="row pt-2 pb-2"
                              *ngIf="uniqueFeedbacks[i].questionType[j] === 'Text'">
                              <div class="col-2"></div>
                              <div class="col-8"> <b>{{
                                  uniqueFeedbacks[i].questions[j] }}</b></div>
                              <div class="col-2"></div>
                              <div class="col-2"></div>
                              <div class="col-9">{{
                                uniqueFeedbacks[i].answers[j] }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

            <div *ngIf="question.questionEnum === 'GrowthDevSA'">
              <mat-card appearance="outlined">
                <mat-card-header>
                  <mat-card-title>PD History</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="col-2"><b>Units CAD</b></div>
                  <div class="text-right"
                    *ngIf="PDDeductionSummaryUnitsPrevious.length === 0 && PDDeductionSummaryUnitsCurrent.length === 0">
                    <b class="pr-4">{{0 |currency}}</b>
                  </div>
                  <div *ngIf="PDDeductionSummaryUnitsPrevious.length !== 0">
                    <div class="col-1">
                      {{ budgetYearPrevious }}
                    </div>
                    <div
                      class="row"
                      *ngFor="let option2 of PDDeductionSummaryUnitsPrevious">
                      <div class="col-1"></div>
                      <div class="col-3">
                        {{ option2.deductionDesc }}
                      </div>
                      <div class="col-6">
                        {{ option2.comment }}
                      </div>
                      <div class="col-2 text-right">
                        <span class="pr-4">{{
                          option2.deductionValue | currency
                          }}</span>
                      </div>
                    </div>
                    <div class="text-right">
                      <b class="pr-4">{{
                        sumOfDeductionValues(PDDeductionSummaryUnitsPrevious)
                        | currency
                        }}</b>
                    </div>
                  </div>

                  <div *ngIf="PDDeductionSummaryUnitsCurrent.length !== 0">
                    <div class="col-1">
                      {{ budgetYearCurrent }}
                    </div>
                    <div
                      class="row"
                      *ngFor="let option of PDDeductionSummaryUnitsCurrent">
                      <div class="col-1"></div>
                      <div class="col-3">
                        {{ option.deductionDesc }}
                      </div>
                      <div class="col-6">
                        {{ option.comment }}
                      </div>
                      <div class="col-2 text-right">
                        <span class="pr-4">{{
                          option.deductionValue | currency
                          }}</span>
                      </div>
                    </div>
                    <div class="text-right">
                      <b class="pr-4">{{
                        sumOfDeductionValues(PDDeductionSummaryUnitsCurrent)
                        | currency
                        }}</b>
                    </div>
                  </div>

                  <div class="col-2"><b>Hours</b></div>
                  <div class="text-right"
                    *ngIf="PDDeductionSummaryHoursPrevious.length === 0 && PDDeductionSummaryHoursCurrent.length === 0">
                    <b class="pr-4">0</b>
                  </div>
                  <div *ngIf="PDDeductionSummaryHoursPrevious.length !== 0">
                    <div class="col-2">
                      {{ budgetYearPrevious }}
                    </div>
                    <div
                      class="row"
                      *ngFor="let option2 of PDDeductionSummaryHoursPrevious">
                      <div class="col-1"></div>
                      <div class="col-3">
                        {{ option2.deductionDesc }}
                      </div>
                      <div class="col-6">
                        {{ option2.comment }}
                      </div>
                      <div class="col-2 text-right">
                        <span class="pr-4">{{
                          option2.deductionValue
                          }}</span>
                      </div>
                    </div>
                    <div class="text-right">
                      <b class="pr-4">{{
                        sumOfDeductionValues(PDDeductionSummaryHoursPrevious)
                        }}</b>
                    </div>
                  </div>

                  <div *ngIf="PDDeductionSummaryHoursCurrent.length !== 0">
                    <div class="col-2">
                      {{ budgetYearCurrent }}
                    </div>
                    <div
                      class="row"
                      *ngFor="let option of PDDeductionSummaryHoursCurrent">
                      <div class="col-1"></div>
                      <div class="col-3">
                        {{ option.deductionDesc }}
                      </div>
                      <div class="col-6">
                        {{ option.comment }}
                      </div>
                      <div class="col-2 text-right">
                        <span class="pr-4">{{
                          option.deductionValue
                          }}</span>
                      </div>
                    </div>
                    <div class="text-right">
                      <b class="pr-4">{{
                        sumOfDeductionValues(PDDeductionSummaryHoursCurrent)
                        }}</b>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

            <div *ngIf="question.questionEnum === 'DeliverySA'">
              <mat-card appearance="outlined">
                <mat-card-header>
                  <mat-card-title>Data in this section is accurate as of the
                    end of {{ deliveryDisclaimerMonthYear }}</mat-card-title>
                </mat-card-header>
                <mat-card-content class="pt-3 pb-3">
                  <div class="row">
                    <div class="col-6"></div>
                    <div class="col-3 text-right pr-4">
                      <b>{{ previousFiscalYear }}</b>
                    </div>
                    <div class="col-3 text-right pr-4">
                      <b>{{ currentFiscalYear }}</b>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6"><b>Base Camp Hours</b></div>
                    <div class="col-3 text-right">
                      {{ previousHours }}
                    </div>
                    <div class="col-3 text-right">
                      {{ currentHours }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6"><b>Total Billable Hours</b></div>
                    <div class="col-3 text-right">
                      {{ previousBillableHours }}
                    </div>
                    <div class="col-3 text-right">
                      {{ currentBillableHours }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6"><b>Utilization %</b></div>
                    <div
                      class="col-3 text-right"
                      *ngIf="checkUtilizationAvailability('previous year')">
                      {{ getUtilization("previous year") | percent: "1.2" }}
                    </div>
                    <div
                      class="col-3 text-right"
                      *ngIf="!checkUtilizationAvailability('previous year')">
                      Not Available
                    </div>
                    <div
                      class="col-3 text-right"
                      *ngIf="checkUtilizationAvailability('current year')">
                      {{ getUtilization("current year") | percent: "1.2" }}
                    </div>
                    <div
                      class="col-3 text-right"
                      *ngIf="!checkUtilizationAvailability('current year')">
                      Not Available
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6"><b>Wellness Hours Taken</b></div>
                    <div class="col-3 text-right">
                      {{ previousSickHours }}
                    </div>
                    <div class="col-3 text-right">
                      {{ currentSickHours }}
                    </div>
                  </div>
                </mat-card-content>
                <mat-card-header>
                  <mat-card-title>Work History (YTD)</mat-card-title>
                </mat-card-header>
                <mat-card-content class="pl-3 pt-3 pb-3">
                  <div *ngFor="let option of focalWorkHistory">
                    {{ option.companyName }}
                  </div>
                  <div *ngIf="focalWorkHistory.length === 0">
                    Not Available
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

            <div *ngIf="question.type.toString() === 'Text'">
              <span
                class="reduce-label-spacing text-label font-weight-bold"
                [ngClass]="{ 'required-label': !question.isOptional }"
                [innerHTML]="displayQuestion(question)">
              </span>
              <mat-form-field
                appearance="outline"
                class="no-padding row form-group col-md-12 col-sm-12 col-xs-12"
                [ngClass]="{
                  'has-error':
                    reportForm.get(getID(question.id))?.errors &&
                    reportForm.get(getID(question.id))?.touched
                }">
                <textarea
                  type="text"
                  matInput
                  #textareafield
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="5"
                  style="overflow: hidden; border: 1px"
                  id="{{ getID(question.id) }}"
                  formControlName="{{ getID(question.id) }}"
                  placeholder="Enter comments here..."
                  maxlength="{{question.charLimit}}">
                </textarea>
                <mat-hint *ngIf="question.charLimit" align="end">
                  {{question.charLimit - textareafield.value.length}} chars left
                </mat-hint>
              </mat-form-field>
            </div>
            <div *ngIf="question.type.toString() === 'Radio'">
              <span class="label-header reduce-label-spacing"
                [innerHTML]="displayQuestion(question)"></span>
              <span
                [ngClass]="{ 'required-label': !question.isOptional }"
                class="font-weight-bold">
                Please evaluate yourself based on the above expectation
                description and provide comments to support
              </span>
              <div
                class="row"
                [ngClass]="{
                  'has-error':
                    reportForm.get(getID(question.id))?.errors &&
                    reportForm.get(getID(question.id))?.touched
                }">
                <div class="col"></div>
                <div class="form-group col-md-6 col-sm-12">
                  <mat-grid-list
                    cols="{{ validQuestionOptions(question).length }}"
                    rowHeight="60px">
                    <mat-grid-tile
                      id="mat-grid-option-display"
                      *ngFor="let option of validQuestionOptions(question)"><span
                        class="font-weight-bold displaySpan centerText">{{
                        optionDisplay(option)
                        }}</span></mat-grid-tile>
                  </mat-grid-list>

                  <div *ngIf="optionsHaveScore(question)">
                    <mat-grid-list
                      cols="{{ validQuestionOptions(question).length }}"
                      rowHeight="60px">
                      <mat-grid-tile
                        id="mat-grid-option-display"
                        *ngFor="let option of validQuestionOptions(question)"><span
                          class="font-weight-bold displaySpan centerText">{{
                          radioOptionValueDisplay(option)
                          }}</span></mat-grid-tile>
                    </mat-grid-list>
                  </div>

                  <mat-grid-list
                    cols="{{ validQuestionOptions(question).length }}"
                    rowHeight="60px">
                    <mat-radio-group formControlName="{{ question.id }}">
                      <mat-grid-tile
                        *ngFor="let option of validQuestionOptions(question)">
                        <mat-radio-button
                          class="question-radio-button"
                          [value]="option.id">
                        </mat-radio-button>
                      </mat-grid-tile>
                    </mat-radio-group>
                  </mat-grid-list>
                </div>
                <div class="col"></div>
              </div>
            </div>

            <div *ngFor="let subQuestion of subQuestionsForThisForm">
              <div *ngIf="subQuestion.questionEnum === question.questionEnum"
                class="w-100 p-1">
                <b *ngIf="subQuestion.type.toString() === 'Text' && subQuestion.question"
                  class="reduce-label-spacing text-label"
                  [ngClass]="{ 'required-label': !subQuestion.isOptional }">{{
                  displayQuestion(subQuestion) }}</b>
                <div
                  *ngIf="subQuestion.type.toString() === 'Text'">
                  <mat-form-field
                    appearance="outline"
                    class="no-padding row form-group col-md-12 col-sm-12 col-xs-12"
                    [ngClass]="{
                          'has-error':
                            reportForm.get(getID(subQuestion.id))?.errors &&
                            reportForm.get(getID(subQuestion.id))?.touched
                        }">
                    <textarea
                      type="text"
                      matInput
                      #textareafield
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="5"
                      cols="40"
                      style="overflow: hidden; border: 1px"
                      id="{{ getID(subQuestion.id) }}"
                      formControlName="{{ getID(subQuestion.id) }}"
                      placeholder="Enter comments here..."
                      maxlength="{{subQuestion.charLimit}}">
                    </textarea>
                    <mat-hint *ngIf="subQuestion.charLimit" align="end">
                      {{subQuestion.charLimit - textareafield.value.length}}
                      chars left
                    </mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </div>

          </mat-card-content>
        </mat-card>
        <p *ngIf="question.questionEnum === 'CoreValueSA'">
          <strong>Developing</strong> - Not currently achieving consistent results
          but in the process of developing/working towards a more consistent
          outcome.<br>
          <strong>Meeting Expectations</strong> - Consistently meeting
          expectations, possibly exceeding expectations at times.<br>
          <strong>Exceeding Expectations</strong> - Consistently exceeding
          expectations.<br>
        </p>
      </div>
    </div>
  </div>
  <div [ngClass]="isCollapsed ? 'fixed-above-footer fixed-collapsed' : 'fixed-above-footer fixed-expanded'">
    <button
      type="button"
      mat-stroked-button
      class="save-btn"
      color="basic"
      (click)="saveFeedback()"
      [disabled]="reportForm.pristine || isSubmitting || isSaving || submittedForThisYear || !hasQuestions">
      <span *ngIf="isSaving"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>
      {{ isSaved ? "UPDATE" : "SAVE" }}
    </button>
    <button
      type="button"
      mat-stroked-button
      class="submit-btn"
      color="primary"
      (click)="displayPreSubmissionConfirmation()"
      [disabled]="reportForm.invalid || isSubmitting || isSaving || submittedForThisYear || !hasQuestions">
      <span *ngIf="isSubmitting"><i class="fa fa-spinner fa-spin"
          aria-hidden="true"></i></span>
      SUBMIT
    </button>
  </div>
</form>

<div *ngIf="isLoading" class="grid place-items-center inset-0">
  <mat-spinner></mat-spinner>
</div>
