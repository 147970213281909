<vex-secondary-toolbar current="Relocation Requests">
  <vex-breadcrumbs [crumbs]="['Request', 'Relocation Requests']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="component-container">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <i class="fas fa-filter fa-lg"></i>
            <span>Filter Requests ({{filtersValue}})</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-remote-work-request-criteria-form *ngIf="remoteWorkRequests"
          [searchCriteriaForm]="filterCriteriaForm"
          [isOnlinerFilterVisible]="isAuthorizedToChangeStatus && isRequestApproval"
          onlinerFilterMode="AllUsers"
          (filterRemoteWorkRequestTriggered)="filterRemoteWorkRequests()">
        </app-remote-work-request-criteria-form>
    </mat-expansion-panel>
    <br>
    <h3 *ngIf="!(isAuthorizedToChangeStatus && isRequestApproval)" class="page-title">Relocation Requests</h3>
    <h3 *ngIf="isAuthorizedToChangeStatus && isRequestApproval" class="page-title">Relocation Requests</h3>
    <app-remote-work-request-list *ngIf="remoteWorkRequests" [remoteWorkRequests]="remoteWorkRequests" [setRemoteWorkRequests]="filteredRemoteWorkRequests" [canChangeStatus]="isAuthorizedToChangeStatus && isRequestApproval" [(isRequestApproval)]="isRequestApproval"></app-remote-work-request-list>
  </div>

