import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LayoutService } from 'src/@vex/services/layout.service';
import { FbAnswer } from 'src/app/core/services/assessment.service';
import {
  ClientFeedbackService,
  QuestionAnswerDict,
} from 'src/app/core/services/client-feedback.service';
import {
  FeedbackAnswer,
  Question,
  QuestionGroup,
  QuestionOption,
  QuestionOptionGroup,
} from 'src/app/core/services/feedback.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { QuestionTypeEnum } from 'src/app/shared/enums/enums.model';
import { environment } from 'src/environments/environment';
import { PublicAssessment } from '../client-feedback.component';

@Component({
  selector: 'app-client-feedback-form',
  templateUrl: './client-feedback-form.component.html',
  styleUrls: ['./client-feedback-form.component.scss'],
})
export class ClientFeedbackFormComponent implements OnInit, OnDestroy {
  @Input()
  public questions!: Question[];
  @Input()
  public feedbackForm!: UntypedFormGroup;
  @Input()
  public feedbackId!: string;
  @Input()
  public questionOptions!: QuestionOption[];
  @Input()
  public assessment!: PublicAssessment;
  @Input()
  public questionOptionGroups!: QuestionOptionGroup[];
  @Input()
  public questionOptionGroupIDs!: number[];
  @Input()
  public questionGroupIDs!: number[];
  @Input()
  public questionGroups!: QuestionGroup[];
  @Input()
  public contactName!: string;
  isCollapsed = true;
  ClientFeebackQuestionType = QuestionTypeEnum;
  questionsForThisForm!: Question[];
  answers!: QuestionAnswerDict;
  numberOfTextQuestions!: number;
  textQuestionStatus: boolean[] = [];
  questionGroupsForThisForm!: QuestionGroup[];
  onlinerName!: string;
  isSubmitting = false;
  isSaving = false;
  isQuestionFilled = false;
  isTextQuestionFilled = false;
  cookieDomain = '';
  onlineFeedbackActive = false;
  onlineSelectionMade = false;
  isSubmitted = false;
  isLoading = true;
  othersStoodOutId!: number;
  autoSaveSubscription!: Subscription | null;
  builtRadioGridQuestions: boolean[] = [];

  constructor(
    private clientFeedbackService: ClientFeedbackService,
    private snackBarService: SnackBarService,
    private layoutService: LayoutService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.onlinerName = this.assessment?.onliner;
    this.cookieDomain = this.extractHostname(location.origin);

    if (this.questionGroupIDs) {
      let questionsToFill: Question[] = [];
      let filteredQuestions: Question[] = [];
      let filteredQuestionGroups: QuestionGroup[] = [];

      this.questionGroupIDs.forEach(qGroupId => {
        filteredQuestions = this.questions.filter(q => q.groupId === qGroupId);
        if (questionsToFill.length > 0) {
          questionsToFill = [...questionsToFill.concat(filteredQuestions)];
          const group = this.questionGroups.find(qg => qg.id === qGroupId);
          if (group) {
            filteredQuestionGroups = [...filteredQuestionGroups.concat(group)];
          }
        } else {
          questionsToFill = [...filteredQuestions];
          const group = this.questionGroups.find(qg => qg.id === qGroupId);
          if (group) {
            filteredQuestionGroups.push(group);
          }
        }
      });

      this.questionsForThisForm = [...questionsToFill];
      this.questionGroupsForThisForm = [...filteredQuestionGroups];
      const othersStoodOutQuestion =
        this.questionsForThisForm.find(
          q => q.question === 'Any others that stood out to you?'
        ) || null;
      if (othersStoodOutQuestion !== null) {
        this.othersStoodOutId = othersStoodOutQuestion.id;
      }

      // set up the variables necessary to support save detection for text questions
      this.questionsForThisForm.forEach(q => {
        if (q.type.toString() === 'Text') {
          this.textQuestionStatus[q.id] = false;
        } else if (
          q.type.toString() === 'Radio' &&
          this.builtRadioGridQuestions[q.groupId] === undefined &&
          (q.groupId === 0 || q.groupId === 1)
        ) {
          this.builtRadioGridQuestions[q.groupId] = false;
        }
      });

      this.setupPage();
      this.layoutService.sidenavCollapsed$.subscribe(collapsed => {
        this.isCollapsed = collapsed;
      });
    }
  }

  ngOnDestroy() {
    this.autoSaveSubscription?.unsubscribe();
    this.autoSaveSubscription = null;
  }

  updateFeedbackForm() {
    // set all relevant fields on the form
    const txt = document.createElement('textarea');
    let cookie: string | null;
    let cookieRadioText: string | null;
    let stringValue: string | null;
    let intValue: number | null;

    // fill in answers to the questions, if there are any...
    this.questionsForThisForm.forEach(q => {
      cookie = localStorage.getItem(String(q.id));
      if (q.type.toString() === 'Radio_Text')
        cookieRadioText = localStorage.getItem(String(q.id) + '_text');

      if (cookie !== 'null') {
        stringValue = cookie;
        intValue = parseInt(cookie || '', 10) || 0;
      } else {
        intValue = null;
        stringValue = null;
      }

      if (stringValue !== '' || stringValue !== null || intValue !== null) {
        this.questionFilled();
      }

      switch (q.type.toString()) {
        case 'Select':
        case 'Scale':
          this.feedbackForm.controls[`${q.id}`].setValue(intValue);
          break;
        case 'Radio':
          this.feedbackForm.controls[`${q.id}`].setValue(stringValue);
          break;
        case 'Text':
          txt.innerHTML = stringValue ?? '';
          this.feedbackForm.controls[`${q.id}`].setValue(txt.value);
          break;
        case 'Radio_Text':
          this.feedbackForm.controls[`${q.id}`].setValue(stringValue);
          this.feedbackForm.controls[`${q.id}_text`].setValue(cookieRadioText);
          intValue = Number(localStorage.getItem(String(q.id) + '_score'));

          if (intValue !== null && intValue === -1) {
            this.feedbackForm.controls[`${q.id}_text`].setValidators([
              Validators.required,
              Validators.minLength(1),
            ]);
          }

          break;
      }

      if (q.groupId === 3 && !this.onlineFeedbackActive) {
        this.feedbackForm.controls[`${q.id}`].disable();
      }
    });
    this.isLoading = false;
  }

  questionsPerGroup(questionGroupID: number): Question[] {
    let questions: Question[] = [];

    if (questionGroupID < 2) {
      questions = this.questionsForThisForm.filter(
        q => q.groupId === questionGroupID && q.type.toString() !== 'Radio'
      );
    } else if (
      (questionGroupID === 3 && this.onlineFeedbackActive) ||
      questionGroupID === 2
    ) {
      questions = this.questionsForThisForm.filter(
        q => q.groupId === questionGroupID
      );
    } else if (questionGroupID > 3) {
      questions = this.questionsForThisForm.filter(
        q => q.groupId === questionGroupID
      );
    }

    return questions;
  }

  shouldBuildRadioGrid(questionGroupId: number): boolean {
    if (
      (questionGroupId === 1 || questionGroupId === 0) &&
      !this.builtRadioGridQuestions[questionGroupId]
    ) {
      return true;
    } else {
      return false;
    }
  }

  buildRadioGrid(questionGroupId: number): Question[] {
    let questions: Question[] = [];

    questions = this.questionsForThisForm.filter(
      q => q.groupId === questionGroupId && q.type.toString() === 'Radio'
    );
    this.builtRadioGridQuestions[questionGroupId] = true;

    return questions;
  }

  extractHostname(url: string): string {
    let hostname;

    if (url.indexOf('//') > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }

    if (url.indexOf('www.') > -1) {
      hostname = url.split('www.')[1];
    }

    hostname = hostname.split(':')[0];
    hostname = hostname.split('?')[0];

    return hostname;
  }

  getID(questionID: number): string {
    return questionID.toString();
  }

  displayQuestionGroup(questionGroup: QuestionGroup): string {
    return questionGroup.name;
  }

  displayQuestion(question: Question): string {
    return question.question;
  }

  displayedColumns(questionGroup: QuestionGroup): QuestionOption[] {
    const questions = this.questionsForThisForm.filter(
      q => q.groupId === questionGroup.id
    );
    const optionGroupIds: number[] = [];

    questions.forEach(q => {
      if (q.optionGroupId !== null && q.optionGroupId !== undefined) {
        if (!optionGroupIds.some(qo => qo === q.optionGroupId)) {
          optionGroupIds.push(q.optionGroupId);
        }
      }
    });

    const options = this.questionOptions.filter(
      qo => qo.groupId === optionGroupIds[0]
    );

    return options;
  }

  optionDisplay = (option?: QuestionOption): string | undefined => {
    const text = option?.value;
    return text;
  };

  radioOptionValueDisplay(option?: QuestionOption): number | undefined {
    const value = option?.score;
    return value;
  }

  getSliderTickInterval(question: Question) {
    const options = this.filterSortThisQuestionOption(question);
    const interval = options[1].score - options[0].score;
    return interval;
  }

  getIntervalMin(question: Question) {
    const minOption = this.filterSortThisQuestionOption(question);
    return minOption[0].score;
  }

  getIntervalMax(question: Question) {
    const maxOption = this.filterSortThisQuestionOption(question);
    return maxOption[maxOption.length - 1].score;
  }

  filterSortThisQuestionOption(question: Question): QuestionOption[] {
    return this.questionOptions
      .filter(qo => qo.groupId === question.optionGroupId && qo.id > 1000)
      .sort((a, b) => a.score - b.score);
  }

  getIntervalTextValue(question: Question, minOrMax: number): string {
    let textValue: string;

    if (minOrMax === 0) {
      const minOption = this.filterSortThisQuestionOption(question);
      textValue = minOption[0].value;
    } else {
      const maxOption = this.filterSortThisQuestionOption(question);
      textValue = maxOption[maxOption.length - 1].value;
    }

    return textValue;
  }

  validQuestionOptions(question: Question) {
    let qOptions: QuestionOption[] = [];

    if (question.type.toString() === 'Select') {
      qOptions = this.questionOptions.filter(
        qo => qo.groupId === question.optionGroupId && qo.id < 1000
      );
    } else {
      qOptions = this.questionOptions.filter(
        qo => qo.groupId === question.optionGroupId
      );
    }

    return qOptions;
  }

  optionsHaveScore(question: Question) {
    const qOptions = this.validQuestionOptions(question);
    let haveScore = false;

    qOptions.forEach(qo => {
      haveScore = haveScore || qo.score !== null;
    });

    return haveScore;
  }

  onSearchChange(target: QuestionOption): void {
    localStorage.setItem(String(target.id), target.value);
  }

  onSearchChangeRadio(target: QuestionOption, id: number): void {
    localStorage.setItem(String(id), String(target.id));
  }

  openOnlineFeedback() {
    this.onlineFeedbackActive = true;
    this.onlineSelectionMade = true;
    localStorage.setItem('okSkipSelection', 'true');
    this.enableOnlineQuestions();
  }

  skipOnlineFeedback() {
    this.onlineSelectionMade = true;
    localStorage.setItem('okSkipSelection', 'false');
  }

  enableOnlineQuestions() {
    this.questionsForThisForm.forEach(q => {
      this.feedbackForm.controls[`${q.id}`].enable();
    });
  }

  questionFilled() {
    this.isQuestionFilled = true;
  }

  textQuestionFilled(event: string, questionId: number) {
    if (event !== '') {
      this.textQuestionStatus[questionId] = true;
    } else {
      this.textQuestionStatus[questionId] = false;
    }

    this.isTextQuestionFilled = this.getTextQuestionStatus();
  }

  getTextQuestionStatus(): boolean {
    let status = false;

    this.textQuestionStatus.forEach(t => {
      status = t || status;
    });

    return status;
  }

  submitClientFeedback() {
    this.isSubmitting = true;
    this.feedbackForm.disable();
    const answers = this.updateFeedbackFromForm();

    answers[0].stringValue = this.contactName;

    this.clientFeedbackService
      .submitFeedback(this.feedbackId, answers, this.onlineFeedbackActive)
      .subscribe(
        f => {
          if (f) {
            this.snackBarService.clientMessage(
              'Thank you for your time! Upon clicking the "OK" button, you will be directed to our website'
            );
            this.isSubmitting = false;
            this.isSubmitted = true;
            this.feedbackForm.markAsPristine();
          }
        },
        error => {
          this.snackBarService.error(error);
          this.isSubmitting = false;
          this.enableFeedbackFields();
        }
      );
  }

  saveClientFeedback() {
    this.isSaving = true;
    let answers: FbAnswer[] = [];
    let answer = new FbAnswer();
    const expiredDate = new Date();
    expiredDate.setDate(expiredDate.getDate() + 365);
    let value: string | null;

    answers = this.updateFeedbackFromForm();
    answers[0].stringValue = this.contactName;

    this.questionsForThisForm.forEach(q => {
      answer = answers.find(a => a.questionId === q.id) || answer;
      const isTextValue =
        q.type.toString() === 'Text' ||
        q.type.toString() === 'Single_Line_Input';

      if (answer) {
        if (isTextValue) {
          value = answer.stringValue;
        } else if (q.type.toString() === 'Scale') {
          value = answer.intValue?.toString() || '';
        } else if (q.type.toString() === 'Radio_Text') {
          localStorage.setItem(
            String(q.id) + '_text',
            String(answer.stringValue ? answer.stringValue : '')
          );
          localStorage.setItem(
            String(q.id) + '_score',
            String(answer.intValue)
          );
          value = answer.optionId?.toString() || '';
        } else {
          value = answer.optionId?.toString() || '';
        }

        localStorage.setItem(String(q.id), String(value));
      }
    });

    this.snackBarService.message(
      'You have successfully saved your feedback. Thank you!'
    );
    this.isSaving = false;
    this.feedbackForm.markAsPristine();
  }

  enableFeedbackFields(): void {
    this.questionsForThisForm.forEach(q => {
      // TODO: this.enableOnlineQuestions is a void function, why we need to check that?
      // if (q.groupId === 3 && this.enableOnlineQuestions) {
      if (q.groupId === 3) {
        this.feedbackForm.controls[`${q.id}`].enable();
      } else if (q.groupId !== 3) {
        this.feedbackForm.controls[`${q.id}`].enable();
      }
    });
  }

  getRadioTextQuestionId(questionId: number): string {
    const idString = '' + questionId + '_text';
    return idString;
  }

  radioTextChange(optionId: number, questionId: number) {
    const option = this.questionOptions.find(qo => qo.id === optionId);
    if (option !== null && option !== undefined) {
      const question = this.questions.find(q => q.id === questionId);
      if (option.score === -1) {
        this.feedbackForm.controls[`${question?.id}_text`].setValidators([
          Validators.required,
          Validators.minLength(1),
        ]);
        if (question?.id) {
          this.commentRequired(question.id, optionId);
        }
      } else {
        this.feedbackForm.controls[`${question?.id}_text`].clearValidators();
      }

      this.feedbackForm.controls[
        `${question?.id}_text`
      ]?.updateValueAndValidity();
    }
  }

  get hasQuestions(): boolean {
    return this.questionsForThisForm.length > 0;
  }

  private setupPage() {
    this.isLoading = true;

    this.onlineSelectionMade =
      localStorage.getItem('okSkipSelection') === 'true' ||
      localStorage.getItem('okSkipSelection') === 'false';
    this.onlineFeedbackActive =
      localStorage.getItem('okSkipSelection') === 'true';

    if (this.feedbackId) {
      this.updateFeedbackForm();
    } else {
      this.isLoading = false;
    }

    this.setupAutoSave();
  }

  private setupAutoSave() {
    this.autoSaveSubscription = this.feedbackForm.valueChanges
      .pipe(debounceTime(environment.autoSaveInterval))
      .subscribe(() => {
        if (this.feedbackForm.valid && this.feedbackForm.dirty) {
          this.saveClientFeedback();
        }
      });
  }

  private updateFeedbackFromForm(): FeedbackAnswer[] {
    const answers: FeedbackAnswer[] = [];
    const txt = document.createElement('textarea');

    this.questionsForThisForm.forEach(q => {
      const options = this.questionOptions.filter(
        qo => qo.groupId === q.optionGroupId
      );

      let stringValue: string | null = '';
      let intValue: number | null = 0;
      let optionID: number | null = 0;
      const isTextValue =
        q.type.toString() === 'Text' ||
        q.type.toString() === 'Single_Line_Input';

      if (q.type.toString() === 'Scale') {
        intValue = this.feedbackForm.controls[q.id].value;
        optionID =
          this.questionOptions.find(
            qo =>
              qo.score === intValue &&
              qo.groupId ===
                this.questionOptionGroupIDs.find(
                  qoid => qoid === q.optionGroupId
                )
          )?.id || null; // this is slightly better
      } else if (q.type.toString() === 'Radio_Text') {
        intValue = !this.feedbackForm.value[q.id]
          ? null
          : options.find(sv => sv.id === this.feedbackForm.value[q.id])
              ?.score || null;
        stringValue = this.feedbackForm.controls[`${q.id}_text`].value;
        optionID = !this.feedbackForm.value[q.id]
          ? null
          : options.find(sv => sv.id === this.feedbackForm.value[q.id])?.id ||
            null;
      } else {
        txt.innerHTML = String(this.feedbackForm.value[q.id]);

        stringValue = isTextValue
          ? txt.value
          : !this.feedbackForm.value[q.id]
            ? null
            : options.find(
                sv => sv.id === Number(this.feedbackForm.value[q.id])
              )?.value || null;
        intValue = isTextValue
          ? null
          : !this.feedbackForm.value[q.id]
            ? null
            : options.find(
                sv => sv.id === Number(this.feedbackForm.value[q.id])
              )?.score || null;
        optionID = isTextValue
          ? null
          : !this.feedbackForm.value[q.id]
            ? null
            : options.find(
                sv => sv.id === Number(this.feedbackForm.value[q.id])
              )?.id || null;
      }

      // TODO: answers is a const with empty array, which makes `findIndex` meaningless
      const index = answers.findIndex(a => a.questionId === q.id);

      if (index > -1) {
        answers[index].stringValue = stringValue || '';
        answers[index].intValue = intValue;
        answers[index].optionId = optionID;
      } else {
        answers.push({
          id: -1,
          questionId: q.id,
          question: q,
          stringValue: stringValue || '',
          intValue: isTextValue ? null : intValue,
          optionId: isTextValue ? null : optionID,
          option: null,
        });
      }
    });

    if (this.isSubmitting) {
      localStorage.clear();
    }

    return answers;
  }

  private commentRequired(questionId: number, optionId: number): boolean {
    const option = this.questionOptions.find(qo => qo.id === optionId);
    let required = false;

    if (option !== null && option !== undefined) {
      if (option.score === -1) {
        this.feedbackForm.controls[`${questionId}_text`].markAsTouched();
        required = true;
      }
    }

    return required;
  }
}
