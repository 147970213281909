import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LayoutService } from 'src/@vex/services/layout.service';
import {
  AssessmentService,
  FbConfidential,
  FbConfidentialAnswer,
} from 'src/app/core/services/assessment.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { AutoSuggestService } from 'src/app/core/services/auto-suggest.service';
import { CareerMentor } from 'src/app/core/services/career-mentor.service';
import {
  FeedbackService,
  Question,
  QuestionOption,
} from 'src/app/core/services/feedback.service';
import {
  Employee,
  OnlinerService,
} from 'src/app/core/services/onliner.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { FeedbackTypeEnum } from 'src/app/shared/enums/enums.model';
import * as formValidators from 'src/app/shared/validators/form-validator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confidential-feedback-form',
  templateUrl: './confidential-feedback-form.component.html',
  styleUrls: ['./confidential-feedback-form.component.scss'],
})
export class ConfidentialFeedbackFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('autosize')
  autosize!: CdkTextareaAutosize;

  @Input()
  feedbackId!: number | null;
  @Input()
  feedbackForm!: UntypedFormGroup;
  @Input() selectedFormId: number = FeedbackTypeEnum.ConfidentialFeedback;
  @Input()
  selectedTypeLoaded!: boolean;
  @Input() set questionGroupIDs(qGroupIds: number[]) {
    if (qGroupIds !== null) {
      if (qGroupIds.length === 1) {
        if (
          !this._questionGroupIds ||
          this._questionGroupIds[0] === qGroupIds[0]
        ) {
          this.reloadRequired = false;
        } else {
          this.reloadRequired = true;
        }
      } else if (qGroupIds.length > 1) {
        if (!this._questionGroupIds) {
          this.reloadRequired = false;
        } else {
          this.reloadRequired = !this._questionGroupIds.every(qg =>
            qGroupIds.every(question => question === qg)
          );
        }
      }

      this._questionGroupIds = [...qGroupIds];
    }
  }
  @Input()
  questionOptions!: QuestionOption[];
  @Input()
  questionOptionGroupIDs!: number[];
  @Input()
  questions!: Question[];
  isCollapsed = true;
  onliners!: Employee[];
  feedback!: FbConfidential | null;
  questionsForThisForm: Question[] = [];
  isFeedbackLead!: boolean;
  reloadRequired = false;
  autoSaveSubscription!: Subscription | null;
  feedbackTypeIndicator!: number;
  isLoading!: boolean;
  isSaving = false;
  isDraft = false;
  isSubmitting = false;
  dismissed = false;
  filteredOnliners!: Observable<Employee[]> | Observable<CareerMentor[]>;
  onlinerAssigned = false;
  selectedOnliner!: Employee;
  practice!: string;
  isSaved = false;
  defaultColor = 'rgb(156, 30, 54)';
  disabledColor = 'rgba(0,0,0,.38)';
  errorMatcher = new formValidators.CompControlErrorStateMatcher();
  private _questionGroupIds!: number[];

  constructor(
    private assessmentService: AssessmentService,
    private feedbackService: FeedbackService,
    private onlinerService: OnlinerService,
    private snackBarService: SnackBarService,
    private autoSuggestService: AutoSuggestService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private router: Router,
    private layoutService: LayoutService
  ) {}

  ngOnInit() {
    this.isFeedbackLead = this.authService.doesUserHaveRole([
      environment.roles.FeedbackLead,
    ]);
    if (this._questionGroupIds) {
      this._questionGroupIds.forEach(qgId => {
        const questionsToFill = this.questions.filter(q => q.groupId === qgId);

        if (!this.questionsForThisForm) {
          this.questionsForThisForm = [...questionsToFill];
        } else {
          this.questionsForThisForm =
            this.questionsForThisForm.concat(questionsToFill);
        }
      });
    }
    this.setupPage();
    this.layoutService.sidenavCollapsed$.subscribe(collapsed => {
      this.isCollapsed = collapsed;
    });
  }

  ngOnDestroy() {
    if (this.autoSaveSubscription) {
      this.autoSaveSubscription.unsubscribe();
      this.autoSaveSubscription = null;
    }
  }

  ngOnChanges() {
    if (this.reloadRequired) {
      if (this._questionGroupIds !== null) {
        this.questionsForThisForm = [];
        this._questionGroupIds.forEach(qgId => {
          const questionsToFill = this.questions.filter(
            q => q.groupId === qgId
          );
          this.questionsForThisForm = [
            ...this.questionsForThisForm,
            ...questionsToFill,
          ];
        });
      }

      this.setupPage();
    }
  }

  get onlinerFilterControl() {
    return this.feedbackForm.controls['onliner'];
  }

  saveFeedback() {
    this.isSaving = true;
    this.updateFeedbackFromForm();

    if (this.feedback) {
      this.assessmentService.saveConfidentialFeedback(this.feedback).subscribe(
        savedFeedback => {
          this.snackBarService.message(
            'Your confidential notes were saved successfully!'
          );
          this.feedback = savedFeedback;
          this.isSaved = true;
          this.feedbackForm.controls['onliner'].disable();
          this.isSaving = false;
          this.isDraft = true;
          this.feedbackForm.markAsPristine();
        },
        error => {
          this.isSaving = false;
          this.snackBarService.error(error);
        }
      );
    }
  }

  submitFeedback() {
    this.isSubmitting = true;
    this.feedbackForm.disable();
    this.updateFeedbackFromForm();

    if (this.feedback) {
      this.assessmentService
        .submitConfidentialFeedback(this.feedback)
        .subscribe(
          () => {
            this.snackBarService.message('Submission successful!');
            this.isSubmitting = false;
            this.isSaving = false;
            this.enableFeedbackFields();
            this.resetFeedbackForm();
            this.isSaved = false;
            this.onlinerAssigned = false;
            this.navigateToFeedback();
          },
          error => {
            this.isSubmitting = false;
            this.enableFeedbackFields();
            this.snackBarService.error(error);
          }
        );
    }
  }

  navigateToFeedback() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/confidentialNotes']));
  }

  validateOnliner(): ValidatorFn {
    return (onlinerControl: AbstractControl) => {
      if (!onlinerControl.value || !onlinerControl.value.userId) {
        const temp: ValidationErrors = {};
        temp['required'] = true;
        return temp;
      }
      return null;
    };
  }

  enableFeedbackFields(): void {
    this.feedbackForm.controls['onliner'].enable();
    this.questionsForThisForm.forEach(q => {
      this.feedbackForm.controls[`${q.id}`].enable();
    });
  }

  setOnlinerFilter() {
    this.filteredOnliners = this.autoSuggestService.setOnlinerFilter(
      this.onlinerFilterControl,
      this.onliners
    );
  }

  getOnliner() {
    let onliner = '';

    if (this.feedbackForm.value.onliner) {
      onliner = this.onlinerDisplay(this.feedbackForm.value.onliner);
    }

    return onliner;
  }

  onlinerSelected(e: MatAutocompleteSelectedEvent) {
    const value = e.option.value;

    if ((e.source && value) || value === '') {
      this.onlinerAssigned = true;
      this.updatePracticeValue(value.userId);
    }
  }

  displayQuestion(question: Question): string {
    return question.question;
  }

  getID(questionID: number): string {
    const idString = '' + questionID + '';
    return idString;
  }

  optionDisplay = (option?: QuestionOption): string | undefined => {
    const text = option?.value;
    return text;
  };

  validQuestionOptions(question: Question) {
    return this.questionOptions.filter(
      qo => qo.groupId === question.optionGroupId
    );
  }

  htmlDecode(input: string) {
    const txt = document.createElement('textarea');
    txt.innerHTML = input;
    return txt.value;
  }

  getOnlinerFromDropdown(employeeId?: string) {
    return this.onliners
      ? (this.onliners.find(
          onliner => onliner.userId === employeeId
        ) as Employee)
      : null;
  }

  onlinerDisplay = (option?: Employee | CareerMentor): string =>
    this.autoSuggestService.onlinerDisplay(option);

  showError = (controlName: string) => {
    const control = this.feedbackForm.controls[controlName];

    if (control && control.hasError('required') && control.touched) {
      return true;
    }
    return false;
  };

  updatePracticeValue(userId: string): void {
    this.onlinerService
      .getPracticeForOnliner(userId)
      .subscribe(data => (this.practice = data));
  }

  radioTextChange(optionId: number, questionId: number) {
    const option = this.questionOptions.find(qo => qo.id === optionId);
    if (option !== null && option !== undefined) {
      const question = this.questions.find(q => q.id === questionId);
      if (option.score === -1) {
        this.feedbackForm.controls[`${question?.id}_text`].setValidators([
          Validators.required,
          Validators.minLength(1),
          formValidators.requiredAndNoWhiteSpaceValidator,
        ]);
        if (question?.id) {
          this.commentRequired(question.id, optionId);
        }
      } else {
        this.feedbackForm.controls[`${question?.id}_text`].clearValidators();
      }

      this.feedbackForm.controls[
        `${question?.id}_text`
      ].updateValueAndValidity();
    }
  }

  getRadioTextQuestionId(questionId: number): string {
    const idString = '' + questionId + '_text';
    return idString;
  }

  isSaveButtonDisabled(): boolean {
    const isDisabled =
      this.isSaving ||
      this.isSubmitting ||
      !this.onlinerAssigned ||
      this.feedbackForm.controls['onliner'].errors !== null;
    return isDisabled;
  }

  resetFeedbackForm() {
    this.feedbackForm.reset();
    this.feedback = null;
    this.feedbackId = null;
    this.ngOnInit();
  }

  updateFeedbackForm() {
    this.feedbackForm.controls['onliner'].setValue(
      this.getOnlinerFromDropdown(this.feedback?.employeeId)
    );
    this.onlinerAssigned = true;
    const txt = document.createElement('textarea');

    if (this.isDraft) {
      this.noEventOnlinerSelected(this.feedback?.employeeId);
    }

    // fill in answers to the questions, if there are any...
    this.questionsForThisForm.forEach(q => {
      if (this.feedback && this.feedback.answers) {
        this.feedback.answers.forEach(a => {
          if (q.id === a.questionId) {
            if (q.type.toString() === 'Text') {
              txt.innerHTML = a.stringValue;
              this.feedbackForm.controls[`${q.id}`].setValue(txt.value);
            } else if (q.type.toString() === 'Scale') {
              this.feedbackForm.controls[`${q.id}`].setValue(a.intValue);
            } else if (q.type.toString() === 'Radio_Text') {
              this.feedbackForm.controls[`${q.id}`].setValue(a.optionId);
              this.feedbackForm.controls[`${q.id}_text`].setValue(
                a.stringValue
              );

              if (a.intValue === -1) {
                this.feedbackForm.controls[`${q.id}_text`].setValidators([
                  Validators.required,
                  Validators.minLength(1),
                  formValidators.requiredAndNoWhiteSpaceValidator,
                ]);
              }
            } else {
              this.feedbackForm.controls[`${q.id}`].setValue(a.optionId);
            }
          }
        });
      }
    });

    this.feedbackForm.updateValueAndValidity();
    this.changeDetectorRef.detectChanges();
    this.isLoading = false;
  }

  noEventOnlinerSelected(empUserId?: string) {
    if (empUserId !== undefined || empUserId !== null) {
      this.onlinerAssigned = true;
      this.updatePracticeValue((empUserId || '').toLowerCase());
    }
  }

  closeAlert() {
    this.dismissed = true;
  }

  get hasQuestions() {
    return this.questionsForThisForm.length > 0;
  }

  private setupPage() {
    this.feedbackTypeIndicator = this.selectedFormId;
    this.isLoading = true;

    this.onlinerService.getOnlinersExcludingDefault().subscribe(
      data => {
        this.onliners = data;
        this.setOnlinerFilter();
        this.feedbackForm.controls['onliner'].setValidators(
          this.validateOnliner()
        );

        if (!this.feedbackId) {
          this.isLoading = false;
        }
      },
      error => {
        this.isLoading = false;
        this.snackBarService.error(error);
      }
    );
    this.setupAutoSave();

    if (this.feedbackId) {
      this.getConfidentialFeedback();
    } else {
      this.isLoading = false;
    }
  }

  private setupAutoSave() {
    this.autoSaveSubscription = this.feedbackForm?.valueChanges
      .pipe(debounceTime(environment.autoSaveInterval))
      .subscribe(() => {
        if (this.feedbackForm.dirty && !this.isSaveButtonDisabled()) {
          this.saveFeedback();
        }
      });
  }

  private getConfidentialFeedback() {
    if (this.feedbackId) {
      this.feedbackService
        .getConfidentialFeedback(this.feedbackId, true)
        .subscribe(savedFeedback => {
          this.feedback = {
            answers: savedFeedback.answers,
            employeeId: savedFeedback.employeeId,
            id: savedFeedback.id,
            submittedBy: savedFeedback.submittedById,
            submittedDate: savedFeedback.submittedDate,
          };
          this.isDraft = true;
          this.isSaved = true;
          this.feedbackForm.controls['onliner'].disable();
          this.updateFeedbackForm();
        });
    }
  }

  private updateFeedbackFromForm() {
    let answers: FbConfidentialAnswer[] = [];
    if (!this.feedback) {
      this.feedback = new FbConfidential();
      this.feedback.id = -1;
    } else {
      if (this.feedback.answers) {
        answers = [...this.feedback.answers];
        this.feedback.answers = [];
      }
    }

    this.feedback.submittedBy = this.authService.getUserId().toLowerCase();
    this.feedback.submittedDate = new Date();
    this.feedback.employeeId = this.feedbackForm.controls['onliner'].value
      .userId
      ? this.feedbackForm.controls['onliner'].value.userId
      : '';

    this.questionsForThisForm.forEach(q => {
      if (this.feedback && !this.feedback.answers) {
        this.feedback.answers = [];
      }

      const options = this.questionOptions.filter(
        qo => qo.groupId === q.optionGroupId
      );

      let stringValue: string | null = '';
      let intValue: number | null = 0;
      let optionID: number | null = 0;

      if (q.type.toString() === 'Scale') {
        intValue = this.feedbackForm.controls[q.id].value;
        optionID =
          this.questionOptions.find(
            qo =>
              qo.score === intValue &&
              qo.groupId ===
                this.questionOptionGroupIDs.find(
                  qoid => qoid === q.optionGroupId
                )
          )?.id || null; // this is slightly better
      } else if (q.type.toString() === 'Radio_Text') {
        intValue =
          this.feedbackForm.value[q.id] === null
            ? null
            : options.find(sv => sv.id === this.feedbackForm.value[q.id])
                ?.score || null;
        stringValue = this.feedbackForm.controls[`${q.id}_text`].value;
        optionID =
          this.feedbackForm.value[q.id] === null
            ? null
            : options.find(sv => sv.id === this.feedbackForm.value[q.id])?.id ||
              null;
      } else {
        stringValue =
          q.type.toString() === 'Text'
            ? this.feedbackForm.value[q.id]
            : !this.feedbackForm.value[q.id]
              ? null
              : options.find(sv => sv.id === this.feedbackForm.value[q.id])
                  ?.value || null;
        intValue =
          q.type.toString() === 'Text'
            ? null
            : !this.feedbackForm.value[q.id]
              ? null
              : options.find(sv => sv.id === this.feedbackForm.value[q.id])
                  ?.score || null;
        optionID =
          q.type.toString() === 'Text'
            ? null
            : !this.feedbackForm.value[q.id]
              ? null
              : options.find(sv => sv.id === this.feedbackForm.value[q.id])
                  ?.id || null;
      }

      const index = answers.findIndex(a => a.questionId === q.id);

      if (index > -1) {
        this.feedback?.answers.push({
          confidentialFeedbackAnswerId:
            answers[index].confidentialFeedbackAnswerId,
          confidentialFeedbackId: this.feedback.id,
          questionId: q.id,
          question: q,
          stringValue: stringValue ? stringValue.trim() : '',
          intValue:
            q.type.toString() === 'Text' ? answers[index].intValue : intValue,
          optionId: optionID,
          option: answers[index].option,
        });
      } else {
        this.feedback?.answers.push({
          confidentialFeedbackAnswerId: -1,
          confidentialFeedbackId: this.feedback.id,
          questionId: q.id,
          question: q,
          stringValue: stringValue || '', // this.feedbackForm.value[q.id],
          intValue: q.type.toString() === 'Text' ? null : intValue, // q.optionGroup.options.find(o => o.value == this.feedbackForm.value.qID).score,
          optionId: q.type.toString() === 'Text' ? null : optionID, // optionId: q.type.toString() == 'Text' ? null: q.optionGroup.options.find(o => o.value == this.feedbackForm.value.qID).id,
          option: null,
        });
      }
    });
  }

  private commentRequired(questionId: number, optionId: number): boolean {
    const option = this.questionOptions.find(qo => qo.id === optionId);
    let required = false;

    if (option !== null && option !== undefined) {
      if (option.score === -1) {
        this.feedbackForm.controls[`${questionId}_text`].markAsTouched();
        required = true;
      }
    }

    return required;
  }
}
