import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSelectChange } from '@angular/material/select';
import { Observable, forkJoin } from 'rxjs';
import { AutoSuggestService } from 'src/app/core/services/auto-suggest.service';
import { CareerMentor } from 'src/app/core/services/career-mentor.service';
import {
  Employee,
  OnlinerService,
} from 'src/app/core/services/onliner.service';
import {
  RemoteWorkConfig,
  RemoteWorkConfigService,
} from 'src/app/core/services/remote-work-config.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import * as formValidator from 'src/app/shared/validators/form-validator';

@Component({
  selector: 'app-remote-work-config',
  templateUrl: './remote-work-config.component.html',
  styleUrls: ['./remote-work-config.component.scss'],
})
export class RemoteWorkConfigComponent implements OnInit {
  rwConfigForm: UntypedFormGroup = this.createForm();
  selected: string | null = 'rwConfig';
  rwConfigs!: RemoteWorkConfig[] | null;
  rwConfig!: RemoteWorkConfig | null;

  onliners!: Employee[] | null;
  secOnliners!: Employee[] | null;
  filteredApprovers!: Observable<Employee[]> | Observable<CareerMentor[]>;
  filteredSecApprovers!: Observable<Employee[]> | Observable<CareerMentor[]>;
  approver!: Employee | null;
  secApprover!: Employee | null;

  get approverControl() {
    return this.rwConfigForm.controls['approver'];
  }
  get secApproverControl() {
    return this.rwConfigForm.controls['secApprover'];
  }

  get grantAccessButton() {
    return this.rwConfigForm.controls['grantAccessButton'];
  }

  isLoading!: boolean;
  isSubmitting!: boolean;
  rwConfigSelected!: boolean;
  changesMade!: boolean;

  constructor(
    private remoteWorkConfigService: RemoteWorkConfigService,
    private onlinerService: OnlinerService,
    private formBuilder: UntypedFormBuilder,
    private snackBarService: SnackBarService,
    private autoSuggestService: AutoSuggestService
  ) {}

  ngOnInit() {
    this.setupPage();
  }

  public onChange(event: MatSelectChange) {
    this.rwConfig = event.value as RemoteWorkConfig;
    this.rwConfigSelected = true;

    this.setupOnlinerDropdownDisabling();
    this.filteredApprovers = this.autoSuggestService.setOnlinerFilter(
      this.approverControl,
      this.onliners || []
    );
    this.filteredSecApprovers = this.autoSuggestService.setOnlinerFilter(
      this.secApproverControl,
      this.secOnliners || []
    );

    this.approver =
      this.onliners?.find(o => o.userId === this.rwConfig?.primaryEmail) ||
      null;
    this.approverControl.setValue(this.approver);

    if (this.rwConfig.ccEmail) {
      this.secApprover =
        this.secOnliners?.find(o => o.userId === this.rwConfig?.ccEmail) ||
        null;
      this.secApproverControl.setValue(this.secApprover);
    } else {
      this.secApprover =
        this.secOnliners?.find(o => o.firstName === ' ') || null;
      this.secApproverControl.setValue(this.secApprover);
    }
  }

  setDropDownValues() {
    this.approver =
      this.onliners?.find(o => o.userId === this.rwConfig?.primaryEmail) ||
      null;
    this.approverControl.setValue(this.approver);

    if (this.rwConfig?.ccEmail) {
      this.secApprover =
        this.secOnliners?.find(o => o.userId === this.rwConfig?.ccEmail) ||
        null;
      this.secApproverControl.setValue(this.secApprover);
    } else {
      this.secApprover =
        this.secOnliners?.find(o => o.firstName === ' ') || null;
      this.secApproverControl.setValue(this.secApprover);
    }

    this.changesMade = false;
  }

  public validSave() {
    if (this.changesMade) {
      this.snackBarService.message(
        'There are unsaved changes, press the save button or cancel your changes to continue.'
      );
    }
  }

  approverSelected(e: MatAutocompleteSelectedEvent) {
    const value = e.option.value;
    if ((e.source && value) || value === '') {
      this.changesMade = true;
    }
  }

  approverTwoSelected(e: MatAutocompleteSelectedEvent) {
    const value = e.option.value;

    if (
      (e.source && value) ||
      value === null ||
      value === undefined ||
      value === ''
    ) {
      this.changesMade = true;
    }
  }

  grantAccess() {
    const approver = this.approverControl.value as Employee;
    const secApprover = this.secApproverControl.value as Employee;

    if (this.rwConfig && approver) {
      this.isSubmitting = true;
      this.rwConfig.primaryEmail = approver.userId;

      if (secApprover.userId) {
        this.rwConfig.ccEmail = secApprover.userId;
      } else {
        this.rwConfig.ccEmail = null;
      }

      this.remoteWorkConfigService
        .updateRemoteWorkConfig(this.rwConfig)
        .subscribe(
          () => {
            this.approverControl.reset();
            this.secApproverControl.reset();
            this.snackBarService.message(
              'Relocation configuration assigned successfully!'
            );
            this.isSubmitting = false;
            this.resetRWConfigForm();
          },
          error => {
            this.isSubmitting = false;
            this.snackBarService.error(error);
          }
        );
    }
  }

  onlinerDisplay = (option?: Employee | CareerMentor): string =>
    this.autoSuggestService.onlinerDisplay(option);

  resetRWConfigForm() {
    this.rwConfig = null;
    this.rwConfigs = null;

    this.approver = null;
    this.secApprover = null;

    this.onliners = null;
    this.secOnliners = null;

    this.selected = null;
    this.rwConfigForm.reset();

    this.setupPage();
  }

  private setupPage() {
    this.isLoading = true;
    forkJoin(
      this.remoteWorkConfigService.getRemoteWorkConfigs(),
      this.onlinerService.getOnliners()
    ).subscribe(
      data => {
        this.rwConfigs = data[0];
        this.onliners = data[1];
        this.secOnliners = JSON.parse(JSON.stringify(data[1]));
        const newRow = new Employee();
        newRow.firstName = ' ';
        newRow.lastName = '';
        this.secOnliners?.unshift(newRow);
        this.isLoading = false;
        this.changesMade = false;
        this.rwConfigSelected = false;
        this.isSubmitting = false;
        this.setupOnlinerDropdownDisabling();
      },
      error => {
        this.isLoading = false;
        this.snackBarService.error(error);
      }
    );
  }

  private setupOnlinerDropdownDisabling() {
    this.approverControl.disable();
    this.secApproverControl.disable();

    if (this.rwConfigSelected) {
      this.approverControl.reset();
      this.approverControl.enable();
      this.secApproverControl.reset();
      this.secApproverControl.enable();
    }
  }

  private createForm() {
    return this.formBuilder.group({
      approver: ['', formValidator.validateOnliner()],
      secApprover: ['', formValidator.validateSecApprover()],
      grantAccessButton: [''],
    });
  }
}
