import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { distinct, filter } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';

@Directive({
  selector: '[ifRole]',
})
export class IfRoleDirective implements OnInit {
  @Input('ifRole') roleName!: string | string[];

  constructor(
    private cd: ChangeDetectorRef,
    private templateRef: TemplateRef<Element>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  ngOnInit() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        distinct()
      )
      .subscribe({
        next: () => this.createView(),
      });
  }

  createView(): void {
    const roles =
      typeof this.roleName === 'string'
        ? this.roleName.split(',')
        : this.roleName;
    if (this.authService.doesUserHaveRole(roles)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
    this.cd.markForCheck();
  }
}
