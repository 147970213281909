<vex-secondary-toolbar current="Request">
  <vex-breadcrumbs [crumbs]="['Request', 'Onliner']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div class="row m-0 component-container">
  <div class="mb-3 col-md-12 col-sm-12 col-xs-12 m-0 p-0">
    <h3 class="page-title">Request Onliner Feedback</h3>

    <div *ngIf="isCM || isDelegate">
      <mat-slide-toggle [checked]="isCMRequest"
        (change)="isCMRequest = !isCMRequest">
        CM Feedback
      </mat-slide-toggle>
    </div>

  </div>
  <div class="row m-0 col-md-8 col-sm-12 col-xs-12">
    <div class="col-md-12 col-sm-12 col-xs-12 m-0 p-0">
      <app-request-onliner-feedback-form [requestFeedbackForm]="requestFeedbackForm" [isCMRequest]="isCMRequest"></app-request-onliner-feedback-form>
    </div>
  </div>
</div>
