import { NavigationItem } from 'src/@vex/interfaces/navigation-item.interface';
import { roles } from './roles.model';

export const sideMenu: NavigationItem[] = [
  {
    type: 'link',
    label: 'Home',
    route: '/home',
    icon: 'mat:home',
    roles: [roles.user],
  },
  {
    type: 'dropdown',
    label: 'Provide',
    children: [
      {
        type: 'link',
        label: 'Feedback',
        route: '/feedback',
        icon: 'mat:chat_bubble',
        roles: [roles.user],
      },
      {
        type: 'link',
        label: 'CM Feedback',
        route: '/cmFeedback',
        icon: 'mat:forum',
        roles: [roles.user],
      },
      {
        type: 'link',
        label: 'Confidential Notes',
        route: '/confidentialNotes',
        icon: 'mat:lock_person',
        roles: [roles.lead],
      },
    ],
    icon: 'mat:edit',
  },
  {
    type: 'dropdown',
    label: 'My Surveys',
    children: [
      {
        type: 'link',
        label: 'Weekly PulseCheck',
        route: '/weeklyDelivery',
        icon: 'mat:monitor_heart',
        roles: [roles.user],
      },
      {
        type: 'link',
        label: 'Self Assessment',
        route: '/selfAssessment',
        icon: 'mat:sms',
        roles: [roles.user],
      },
    ],
    icon: 'mat:assignment_ind',
  },
  {
    type: 'link',
    label: 'Drafts',
    route: '/savedFeedback',
    icon: 'mat:description',
    roles: [roles.user],
  },
  {
    type: 'dropdown',
    label: 'Request',
    children: [
      {
        type: 'link',
        label: 'Onliner',
        route: '/requestOnlinerFeedback',
        icon: 'mat:group',
        roles: [roles.user],
      },
      {
        type: 'link',
        label: 'Client',
        route: '/requestClientFeedback',
        icon: 'mat:domain',
        roles: [roles.user],
      },
      // {
      //   type: 'link',
      //   label: 'Relocation',
      //   route: '/requestRemoteWorkWorkflow',
      //   icon: 'mat:edit_location',
      //   countries: ['US'],
      //   roles: [roles.admin, roles.user],
      // },
      // {
      //   type: 'link',
      //   label: 'Relocation Requests',
      //   route: '/remoteWorkRequests/false',
      //   icon: 'mat:route',
      //   countries: ['US'],
      //   roles: [roles.admin, roles.user],
      // },
    ],
    icon: 'mat:person_add',
  },
  {
    type: 'dropdown',
    label: 'My Feedback',
    children: [
      {
        type: 'link',
        label: 'Received',
        route: '/feedbackReceived',
        icon: 'mat:call_received',
        roles: [roles.user],
      },
      {
        type: 'link',
        label: 'Provided',
        route: '/feedbackProvided',
        icon: 'mat:call_made',
        roles: [roles.user],
      },
      {
        type: 'link',
        label: 'Feedback Requests',
        route: '/feedbackRequests',
        icon: 'mat:schedule',
        roles: [roles.user],
      },
      {
        type: 'link',
        label: 'Weekly Reports',
        route: '/weeklyDeliveryHistory',
        icon: 'mat:monitor_heart',
        roles: [roles.user],
      },
      {
        type: 'link',
        label: 'Self Assessments',
        route: '/viewYearlyAssessmentReport',
        icon: 'mat:sms',
        roles: [roles.user],
      },
    ],
    icon: 'mat:chat_bubble',
  },
  {
    type: 'dropdown',
    label: 'My CM Feedback',
    children: [
      {
        type: 'link',
        label: 'Received',
        route: '/receivedCMFeedback',
        icon: 'mat:call_received',
        roles: [roles.cm],
      },
      {
        type: 'link',
        label: 'Provided',
        route: '/providedCMFeedback',
        icon: 'mat:call_made',
        roles: [roles.user],
      },
    ],
    icon: 'mat:forum',
  },
  {
    type: 'dropdown',
    label: 'Review',
    roles: [
      roles.cm,
      roles.admin,
      roles.peoplecare,
      roles.marketing,
      roles.lead,
      roles.reviewer,
    ],
    groups: ['reviewer'],
    children: [
      {
        type: 'link',
        label: 'My Team Feedback',
        route: '/teamFeedback',
        icon: 'mat:groups',
        roles: [roles.cm, roles.lead, roles.admin],
      },
      {
        type: 'link',
        label: 'My Team Surveys',
        route: '/teamSurvey',
        icon: 'mat:assignment',
        roles: [roles.reviewer, roles.admin],
      },
      {
        type: 'link',
        label: 'Confidential Notes List',
        route: '/reviewConfidentialNotes',
        icon: 'mat:lock_person',
        roles: [roles.lead],
      },
      {
        type: 'link',
        label: 'CM Feedback',
        route: '/reviewableCMFeedback',
        icon: 'mat:forum',
        roles: [roles.peoplecare],
      },
      {
        type: 'link',
        label: 'CM Dashboard',
        route: '/cmdashboard',
        icon: 'mat:analytics',
        roles: [roles.peoplecare],
      },
      // {
      //   type: "link",
      //   label: "Relocation Requests",
      //   route: "/remoteWorkRequests/true",
      //   icon: "mat:route",
      //   roles: [roles.peoplecare, roles.ceo]
      // },
      {
        type: 'link',
        label: 'Client Feedback - Online',
        route: '/clientFeedbackOnline',
        icon: 'mat:domain',
        roles: [roles.marketing],
      },
    ],
    icon: 'mat:search',
  },
];
