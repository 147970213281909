import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { QuestionTypeEnum } from 'src/app/shared/enums/enums.model';
import { ApiErrorData } from 'src/app/shared/interfaces/api-error.interface';
import { MatchType } from 'src/app/shared/shared.module';
import { environment } from 'src/environments/environment';
import { AuthorizedHttp } from './authorized-http.service';
import { Client, CommonService } from './common.service';
import { Question, QuestionOption } from './feedback.service';
import { Employee } from './onliner.service';

@Injectable({
  providedIn: 'root',
})
export class AssessmentService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/assessment`;

  private myFeedbackRequests!: MyFeedbackRequests[] | null;
  private myReceivedAssessments!: SavedAssessment[] | null;
  private myProvidedFeedbacks!: SavedAssessment[] | null;
  private myProvidedCmFeedbacks!: SavedAssessment[] | null;
  private clientFeedbackOnOnline!: ClientFeedbackOnOnline[] | null;

  constructor(
    private http: AuthorizedHttp,
    private commonService: CommonService
  ) {}

  public getAssessment(assessmentId: number): Observable<FbAssessment> {
    return this.http
      .get<FbAssessment>(`${this._apiEndpoint}/${assessmentId}`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getAssessments(): Observable<FbAssessment[]> {
    return this.http
      .get<FbAssessment[]>(`${this._apiEndpoint}`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getMyReceivedAssessments(): Observable<SavedAssessment[]> {
    return this.myReceivedAssessments
      ? of(this.myReceivedAssessments)
      : this.http
          .get<SavedAssessment[]>(`${this._apiEndpoint}/myReceivedFeedbacks`)
          .pipe(
            map(
              this.mapSavedAssessments(
                results => (this.myReceivedAssessments = results)
              )
            ),
            catchError((errorResponse: ApiErrorData) =>
              throwError(
                errorResponse?.error?.exceptionMessage || 'Server error'
              )
            )
          );
  }

  public getMyProvidedFeedbacks(): Observable<SavedAssessment[]> {
    return this.myProvidedFeedbacks
      ? of(this.myProvidedFeedbacks)
      : this.http
          .get<SavedAssessment[]>(`${this._apiEndpoint}/myProvidedFeedbacks`)
          .pipe(
            map(
              this.mapSavedAssessments(
                results => (this.myProvidedFeedbacks = results)
              )
            ),
            catchError((errorResponse: ApiErrorData) =>
              throwError(
                errorResponse?.error?.exceptionMessage || 'Server error'
              )
            )
          );
  }

  public getMyCmProvidedFeedbacks(): Observable<SavedAssessment[]> {
    return this.myProvidedCmFeedbacks
      ? of(this.myProvidedCmFeedbacks)
      : this.http
          .get<SavedAssessment[]>(`${this._apiEndpoint}/myCmProvidedFeedbacks`)
          .pipe(
            map(
              this.mapSavedAssessments(
                results => (this.myProvidedCmFeedbacks = results)
              )
            ),
            catchError((errorResponse: ApiErrorData) =>
              throwError(
                errorResponse?.error?.exceptionMessage || 'Server error'
              )
            )
          );
  }

  public getClientFeedbackOnOnline(): Observable<ClientFeedbackOnOnline[]> {
    return this.clientFeedbackOnOnline
      ? of(this.clientFeedbackOnOnline)
      : this.http
          .get<ClientFeedbackOnOnline[]>(
            `${this._apiEndpoint}/clientFeedbacksOnOnline`
          )
          .pipe(
            map(results => (this.clientFeedbackOnOnline = results)),
            catchError((errorResponse: ApiErrorData) =>
              throwError(
                errorResponse?.error?.exceptionMessage || 'Server error'
              )
            )
          );
  }

  public getFeedbackDetail(feedbackId: number): Observable<SavedAssessment> {
    return this.http
      .get<SavedAssessment>(`${this._apiEndpoint}/feedbackDetail/${feedbackId}`)
      .pipe(
        map(data => {
          const result = MatchType.MatchData(data, SavedAssessment);
          result.log = MatchType.MatchDataArray(data.log, FbAssessmentAudit);
          result.answers = MatchType.MatchDataArray(data.answers, FbAnswer);
          return result;
        }),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getSavedFeedback(feedbackId: number): Observable<SavedAssessment> {
    return this.http
      .get<SavedAssessment>(`${this._apiEndpoint}/${feedbackId}`)
      .pipe(
        map(data => {
          const result = MatchType.MatchData(data, SavedAssessment);
          result.log = MatchType.MatchDataArray(data.log, FbAssessmentAudit);
          result.answers = MatchType.MatchDataArray(data.answers, FbAnswer);
          return result;
        }),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public createNewAnswers(
    assessmentId: number,
    answers: FbAnswer[]
  ): Observable<boolean> {
    return this.http
      .post<boolean>(
        `${this._apiEndpoint}/createNewAnswers?assessmentId=${assessmentId}`,
        answers
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getPublicAssessment(id: Guid): Observable<FbAssessmentPublic> {
    return this.http
      .get<FbAssessmentPublic>(`${this._apiEndpoint}/external/${id}`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  // public postPublicAnswers(id: Guid, answers: FbAnswer[]): Observable<any> {
  //     return this.http
  //         .post(`${this._apiEndpoint}/postPublicAnswers?id=${id}`, answers)
  //         .pipe(
  //             catchError((errorResponse: ApiErrorData) => throwError(errorResponse?.error?.exceptionMessage || 'Server error'))
  //         );
  // }

  public deleteFeedback(feedbackId: number): Observable<number> {
    return this.http
      .delete<number>(`${this._apiEndpoint}/deleteFeedback/${feedbackId}`)
      .pipe(
        map(feedback => {
          this.resetRetrievedDbFeedbackRequests();
          return feedback;
        }),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public deleteConfidentialFeedback(feedbackId: number): Observable<number> {
    return this.http
      .delete<number>(
        `${this._apiEndpoint}/deleteConfidentialFeedback/${feedbackId}`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public saveFeedback(feedback: FbAssessment): Observable<FbAssessment> {
    return this.http
      .post<FbAssessment>(`${this._apiEndpoint}/saveFeedback`, feedback)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public submitFeedback(feedback: FbAssessment): Observable<SavedAssessment> {
    return this.http
      .post<SavedAssessment>(`${this._apiEndpoint}/submitFeedback`, feedback)
      .pipe(
        map(f => {
          this.resetRetrievedDbFeedback();
          return f;
        }),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public saveConfidentialFeedback(
    feedback: FbConfidential
  ): Observable<FbConfidential> {
    return this.http
      .post<FbConfidential>(
        `${this._apiEndpoint}/saveConfidentialFeedback`,
        feedback
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public submitConfidentialFeedback(
    feedback: FbConfidential
  ): Observable<FbConfidential> {
    return this.http
      .post<FbConfidential>(
        `${this._apiEndpoint}/submitConfidentialFeedback`,
        feedback
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public requestOnlinerFeedback(
    onlinerFeedbackRequests: OnlinerFeedbackRequest[]
  ): Observable<OnlinerFeedbackRequest[]> {
    return this.http
      .post<OnlinerFeedbackRequest[]>(
        `${this._apiEndpoint}/requestOnlinerFeedback`,
        onlinerFeedbackRequests
      )
      .pipe(
        map(feedbacks => {
          this.resetRetrievedDbFeedbackRequests();
          return feedbacks;
        }),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public requestClientFeedback(
    clientFeedbackRequest: ClientFeedbackRequest[]
  ): Observable<number> {
    return this.http
      .post<number>(
        `${this._apiEndpoint}/requestClientFeedback`,
        clientFeedbackRequest
      )
      .pipe(
        map(feedback => {
          this.resetRetrievedDbFeedbackRequests();
          return feedback;
        }),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getMyFeedbackRequests(): Observable<MyFeedbackRequests[]> {
    return this.myFeedbackRequests
      ? of(this.myFeedbackRequests)
      : this.http
          .get<MyFeedbackRequests[]>(`${this._apiEndpoint}/myFeedbackRequests`)
          .pipe(
            map(result => {
              result.forEach((x: MyFeedbackRequests) =>
                MyFeedbackRequests.RemoveTimePartFromDate(x)
              );
              this.myFeedbackRequests = result;
              return result;
            }),
            catchError((errorResponse: ApiErrorData) =>
              throwError(
                errorResponse?.error?.exceptionMessage || 'Server error'
              )
            )
          );
  }

  private mapSavedAssessments(
    assignResults: (assessments: SavedAssessment[]) => void
  ) {
    return (data: SavedAssessment[]) => {
      const results: SavedAssessment[] = MatchType.MatchDataArray(
        data,
        SavedAssessment
      );
      results.forEach((assessment: SavedAssessment) => {
        assessment.RemoveTimePartFromDate();
      });
      assignResults(results);
      return results;
    };
  }

  private resetRetrievedDbFeedback = () => {
    this.myFeedbackRequests = null;
    this.myReceivedAssessments = null;
    this.myProvidedFeedbacks = null;
    this.myProvidedCmFeedbacks = null;
  };

  private resetRetrievedDbFeedbackRequests = () => {
    this.myFeedbackRequests = null;
  };
}

export class OnlinerFeedbackRequest {
  requestId!: number;
  requestTo!: string;
  requestFrom!: string;
  requestContext!: string;
  requestDate!: Date;
  requestDelegate!: string;
  clientId!: number;
  otherClient!: string;
  feedbackTypeId!: number;
}

export class ClientFeedbackRequest {
  requestContext!: string;
  requestDate!: Date;
  clientId!: number;
  otherClient!: string;
  contactName!: string;
}

export class MyFeedbackRequests {
  feedbackId!: number;
  requestTo!: string;
  requestDate!: Date;
  submittedDate!: Date | null;
  daysToSubmission!: number;
  requestType!: string;
  status!: string;
  statusId!: number;

  // to have a good sorting in UI
  public static RemoveTimePartFromDate(feedback: MyFeedbackRequests): void {
    let date = new Date(feedback.requestDate);
    feedback.requestDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );

    if (feedback.submittedDate) {
      date = new Date(feedback.submittedDate);
      feedback.submittedDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
    }
  }
}

export class FbAssessment {
  id!: number;
  answers!: FbAnswer[];
  employee!: Employee;
  feedbackTypeId!: number;
  isAnonymous!: boolean;
  client!: Client;
  otherClient!: string;
  submittedByEmployee!: Employee;
  submittedBy!: string;
  submittedDate!: Date;
  requestedBy!: Employee;
  requestedDate!: Date;
  externalId?: string | null;
  log!: FbAssessmentAudit[];

  RemoveTimePartFromDate(): void {
    const date = new Date(this.submittedDate);
    this.submittedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }
}

export class FbAnswer {
  id!: number;
  questionId!: number;
  question!: Question;
  intValue?: number | null;
  stringValue!: string | null;
  optionId?: number | null;
  option?: QuestionOption | null;
}

export class FbAssessmentAudit {
  id!: number;
  statusId!: number;
  description!: string;
  changedByEmployee!: Employee;
  changedDate!: Date;

  RemoveTimePartFromDate(): void {
    const date = new Date(this.changedDate);
    this.changedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }
}

export class FbQuestion {
  id!: number;
  question!: string;
  isOptional!: boolean;
  isActive!: boolean;
  sortOrder!: number;
  type!: QuestionTypeEnum;
  groupId!: number;
  questionGroupSortOrder!: number;
  questionGroupType!: number;
  optionGroupId!: number;
  optionGroup!: FbQuestionOptionGroup;
}

export class FbQuestionOption {
  id!: number;
  isActive!: boolean;
  value!: string;
  groupId!: number;
  sortOrder!: number;
  score!: number;
  groupLabel!: number;
}

export class FbQuestionOptionGroup {
  id!: number;
  label!: string;
  options!: FbQuestionOption[];
}

export class SavedAssessment {
  id!: number;
  answers!: FbAnswer[];
  employeeId!: string;
  employeeName!: string;
  careerMentorId!: string;
  careerMentorName!: string;
  feedbackTypeId!: number;
  feedbackType!: string;
  submissionTypeId!: number;
  submissionType!: string;
  isAnonymous!: boolean;
  client!: Client;
  clientName!: string;
  otherClientId!: string;
  submittedById!: string;
  submittedByName!: string;
  submittedDate!: Date;
  currentStatus!: string;
  currentStatusId!: number;

  requestedById!: string;
  requestedByName!: string;
  requestedDate!: Date;
  externalId!: Guid;
  log!: FbAssessmentAudit[];

  RemoveTimePartFromDate(): void {
    let date: Date;

    if (this.submittedDate) {
      date = new Date(this.submittedDate);
      this.submittedDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
    }

    if (this.requestedDate) {
      date = new Date(this.requestedDate);
      this.requestedDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
    }
  }

  GetCurrentStatusId(): number {
    this.log.sort((a, b) =>
      a.changedDate < b.changedDate ? 1 : b.changedDate < a.changedDate ? -1 : 0
    );
    return this.log[0].statusId;
  }
}

export class FbAssessmentPublic {
  onliner!: string;
}

export class ClientFeedbackOnOnline {
  clientName!: string;
  clientId!: number;
  submittedDate!: Date;
  answers!: FbAnswer[];
}

export class FbConfidential {
  id!: number;
  employeeId!: string;
  submittedBy!: string;
  submittedDate!: Date;
  answers!: FbConfidentialAnswer[];
}

export class SavedFbConfidential {
  id!: number;
  answers!: FbConfidentialAnswer[];
  employeeId!: string;
  employeeName!: string;
  submittedById!: string;
  submittedByName!: string;
  submittedDate!: Date;
  practice!: string;
}

export class FbConfidentialAnswer {
  confidentialFeedbackAnswerId!: number;
  confidentialFeedbackId!: number;
  questionId!: number;
  question!: Question;
  intValue?: number | null;
  stringValue!: string;
  optionId?: number | null;
  option?: FbQuestionOption | null;
}
