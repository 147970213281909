import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  AssessmentService,
  SavedAssessment,
} from 'src/app/core/services/assessment.service';
import { BaseFeedbackFilterService } from 'src/app/core/services/base-feedback-filter.service';
import { CmFeedbackFilterService } from 'src/app/core/services/cm-feedback-filter.service';
import { CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-cm-feedback-provided',
  templateUrl: './cm-feedback-provided.component.html',
  styleUrls: ['./cm-feedback-provided.component.scss'],
  providers: [
    {
      provide: BaseFeedbackFilterService,
      useExisting: CmFeedbackFilterService,
    },
  ],
})
export class CmFeedbackProvidedComponent implements OnInit {
  cmFeedbacks!: SavedAssessment[];
  filteredCmFeedbacks!: SavedAssessment[];
  filterCriteriaForm!: UntypedFormGroup;
  filterDateDisplay!: string;
  filterCMValue!: string;
  filterFeedbackStatusValue!: string;
  filterAnonymousValue!: string;
  filtersValue!: string;
  isLoading = true;

  constructor(
    private assessmentService: AssessmentService,
    private snackBarService: SnackBarService,
    private feedbackFilterService: CmFeedbackFilterService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.filterCriteriaForm =
      this.feedbackFilterService.getFilterCriteriaForm();
    this.getMyProvidedCmFeedbacks();
  }

  filterCmFeedbacks() {
    const searchCriteria = this.feedbackFilterService.getFilterCriteriaModel();
    this.filterDateDisplay =
      this.feedbackFilterService.getFilterDateRangeDisplay(searchCriteria);
    this.filteredCmFeedbacks = this.cmFeedbacks.filter(
      f =>
        this.feedbackFilterService.filterCareerMentor(f, searchCriteria) &&
        this.feedbackFilterService.filterStartDate(f, searchCriteria) &&
        this.feedbackFilterService.filterEndDate(f, searchCriteria) &&
        this.feedbackFilterService.filterFeedbackStatus(f, searchCriteria) &&
        this.feedbackFilterService.filterAnonymous(f, searchCriteria)
    );
    this.getFilterFormValues();
  }

  getFilterFormValues() {
    this.filterCMValue = `${this.filterCriteriaForm.controls.careerMentor.value['firstName']} ${this.filterCriteriaForm.controls.careerMentor.value['lastName']}`;
    this.filterCMValue = this.filterCMValue.trim();
    this.filterFeedbackStatusValue =
      this.filterCriteriaForm.controls.status.value['statusDescription'];
    this.filterAnonymousValue =
      this.filterCriteriaForm.controls.anonymous.value['anonymousDesc'];

    this.filtersValue = `${this.filterFeedbackStatusValue}, ${this.filterCMValue}, ${this.filterAnonymousValue}`;
  }

  setCurrentStatuses() {
    this.commonService.getFeedbackStatuses().subscribe(statuses => {
      this.cmFeedbacks.forEach(f => {
        f.currentStatus =
          statuses.find(s => s.statusId === f.currentStatusId)
            ?.statusDescription || '';
      });
    });
  }

  private getMyProvidedCmFeedbacks() {
    this.assessmentService.getMyCmProvidedFeedbacks().subscribe(
      data => {
        this.cmFeedbacks = data;
        this.filterCmFeedbacks();
        this.setCurrentStatuses();
        this.isLoading = false;
      },
      error => {
        this.snackBarService.error(error);
      }
    );
  }
}
