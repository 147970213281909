import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AssessmentService,
  FbAnswer,
  SavedAssessment,
} from 'src/app/core/services/assessment.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { FeedbackTypeEnum } from 'src/app/shared/enums/enums.model';

@Component({
  selector: 'app-view-feedback',
  templateUrl: './view-feedback.component.html',
  styleUrls: ['./view-feedback.component.scss'],
})
export class ViewFeedbackComponent implements OnInit {
  feedbackId!: number;
  feedbackTypeId!: number;

  feedback!: SavedAssessment;
  operationInProgress: string | null = null;
  showActionPanel = true;
  // isLegacyFeedback: boolean;
  isClientFeedback!: boolean;
  isLoading = true;

  constructor(
    private assessmentService: AssessmentService,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.feedbackId = +params['id'];
      this.feedbackTypeId = +params['feedbackTypeId'];

      this.isClientFeedback =
        this.feedbackTypeId === FeedbackTypeEnum.ClientFeedback ? true : false;

      this.operationInProgress = 'loading';
      // this.isLegacyFeedback = this.feedbackId !== null &&
      //                      this.feedbackTypeId !== null &&
      //                      this.feedbackTypeId !== 0 &&
      //                      this.feedbackTypeId !== FeedbackTypeEnum.AdhocFeedback &&
      //                      this.feedbackTypeId !== FeedbackTypeEnum.ClientFeedback &&
      //                      this.feedbackTypeId !== FeedbackTypeEnum.CultureInAction;
      // this.isLegacyFeedback ? this.getLegacyFeedbackDetail(this.feedbackId) : this.getFeedbackDetail(this.feedbackId);
      this.getFeedbackDetail(this.feedbackId);
    });
  }

  private getFeedbackDetail(feedbackId: number) {
    this.assessmentService.getFeedbackDetail(feedbackId).subscribe(
      data => {
        this.feedback = data;
        if (this.isClientFeedback) {
          this.feedback.answers = data.answers.filter(
            a => a.question.groupId !== 3
          );
          const contactName: FbAnswer | undefined = data.answers.find(
            a => a.questionId === 45
          );

          if (contactName !== null && contactName !== undefined) {
            this.feedback.submittedByName = contactName.stringValue || '';
            const ix = this.feedback.answers.findIndex(
              a => a.questionId === 45
            );
            this.feedback.answers.splice(ix, 1);
          }
        }
        this.operationInProgress = null;
        this.isLoading = false;
      },
      error => {
        this.snackBarService.error(error);
        this.operationInProgress = null;
      }
    );
  }

  // may not be using legacy feedbacks anymore
  // private getLegacyFeedbackDetail(feedbackId) {
  // this.feedbackService.getLegacyFeedbackDetail(feedbackId)
  //  .subscribe(data => {
  //    this.feedback = data;
  //    this.operationInProgress = null;
  //  }, error => {
  //    this.snackBarService.error(error);
  //    this.operationInProgress = null;
  //  });
  // }
}
