<section class="text-base">
<div class="p-3 flex justify-between bg-obs-primary">
  <span class="inline text-white dialog-title">{{data.title}}</span>
  <button (click)="close()" type="button" class="hover:font-bold focus:no-underline leading-none text-white" aria-label="Close">
    <mat-icon aria-hidden="true" svgIcon="mat:close"></mat-icon>
  </button>
</div>

<div class="dialog-body py-12 px-8">
  <form *ngIf="dialogForm" [formGroup]="dialogForm" class="mb-4">
    <div class="">
      <span>{{data.message}}</span>
    </div>
    <div *ngIf="data.hint">
      <span class="required-label">{{data.hint}}</span>
    </div>
    <div>
      <textarea type="text" class="mt-2 form-control field-input box-border h-24" id="reasons" formControlName="reasons">
      </textarea>
    </div>
  </form>
  <div class="flex justify-center">
    <button *ngIf="data.cancelButtonTitle" type="button" class="
    py-1 px-3 bg-obs-gray-20 border-obs-gray-20 text-black rounded
      mr-1" (click)="close()">{{data.cancelButtonTitle}}</button>
    <button *ngIf="data.okButtonTitle" type="button" [disabled]="!dialogForm.valid && !isEmptyInputAllowed" class="
    py-1 px-3 text-white rounded ml-1"
    [ngClass]="!dialogForm.valid && !isEmptyInputAllowed ? 'bg-obs-success/50' : 'bg-obs-success'" (click)="submit()">
      {{data.okButtonTitle}}
    </button>
  </div>
</div>
</section>
