import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FeedbackStatusEnum } from 'src/app/shared/enums/enums.model';
import * as formValidator from '../../shared/validators/form-validator';
import {
  ClientFeedbackOnOnline,
  MyFeedbackRequests,
  SavedAssessment,
} from './assessment.service';
import { BaseFeedbackFilterService } from './base-feedback-filter.service';
import { CareerMentor } from './career-mentor.service';
import {
  defaultClient,
  defaultDraftFeedbackStatus,
  defaultFeedbackStatus,
  defaultFeedbackType,
  defaultSubmissionType,
} from './feedback.service';
import { defaultOnliner } from './onliner.service';

@Injectable()
export class FeedbackFilterService implements BaseFeedbackFilterService {
  _filterCriteriaForm!: UntypedFormGroup;
  _requestedCriteriaForm!: UntypedFormGroup;
  _clientFeedbackOnOnlineCriteriaForm!: UntypedFormGroup;
  _selfAssessmentCriteriaForm!: UntypedFormGroup;

  firstDayOfTheYear = new Date(new Date().getFullYear(), 0, 1);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe
  ) {}

  resetForm(careerMentor?: CareerMentor, isOnMyTeamFeedbackPage?: boolean) {
    this._filterCriteriaForm.reset({
      onliner: defaultOnliner,
      submissionType: defaultSubmissionType,
      careerMentor:
        isOnMyTeamFeedbackPage && careerMentor ? careerMentor : defaultOnliner,
      submittedBy: defaultOnliner,
      client: defaultClient,
      otherClient: '',
      startDate: this.firstDayOfTheYear,
      endDate: new Date(),
      feedbackType: defaultFeedbackType,
      status: defaultFeedbackStatus,
    });
  }

  resetRequestForm() {
    this._requestedCriteriaForm.reset({
      status: defaultDraftFeedbackStatus,
      startDate: this.firstDayOfTheYear,
      endDate: new Date(),
    });
  }

  resetSelfAssessmentForm(careerMentor: CareerMentor) {
    this._selfAssessmentCriteriaForm.reset({
      submittedBy: defaultOnliner,
      careerMentor: careerMentor ? careerMentor : defaultOnliner,
      startDate: this.firstDayOfTheYear,
      endDate: new Date(),
    });
  }

  resetClientFeedbackOnlineForm() {
    this._clientFeedbackOnOnlineCriteriaForm.reset({
      client: defaultClient,
      startDate: this.firstDayOfTheYear,
      endDate: new Date(),
    });
  }

  getFilterCriteriaForm(
    careerMentor?: CareerMentor,
    isOnMyTeamFeedbackPage?: boolean
  ): UntypedFormGroup {
    if (this._filterCriteriaForm) {
      if (isOnMyTeamFeedbackPage && careerMentor) {
        this._filterCriteriaForm.controls['careerMentor'].setValue(
          careerMentor
        );
      }
      if (!isOnMyTeamFeedbackPage) {
        if (
          this._filterCriteriaForm.controls['careerMentor'].value.userId !==
          defaultOnliner.userId
        ) {
          this._filterCriteriaForm.markAsDirty();
        }
      }
      return this._filterCriteriaForm;
    } else {
      return this.createFilterCriteriaForm(careerMentor);
    }
  }

  getRequestedFilterCriteriaForm(): UntypedFormGroup {
    return this._requestedCriteriaForm
      ? this._requestedCriteriaForm
      : this.createRequestedFilterCriteriaForm();
  }

  getClientFeedbackOnOnlineFilterCriteriaForm(): UntypedFormGroup {
    return this._clientFeedbackOnOnlineCriteriaForm
      ? this._clientFeedbackOnOnlineCriteriaForm
      : this.createClientFeedbackOnOnlineFilterCriteriaForm();
  }

  getSelfAssessmentFilterCriteriaForm(
    careerMentor?: CareerMentor
  ): UntypedFormGroup {
    if (careerMentor) {
      this._selfAssessmentCriteriaForm.controls['careerMentor'].setValue(
        careerMentor
      );
    }
    return this._selfAssessmentCriteriaForm
      ? this._selfAssessmentCriteriaForm
      : this.createSelfAssessmentFilterCriteriaForm(careerMentor);
  }

  getFilterCriteriaModel(): FeedbackFilterCriteria {
    const filterCriteria = new FeedbackFilterCriteria();
    filterCriteria.clientId = this._filterCriteriaForm.controls['client'].value
      ? this._filterCriteriaForm.controls['client'].value.clientId
      : defaultClient.clientId;
    filterCriteria.employeeId = this._filterCriteriaForm.controls['onliner']
      .value
      ? this._filterCriteriaForm.controls['onliner'].value.userId
      : defaultOnliner.userId;
    filterCriteria.submissionTypeId = this._filterCriteriaForm.controls[
      'submissionType'
    ].value
      ? this._filterCriteriaForm.controls['submissionType'].value.id
      : defaultSubmissionType.id;
    filterCriteria.careerMentorId = this._filterCriteriaForm.controls[
      'careerMentor'
    ].value
      ? this._filterCriteriaForm.controls['careerMentor'].value.userId
      : defaultOnliner.userId;
    filterCriteria.submitterId = this._filterCriteriaForm.controls[
      'submittedBy'
    ].value
      ? this._filterCriteriaForm.controls['submittedBy'].value.userId
      : defaultOnliner.userId;
    filterCriteria.startDate = this._filterCriteriaForm.controls['startDate']
      .value
      ? this._filterCriteriaForm.controls['startDate'].value
      : this.firstDayOfTheYear;
    filterCriteria.endDate = this._filterCriteriaForm.controls['endDate'].value
      ? this._filterCriteriaForm.controls['endDate'].value
      : new Date();
    filterCriteria.feedbackTypeId = this._filterCriteriaForm.controls[
      'feedbackType'
    ].value
      ? this._filterCriteriaForm.controls['feedbackType'].value.feedbackTypeId
      : 0;
    filterCriteria.status = this._filterCriteriaForm.controls['status'].value
      ? this._filterCriteriaForm.controls['status'].value.statusDescription
      : defaultFeedbackStatus.statusDescription;
    // Set the endDate to the end of that day, otherwise the filter service will filter out any feedback
    // created after this service was created or created past this time on that date selected.
    filterCriteria.endDate.setHours(23);
    filterCriteria.endDate.setMinutes(59);
    filterCriteria.endDate.setSeconds(59);

    return filterCriteria;
  }

  getRequestedFilterCriteriaModel(): FeedbackFilterCriteria {
    const requestedFilterCriteria = new FeedbackFilterCriteria();
    requestedFilterCriteria.status = this._requestedCriteriaForm.controls[
      'status'
    ].value
      ? this._requestedCriteriaForm.controls['status'].value.statusDescription
      : defaultFeedbackStatus.statusDescription;
    requestedFilterCriteria.startDate = this._requestedCriteriaForm.controls[
      'startDate'
    ].value
      ? this._requestedCriteriaForm.controls['startDate'].value
      : this.firstDayOfTheYear;
    requestedFilterCriteria.endDate = this._requestedCriteriaForm.controls[
      'endDate'
    ].value
      ? this._requestedCriteriaForm.controls['endDate'].value
      : new Date();

    return requestedFilterCriteria;
  }

  getSAFilterCriteriaModel(): FeedbackFilterCriteria {
    const feedbackFilterCriteria = new FeedbackFilterCriteria();
    feedbackFilterCriteria.submitterId = this._selfAssessmentCriteriaForm
      .controls['submittedBy'].value
      ? this._selfAssessmentCriteriaForm.controls['submittedBy'].value.userId
      : defaultOnliner.userId;
    feedbackFilterCriteria.careerMentorId = this._selfAssessmentCriteriaForm
      .controls['careerMentor'].value
      ? this._selfAssessmentCriteriaForm.controls['careerMentor'].value.userId
      : defaultOnliner.userId;
    feedbackFilterCriteria.startDate = this._selfAssessmentCriteriaForm
      .controls['startDate'].value
      ? this._selfAssessmentCriteriaForm.controls['startDate'].value
      : this.firstDayOfTheYear;
    feedbackFilterCriteria.endDate = this._selfAssessmentCriteriaForm.controls[
      'endDate'
    ].value
      ? this._selfAssessmentCriteriaForm.controls['endDate'].value
      : new Date();
    // Set the endDate to the end of that day, otherwise the filter service will filter out any feedback
    // created after this service was created or created past this time on that date selected.
    feedbackFilterCriteria.endDate.setHours(23);
    feedbackFilterCriteria.endDate.setMinutes(59);
    feedbackFilterCriteria.endDate.setSeconds(59);

    return feedbackFilterCriteria;
  }

  getClientFeedbackOnOnlineFilterCriteriaModel(): FeedbackFilterCriteria {
    const clientFeedbackOnOnlineFilterCriteria = new FeedbackFilterCriteria();
    clientFeedbackOnOnlineFilterCriteria.clientId = this
      ._clientFeedbackOnOnlineCriteriaForm.controls['client'].value
      ? this._clientFeedbackOnOnlineCriteriaForm.controls['client'].value
          .clientId
      : defaultClient.clientId;
    clientFeedbackOnOnlineFilterCriteria.startDate = this
      ._clientFeedbackOnOnlineCriteriaForm.controls['startDate'].value
      ? this._clientFeedbackOnOnlineCriteriaForm.controls['startDate'].value
      : this.firstDayOfTheYear;
    clientFeedbackOnOnlineFilterCriteria.endDate = this
      ._clientFeedbackOnOnlineCriteriaForm.controls['endDate'].value
      ? this._clientFeedbackOnOnlineCriteriaForm.controls['endDate'].value
      : new Date();

    clientFeedbackOnOnlineFilterCriteria.endDate.setHours(23);
    clientFeedbackOnOnlineFilterCriteria.endDate.setMinutes(59);
    clientFeedbackOnOnlineFilterCriteria.endDate.setSeconds(59);

    return clientFeedbackOnOnlineFilterCriteria;
  }

  getFilterDateRangeDisplay(model: FeedbackFilterCriteria): string {
    if (!model) {
      model = this.getFilterCriteriaModel();
    }
    return `(${this.datePipe.transform(
      model.startDate,
      'longDate'
    )} - ${this.datePipe.transform(model.endDate, 'longDate')})`;
  }

  filterSubmitter(
    feedback: SavedAssessment,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return (
      filterCriteria.submitterId === defaultOnliner.userId ||
      feedback.submittedById === filterCriteria.submitterId
    );
  }

  filterReceiver(
    feedback: SavedAssessment,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return (
      filterCriteria.employeeId === defaultOnliner.userId ||
      feedback.employeeId === filterCriteria.employeeId
    );
  }

  filterCareerMentor(
    feedback: SavedAssessment,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return (
      filterCriteria.careerMentorId === defaultOnliner.userId ||
      feedback.careerMentorId === filterCriteria.careerMentorId
    );
  }

  filterSubmissionType(
    feedback: SavedAssessment,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return (
      filterCriteria.submissionTypeId === defaultSubmissionType.id ||
      feedback.submissionTypeId === filterCriteria.submissionTypeId
    );
  }

  filterClient(
    feedback: SavedAssessment,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return (
      filterCriteria.clientId === defaultClient.clientId ||
      feedback.client.clientId === filterCriteria.clientId
    );
  }

  filterStartDate(
    feedback: SavedAssessment,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return filterCriteria.startDate <= new Date(feedback.submittedDate);
  }

  filterEndDate(
    feedback: SavedAssessment,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return filterCriteria.endDate >= new Date(feedback.submittedDate);
  }

  filterFeedbackType(
    feedback: SavedAssessment,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return (
      filterCriteria.feedbackTypeId === defaultFeedbackType.feedbackTypeId ||
      feedback.feedbackTypeId === filterCriteria.feedbackTypeId
    );
  }

  filterFeedbackStatus(
    feedback: SavedAssessment,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return (
      filterCriteria.status === defaultFeedbackStatus.statusDescription ||
      FeedbackStatusEnum[feedback.GetCurrentStatusId()] ===
        filterCriteria.status ||
      feedback.currentStatus === filterCriteria.status
    );
  }

  filterRequestedFeedbackStatus(
    feedback: MyFeedbackRequests,
    filterCriteria: RequestedFeedbackFilterCriteria
  ): boolean {
    return (
      filterCriteria.status === defaultFeedbackStatus.statusDescription ||
      feedback.status === filterCriteria.status
    );
  }

  filterClientFeedbackOnOnlineClient(
    feedback: ClientFeedbackOnOnline,
    filterCriteria: ClientFeedbackOnOnlineFilterCriteria
  ): boolean {
    return (
      filterCriteria.clientId === defaultClient.clientId ||
      feedback.clientId === filterCriteria.clientId
    );
  }

  filterClientFeedbackOnOnlineStartDate(
    feedback: ClientFeedbackOnOnline,
    filterCriteria: ClientFeedbackOnOnlineFilterCriteria
  ): boolean {
    return filterCriteria.startDate <= new Date(feedback.submittedDate);
  }

  filterClientFeedbackOnOnlineEndDate(
    feedback: ClientFeedbackOnOnline,
    filterCriteria: ClientFeedbackOnOnlineFilterCriteria
  ): boolean {
    return filterCriteria.endDate >= new Date(feedback.submittedDate);
  }

  private createFilterCriteriaForm(
    careerMentor?: CareerMentor
  ): UntypedFormGroup {
    return (this._filterCriteriaForm = this.formBuilder.group({
      onliner: [defaultOnliner, formValidator.validateOnliner()],
      submissionType: [
        defaultSubmissionType,
        formValidator.validateSelectListItem(),
      ],
      careerMentor: careerMentor
        ? [careerMentor, formValidator.validateOnliner()]
        : [defaultOnliner, formValidator.validateOnliner()],
      submittedBy: [defaultOnliner, formValidator.validateOnliner()],
      client: [defaultClient],
      otherClient: [''],
      startDate: [this.firstDayOfTheYear],
      endDate: [new Date()],
      feedbackType: [defaultFeedbackType, formValidator.validateFeedbackType()],
      status: [defaultFeedbackStatus, formValidator.validateFeedbackStatus()],
    }));
  }

  private createRequestedFilterCriteriaForm(): UntypedFormGroup {
    return (this._requestedCriteriaForm = this.formBuilder.group({
      status: [defaultFeedbackStatus, formValidator.validateFeedbackStatus()],
      startDate: [this.firstDayOfTheYear],
      endDate: [new Date()],
    }));
  }

  private createClientFeedbackOnOnlineFilterCriteriaForm(): UntypedFormGroup {
    return (this._clientFeedbackOnOnlineCriteriaForm = this.formBuilder.group({
      client: [defaultClient],
      startDate: [this.firstDayOfTheYear],
      endDate: [new Date()],
    }));
  }

  private createSelfAssessmentFilterCriteriaForm(
    careerMentor?: CareerMentor
  ): UntypedFormGroup {
    return (this._selfAssessmentCriteriaForm = this.formBuilder.group({
      submittedBy: [defaultOnliner, formValidator.validateOnliner()],
      careerMentor: careerMentor
        ? [careerMentor, formValidator.validateOnliner()]
        : [defaultOnliner, formValidator.validateOnliner()],
      startDate: [this.firstDayOfTheYear],
      endDate: [new Date()],
    }));
  }
}

class FeedbackFilterCriteria {
  clientId!: number;
  employeeId!: string;
  submissionTypeId!: number;
  careerMentorId!: string;
  submitterId!: string;
  startDate!: Date;
  endDate!: Date;
  feedbackTypeId!: number;
  status!: string;
}

class RequestedFeedbackFilterCriteria {
  status!: string;
  startDate!: Date;
  endDate!: Date;
}

class ClientFeedbackOnOnlineFilterCriteria {
  clientId!: number;
  startDate!: Date;
  endDate!: Date;
}
