import { Observable, of, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { ApiErrorData } from 'src/app/shared/interfaces/api-error.interface';
import { MatchType } from 'src/app/shared/shared.module';
import { AuthorizedHttp } from './authorized-http.service';
import { EmployeeDetails } from './remote-work-request.service';

@Injectable()
export class OnlinerService {
  private apiEndpoint = `${environment.apiEndpoint}/api/Onliner`;
  private _onliners!: Employee[];
  private _onlinersExcludingDefault!: Employee[];
  private _onlinerDetails!: EmployeeDetails;

  constructor(private http: AuthorizedHttp) {}

  public getOnliners(): Observable<Employee[]> {
    return this._onliners
      ? of(this._onliners)
      : this.http.get<Employee[]>(`${this.apiEndpoint}/onliners`).pipe(
          map(result => {
            this._onliners = result;
            return result;
          }),
          catchError((errorResponse: ApiErrorData) =>
            throwError(errorResponse?.error?.exceptionMessage || 'Server error')
          )
        );
  }

  public getPracticeForOnliner(userId: string): Observable<string> {
    return this.http
      .get<string>(`${this.apiEndpoint}/onlinerPractice/${userId}`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getOnlinersExcludingDefault(): Observable<Employee[]> {
    return this._onlinersExcludingDefault
      ? of(this._onlinersExcludingDefault)
      : this.getOnliners().pipe(
          map(onliners => {
            this._onlinersExcludingDefault = onliners.filter(
              o => o.userId !== defaultOnliner.userId
            );
            return this._onlinersExcludingDefault;
          })
        );
  }

  public getOnlinerPublicGoals(
    onlinerId: string
  ): Observable<GoalTrackerGoals[]> {
    return this.http
      .get<GoalTrackerGoals[]>(`${this.apiEndpoint}/onlinerGoals/${onlinerId}`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getOnlinerDetails() {
    return this.http
      .get<EmployeeDetails>(`${this.apiEndpoint}/onlinerDetails`)
      .pipe(
        map(this.mapEmployeeDetails(result => (this._onlinerDetails = result))),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  private mapEmployeeDetails(
    assignResults: (details: EmployeeDetails) => void
  ) {
    return (data: EmployeeDetails) => {
      const results: EmployeeDetails = MatchType.MatchData(
        data,
        EmployeeDetails
      );
      assignResults(results);
      return results;
    };
  }
}

export class Employee {
  userId!: string;
  lastName!: string;
  firstName!: string;
  careerManager!: string;
  email!: string;
  isCMDelegate!: boolean;
  isCM!: boolean;
}

export const defaultOnliner: Employee = {
  userId: 'all',
  firstName: 'All',
  lastName: '',
  careerManager: '',
  email: '',
  isCMDelegate: false,
  isCM: false,
};

export class GoalTrackerGoals {
  goalTrackerId!: number;
  userId!: string;
  fiscalYear!: number;
  goal!: string;
  showGoal!: boolean;
  goalStatus!: number;
}
