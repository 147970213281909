import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ViewSelfAssessmentDetailRoutingModule } from './view-self-assessment-detail-routing.module';
import { ViewSelfAssessmentDetailComponent } from './view-self-assessment-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { SelfAssessmentQuestionComponent } from './self-assessment-question/self-assessment-question.component';

@NgModule({
  declarations: [
    ViewSelfAssessmentDetailComponent,
    SelfAssessmentQuestionComponent,
  ],
  imports: [
    CommonModule,
    ViewSelfAssessmentDetailRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,
    MatProgressSpinnerModule,

    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class ViewSelfAssessmentDetailModule {}
