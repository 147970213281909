import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WeeklyHealthDeliveryHistoryRoutingModule } from './weekly-health-delivery-history-routing.module';
import { WeeklyHealthDeliveryHistoryComponent } from './weekly-health-delivery-history.component';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { WeeklyHealthDeliveryHistoryListComponent } from './weekly-health-delivery-history-list/weekly-health-delivery-history-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    WeeklyHealthDeliveryHistoryComponent,
    WeeklyHealthDeliveryHistoryListComponent,
  ],
  imports: [
    CommonModule,
    WeeklyHealthDeliveryHistoryRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class WeeklyHealthDeliveryHistoryModule {}
