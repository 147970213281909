import {
  CdkDragDrop,
  CdkDragStart,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  FeedbackType,
  Question,
  QuestionGroup,
} from 'src/app/core/services/feedback.service';
import { QuestionsConfigService } from 'src/app/core/services/questions-config.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-question-group-detail',
  templateUrl: './question-group-detail.component.html',
  styleUrls: [
    './question-group-detail.component.scss',
    '../questions-config.component.scss',
  ],
})
export class QuestionGroupDetailComponent implements OnInit {
  @Input()
  selectedForm!: FeedbackType;
  @Input()
  questionGroup!: QuestionGroup;
  @Input()
  title!: string;
  @Output() selectedQuestion = new EventEmitter<{
    question: Question;
    title: string;
    questionGroup: QuestionGroup;
    numQuestions: number;
  }>();
  @Output() return = new EventEmitter<void>();

  dragDisabled = true;
  previousIndex!: number;
  dragHandleTooltip = 'Drag to reorder questions';
  addQuestionTooltip =
    'Please save the Question Group before adding a question';
  orderChanged = false;
  questions!: Question[];
  isLoading = true;
  isLoadingQuestions = true;
  formGroups!: FormGroup[];
  questionGroupForm!: FormGroup;
  questionColumns: string[] = [
    'dragHandle',
    'active',
    'question',
    'type',
    'optional',
    'optionGroup',
  ];

  constructor(
    private questionsConfigService: QuestionsConfigService,
    private fb: FormBuilder,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit() {
    this.setUpQuestions();
  }

  editQuestion(whichQuestion: Question) {
    this.selectedQuestion.emit({
      question: whichQuestion,
      title: 'Edit Question',
      questionGroup: this.questionGroup,
      numQuestions: this.questions.length,
    });
  }

  addQuestion() {
    this.selectedQuestion.emit({
      question: new Question(),
      title: 'Add Question',
      questionGroup: this.questionGroup,
      numQuestions: this.questions.length,
    });
  }

  close() {
    this.return.emit();
  }

  save() {
    this.isLoading = true;

    this.questionGroup.name = this.questionGroupForm.value.questionGroupControl;

    if (this.isNewQuestionGroup) {
      this.questionsConfigService
        .addQuestionGroup(this.questionGroup)
        .subscribe(
          (data: QuestionGroup) => {
            this.questionGroup = data;
            this.questionGroupForm.markAsPristine();
            this.isLoading = false;
            this.snackBarService.message('Question Group Added');
          },
          (error: string) => {
            this.snackBarService.error(error);
            this.isLoading = false;
          }
        );
    } else {
      this.questionsConfigService
        .updateQuestionGroup(this.questionGroup)
        .subscribe(
          () => {
            this.questionGroupForm.markAsPristine();
            this.isLoading = false;
            this.snackBarService.message('Question Group Saved');
          },
          (error: string) => {
            this.snackBarService.error(error);
            this.isLoading = false;
          }
        );
    }

    if (this.questions.length > 0) {
      this.isLoadingQuestions = true;

      this.questions.forEach(question => {
        this.questionsConfigService.updateQuestion(question).subscribe(
          () => {
            this.formGroups.forEach(formGroup => {
              formGroup.markAsPristine();
            });
            this.orderChanged = false;
            this.isLoadingQuestions = false;
          },
          (error: string) => {
            this.snackBarService.error(error);
            this.orderChanged = false;
            this.isLoadingQuestions = false;
          }
        );
      });
    }
  }

  toggleActive(whichQuestion: Question) {
    whichQuestion.isActive = !whichQuestion.isActive;
  }

  toggleOptional(whichQuestion: Question) {
    whichQuestion.isOptional = !whichQuestion.isOptional;
  }

  drop(event: CdkDragDrop<string[]>) {
    const updatedQuestions = [...this.questions];
    const updatedFormGroups = [...this.formGroups];

    moveItemInArray(updatedQuestions, this.previousIndex, event.currentIndex);
    moveItemInArray(updatedFormGroups, this.previousIndex, event.currentIndex);

    updatedQuestions.forEach((question, index) => {
      question.sortOrder = index;
    });

    this.formGroups = updatedFormGroups;
    this.questions = updatedQuestions;
    this.orderChanged = true;
  }

  start(event: CdkDragStart<string[]>, toIndex: number) {
    this.previousIndex = toIndex;
  }

  get isSaveDisabled(): boolean {
    return (
      (this.questionGroupForm.pristine || !this.questionGroupForm.valid) &&
      !this.orderChanged &&
      this.formGroups.every(group => group.pristine)
    );
  }

  get hasUnsavedChanges(): boolean {
    return (
      !this.questionGroupForm.pristine ||
      this.orderChanged ||
      (this.formGroups !== null &&
        this.formGroups.some(group => !group.pristine))
    );
  }

  get isNewQuestionGroup(): boolean {
    return this.questionGroup?.id === undefined;
  }

  private setUpQuestions() {
    this.isLoading = true;
    this.isLoadingQuestions = true;

    if (!this.isNewQuestionGroup && this.questionGroup?.id) {
      this.questionsConfigService
        .getAllQuestionsFromQuestionGroup(this.questionGroup.id)
        .subscribe(
          (data: Question[]) => {
            this.questions = data;
            this.formGroups = this.questions.map(question =>
              this.fb.group({
                activeControl: [question.isActive],
                optionalControl: [question.isOptional],
              })
            );
            this.questionGroupForm = this.fb.group({
              questionGroupControl: [this.questionGroup.name],
            });

            this.isLoading = false;
            this.isLoadingQuestions = false;
          },
          (error: string) => {
            this.snackBarService.error(error);
            this.isLoading = false;
            this.isLoadingQuestions = false;
          }
        );
    } else {
      this.questions = [];
      this.formGroups = [];
      this.questionGroupForm = this.fb.group({
        questionGroupControl: ['', Validators.required],
      });
      this.isLoading = false;
      this.isLoadingQuestions = false;
    }
  }
}
