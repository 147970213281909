import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { AuthorizedHttp } from 'src/app/core/services/authorized-http.service';
import { AutoSuggestService } from 'src/app/core/services/auto-suggest.service';
import { CommonService } from 'src/app/core/services/common.service';
import {
  Question,
  QuestionOption,
} from 'src/app/core/services/feedback.service';
import { Employee } from 'src/app/core/services/onliner.service';
import {
  Country,
  ExtendRemoteWorkRequest,
  FbRemoteWorkRequestAnswer,
  RemoteWorkRequestService,
  RemoteWorkRequests,
} from 'src/app/core/services/remote-work-request.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import {
  UpdateDialog,
  UpdateDialogComponent,
} from 'src/app/shared/components/update-dialog/update-dialog.component';
import {
  RemoteWorkRequestStatusEnum,
  RemoteWorkRequestTypeEnum,
} from 'src/app/shared/enums/enums.model';
import {
  CompControlErrorStateMatcher,
  requiredAndNoWhiteSpaceValidator,
} from 'src/app/shared/validators/form-validator';
// import * as formValidators from 'src/app/shared/validators/form-validator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-request-remote-work-workflow-form',
  templateUrl: './request-remote-work-workflow-form.component.html',
  styleUrls: ['./request-remote-work-workflow-form.component.scss'],
})
export class RequestRemoteWorkWorkflowFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('autosize')
  autosize!: CdkTextareaAutosize;
  @Input()
  remoteWorkRequestId!: number;
  @Input()
  remoteWorkRequestForm!: UntypedFormGroup;
  @Input() set questionGroupIDs(qGroupIds: number[]) {
    if (qGroupIds !== null) {
      if (qGroupIds.length === 1) {
        if (
          !this._questionGroupIds ||
          this._questionGroupIds[0] === qGroupIds[0]
        ) {
          this.reloadRequired = false;
        } else {
          this.reloadRequired = true;
        }
      } else if (qGroupIds.length > 1) {
        if (!this._questionGroupIds) {
          this.reloadRequired = false;
        } else {
          this.reloadRequired = this._questionGroupIds.every(qg =>
            qGroupIds.every(question => question === qg)
          );
        }
      }
      this._questionGroupIds = [...qGroupIds];
    }
  }
  @Input()
  questionOptions!: QuestionOption[];
  @Input()
  questionOptionGroupIDs!: number[];
  @Input()
  questions!: Question[];
  @Input()
  targetWeek!: Date;
  @Input()
  requestStatus!: string;
  @Input() userCanChangeStatus = false;

  @Output() draftCheck: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() requestStatusChange = new EventEmitter<string>();

  isRequestApproval = false;
  filteredCountries!: Observable<Country[]>;
  countries!: Country[];
  isLoading!: boolean;
  isSaving = false;
  isSubmitting = false;
  isNew!: boolean;
  submitted = false;
  isRequestExpired = false;
  minDate: Date = new Date();
  vacationSelected = false;
  onliners!: Employee[];
  onlinerCM = '';
  onlinerCMUserId = '';
  tooltipItems!: string[];
  toolTip!: string;
  dismissed = false;
  questionsForThisForm!: Question[];
  initialQuestions!: Question[];
  isDraft = false;
  reloadRequired = false;
  request!: RemoteWorkRequests | null | undefined;
  requests!: RemoteWorkRequests[];
  userRequests!: RemoteWorkRequests[];
  validDates = true;
  defaultColor = 'rgb(156, 30, 54)';
  disabledColor = 'rgba(0,0,0,.38)';
  autoSaveSubscription!: Subscription | null;
  readOnly = false;
  isChangingStatus = false;
  isApproved = false;
  isRejected = false;
  activateChangeStatusButtons = false;
  isClientAssignment = false;
  isBusinessTeam = false;
  requestValid = true;
  isCanadian = false;
  isPermanent!: boolean;
  requestTypeId!: number;
  relocationCountry!: Country;
  expectedStartDate!: Date | null;
  expectedReturnDate!: Date | null;
  updateInfo!: ExtendRemoteWorkRequest;
  isPermanentRequest = false;
  isCEO = false;
  isPeopleCare = false;
  errorMatcher = new CompControlErrorStateMatcher();
  initSubscription!: Subscription | null;

  readonly projectNameQuestionId: number = 76;
  readonly laptopQuestionId: number = 67;
  readonly clientApproveQuestionId: number = 68;
  private _questionGroupIds!: number[];
  private readonly assignmentQuestionId: number = 66;
  private readonly startDateQuestionId: number = 62;
  private readonly endDateQuestionId: number = 63;
  private readonly requestTypeQuestionId: number = 61;
  private readonly countryQuestionId: number = 65;
  private readonly workingTemoraryOption: number = 95;
  private readonly permanentRelocationOption: number = 96;

  constructor(
    private remoteWorkService: RemoteWorkRequestService,
    private commonService: CommonService,
    private snackBarService: SnackBarService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private authHttp: AuthorizedHttp,
    private router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    private autoSuggestService: AutoSuggestService
  ) {}

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    return this.remoteWorkRequestForm && !this.remoteWorkRequestForm.dirty;
  }

  get countryControl() {
    return this.remoteWorkRequestForm.controls[`${this.countryQuestionId}`];
  }

  get isSaved() {
    return this.request && this.request.id > 0;
  }

  isDraftCheck() {
    this.draftCheck.emit(this.isDraft);
  }

  setCountryFilter = () => {
    this.filteredCountries = this.autoSuggestService.setCountriesFilter(
      this.countryControl,
      this.countries,
      true,
      false
    );
  };

  showError = (controlName: string) => {
    const control = this.remoteWorkRequestForm.controls[controlName];

    if (control && control.hasError('required') && control.touched) {
      return true;
    }
    return false;
  };

  ngOnInit() {
    this.route.params.subscribe(param => {
      if (param['isRequestApproval']) {
        this.isRequestApproval = param['isRequestApproval'] === 'true';
      }

      if (param['id']) {
        this.remoteWorkRequestId = param['id'];
      }

      this.isPeopleCare = this.authService.doesUserHaveRole([
        environment.roles.FeedbackPeopleCare,
      ]);
      this.isCEO = this.authService.doesUserHaveRole([
        environment.roles.FeedbackCEO,
      ]);
      this.isLoading = true;

      forkJoin([this.commonService.getCountries()]).subscribe(
        data => {
          this.activateChangeStatusButtons =
            this.isRequestApproval && this.userCanChangeStatus;
          if (this._questionGroupIds !== null) {
            this._questionGroupIds.forEach(qgId => {
              const questionsToFill = this.questions.filter(
                q => q.groupId === qgId
              );
              if (!this.questionsForThisForm) {
                this.questionsForThisForm = [...questionsToFill];
                this.initialQuestions = [...questionsToFill];
              }
            });
          }
          this.setUpDropDownData(data);
          this.setupPage();
        },
        error => {
          this.isLoading = false;
          this.snackBarService.error(error);
        }
      );
    });
  }

  public selectCountry(event: MatAutocompleteSelectedEvent) {
    this.relocationCountry = event.option.value;
    this.ngOnChanges();
  }

  countryDisplay = (option?: Country): string =>
    this.autoSuggestService.countryDisplay(option);

  onSubmit() {
    this.snackBarService.message(
      'Your Relocation Request was successfully submitted.'
    );
  }

  ngOnDestroy() {
    if (this.autoSaveSubscription) {
      this.autoSaveSubscription.unsubscribe();
    }
    this.autoSaveSubscription = null;

    if (this.initSubscription) {
      this.initSubscription.unsubscribe();
    }
    this.initSubscription = null;
  }

  ngOnChanges() {
    if (this.reloadRequired) {
      if (this._questionGroupIds !== null) {
        this._questionGroupIds.forEach(qgId => {
          const questionsToFill = this.questions.filter(
            q => q.groupId === qgId
          );
          this.questionsForThisForm = [...questionsToFill];
        });
      }

      this.setupPage();
    }

    if (this.relocationCountry) {
      this.validateRequest();
    }

    if (!this.requestValid) {
      this.usaRelocationPopup();
    }
  }

  closeAlert() {
    this.dismissed = true;
  }

  formatDate(date: Date) {
    const day = this.addPadding(date.getDate());
    const month = this.addPadding(date.getMonth() + 1);
    const year = this.addPadding(date.getFullYear());
    const hours = '00';
    const minutes = '00';
    const seconds = '00';
    const partialDate = [month, day, year].join('/');
    const time = [hours, minutes, seconds].join(':');
    const fullDate = '' + partialDate + ' ' + time;
    return fullDate;
  }
  displayQuestion(question: Question): string {
    return question.question;
  }

  getID(questionID: number): string {
    const idString = '' + questionID + '';
    return idString;
  }

  hasErrorClass(id: number) {
    const control = this.remoteWorkRequestForm.get(this.getID(id));
    const res = control?.errors && control?.touched;
    return res;
  }

  getRadioTextQuestionId(questionId: number): string {
    const idString = '' + questionId + '_text';
    return idString;
  }

  filterSortThisQuestionOption(question: Question): QuestionOption[] {
    return this.questionOptions
      .filter(qo => qo.groupId === question.optionGroupId && qo.id > 1000)
      .sort((a, b) => a.score - b.score);
  }

  validQuestionOptions(question: Question) {
    return this.questionOptions.filter(
      qo => qo.groupId === question.optionGroupId
    );
  }

  optionDisplay = (option?: QuestionOption): string | undefined => {
    const text = option?.value;
    return text;
  };

  onSearchChange(target: QuestionOption): void {
    localStorage.setItem(String(target.id), target.value);
  }

  radioChange(optionId: number) {
    this.requestTypeId = optionId;
    this.ngOnChanges();

    if (
      optionId === this.workingTemoraryOption &&
      (this.remoteWorkRequestForm.controls[`${this.endDateQuestionId}`]
        .value as string) === ''
    ) {
      // when selecting "working remotely (temporary)" and nothing was cached for the enddate
      this.remoteWorkRequestForm.controls[`${this.endDateQuestionId}`].setValue(
        null
      );
    }

    const option = this.questionOptions.find(qo => qo.id === optionId);
    if (option !== null && option !== undefined) {
      const question = this.questions.find(
        q => q.optionGroupId === option.groupId
      );
      if (question) {
        this.CheckFormFlowValues(question, option.id);
        if (question.id === this.assignmentQuestionId) {
          if (this.isBusinessTeam || this.isClientAssignment) {
            this.remoteWorkRequestForm.controls[
              `${this.projectNameQuestionId}`
            ].setValidators([Validators.required, Validators.minLength(1)]);
          } else {
            this.remoteWorkRequestForm.controls[
              `${this.projectNameQuestionId}`
            ].clearValidators();
          }
          if (this.isClientAssignment) {
            this.remoteWorkRequestForm.controls[
              `${this.laptopQuestionId}`
            ].setValidators([Validators.required, Validators.minLength(1)]);
            this.remoteWorkRequestForm.controls[
              `${this.clientApproveQuestionId}`
            ].setValidators([Validators.required, Validators.minLength(1)]);
          } else {
            this.remoteWorkRequestForm.controls[
              `${this.laptopQuestionId}`
            ].clearValidators();
            this.remoteWorkRequestForm.controls[
              `${this.clientApproveQuestionId}`
            ].clearValidators();
          }
          this.remoteWorkRequestForm.controls[
            `${this.laptopQuestionId}`
          ].updateValueAndValidity();
          this.remoteWorkRequestForm.controls[
            `${this.clientApproveQuestionId}`
          ].updateValueAndValidity();
          this.remoteWorkRequestForm.controls[
            `${this.projectNameQuestionId}`
          ].updateValueAndValidity();
        } else if (question.id === this.requestTypeQuestionId) {
          if (!this.isPermanentRequest) {
            this.remoteWorkRequestForm.controls[
              `${this.endDateQuestionId}`
            ].setValidators([Validators.required]);
          } else {
            this.remoteWorkRequestForm.controls[
              `${this.endDateQuestionId}`
            ].clearValidators();
          }
          this.remoteWorkRequestForm.controls[
            `${this.endDateQuestionId}`
          ].updateValueAndValidity();
        }
      }
    }
  }

  radioTextChange(optionId: number) {
    const option: QuestionOption | undefined = this.questionOptions.find(
      qo => qo.id === optionId
    );
    if (option !== null && option !== undefined) {
      const question: Question | undefined = this.questions.find(
        q => q.optionGroupId === option.groupId
      );
      if (option.score === -1) {
        this.remoteWorkRequestForm.controls[
          `${question?.id}_text`
        ].setValidators([
          Validators.required,
          Validators.minLength(1),
          requiredAndNoWhiteSpaceValidator,
        ]);
        if (question) {
          this.commentRequired(question.id, optionId);
        }
      } else {
        this.remoteWorkRequestForm.controls[
          `${question?.id}_text`
        ].clearValidators();
      }
      this.remoteWorkRequestForm.controls[
        `${question?.id}_text`
      ].updateValueAndValidity();
    }
  }

  public validRequest() {
    const newStart = new Date(
      this.remoteWorkRequestForm.controls[`${this.startDateQuestionId}`].value
    );
    let newEnd: Date;
    if (
      this.remoteWorkRequestForm.controls[`${this.endDateQuestionId}`].value ===
      null
    ) {
      newEnd = new Date(newStart);
      newEnd.setDate(newEnd.getDate() + 1);
    } else {
      newEnd = new Date(
        this.remoteWorkRequestForm.controls[`${this.endDateQuestionId}`].value
      );
    }
    newStart.setHours(0, 0, 0, 0);
    newEnd.setHours(0, 0, 0, 0);

    this.remoteWorkRequestForm.controls[`${this.startDateQuestionId}`].setValue(
      newStart
    );
    this.remoteWorkRequestForm.controls[`${this.endDateQuestionId}`].setValue(
      newEnd
    );

    if (newStart >= newEnd && !this.isPermanentRequest) {
      this.validDates = false;

      if (newStart >= newEnd) {
        this.remoteWorkRequestForm.controls[
          `${this.startDateQuestionId}`
        ].setErrors({ invalidStartDate: true });
      } else {
        this.remoteWorkRequestForm.controls[
          `${this.startDateQuestionId}`
        ].clearValidators();
      }
    } else {
      this.validDates = true;
      this.remoteWorkRequestForm.controls[
        `${this.startDateQuestionId}`
      ].clearValidators();
      this.remoteWorkRequestForm.controls[
        `${this.startDateQuestionId}`
      ].updateValueAndValidity();
    }
  }

  resetRemoteWorkRequestForm() {
    this.remoteWorkRequestForm.reset();
    this.request = null;
    this.remoteWorkRequestId = 0;
    this.isDraft = true;
  }

  updateRemoteWorkRequestForm() {
    this.remoteWorkRequestForm.controls['onliner'].setValue(
      this.authService.getUserId().toLowerCase()
    );
    const txt = document.createElement('textarea');
    let length = this.questionsForThisForm.length;
    let q: Question | null = null;
    let datePlusTimezoneOffset = new Date();

    if (this.isDraft) {
      this.isLoading = false;
    }

    if (this.submitted) {
      this.disableRequestFields();
    }
    // fill in answers to the questions, if there are any...
    for (let i = 0; i < length; i++) {
      q = this.questionsForThisForm[i];

      if (this.request && this.request.answers) {
        this.request.answers.forEach(a => {
          if (q && q.id === a.questionId) {
            if (q.id === this.countryQuestionId) {
              const country = this.getCountry(a.stringValue);
              this.remoteWorkRequestForm.controls[`${q.id}`].setValue(country);
            } else if (q.type.toString() === 'Text') {
              txt.innerHTML = a.stringValue;
              this.remoteWorkRequestForm.controls[`${q.id}`].setValue(
                txt.value
              );
            } else if (q.type.toString() === 'Scale') {
              this.remoteWorkRequestForm.controls[`${q.id}`].setValue(
                a.intValue
              );
            } else if (
              q.type.toString() === 'Date_Input' &&
              a.stringValue.length > 0
            ) {
              datePlusTimezoneOffset = new Date(a.stringValue);
              datePlusTimezoneOffset.setMinutes(
                datePlusTimezoneOffset.getMinutes() +
                  datePlusTimezoneOffset.getTimezoneOffset()
              );
              this.remoteWorkRequestForm.controls[`${q.id}`].setValue(
                datePlusTimezoneOffset.toISOString()
              );
            } else {
              if (q.type.toString() === 'Radio') {
                this.remoteWorkRequestForm.controls[`${q.id}`].setValue(
                  a.optionId
                );
                if (a.optionId) {
                  this.CheckFormFlowValues(q, a.optionId);
                }
              } else if (
                this.remoteWorkRequestForm.controls[`${q.id}`].value !== ''
              ) {
                this.remoteWorkRequestForm.controls[`${q.id}`].setValue(
                  a.optionId
                );
              }
              if (q.type.toString() === 'Radio_Text') {
                this.remoteWorkRequestForm.controls[`${q.id}`].setValue(
                  a.optionId
                );
                this.remoteWorkRequestForm.controls[`${q.id}_text`].setValue(
                  a.stringValue
                );
                if (a.optionId) {
                  this.radioTextChange(a.optionId);
                }
              }
            }
          }
        });
      }
      length = this.questionsForThisForm.length;
    }
    if (!this.isBusinessTeam || !this.isClientAssignment) {
      this.remoteWorkRequestForm.controls[
        `${this.projectNameQuestionId}`
      ].setErrors(null);
      this.remoteWorkRequestForm.controls[
        `${this.projectNameQuestionId}`
      ].clearValidators();
    }
    if (!this.isClientAssignment) {
      this.remoteWorkRequestForm.controls[`${this.laptopQuestionId}`].setErrors(
        null
      );
      this.remoteWorkRequestForm.controls[
        `${this.laptopQuestionId}`
      ].clearValidators();
      this.remoteWorkRequestForm.controls[
        `${this.clientApproveQuestionId}`
      ].setErrors(null);
      this.remoteWorkRequestForm.controls[
        `${this.clientApproveQuestionId}`
      ].clearValidators();
    }
    if (this.isPermanentRequest) {
      this.remoteWorkRequestForm.controls[
        `${this.endDateQuestionId}`
      ].setErrors(null);
      this.remoteWorkRequestForm.controls[
        `${this.endDateQuestionId}`
      ].clearValidators();
    }
    this.remoteWorkRequestForm.updateValueAndValidity();
    this.changeDetectorRef.detectChanges();
  }

  approveRemoteWorkRequest() {
    if (!this.request) {
      return;
    }
    this.isChangingStatus = true;
    this.remoteWorkService.approveRemoteWorkRequest(this.request.id).subscribe(
      response => {
        this.isChangingStatus = false;
        if (response.success) {
          this.isApproved = true;
          this.requestStatus = this.request?.requestStatusDescription || '';
          if (this.isAwaiting()) {
            this.requestStatus = 'Awaiting Return';
          }
          this.requestStatusChange.emit(this.requestStatus);
        }
        this.snackBarService.message(response.message);
      },
      error => {
        this.isChangingStatus = false;
        this.snackBarService.error(error);
      }
    );
  }

  approveRemoteWorkRequestConfirmation() {
    const confirmDialog = new ConfirmDialog();
    confirmDialog.message = 'Are you sure you want to approve this request?';
    confirmDialog.title = 'Approval confirmation';
    confirmDialog.okButtonTitle = 'Yes';
    confirmDialog.cancelButtonTitle = 'No';
    this.dialog
      .open(ConfirmationDialogComponent, {
        width: '400px',
        data: confirmDialog,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((choice: string) => {
        if (choice === 'ok') {
          this.approveRemoteWorkRequest();
        }
      });
  }

  rejectRemoteWorkRequest() {
    if (!this.request) {
      return;
    }
    this.isChangingStatus = true;
    this.remoteWorkService.rejectRemoteWorkRequest(this.request.id).subscribe(
      response => {
        if (response.success) {
          this.isRejected = true;
        }
        this.isChangingStatus = false;
        this.snackBarService.message(response.message);
      },
      error => {
        this.isChangingStatus = false;
        this.snackBarService.error(error);
      }
    );
  }

  rejectRemoteWorkRequestConfirmation() {
    const confirmDialog = new ConfirmDialog();
    confirmDialog.message = 'Are you sure you want to reject this request?';
    confirmDialog.title = 'Reject confirmation';
    confirmDialog.okButtonTitle = 'Yes';
    confirmDialog.cancelButtonTitle = 'No';
    this.dialog
      .open(ConfirmationDialogComponent, {
        width: '400px',
        data: confirmDialog,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((choice: string) => {
        if (choice === 'ok') {
          this.rejectRemoteWorkRequest();
        }
      });
  }

  saveRemoteWorkRequest() {
    this.isSaving = true;
    this.updateRequestFromForm();
    let message = '';
    if (this.request) {
      this.remoteWorkService.saveRequest(this.request).subscribe(
        result => {
          if (!result.success) {
            if (result.message) {
              this.snackBarService.message(result.message);
            } else {
              this.snackBarService.message('Unknown error ocurred.');
            }
            this.isSaving = false;
            return;
          }
          this.request = result.requestDto ?? this.request;
          message = 'Your Relocation Request form has been saved.';
          this.snackBarService.message(message);
          this.isSaving = false;
          this.isDraft = this.evalIsInDrafStatus();
          this.remoteWorkRequestForm.markAsPristine();
        },
        error => {
          this.isSaving = false;
          this.snackBarService.error(error);
        }
      );
    }
  }

  enableRequestFields(): void {
    this.questionsForThisForm.forEach(q => {
      this.remoteWorkRequestForm.controls[`${q.id}`].enable();
    });
  }

  disableRequestFields(): void {
    this.questionsForThisForm.forEach(q => {
      this.remoteWorkRequestForm.controls[`${q.id}`].disable();
    });
  }

  submissionConfirmation() {
    const confirmDialog = new ConfirmDialog();
    confirmDialog.message = 'Are you sure you want to submit this form?';
    confirmDialog.title = 'Submission confirmation';
    confirmDialog.okButtonTitle = 'Yes';
    confirmDialog.cancelButtonTitle = 'No';

    this.dialog
      .open(ConfirmationDialogComponent, {
        width: '400px',
        data: confirmDialog,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((choice: string) => {
        if (choice === 'ok') {
          this.submitRequest();
        }
      });
  }

  public cannotReview(): boolean {
    return (
      this.isChangingStatus ||
      this.isApproved ||
      this.isRejected ||
      !this.userCanChangeStatus ||
      !this.isRequestApproval
    );
  }

  public cannotSaveOrSubmit(): boolean {
    return (
      this.isSubmitting ||
      this.isSaving ||
      this.readOnly ||
      !this.validDates ||
      this.remoteWorkRequestForm.invalid
    );
  }

  submitRequest() {
    this.isSubmitting = true;
    this.remoteWorkRequestForm.disable();
    this.updateRequestFromForm();
    let message = '';

    if (this.request) {
      this.remoteWorkService.submitRequest(this.request).subscribe(
        result => {
          if (!result.success) {
            if (result.message) {
              this.snackBarService.message(result.message);
            } else {
              this.snackBarService.message('Unknown error ocurred.');
            }
            this.isSaving = false;
            this.isSubmitting = false;
            this.remoteWorkRequestForm.enable();
            return;
          }
          message = 'Your Relocation Request form has been submitted.';
          this.snackBarService.message(message);
          this.isSubmitting = false;
          this.submitted = true;
          this.isSaving = false;
          this.remoteWorkRequestForm.markAsPristine();
          this.navigateToRequest();
        },
        error => {
          this.isSubmitting = false;
          this.enableRequestFields();
          this.snackBarService.error(error);
        }
      );
    }
  }

  enableFormFields(): void {
    this.questionsForThisForm.forEach(q => {
      // if (q.groupId === 3 && this.enableQuestions) {
      if (q.groupId === 3) {
        this.remoteWorkRequestForm.controls[`${q.id}`].enable();
      } else if (q.groupId !== 3) {
        this.remoteWorkRequestForm.controls[`${q.id}`].enable();
      }
    });
  }

  // enableQuestions(): void {
  //   this.questionsForThisForm.forEach(q => {
  //     this.remoteWorkRequestForm.controls[`${q.id}`].enable();
  //   });
  // }

  navigateToRequest() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/home']));
  }

  isSaveButtonDisabled(): boolean {
    const isDisabled =
      this.isSaving ||
      this.isSubmitting ||
      this.remoteWorkRequestForm.get('onliner')?.errors !== null;
    return isDisabled;
  }

  returnConfirmation() {
    const updateDialog = new UpdateDialog();

    updateDialog.title1 = 'Relocation Return Confirmation';
    updateDialog.title2 = 'Relocation Request Extension';
    updateDialog.question1 = 'Have you returned from your destination?';
    updateDialog.question2A = 'Choose the new return date';
    updateDialog.question2B = 'Confirm return date';
    updateDialog.question3 =
      'Do you want to extend your stay at your destination?';
    updateDialog.okButtonTitle = 'SUBMIT';
    updateDialog.expctdStartDate = this.expectedStartDate;
    updateDialog.expctdReturnDate = this.expectedReturnDate;

    const updateRef = this.dialog.open(UpdateDialogComponent, {
      width: '480px',
      height: '400px',
      data: updateDialog,
      disableClose: true,
    });

    updateRef.afterClosed().subscribe(data => {
      if (data) {
        this.confirmReturn(data.returning, data.extending, data.returnDate);
      }
    });
  }

  private setUpDropDownData(data: [Country[]]) {
    this.countries = data[0];
    this.setCountryFilter();
    this.isLoading = false;
  }

  private usaRelocationPopup() {
    const usaPopup = new ConfirmDialog();
    usaPopup.title = 'Permanent Relocation to the USA';
    usaPopup.message =
      'Thank you for filling out the application. Permanent relocation requests between Canada and the USA are currently not supported through the feedback app. Reach out to People Care to initiate your relocation. Thank you!';
    usaPopup.cancelButtonTitle = 'Ok';

    this.dialog.open(ConfirmationDialogComponent, {
      width: '200',
      height: '200',
      data: usaPopup,
      disableClose: true,
    });
  }

  private displayConfirmationMessage() {
    const confirmDialog = new ConfirmDialog();
    const message =
      'You have entered answers to some questions on the form. They will be cleared if you change your selection. Do you wish to continue?';
    confirmDialog.message = message;
    confirmDialog.title = 'Form Type Switch confirmation';
    confirmDialog.okButtonTitle = 'OK';
    confirmDialog.cancelButtonTitle = 'Cancel';

    this.dialog
      .open(ConfirmationDialogComponent, {
        width: '400px',
        data: confirmDialog,
        disableClose: true,
      })
      .afterClosed()
      .subscribe(() => {
        this.remoteWorkRequestForm.markAsPristine();
      });
  }

  private setupPage() {
    this.isLoading = true;
    if (this.remoteWorkRequestId > 0) {
      this.initSubscription = forkJoin([
        this.remoteWorkService.getCurrentRemoteWorkRequests(
          this.remoteWorkRequestId,
          this.isRequestApproval
        ),
      ]).subscribe(
        data => {
          this.request = data[0];
          this.setupAutoSave();
          if (this.request) {
            this.getRemoteWorkRequest();
            this.requestStatus = this.request.requestStatusDescription;
            this.isPermanentRequest =
              this.request.requestTypeId ===
              RemoteWorkRequestTypeEnum.Permanent;
            if (this.isAwaiting()) {
              this.expectedStartDate = this.request.expectedTravelDate;
              this.expectedReturnDate = this.request.expectedReturnDate;
              this.requestStatus = 'Awaiting Return';
              this.returnConfirmation();
            }
          } else {
            this.isLoading = false;
            this.isDraftCheck();
          }

          this.requestStatusChange.emit(this.requestStatus);
          if (
            this.isRequestApproval &&
            (this.isPeopleCare || this.isCEO) &&
            (this.request.requestStatusId ===
              RemoteWorkRequestStatusEnum.Complete ||
              this.request.requestStatusId ===
                RemoteWorkRequestStatusEnum.ApprovedByCEO)
          ) {
            this.readOnly = true;
          }
          this.isDraftCheck();
          this.remoteWorkRequestForm.markAsPristine();
        },
        () => {
          this.isLoading = false;
        }
      );
    } else {
      this.initSubscription = forkJoin([
        this.remoteWorkService.getDraftRemoteWorkRequests(),
      ]).subscribe(
        remoteWorkRequest => {
          this.requests = remoteWorkRequest[0];
          if (this.requests) {
            const length = this.requests.length;
            if (this.remoteWorkRequestId > 0 && length > 0) {
              this.request = this.requests[length - 1];
            } else if (length > 0) {
              // Get request in draft state
              this.request = this.requests.find(r => {
                // check if last status is Draft
                const logCount = r.log.length;
                if (logCount > 0) {
                  return (
                    r.log[logCount - 1].statusId ===
                    RemoteWorkRequestStatusEnum.Draft
                  );
                } else {
                  return false;
                }
              });
            } else {
              this.request = null;
            }
          } else {
            this.request = null;
          }
          this.setupAutoSave();
          if (this.request) {
            this.getRemoteWorkRequest();
            this.requestStatus = this.request.requestStatusDescription;
          } else {
            this.remoteWorkService
              .getActiveRemoteWorkRequest()
              .subscribe((value: RemoteWorkRequests) => {
                this.request = value;
                this.expectedStartDate = this.request.expectedTravelDate;
                this.expectedReturnDate = this.request.expectedReturnDate;
                if (this.request.requestStatusDescription) {
                  this.getRemoteWorkRequest();
                  this.requestStatus = this.request.requestStatusDescription;
                  if (this.isAwaiting()) {
                    this.requestStatus = 'Awaiting Return';
                    this.returnConfirmation();
                  }
                } else {
                  this.requestStatus = 'New';
                }

                this.requestStatusChange.emit(this.requestStatus);
              });

            this.isLoading = false;
          }

          this.isDraftCheck();
          this.remoteWorkRequestForm.markAsPristine();
        },
        error => {
          this.isLoading = false;
          this.snackBarService.error(error);
        }
      );
    }

    this.remoteWorkService.getCurrentUserDetails().subscribe(data => {
      if (data.countryAlpha2Code === 'CA') {
        this.isCanadian = true;
      }
    });
  }

  private isAwaiting(): boolean {
    let isAwaiting = false;

    if (
      this.request &&
      this.request.requestTypeId === RemoteWorkRequestTypeEnum.Temporary &&
      (this.request.requestStatusId ===
        RemoteWorkRequestStatusEnum.ApprovedByPeopleCare ||
        this.request.requestStatusId ===
          RemoteWorkRequestStatusEnum.ExtensionApproved) &&
      this.request?.expectedTravelDate &&
      new Date(this.request.expectedTravelDate) <= new Date()
    ) {
      isAwaiting = true;
    }

    return isAwaiting;
  }

  private addPadding(datePart: number) {
    if (datePart.toString().length === 1) {
      return '0' + datePart.toString();
    }
    return datePart.toString();
  }

  private CheckFormFlowValues(question: Question, optionId: number) {
    if (question.id === this.assignmentQuestionId) {
      const clientAssignmentOptionId = 92;
      const businessTeamOptionId = 100;
      this.isClientAssignment = optionId === clientAssignmentOptionId;
      this.isBusinessTeam = optionId === businessTeamOptionId;
    } else if (question.id === this.requestTypeQuestionId) {
      const permanentOptionId = 96;
      this.isPermanentRequest = optionId === permanentOptionId;
    }
  }

  private commentRequired(questionId: number, optionId: number): boolean {
    const option = this.questionOptions.find(qo => qo.id === optionId);
    let required = false;

    if (option !== null && option !== undefined) {
      if (option.score === -1) {
        this.remoteWorkRequestForm.controls[
          `${questionId}_text`
        ].markAsTouched();
        required = true;
      }
    }
    return required;
  }

  private getRemoteWorkRequest() {
    this.evaluateRequestStatus();
    this.updateRemoteWorkRequestForm();
    if (!this.submitted) {
      this.isDraftCheck();
    }
    if (
      this.request &&
      this.request.expectedReturnDate !== null &&
      new Date(this.request.expectedReturnDate) < new Date()
    ) {
      this.isRequestExpired = true;
    }

    this.isLoading = false;
  }

  private evalIsInDrafStatus(): boolean {
    return (
      !!this.request &&
      this.request.log &&
      this.request.log.length > -1 &&
      this.request.log[this.request.log.length - 1]?.statusId ===
        RemoteWorkRequestStatusEnum.Draft
    );
  }

  private evaluateRequestStatus() {
    this.readOnly = true;
    this.submitted = true;

    if (this.evalIsInDrafStatus()) {
      this.readOnly = false;
      this.submitted = false;
      this.isDraft = true;
      return;
    }

    const pendings = this.request?.log.filter(
      l =>
        l.statusId === RemoteWorkRequestStatusEnum.Pending ||
        l.statusId === RemoteWorkRequestStatusEnum.ExtensionPending
    );
    if (pendings && pendings.length > 0) {
      const lastPendingIdx = pendings.length - 1;
      const approvesOrDenies = this.request?.log.filter(
        l =>
          l.statusId === RemoteWorkRequestStatusEnum.ApprovedByCEO ||
          l.statusId === RemoteWorkRequestStatusEnum.ApprovedByPeopleCare ||
          l.statusId === RemoteWorkRequestStatusEnum.ExtensionApproved ||
          l.statusId === RemoteWorkRequestStatusEnum.DeniedByCEO ||
          l.statusId === RemoteWorkRequestStatusEnum.DeniedByPeopleCare
      );
      if (approvesOrDenies && approvesOrDenies.length > 0) {
        const lastApproveDenyIdx = approvesOrDenies.length - 1;
        // Approvals and denials follow a pending status
        if (lastPendingIdx < lastApproveDenyIdx) {
          this.readOnly = true;
          this.submitted = true;
          this.isDraft = false;
        }
      }
    }
    // Check last status
    const numLogs = this.request?.log.length || 0;
    if (this.request && numLogs > 0) {
      const lastStatus = this.request.log[numLogs - 1];
      if (lastStatus.statusId === RemoteWorkRequestStatusEnum.ApprovedByCEO) {
        this.isApproved = true;
      } else if (
        lastStatus.statusId ===
          RemoteWorkRequestStatusEnum.ApprovedByPeopleCare &&
        !this.isCEO
      ) {
        this.isApproved = true;
      } else if (
        lastStatus.statusId === RemoteWorkRequestStatusEnum.DeniedByCEO ||
        lastStatus.statusId === RemoteWorkRequestStatusEnum.DeniedByPeopleCare
      ) {
        this.isRejected = true;
      }
    }
  }

  private getCountry(alpha2CodeId: string): Country | null {
    if (!this.countries) {
      return null;
    }
    const country = this.countries.find(c => c.alpha2CodeId === alpha2CodeId);
    return country || null;
  }

  private setupAutoSave() {
    this.autoSaveSubscription = this.remoteWorkRequestForm.valueChanges
      .pipe(debounceTime(environment.autoSaveInterval))
      .subscribe(() => {
        if (this.remoteWorkRequestForm.dirty && !this.cannotSaveOrSubmit()) {
          this.saveRemoteWorkRequest();
        }
      });
  }

  private validateRequest() {
    if (
      this.isCanadian &&
      this.requestTypeId === 96 &&
      this.relocationCountry.alpha2CodeId === 'US'
    ) {
      this.requestValid = false;
    } else {
      this.requestValid = true;
    }
  }

  private updateRequestFromForm() {
    let answers: FbRemoteWorkRequestAnswer[] = [];

    if (!this.request) {
      this.request = new RemoteWorkRequests();
      this.request.id = -1;
    } else {
      if (this.request.answers) {
        answers = [...this.request.answers];
        this.request.answers = [];
      }
    }

    this.questionsForThisForm.forEach(q => {
      if (this.request && !this.request.answers) {
        this.request.answers = [];
      }
      let validQuestion = true;
      if (
        q.id === this.laptopQuestionId ||
        q.id === this.clientApproveQuestionId
      ) {
        validQuestion = this.isClientAssignment;
      } else if (q.id === this.endDateQuestionId) {
        validQuestion = !this.isPermanentRequest;
      } else if (q.id === this.projectNameQuestionId) {
        validQuestion = this.isClientAssignment || this.isBusinessTeam;
      }
      if (validQuestion) {
        const options = this.questionOptions.filter(
          qo => qo.groupId === q.optionGroupId
        );
        let stringValue: string | null = '';
        let intValue: number | null = 0;
        let optionID: number | null = 0;

        if (q.type.toString() === 'Scale') {
          intValue = this.remoteWorkRequestForm.controls[q.id].value;
          optionID =
            this.questionOptions?.find(
              qo =>
                qo.score === intValue &&
                qo.groupId ===
                  this.questionOptionGroupIDs.find(
                    qoid => qoid === q.optionGroupId
                  )
            )?.id || null; // this is slightly better
        } else {
          if (q.type.toString() === 'Text') {
            // value = new Date(this.remoteWorkRequestForm.value[q.id]);
            stringValue = this.GetAnswerTextValue(
              q.id,
              this.remoteWorkRequestForm.value[q.id]
            );
            intValue = null;
            optionID = null;
          } else if (q.type.toString() === 'Date_Input') {
            const value = new Date(this.remoteWorkRequestForm.value[q.id]);
            stringValue =
              value instanceof Date && value ? this.formatDate(value) : null;
            intValue = null;
            optionID = null;
          } else {
            if (q.type.toString() === 'Radio_Text') {
              stringValue = this.remoteWorkRequestForm.value[`${q.id}_text`];
            } else {
              stringValue = !this.remoteWorkRequestForm.value[q.id]
                ? null
                : options.find(
                    sv => sv.id === this.remoteWorkRequestForm.value[q.id]
                  )?.value || null;
            }
            intValue = !this.remoteWorkRequestForm.value[q.id]
              ? null
              : options.find(
                  sv => sv.id === this.remoteWorkRequestForm.value[q.id]
                )?.score || null;
            optionID = !this.remoteWorkRequestForm.value[q.id]
              ? null
              : options.find(
                  sv => sv.id === this.remoteWorkRequestForm.value[q.id]
                )?.id || null;
          }
        }

        const index = answers.findIndex(a => a.questionId === q.id);

        if (index > -1) {
          this.request?.answers.push({
            id: answers[index].id,
            questionId: answers[index].questionId,
            question: answers[index].question,
            stringValue: stringValue ? stringValue.trim() : '',
            intValue:
              q.type.toString() === 'Text'
                ? answers[index]?.intValue || null
                : intValue,
            optionId: optionID,
            option: answers[index].option,
          });
        } else {
          this.request?.answers.push({
            id: -1,
            questionId: q.id,
            question: q,
            stringValue: stringValue ? stringValue.trim() : '',
            intValue: q.type.toString() === 'Text' ? null : intValue,
            optionId: q.type.toString() === 'Text' ? null : optionID,
            option: null,
          });
        }
      }
    });
  }

  private GetAnswerTextValue(
    questionId: number,
    value: Country | string
  ): string {
    if (questionId === this.countryQuestionId) {
      return (value as Country)?.alpha2CodeId;
    }
    return value as string;
  }

  private confirmReturn(
    returning: boolean,
    extending: boolean,
    returnDate: Date
  ) {
    this.updateInfo = new ExtendRemoteWorkRequest();
    this.updateInfo.requestId = this.request?.id || 0;
    this.updateInfo.isReturning = returning;
    this.updateInfo.endDate = returnDate;

    if (this.request) {
      if (returning) {
        this.request.requestStatusId = RemoteWorkRequestStatusEnum.Complete;
        this.request.requestStatusDescription = 'Complete';
      } else if (!returning && extending) {
        this.request.requestStatusId =
          RemoteWorkRequestStatusEnum.ExtensionPending;
        this.request.requestStatusDescription = 'Extension Pending';
      }
    }

    if (returning || extending) {
      this.remoteWorkService.updateRequest(this.updateInfo).subscribe(
        () => {
          if (this.request) {
            this.request.expectedReturnDate = returnDate;
          }
          this.snackBarService.message(
            'Relocation request updated successfully'
          );
          if (returning) {
            this.navigateToRequest();
          } else {
            this.setupPage();
          }
        },
        error => {
          this.snackBarService.error(error);
        }
      );
    }
  }
}
