<div *ngIf="isAuthenticated; else placeholder" #originRef
     (click)="showPopover(originRef)"
     [class.bg-hover]="dropdownOpen"
     class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-3">
  <div
  #tooltip="matTooltip"
  [matTooltip]="userRoles"
  matTooltipPosition="left"
   class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 hidden sm:block">{{userName}}</div>
  <div class="overflow-hidden rounded-full h-9 w-9 flex items-center justify-center text-obs-secondary bg-obs-secondary/10">
    <img class="select-none pointer-events-none" [src]="profilePhotoData" *ngIf="!isImageLoading && profilePhotoData; else noImageFound" alt="Signed in as {{userName}}" />
    <ng-template #noImageFound>
      <mat-icon svgIcon="mat:person"></mat-icon>
    </ng-template>
  </div>
</div>

<ng-template #placeholder>
  <div class="ltr:ml-4 rtl:mr-4 flex w-32 bg-black"></div>
</ng-template>
