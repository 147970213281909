import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ViewConfidentialFeedbackRoutingModule } from './view-confidential-feedback-routing.module';
import { ViewConfidentialFeedbackComponent } from './view-confidential-feedback.component';
import { ConfidentialFeedbackDetailComponent } from './confidential-feedback-detail/confidential-feedback-detail.component';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';

@NgModule({
  declarations: [
    ViewConfidentialFeedbackComponent,
    ConfidentialFeedbackDetailComponent,
  ],
  imports: [
    CommonModule,
    ViewConfidentialFeedbackRoutingModule,

    AngularMaterialModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,
  ],
})
export class ViewConfidentialFeedbackModule {}
