<form *ngIf="!isLoading && searchCriteriaForm" [formGroup]="searchCriteriaForm" novalidate [style.fontSize.px]="18">

  <div class="row form-group" >
    <mat-form-field class="mr-3 col-md-3 search" [hideRequiredMarker]="hideAsterisk" *ngIf="isClientFilterVisible && !isOnRequestsPage">
        <mat-label>Client: </mat-label>
        <input matInput name="client" id="client" placeholder="Choose Client" [matAutocomplete]="clientauto"
          formControlName="client" />
        <mat-error *ngIf="searchCriteriaForm.get('client')?.hasError('required') && searchCriteriaForm.get('client')?.touched">
            Please enter the name of the Client.</mat-error>
        <mat-autocomplete #clientauto="matAutocomplete" [displayWith]="clientDisplay" autoActiveFirstOption (optionSelected)="filterFeedbacks()" panelWidth="280px">
          <mat-option class="options" *ngFor="let option of filteredClients | async" [value]="option">
            {{ clientDisplay(option) }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="mr-3 col-md-2 search" [hideRequiredMarker]="hideAsterisk" *ngIf="!isOnRequestsPage">
        <mat-label for="client">Date From: </mat-label>
        <div [ngClass]="{'has-error': searchCriteriaForm.get('startDate')?.errors && searchCriteriaForm.get('startDate')?.touched}">
            <input
            matInput
            formControlName="startDate"
            [matDatepicker]="startDatePicker"
            placeholder="MM/DD/YYYY"
            name="dateStart"
            maxDateRangeValidator="endDate"
            (click)="startDatePicker.open()"
            (focus)="startDatePicker.open()"
            (dateChange) = "filterDates()"
            [min]="minDate">
            <mat-datepicker #startDatePicker></mat-datepicker>
        </div>
        <span class="error-message" *ngIf="searchCriteriaForm.get('startDate')?.hasError('datePatternError') && searchCriteriaForm.get('startDate')?.dirty">
            Must be in Date format
        </span>
        <span class="error-message" *ngIf="(searchCriteriaForm.get('startDate')?.hasError('invalidStartDate') && searchCriteriaForm.get('startDate')?.touched)">
            Start Date must be a date and before the End Date.
        </span>
      </mat-form-field>

      <mat-form-field class="mr-3 col-md-2 search" [hideRequiredMarker]="hideAsterisk" *ngIf="!isOnRequestsPage">
        <mat-label for="client">Date To: </mat-label>
        <div [ngClass]="{'has-error': searchCriteriaForm.get('endDate')?.errors && searchCriteriaForm.get('endDate')?.touched}">
            <input
            matInput
            formControlName="endDate"
            [matDatepicker]="endDatePicker"
            name="dateEnd"
            placeholder="MM/DD/YYYY"
            minDateRangeValidator="startDate"
            (click)="endDatePicker.open()"
            (focus)="endDatePicker.open()"
            (dateChange) = "filterDates()">
            <mat-datepicker #endDatePicker></mat-datepicker>
        </div>
        <span class="error-message" *ngIf="searchCriteriaForm.get('endDate')?.hasError('datePatternError') && searchCriteriaForm.get('endDate')?.dirty">
            Must be in Date format
        </span>
        <span class="error-message" *ngIf="searchCriteriaForm.get('endDate')?.hasError('invalidEndDate') && searchCriteriaForm.get('endDate')?.touched">
            End Date must be a date and greater than Start Date.
        </span>
      </mat-form-field>
  </div>

    <div class="row form-group" >
      <mat-form-field class="mr-3 col-md-3 search" *ngIf="isOnlinerFilterVisible && !isOnRequestsPage && !isOnClientFeedbackOnlinePage" [hideRequiredMarker]="hideAsterisk">
          <mat-label for="onliner">Onliner: </mat-label>
          <input matInput name="onliner" id="onliner" placeholder="Choose Onliner" [matAutocomplete]="onlinerauto"
            formControlName="onliner"/>
          <mat-error *ngIf="searchCriteriaForm.get('onliner')?.hasError('required') && searchCriteriaForm.get('onliner')?.touched">
              Please enter the name of the Onliner.</mat-error>
          <mat-autocomplete #onlinerauto="matAutocomplete" [displayWith]="onlinerDisplay" autoActiveFirstOption (optionSelected)="filterFeedbacks()"
            panelWidth="280px">
            <mat-option class="options" *ngFor="let option of filteredOnliners | async" [value]="option">
              {{ onlinerDisplay(option) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

      <mat-form-field class="mr-3 col-md-3 search" *ngIf="isSubmittedByFilterVisible && !isOnRequestsPage && !isOnClientFeedbackOnlinePage" [hideRequiredMarker]="hideAsterisk">
          <mat-label for="submittedBy">Submitted By: </mat-label>
          <mat-label for="submittedBy">Onliner: </mat-label>
          <input matInput name="submittedBy" id="submittedBy" [matAutocomplete]="submittedbyauto"
            formControlName="submittedBy"/>
          <mat-error *ngIf="searchCriteriaForm.get('submittedBy')?.hasError('required') && searchCriteriaForm.get('submittedBy')?.touched">
              Please enter the name of the Submitter.</mat-error>
          <mat-autocomplete #submittedbyauto="matAutocomplete" [displayWith]="onlinerDisplay" autoActiveFirstOption (optionSelected)="filterFeedbacks()"
             panelWidth="280px">
            <mat-option class="options" *ngFor="let option of filteredSubmittedBy | async" [value]="option">
              {{ onlinerDisplay(option) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

      <mat-form-field class="mr-3 col-md-2 search" [hideRequiredMarker]="hideAsterisk" *ngIf="isSubmissionTypeFilterVisible && !isOnRequestsPage && !isOnClientFeedbackOnlinePage">
          <mat-label for="submissionType">Feedback Type: </mat-label>
          <input matInput name="submissionType" id="submissionType" [matAutocomplete]="submissionTypeauto"
            formControlName="submissionType"/>
          <mat-autocomplete #submissionTypeauto="matAutocomplete" [displayWith]="submissionTypeDisplay" autoActiveFirstOption (optionSelected)="filterFeedbacks()"
             panelWidth="280px">
            <mat-option class="options" *ngFor="let option of filteredSubmissionTypes | async" [value]="option">
              {{ submissionTypeDisplay(option) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="mr-3 col-md-3 search" [hideRequiredMarker]="hideAsterisk" *ngIf="isConfidentialFeedbackPage">
          <mat-label for="practice">Practice: </mat-label>
          <input matInput name="practice" id="practice" [matAutocomplete]="practiceAuto"
            formControlName="practice"/>
          <mat-autocomplete #practiceAuto="matAutocomplete" autoActiveFirstOption (optionSelected)="filterFeedbacks()"
             panelWidth="280px">
            <mat-option class="options" *ngFor="let option of practices" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

      <mat-form-field class="mr-3 col-md-2 search" *ngIf="(!isOnClientFeedbackOnlinePage) && !isConfidentialFeedbackPage">
        <mat-label for="feedbackStatus">Feedback Status: </mat-label>
        <input matInput name="feedbackStatus" id="feedbackStatus" [matAutocomplete]="feedbackStatusAuto"
            formControlName="status"/>
          <mat-autocomplete #feedbackStatusAuto="matAutocomplete" [displayWith]="feedbackStatusDisplay" autoActiveFirstOption (optionSelected)="filterFeedbacks()"
             panelWidth="280px">
            <mat-option class="options" *ngFor="let option of filteredFeedbackStatuses | async" [value]="option">
              {{ feedbackStatusDisplay(option) }}
            </mat-option>
          </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="mr-3 col-md-2 search" *ngIf="isAnonymousFilterVisible">
        <mat-label for="anonymous">Anonymous: </mat-label>
        <input matInput name="anonymous" id="anonymous" [matAutocomplete]="anonymousAuto"
            formControlName="anonymous"/>
          <mat-autocomplete #anonymousAuto="matAutocomplete" [displayWith]="anonymousDisplay" autoActiveFirstOption (optionSelected)="filterFeedbacks()"
             panelWidth="280px">
            <mat-option class="options" *ngFor="let option of filteredAnonymous | async" [value]="option">
              {{ anonymousDisplay(option) }}
            </mat-option>
          </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="row form-group" >

      <mat-form-field class="mr-3 col-md-3 search" [hideRequiredMarker]="hideAsterisk" *ngIf="!isOnRequestsPage && !isOnClientFeedbackOnlinePage && !isConfidentialFeedbackPage">
          <mat-label for="careerMentor">Career Mentor: </mat-label>
          <input matInput name="careerMentor" id="careerMentor" [matAutocomplete]="careerMentorAuto"
              formControlName="careerMentor"/>
          <mat-error *ngIf="searchCriteriaForm.get('careerMentor')?.hasError('required') && searchCriteriaForm.get('careerMentor')?.touched">
              Please enter the name of the Career Mentor.</mat-error>
            <mat-autocomplete #careerMentorAuto="matAutocomplete" [displayWith]="careerMentorDisplay" autoActiveFirstOption (optionSelected)="filterFeedbacks()"
              panelWidth="280px">
              <mat-option class="options" *ngFor="let option of filteredCareerMentors | async" [value]="option">
                {{ careerMentorDisplay(option) }}
              </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <div class="col-md-3">
          <div class="row">

          </div>
        </div>
      </div>
    <div class="row form-group" >
      <div class="col-md-3 padding-left-15">
        <button type="button" mat-stroked-button color="warn" class="reject-btn" (click)="resetForm()" [disabled]="!searchCriteriaForm.dirty">RESET</button>
      </div>
    </div>

</form>

<div *ngIf="isLoading" class="grid place-items-center inset-0">
  <mat-spinner></mat-spinner>
</div>

