<vex-secondary-toolbar current="Self Assessments">
  <vex-breadcrumbs [crumbs]="['My Feedback', 'Self Assessments']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="component-container">
  <div class="row m-0">
    <div class="col-md-8 col-sm-12 col-xs-12 feedback-form-div">
      <p class="mandatory-text" *ngIf="!isLoading">No Self Assessment found</p>

    </div>
  </div>
</div>
