<div class ="position-relative h-100">
    <div class="p-3 option-group-dialog-header">
        <span class="questions-dialog-title d-inline">{{config.title}}</span>
    </div>

    <div class="option-group-dialog-body">
        <mat-form-field class="option-group-input" appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput [formControl]="labelControl" placeholder="Group Name">
        </mat-form-field>
    </div>

    <mat-dialog-actions class="position-absolute">
        <button type="button" class="btn btn-action-dialog success-button mr-3" (click)="close()">CANCEL</button>
        <button type="button" class="btn btn-action-dialog success-button" (click)="save()">OK</button>
    </mat-dialog-actions>
</div>
