import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackFilterCriteriaFormComponent } from './feedback-filter-criteria-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';

@NgModule({
  declarations: [FeedbackFilterCriteriaFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
  ],
  exports: [FeedbackFilterCriteriaFormComponent],
})
export class FeedbackFilterCriteriaFormModule {}
