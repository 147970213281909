import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiErrorData } from 'src/app/shared/interfaces/api-error.interface';
import { CMSurvey } from 'src/app/shared/models/cm-survey.model';
import { environment } from 'src/environments/environment';
import { AuthorizedHttp } from './../services/authorized-http.service';

@Injectable({
  providedIn: 'root',
})
export class CmSurveyService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/cmsurvey`;

  constructor(private http: AuthorizedHttp) {}

  public getCmSurveyByYear(year: number): Observable<CMSurvey> {
    return this.http
      .get<CMSurvey>(`${this._apiEndpoint}/all/${year}`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getCMFeedbackYears(): Observable<number[]> {
    return this.http
      .get<number[]>(`${this._apiEndpoint}/years`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }
}
