import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RequestRemoteWorkWorkflowComponent } from './request-remote-work-workflow.component';
import { PendingChangesGuard } from 'src/app/core/guards/pending-changes-guard';

const routes: Routes = [
  {
    path: '',
    component: RequestRemoteWorkWorkflowComponent,
    pathMatch: 'full',
    canDeactivate: [PendingChangesGuard], // You can only place the canDeactivate guard in a module where `component` is defined: https://github.com/angular/angular/issues/16868#issuecomment-331037915
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RequestRemoteWorkWorkflowRoutingModule {}
