<vex-secondary-toolbar current="Home">
  <vex-breadcrumbs [crumbs]="['Home']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div class="component-container">
  <h5 class="text-center text-xl font-medium"><i>"{{currentQuote?.quote}}"</i> - {{currentQuote?.author}}</h5>
  <hr class="mt-5">
  <div *ngIf="isLoading" class="grid place-items-center absolute inset-0" >
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf="!isLoading" class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
    <ng-container *ngFor="let card of cards" >
      <a *ngIf="card.routeLink" class="cursor-pointer" [routerLink]="card.routeLink">
        <ng-template *ngTemplateOutlet="widget; context:{card : card}"></ng-template>
      </a>
      <div *ngIf="!card.routeLink" class="cursor-default pointer-events-none" >
        <ng-template *ngTemplateOutlet="widget; context:{card : card}"></ng-template>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #widget let-card="card">
  <app-widget-quick-value-center *ifRole="card.roles"
    [icon]="card.icon"
    [iconClass]="card.iconClass"
    [label]="card.label"
    [value]="card.value"
    [helpText]="card.helpText || '&nbsp;'"
  >
  </app-widget-quick-value-center>
</ng-template>
