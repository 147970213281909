import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  UntypedFormArray,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { LayoutService } from 'src/@vex/services/layout.service';
import {
  AssessmentService,
  ClientFeedbackRequest,
} from 'src/app/core/services/assessment.service';
import { Client, CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import * as formValidator from '../../../shared/validators/form-validator';

@Component({
  selector: 'app-request-client-feedback',
  templateUrl: './request-client-feedback.component.html',
  styleUrls: ['./request-client-feedback.component.scss'],
})
export class RequestClientFeedbackComponent implements OnInit {
  clientFeedbackForms!: UntypedFormArray;
  clients!: Client[];
  isLoading!: boolean;
  isSubmitting!: boolean;
  isCollapsed = true;
  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private assessmentService: AssessmentService,
    private snackBarService: SnackBarService,
    private layoutService: LayoutService
  ) {}

  ngOnInit() {
    this.clientFeedbackForms = this.formBuilder.array([]);
    this.getClients();
    this.layoutService.sidenavCollapsed$.subscribe(collapsed => {
      this.isCollapsed = collapsed;
    });
  }

  addClientForm() {
    const clientFeedbackForm = this.createRequestFeedbackForm();
    this.setClientValidators(clientFeedbackForm);
    this.clientFeedbackForms.push(clientFeedbackForm);
  }

  asUntypedFormGroup = (control: AbstractControl): UntypedFormGroup =>
    control as UntypedFormGroup;

  resetForms() {
    this.clientFeedbackForms.reset();
    this.clientFeedbackForms = this.formBuilder.array([]);
    this.getClients();
  }

  validateClient(selectedForm: FormGroup): ValidatorFn {
    return (clientControl: AbstractControl) => {
      selectedForm.controls['otherClient'].updateValueAndValidity();
      if (!clientControl.value || !clientControl.value.clientName) {
        const temp: ValidationErrors = {};
        temp['required'] = true;
        return temp;
      }
      return null;
    };
  }

  validateOtherClient(selectedForm: FormGroup): ValidatorFn {
    return (otherClientControl: AbstractControl) => {
      const isOtherClient =
        selectedForm.controls['client'].value &&
        selectedForm.controls['client'].value.clientName === 'Other';
      if (
        (!otherClientControl.value ||
          otherClientControl.value.trim().length <= 0) &&
        isOtherClient
      ) {
        const temp: ValidationErrors = {};
        temp['required'] = true;
        return temp;
      }
      return null;
    };
  }

  submitRequest() {
    this.isSubmitting = true;
    this.clientFeedbackForms.disable(); // Ideally you want to bind the disable property of the control to a function/property that will determine when to disable/enable the form.
    const clientRequests: ClientFeedbackRequest[] = [];

    this.clientFeedbackForms.controls.forEach(clientForm => {
      const clientRequest = this.createClientRequest(clientForm);
      clientRequests.push(clientRequest);
    });

    this.assessmentService.requestClientFeedback(clientRequests).subscribe(
      () => {
        this.snackBarService.message(
          'Request successfully submitted and an email has been sent to the client feedback admin'
        );
        this.isSubmitting = false;
        this.clientFeedbackForms.enable();
        this.resetForms();
      },
      error => {
        this.isSubmitting = false;
        this.clientFeedbackForms.enable();
        this.snackBarService.error(error);
      }
    );
  }

  removeForm(form: FormGroup) {
    if (
      this.clientFeedbackForms &&
      this.clientFeedbackForms.controls.length > 1
    ) {
      const index = this.clientFeedbackForms.controls.indexOf(form);
      if (index !== -1) {
        this.clientFeedbackForms.controls.splice(index, 1);
        this.clientFeedbackForms.updateValueAndValidity();
      }
    }
  }

  private getClients() {
    this.isLoading = true;
    this.commonService.getClientsExcludingDefault().subscribe(
      data => {
        this.clients = data;
        this.addClientForm();
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        this.snackBarService.error(error);
      }
    );
  }

  private setClientValidators(selectedForm: FormGroup) {
    selectedForm.controls['client'].setValidators(
      this.validateClient(selectedForm)
    );
    selectedForm.controls['otherClient'].setValidators(
      this.validateOtherClient(selectedForm)
    );
  }

  private createRequestFeedbackForm(): FormGroup {
    return this.formBuilder.group({
      client: [''],
      contactName: ['', formValidator.requiredAndNoWhiteSpaceValidator],
      contactRole: [''],
      contactEmail: ['', formValidator.requiredAndNoWhiteSpaceValidator],
      otherClient: [''],
      message: [''],
    });
  }

  private createClientRequest(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clientForm: AbstractControl<any, any>
  ): ClientFeedbackRequest {
    const feedbackRequest = new ClientFeedbackRequest();
    feedbackRequest.clientId = clientForm.value.client
      ? clientForm.value.client.clientId
      : null;
    feedbackRequest.contactName = clientForm.value.contactName
      ? clientForm.value.contactName
      : null;

    clientForm.value.message = clientForm.value.contactName + ' / ';
    if (clientForm.value.contactRole) {
      clientForm.value.message += clientForm.value.contactRole + ' / ';
    }
    clientForm.value.message += clientForm.value.contactEmail;

    const requestMessage = clientForm.value.message.replace(/\r?\n/g, '<br>');
    feedbackRequest.requestContext = requestMessage ? requestMessage : null;

    feedbackRequest.otherClient = clientForm.value.otherClient
      ? clientForm.value.otherClient
      : null;
    feedbackRequest.requestDate = new Date();
    return feedbackRequest;
  }
}
