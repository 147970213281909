import { Location } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  FbAnswer,
  SavedAssessment,
} from 'src/app/core/services/assessment.service';
import { CommonService } from 'src/app/core/services/common.service';
import {
  Question,
  QuestionOption,
} from 'src/app/core/services/feedback.service';
import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { FeedbackStatusEnum } from 'src/app/shared/enums/enums.model';

@Component({
  selector: 'app-cm-feedback-detail',
  templateUrl: './cm-feedback-detail.component.html',
  styleUrls: ['./cm-feedback-detail.component.scss'],
})
export class CmFeedbackDetailComponent implements OnInit, OnChanges {
  @Input() feedback!: SavedAssessment;
  @Input() feedbackTypeIdParam!: number;
  @Input() isFeedbackReceived!: boolean;
  @Input() operationInProgress!: string | null;

  questionOptions!: QuestionOption[];

  peopleCareComments!: string | null;
  mismatchedIDs = false;

  constructor(
    private location: Location,
    private commonService: CommonService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.operationInProgress = 'loading';
    this.commonService.getQuestionOptions().subscribe(result => {
      this.questionOptions = [...result];
      this.operationInProgress = null;
    });
  }

  ngOnChanges() {
    if (this.feedback) {
      if (this.feedbackTypeIdParam) {
        this.mismatchedIDs =
          this.feedbackTypeIdParam !== this.feedback.feedbackTypeId;
      }
      if (this.mismatchedIDs) {
        this.showConfirmationDialog();
      } else {
        this.getPeopleCareComments();
        if (
          this.feedback &&
          this.feedback.answers.find(c => c.optionId !== null)
        ) {
          this.feedback.answers.forEach(value => {
            if (
              !value.stringValue &&
              value.option !== null &&
              value.question.type.toString() !== 'Radio_Text'
            ) {
              value.stringValue = value.option?.value || '';
            }
          });
        }
      }
    }
  }

  goBack() {
    this.location.back();
  }

  getFeedbackStatusEnum(enumCode: number): string {
    return FeedbackStatusEnum[enumCode];
  }

  htmlDecode(input: string) {
    const txt = document.createElement('textarea');
    txt.innerHTML = input;
    return txt.value;
  }

  optionDisplay = (option?: QuestionOption): string | undefined => {
    const text = option?.value;
    return text;
  };

  validQuestionOptions(question: Question) {
    return this.questionOptions.filter(
      qo => qo.groupId === question.optionGroupId
    );
  }

  displayOptionDescription(answer: FbAnswer) {
    return this.questionOptions.find(qo => qo.id === answer.optionId)?.value;
  }

  getSliderTickInterval(question: Question) {
    const options = this.filterSortThisQuestionOption(question);
    const interval = options[1].score - options[0].score;
    return interval;
  }

  getIntervalMin(question: Question) {
    const minOption = this.filterSortThisQuestionOption(question);
    return minOption[0].score;
  }

  getIntervalMax(question: Question) {
    const maxOption = this.filterSortThisQuestionOption(question);
    return maxOption[maxOption.length - 1].score;
  }

  filterSortThisQuestionOption(question: Question): QuestionOption[] {
    return this.questionOptions
      .filter(qo => qo.groupId === question.optionGroupId)
      .sort((a, b) => a.score - b.score);
  }

  getIntervalTextValue(question: Question, minOrMax: number): string {
    let textValue: string;

    if (minOrMax === 0) {
      const minOption = this.filterSortThisQuestionOption(question);
      textValue = minOption[0].value;
    } else {
      const maxOption = this.filterSortThisQuestionOption(question);
      textValue = maxOption[maxOption.length - 1].value;
    }

    return textValue;
  }

  private showConfirmationDialog() {
    const confirmDialog = new ConfirmDialog();
    confirmDialog.title = 'Access Error';
    confirmDialog.message =
      "<p> You don't have access to this feedback using this section of the app.</p>";
    confirmDialog.okButtonTitle = 'Continue';
    // confirmDialog.cancelButtonTitle = "Cancel";
    this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: confirmDialog,
      disableClose: true,
    });
  }

  private getPeopleCareComments() {
    this.feedback.log.sort((a, b) =>
      a.changedDate < b.changedDate ? 1 : b.changedDate < a.changedDate ? -1 : 0
    );

    this.peopleCareComments = '';
    for (const audit of this.feedback.log) {
      if (audit.statusId === FeedbackStatusEnum.Posted && audit.description) {
        this.peopleCareComments +=
          'From: ' +
          audit.changedByEmployee.firstName +
          ' ' +
          audit.changedByEmployee.lastName +
          '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
          'Date: ' +
          new Date(audit.changedDate).toLocaleDateString('en-US') +
          '\n' +
          this.htmlDecode(audit.description) +
          '\n\n';
      }
    }
  }
}
