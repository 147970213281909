import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReviewFeedbackComponentRoutingModule } from './review-feedback-routing.module';
import { ReviewFeedbackComponent } from './review-feedback.component';
import { FeedbackDetailModule } from 'src/app/shared/modules/feedback-detail/feedback-detail.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';

@NgModule({
  declarations: [ReviewFeedbackComponent],
  imports: [
    CommonModule,
    ReviewFeedbackComponentRoutingModule,

    AngularMaterialModule,
    FeedbackDetailModule,
    SharedModule,
  ],
})
export class ReviewFeedbackComponentModule {}
