<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" [hidden]="isLoading || !(dataSource && dataSource.data.length > 0)">
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <mat-checkbox [checked]="selection.hasValue() && isAllSelected()"
        (change)="$event ? toggleSelectRows() : null">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let value" class="leftAlignCell">
      <mat-checkbox [checked]="selection.isSelected(value)"
        (click)="$event.stopPropagation()"
        (change)="$event ? selection.toggle(value) : null">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="practice">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Practice</th>
    <td mat-cell *matCellDef="let value" class="leftAlignCell" (click)="isRowClickable(value) && onSelect(value)">
      {{ value.practice }}
    </td>
  </ng-container>

  <ng-container matColumnDef="competencyName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Competency</th>
    <td mat-cell *matCellDef="let value" class="leftAlignCell" (click)="isRowClickable(value) && onSelect(value)">
      {{ value.competencyName}}
    </td>
  </ng-container>

  <ng-container matColumnDef="onliner">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Onliner</th>
    <td mat-cell *matCellDef="let value" class="leftAlignCell" (click)="isRowClickable(value) && onSelect(value)">
      {{ value.onliner }}
    </td>
  </ng-container>

  <ng-container matColumnDef="careerMentorName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Career Mentor</th>
    <td mat-cell *matCellDef="let value" class="leftAlignCell" (click)="isRowClickable(value) && onSelect(value)">
      {{ value.careerMentorName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="submittedDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fiscal Year</th>
    <td mat-cell *matCellDef="let value" class="leftAlignCell" (click)="isRowClickable(value) && onSelect(value)">
      {{ value.fiscalYear }}
    </td>
  </ng-container>

  <ng-container matColumnDef="assesmentAuditStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell *matCellDef="let value" class="leftAlignCell" (click)="isRowClickable(value) && onSelect(value)">
      {{ value.assesmentAuditStatus }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actualReviewerName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Reviewer</th>
    <td mat-cell *matCellDef="let value" class="leftAlignCell" (click)="isRowClickable(value) && onSelect(value)">
      {{ value.actualReviewerName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="reviewDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Review Date</th>
    <td mat-cell *matCellDef="let value" class="leftAlignCell" (click)="isRowClickable(value) && onSelect(value)">
      {{ value.reviewDate | date:'MM/dd/yyyy'}}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="resultColumnHeaders"></tr>
  <tr mat-row [ngClass]="{'mat-row-clickable':isRowClickable(row), 'disabled-row':!isRowClickable(row)}"
     *matRowDef="let row; columns: resultColumnHeaders"></tr>
</table>
<div *ngIf="dataSource && dataSource.data.length === 0 && !isLoading">No records found</div>
<div class="action-button-container">
  <button
    type="button"
    mat-stroked-button
    class="save-btn"
    color="primary"
    (click)="generatePdf()"
    [disabled]="!selection.hasValue() || selection.selected.length === 0">
    <span *ngIf="isProcessingPdf"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>
    Generate PDF
  </button>
</div>