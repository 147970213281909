import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, forkJoin } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { AutoSuggestService } from 'src/app/core/services/auto-suggest.service';
import { BaseFeedbackFilterService } from 'src/app/core/services/base-feedback-filter.service';
import {
  CareerMentor,
  CareerMentorService,
} from 'src/app/core/services/career-mentor.service';
import {
  Client,
  CommonService,
  SelectListItem,
  defaultClient,
} from 'src/app/core/services/common.service';
import { ConfidentialFeedbackFilterService } from 'src/app/core/services/confidential-feedback-filter.service';
import {
  AnonymousOption,
  FeedbackService,
  FeedbackStatus,
  FeedbackType,
} from 'src/app/core/services/feedback.service';
import {
  Employee,
  defaultOnliner,
} from 'src/app/core/services/onliner.service';
import { PracticeType } from 'src/app/core/services/self-assessment.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { FeedbackTypeEnum } from 'src/app/shared/enums/enums.model';
import { environment } from 'src/environments/environment';
import * as formValidator from '../../validators/form-validator';

@Component({
  selector: 'app-feedback-filter-criteria-form',
  templateUrl: './feedback-filter-criteria-form.component.html',
  styleUrls: ['./feedback-filter-criteria-form.component.scss'],
})
export class FeedbackFilterCriteriaFormComponent implements OnInit, OnChanges {
  @Input()
  searchCriteriaForm!: UntypedFormGroup;
  @Input()
  onlinerFilterMode!: string;
  @Input()
  currentCareerMentor!: CareerMentor;
  @Input()
  isOnMyTeamFeedbackPage!: boolean;
  @Input() isConfidentialFeedbackPage = false;
  @Input()
  isOnlinerFilterVisible!: boolean;
  @Input()
  isSubmittedByFilterVisible!: boolean;
  @Input() isSubmissionTypeFilterVisible = true; // default on for backwards compatibility
  @Input() isClientFilterVisible = true;
  @Input() isAnonymousFilterVisible = false; // default off for backwards compatibility
  @Input() isOnRequestsPage = false;
  @Input() isOnClientFeedbackOnlinePage = false;
  @Input() isOnProvidedPage = false;
  @Input() isCiaTypeVisible = false;
  @Input() isCMDelegate = false;
  @Output() filterDatesTriggered = new EventEmitter<boolean>();
  @Output() filterFeedbacksTriggered = new EventEmitter<boolean>();

  readonly OnlinerFilterModes_AllUsers = 'AllUsers';
  readonly OnlinerFilterModes_CurrentUser = 'CurrentUser';
  readonly OnlinerFilterModes_MentoringUsers = 'MentoringUsers';

  onliners!: Employee[];
  submittedBy!: Employee[];
  careerMentors!: CareerMentor[];
  submissionTypes!: SelectListItem[];
  clients!: Client[];
  practices!: string[];
  feedbackTypes!: FeedbackType[];
  feedbackStatuses!: FeedbackStatus[];
  anonymousOptions!: AnonymousOption[];
  onFilter = true;

  includedDefaultOnliner = false;
  includesDefaultClient = false;
  includedDefaultSubmittedBy = false;
  includedDefaultCareerMentor = false;
  includedDefaultSubmissionType = false;

  get onlinerControl() {
    return this.searchCriteriaForm.controls['onliner'];
  }
  get submittedByControl() {
    return this.searchCriteriaForm.controls['submittedBy'];
  }
  get clientControl() {
    return this.searchCriteriaForm.controls['client'];
  }
  get feedbackTypeControl() {
    return this.searchCriteriaForm.controls['feedbackType'];
  }
  get feedbackStatusControl() {
    return this.searchCriteriaForm.controls['status'];
  }
  get careerMentorControl() {
    return this.searchCriteriaForm.controls['careerMentor'];
  }
  get submissionTypeControl() {
    return this.searchCriteriaForm.controls['submissionType'];
  }
  get anonymousControl() {
    return this.searchCriteriaForm.controls['anonymous'];
  }
  get practiceControl() {
    return this.searchCriteriaForm.controls['practice'];
  }

  filteredOnliners!: Observable<Employee[]> | Observable<CareerMentor[]>;
  filteredCareerMentors!: Observable<CareerMentor[]>;
  filteredSubmissionTypes!: Observable<SelectListItem[]>;
  filteredSubmittedBy!: Observable<Employee[]> | Observable<CareerMentor[]>;
  filteredClients!: Observable<Client[]>;
  filteredFeedbackTypes!: Observable<FeedbackType[]>;
  filteredFeedbackStatuses!: Observable<FeedbackStatus[]>;
  filteredAnonymous!: Observable<AnonymousOption[]>;

  minDate: Date = new Date(new Date().getFullYear() - 5, 1, 1);
  isLoading!: boolean;
  isSearching!: boolean;

  hideAsterisk = false;

  constructor(
    private commonService: CommonService,
    private feedbackService: FeedbackService,
    private confidentialFeedbackService: ConfidentialFeedbackFilterService,
    private careerMentorService: CareerMentorService,
    private snackBarService: SnackBarService,
    private autoSuggestService: AutoSuggestService,
    private authService: AuthService,
    private feedbackFilterService: BaseFeedbackFilterService
  ) {}

  ngOnInit() {
    return;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchCriteriaForm && changes.searchCriteriaForm.currentValue) {
      this.getDropdownsData();
    }
  }
  resetForm() {
    if (this.isOnRequestsPage) {
      this.feedbackFilterService.resetRequestForm();
    } else if (this.isOnClientFeedbackOnlinePage) {
      this.feedbackFilterService.resetClientFeedbackOnlineForm();
    } else if (this.isConfidentialFeedbackPage) {
      this.confidentialFeedbackService.resetForm();
    } else {
      this.feedbackFilterService.resetForm(
        this.currentCareerMentor,
        this.isOnMyTeamFeedbackPage
      );
    }

    this.filterDates();
    this.filterFeedbacks();
  }

  setOnlinerFilter() {
    if (
      !(
        this.isOnMyTeamFeedbackPage ||
        this.isOnRequestsPage ||
        this.isOnClientFeedbackOnlinePage ||
        this.isOnProvidedPage ||
        this.isSubmittedByFilterVisible
      )
    ) {
      this.onFilter = false;
    } else {
      this.onFilter = true;
    }

    this.filteredOnliners = this.autoSuggestService.setOnlinerFilter(
      this.onlinerControl,
      this.onliners,
      this.includedDefaultOnliner,
      this.onFilter
    );
  }

  setSubmittedByFilter() {
    if (
      !(
        this.isOnMyTeamFeedbackPage ||
        this.isOnRequestsPage ||
        this.isOnClientFeedbackOnlinePage ||
        this.isOnProvidedPage ||
        this.isSubmittedByFilterVisible
      )
    ) {
      this.onFilter = false;
    } else {
      this.onFilter = true;
    }

    this.filteredSubmittedBy = this.autoSuggestService.setOnlinerFilter(
      this.submittedByControl,
      this.submittedBy,
      this.includedDefaultSubmittedBy,
      this.onFilter
    );
  }

  setClientFilter() {
    if (
      !(
        this.isOnMyTeamFeedbackPage ||
        this.isOnRequestsPage ||
        this.isOnClientFeedbackOnlinePage ||
        this.isOnProvidedPage ||
        this.isSubmittedByFilterVisible
      )
    ) {
      this.onFilter = false;
    } else {
      this.onFilter = true;
    }

    this.filteredClients = this.autoSuggestService.setClientFilter(
      this.clientControl,
      this.clients,
      this.includesDefaultClient,
      this.onFilter
    );
  }

  setFeedbackStatusFilter = () =>
    (this.filteredFeedbackStatuses =
      this.autoSuggestService.setFeedbackStatusFilter(
        this.feedbackStatusControl,
        this.feedbackStatuses
      ));

  setCareerMentorFilter() {
    if (
      !this.isOnMyTeamFeedbackPage &&
      this.isOnRequestsPage &&
      this.isOnClientFeedbackOnlinePage &&
      !this.isOnProvidedPage &&
      !this.isSubmittedByFilterVisible
    ) {
      this.onFilter = false;
    } else if (
      this.isOnMyTeamFeedbackPage &&
      (this.authService.doesUserHaveRole([environment.roles.FeedbackAdmin]) ||
        this.authService.doesUserHaveRole([environment.roles.FeedbackLead]) ||
        this.isCMDelegate ||
        this.isSubmittedByFilterVisible ||
        this.isOnProvidedPage ||
        this.isAnonymousFilterVisible)
    ) {
      // also need to add check for delegate
      this.onFilter = true;
    } else {
      this.onFilter = false;
    }

    this.filteredCareerMentors = this.autoSuggestService.setOnlinerFilter(
      this.careerMentorControl,
      this.careerMentors,
      this.includedDefaultCareerMentor,
      this.onFilter
    );
  }

  setSubmissionTypeFilter = () =>
    (this.filteredSubmissionTypes = this.autoSuggestService.setSelectListFilter(
      this.submissionTypeControl,
      this.submissionTypes
    ));

  setAnonymousFilter = () =>
    (this.filteredAnonymous = this.autoSuggestService.setAnonymousFilter(
      this.anonymousControl,
      this.anonymousOptions
    ));

  // setPracticeFilter = () => this.autoSuggestService.setPracticeFilter(this.practiceControl, this.practices);

  practiceTypeDisplay = (option?: PracticeType): string =>
    this.autoSuggestService.practiceTypeDisplay(option);

  onlinerDisplay = (option?: Employee | CareerMentor): string =>
    this.autoSuggestService.onlinerDisplay(option);

  submissionTypeDisplay = (option?: SelectListItem): string =>
    this.autoSuggestService.submissionTypeDisplay(option);

  careerMentorDisplay = (option?: CareerMentor): string =>
    this.autoSuggestService.careerMentorDisplay(option);

  clientDisplay = (option?: Client): string =>
    this.autoSuggestService.clientDisplay(option);

  feedbackStatusDisplay = (option?: FeedbackStatus): string =>
    this.autoSuggestService.feedbackStatusDisplay(option);

  anonymousDisplay = (option?: AnonymousOption): string =>
    this.autoSuggestService.anonymousDisplay(option);

  filterDates() {
    if (this.searchCriteriaForm.valid) {
      this.filterDatesTriggered.emit(true);
    }
  }

  filterFeedbacks() {
    if (this.searchCriteriaForm.valid) {
      this.filterFeedbacksTriggered.emit(true);
    }
  }

  private getDropdownsData() {
    this.isLoading = true;

    if (this.isOnRequestsPage) {
      this.feedbackService.getAdditionalProvidedFeedackStatuses().subscribe(
        data => {
          this.setUpDropDownDataForRequestPage(data);
        },
        error => {
          this.isLoading = false;
          this.snackBarService.error(error);
        }
      );
    } else if (this.isOnClientFeedbackOnlinePage) {
      this.commonService.getClients().subscribe(
        data => {
          this.setUpDropDownDataForClientFeedbackOnlinePage(data);
        },
        error => {
          this.isLoading = false;
          this.snackBarService.error(error);
        }
      );
    } else if (this.isConfidentialFeedbackPage) {
      forkJoin([
        this.commonService.getEmployeesIncludingInactive(),
        this.commonService.getPractices(),
      ]).subscribe(
        data => {
          this.setUpDropDownDataForConfidential(data);
        },
        error => {
          this.isLoading = false;
          this.snackBarService.error(error);
        }
      );
    } else {
      forkJoin([
        this.commonService.getEmployeesIncludingInactive(),
        this.commonService.getClients(),
        this.feedbackService.getProvidedFeedackStatuses(),
        this.careerMentorService.getCareerMentorsIncludingInactive(),
        this.commonService.getSubmissionTypes(),
      ]).subscribe(
        data => {
          this.setUpDropDownData(data);
        },
        error => {
          this.isLoading = false;
          this.snackBarService.error(error);
        }
      );
    }
  }

  private setUpDropDownDataForClientFeedbackOnlinePage(data: Client[]) {
    this.clients = data;
    this.includesDefaultClient = this.clients.includes(defaultClient);

    this.setClientFilter();
    this.setClientValidators();

    this.isLoading = false;
  }

  private setUpDropDownDataForRequestPage(data: FeedbackStatus[]) {
    this.feedbackStatuses = data;
    this.setFeedbackStatusFilter();

    this.isLoading = false;
  }

  private setUpDropDownDataForConfidential(data: [Employee[], string[]]) {
    const ONLINER_IX = 0,
      PRACTICE_IX = 1;
    this.onliners = data[ONLINER_IX];
    this.includedDefaultOnliner =
      this.onlinerFilterMode !== this.OnlinerFilterModes_CurrentUser &&
      !this.onliners.includes(defaultOnliner);
    this.setOnlinerFilter();

    this.submittedBy = data[ONLINER_IX];
    this.includedDefaultSubmittedBy =
      !this.submittedBy.includes(defaultOnliner);
    this.setSubmittedByFilter();

    this.practices = data[PRACTICE_IX];
    // this.setPracticeFilter();

    this.isLoading = false;
  }

  private setUpDropDownData(
    data: [
      Employee[],
      Client[],
      FeedbackStatus[],
      CareerMentor[],
      SelectListItem[],
    ]
  ) {
    const ONLINER_IX = 0,
      CLIENT_IX = 1,
      FEEDBACK_STATUS_IX = 2,
      FEEDBACK_CAREER_MENTOR_IX = 3,
      FEEDBACK_SUBMISSION_IX = 4;
    if (this.isOnlinerFilterVisible) {
      switch (this.onlinerFilterMode) {
        case this.OnlinerFilterModes_MentoringUsers: {
          this.onliners = data[ONLINER_IX].filter(
            e => e.careerManager === this.authService.getUserId().toLowerCase()
          );
          break;
        }
        case this.OnlinerFilterModes_AllUsers: {
          this.onliners = data[ONLINER_IX];
          break;
        }
        case this.OnlinerFilterModes_CurrentUser: {
          this.onliners = data[ONLINER_IX].filter(
            e => e.userId === this.authService.getUserId().toLowerCase()
          );
          break;
        }
        default:
          this.onliners = [];
      }

      this.includedDefaultOnliner =
        this.onlinerFilterMode !== this.OnlinerFilterModes_CurrentUser &&
        !this.onliners.includes(defaultOnliner);
      this.setOnlinerFilter();
    }

    if (this.isSubmittedByFilterVisible) {
      this.submittedBy = data[ONLINER_IX];
      this.includedDefaultSubmittedBy =
        !this.submittedBy.includes(defaultOnliner);
      this.setSubmittedByFilter();
    }

    if (this.isClientFilterVisible) {
      this.clients = data[CLIENT_IX];
      this.includesDefaultClient = !this.clients.includes(defaultClient);
      this.setClientFilter();
      this.setClientValidators();
    }

    this.careerMentors = data[FEEDBACK_CAREER_MENTOR_IX].sort((a, b) =>
      `${a.firstName} ${a.lastName}`.localeCompare(
        `${b.firstName} ${b.lastName}`
      )
    );
    this.includedDefaultCareerMentor =
      !this.careerMentors.includes(defaultOnliner);
    this.setCareerMentorFilter();

    if (this.isSubmissionTypeFilterVisible) {
      this.submissionTypes = data[FEEDBACK_SUBMISSION_IX];
      if (this.isOnProvidedPage) {
        this.submissionTypes = this.submissionTypes.filter(
          s => s.id !== FeedbackTypeEnum.ClientFeedback
        );
        if (!this.isCiaTypeVisible) {
          this.submissionTypes = this.submissionTypes.filter(
            s => s.id !== FeedbackTypeEnum.CultureInAction
          );
        }
      }
      this.setSubmissionTypeFilter();
    }

    this.feedbackStatuses = data[FEEDBACK_STATUS_IX];
    this.setFeedbackStatusFilter();

    if (this.isAnonymousFilterVisible) {
      this.anonymousOptions = [
        { value: true, anonymousDesc: 'Yes' },
        { value: false, anonymousDesc: ' No' },
      ];
      this.setAnonymousFilter();
    }

    this.isLoading = false;
  }

  private setClientValidators() {
    this.searchCriteriaForm.controls['client'].setValidators(
      formValidator.validateClient()
    );
  }
}
