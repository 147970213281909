import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { SavedFeedbackRoutingModule } from './saved-feedback-routing.module';
import { SavedFeedbackComponent } from './saved-feedback.component';

@NgModule({
  declarations: [SavedFeedbackComponent],
  imports: [
    CommonModule,
    SavedFeedbackRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    AngularMaterialModule,
  ],
})
export class SavedFeedbackModule {}
