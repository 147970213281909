import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import {
  Question,
  QuestionGroup,
  QuestionOption,
  QuestionOptionGroup,
} from 'src/app/core/services/feedback.service';
import { QuestionsConfigService } from 'src/app/core/services/questions-config.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { QuestionTypeEnum } from 'src/app/shared/enums/enums.model';

@Component({
  selector: 'app-question-detail',
  templateUrl: './question-detail.component.html',
  styleUrls: [
    './question-detail.component.scss',
    '../questions-config.component.scss',
  ],
})
export class QuestionDetailComponent implements OnInit {
  @Input()
  selectedQuestion!: Question;
  @Input()
  title!: string;
  @Input()
  selectedQuestionGroup!: QuestionGroup;
  @Input()
  numQuestions!: number;
  @Output() configOptionGroup = new EventEmitter<{
    optionGroup: QuestionOptionGroup;
    title: string;
  }>();
  @Output() return = new EventEmitter<void>();
  options!: QuestionOption[];
  questionOptionGroups!: QuestionOptionGroup[];
  questionForm!: FormGroup;
  loading = true;
  loadingOptions = true;
  questionTypes = Object.keys(QuestionTypeEnum).filter(key =>
    isNaN(Number(key))
  );
  previousIndex!: number;
  dragDisabled = true;
  addOptionGroupTooltip = 'Add new Option Group';
  editOptionGroupTooltip = 'Edit Option Group';
  dragHandleTooltip = 'Drag to reorder';
  changedOrder = false;

  answerColumns: string[] = ['option', 'score'];

  constructor(
    private fb: FormBuilder,
    private questionsConfigService: QuestionsConfigService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit() {
    this.setUpComponent();
  }

  onNewSelect(event: MatSelectChange) {
    this.loadingOptions = true;
    this.selectedQuestion.optionGroup = this.questionOptionGroups.find(
      group => group === event.value
    );
    this.selectedQuestion.optionGroupId = event.value.id;
    this.questionsConfigService
      .getAllOptionsFromOptionGroup(event.value.id)
      .subscribe(questionOptions => {
        this.options = questionOptions;

        this.loadingOptions = false;
      });
  }

  onNewTypeSelect(event: MatSelectChange) {
    this.selectedQuestion.type = event.value;

    if (this.usesOptionGroups()) {
      this.questionForm.addControl(
        'optionGroupControl',
        this.fb.control('', [Validators.required])
      );
      this.loading = false;
    } else {
      this.questionForm.removeControl('optionGroupControl');
      this.selectedQuestion.optionGroup = null;

      this.loadingOptions = true;
      this.loading = false;
    }
  }

  close() {
    this.return.emit();
  }

  save() {
    if (this.questionForm.dirty) {
      this.selectedQuestion.question = this.questionForm.value.questionControl;
      this.selectedQuestion.type = this.questionForm.value.typeControl;
      this.selectedQuestion.isActive = this.questionForm.value.activeControl;
      this.selectedQuestion.isOptional =
        this.questionForm.value.optionalControl;
      this.selectedQuestion.groupId = this.selectedQuestionGroup.id;

      if (this.usesOptionGroups()) {
        this.selectedQuestion.optionGroupId =
          this.questionForm.value.optionGroupControl.id;
      } else {
        this.selectedQuestion.optionGroupId = null;
      }

      if (this.isNewQuestion) {
        this.selectedQuestion.sortOrder = this.numQuestions;
        this.questionsConfigService
          .addQuestion(this.selectedQuestion)
          .subscribe(
            (data: Question) => {
              this.selectedQuestion = data;
              this.questionForm.markAsPristine();
              this.snackBarService.message('Question Added');
            },
            (error: string) => {
              this.snackBarService.error(error);
            }
          );
      } else {
        this.questionsConfigService
          .updateQuestion(this.selectedQuestion)
          .subscribe(
            (data: Question) => {
              this.selectedQuestion = data;
              this.questionForm.markAsPristine();
              this.snackBarService.message('Question Saved');
            },
            (error: string) => {
              this.snackBarService.error(error);
            }
          );
      }
    }
  }

  get isNewQuestion(): boolean {
    return this.selectedQuestion.id === undefined;
  }

  usesOptionGroups(): boolean {
    const questionType = this.questionForm.value.typeControl;
    return (
      questionType === QuestionTypeEnum[QuestionTypeEnum.Radio] ||
      questionType === QuestionTypeEnum[QuestionTypeEnum.Radio_Text] ||
      questionType === QuestionTypeEnum[QuestionTypeEnum.Scale]
    );
  }

  toggleActive(whichQuestion: QuestionOption) {
    whichQuestion.isActive = !whichQuestion.isActive;
  }

  isInvalidScale(): boolean {
    const options = this.options;
    const type = this.selectedQuestion.type.toString();

    if (
      type === 'Scale' &&
      options &&
      !options.every((option: QuestionOption) => option.score !== null)
    ) {
      return true;
    }

    return false;
  }

  editOptionGroup() {
    this.selectedQuestion.optionGroup = this.questionOptionGroups.find(
      group => group === this.questionForm.value.optionGroupControl
    );

    this.configOptionGroup.emit({
      optionGroup: this.questionForm.value.optionGroupControl,
      title: 'Edit Option Group',
    });
  }

  addOptionGroup() {
    this.selectedQuestion.optionGroup = this.questionOptionGroups.find(
      group => group === this.questionForm.value.optionGroupControl
    );

    this.configOptionGroup.emit({
      optionGroup: new QuestionOptionGroup(),
      title: 'Add Option Group',
    });
  }

  get isSaveDisabled(): boolean {
    return (
      this.questionForm.pristine ||
      !this.questionForm.valid ||
      this.isInvalidScale()
    );
  }

  get hasUnsavedChanges(): boolean {
    return !this.questionForm.pristine;
  }

  private setUpComponent() {
    this.questionsConfigService.getQuestionOptionGroup().subscribe(
      (QOGroupData: QuestionOptionGroup[]) => {
        this.questionOptionGroups = QOGroupData;

        if (!this.isNewQuestion) {
          this.questionForm = this.fb.group({
            questionControl: [this.selectedQuestion.question],
            typeControl: [this.selectedQuestion.type],
            activeControl: [this.selectedQuestion.isActive],
            optionalControl: [this.selectedQuestion.isOptional],
          });

          if (this.usesOptionGroups()) {
            this.loadingOptions = true;

            if (this.selectedQuestion.optionGroup === null) {
              this.questionForm.addControl(
                'optionGroupControl',
                this.fb.control('', [Validators.required])
              );
              this.loading = false;
            } else {
              this.questionsConfigService
                .getAllOptionsFromOptionGroup(
                  this.selectedQuestion?.optionGroup?.id
                )
                .subscribe(
                  options => {
                    this.options = options;

                    this.questionForm.addControl(
                      'optionGroupControl',
                      this.fb.control(
                        this.questionOptionGroups.find(
                          group =>
                            group.id === this.selectedQuestion?.optionGroup?.id
                        ),
                        [Validators.required]
                      )
                    );

                    this.loadingOptions = false;
                    this.loading = false;
                  },
                  (error: string) => {
                    this.snackBarService.error(error);
                    this.loadingOptions = false;
                    this.loading = false;
                  }
                );
            }
          } else {
            this.loading = false;
          }
        } else {
          this.questionForm = this.fb.group({
            optionGroupControl: ['', Validators.required],
            activeControl: [false],
            optionalControl: [false],
            typeControl: ['', Validators.required],
            questionControl: ['', Validators.required],
          });

          this.loading = false;
        }
      },
      (error: string) => {
        this.snackBarService.error(error);
        this.loading = false;
      }
    );
  }
}
