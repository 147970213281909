import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientFeedbackOnOnlineRoutingModule } from './client-feedback-on-online-routing.module';
import { ClientFeedbackOnOnlineComponent } from './client-feedback-on-online.component';
import { FeedbackFilterCriteriaFormModule } from 'src/app/shared/modules/feedback-filter-criteria-form/feedback-filter-criteria-form.module';
import { ClientFeedbackOnlineListModule } from './client-feedback-online-list/client-feedback-online-list.module';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';

@NgModule({
  declarations: [ClientFeedbackOnOnlineComponent],
  imports: [
    CommonModule,
    ClientFeedbackOnOnlineRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    AngularMaterialModule,

    FeedbackFilterCriteriaFormModule,
    ClientFeedbackOnlineListModule,
  ],
})
export class ClientFeedbackOnOnlineModule {}
