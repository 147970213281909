import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { Observable, filter, of } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  state: RouterStateSnapshot
): Observable<boolean> | boolean => {
  const authService: AuthService = inject(AuthService);
  const msalBroadcastService: MsalBroadcastService =
    inject(MsalBroadcastService);
  const router: Router = inject(Router);

  if (route.data?.isIgnoreRoute === true) {
    return true;
  }

  return msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None)
    )
    .pipe(() => {
      const currentAccount: AccountInfo | null = authService.getUserProfile();
      if (!currentAccount) {
        return of(true); // let MsalGuard to manage login redirect
      }

      const allowedRoles = route.data?.roles;
      if (!allowedRoles || allowedRoles.length === 0) {
        return of(true);
      } // all logged in users are allowed

      // check if user has enough permission
      const userRoles: string[] = currentAccount.idTokenClaims?.roles || [];
      if (userRoles.some(r => allowedRoles.includes(r))) {
        return of(true);
      }

      // otherwise, this route is forbidden
      router.navigate(['/forbidden']);
      return of(false);
    });
};
