import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CmSurveyDashboardRoutingModule } from './cm-survey-dashboard-routing.module';
import { CmSurveyDashboardComponent } from './cm-survey-dashboard.component';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';

@NgModule({
  declarations: [CmSurveyDashboardComponent],
  imports: [
    CommonModule,
    CmSurveyDashboardRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class CmSurveyDashboardModule {}
