import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  FbAnswer,
  FbQuestion,
  SavedAssessment,
} from 'src/app/core/services/assessment.service';
import { CmSurveyService } from 'src/app/core/services/cm-survey.service';
import {
  CmFeedbackQuantitativeData,
  FeedbackService,
} from 'src/app/core/services/feedback.service';

@Component({
  selector: 'app-cm-feedback-received',
  templateUrl: './cm-feedback-received.component.html',
  styleUrls: ['./cm-feedback-received.component.scss'],
})
export class CmFeedbackReceivedComponent implements OnInit {
  @ViewChild(MatSort, { static: true })
  sort!: MatSort;

  dataSource!: MatTableDataSource<SavedAssessment>;

  isLoading = true;

  firstYear = 2020;
  currentYear = new Date().getFullYear();
  selectedYear = this.currentYear;
  surveyYears: number[] = [];
  quantitativeData!: CmFeedbackQuantitativeData[];
  qualitativeQuestions!: FbQuestion[];
  feedbacks!: SavedAssessment[];

  displayedColumns!: string[];

  constructor(
    private feedbackService: FeedbackService,
    private cmSurveyService: CmSurveyService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getMyReceivedCmFeedbacks(this.currentYear);
    this.getSurveyYears();
  }

  getMyReceivedCmFeedbacks = (year: number) => {
    this.feedbackService.getMyReceivedCmFeedbacks(year).subscribe(result => {
      this.quantitativeData = [...result.quantitativeData];
      this.qualitativeQuestions = [...result.qualitativeQuestions];
      this.feedbacks = [...result.cmFeedbacks];
      this.feedbacks.forEach(f => {
        if (f.isAnonymous) {
          f.submittedByName = 'Anonymous';
        }
      });
      this.dataSource = new MatTableDataSource<SavedAssessment>(this.feedbacks);
      this.dataSource.sort = this.sort;
      if (this.qualitativeQuestions) {
        this.displayedColumns = [
          'submittedByName',
          'submittedDate',
          ...this.qualitativeQuestions.map(q => `${q.id}`),
        ];
      }
      this.isLoading = false;
    });
  };

  getSurveyYears() {
    this.cmSurveyService.getCMFeedbackYears().subscribe(years => {
      this.surveyYears = years;
    });
  }

  displayAnswer(answers: FbAnswer[], questionId: number): string {
    const answer = answers.find(a => a.questionId === questionId);
    const result = !answer || !answer.stringValue ? 'N/A' : answer.stringValue;
    return this.htmlDecode(result);
  }

  isTooltipDisabled(numScores: number, averageScore: number): boolean {
    return numScores >= 2 && averageScore !== 0;
  }

  displayScore(numScores: number, averageScore: number): string {
    return numScores >= 2 && averageScore !== 0
      ? `${Math.round((averageScore + Number.EPSILON) * 100) / 100}`
      : 'N/A';
  }

  htmlDecode(input: string) {
    const txt = document.createElement('textarea');
    txt.innerHTML = input;
    return txt.value;
  }

  onSelect(selected: SavedAssessment) {
    this.router.navigate(['viewCmFeedback', selected.id]);
  }
}
