import {
  EnvironmentProviders,
  ModuleWithProviders,
  NgModule,
  Provider,
  Type,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { CookieService } from 'ngx-cookie-service';
import { QuicklinkModule } from 'ngx-quicklink';
import { ToastrModule } from 'ngx-toastr';
import { CommentDialogComponent } from 'src/app/shared/components/comment-dialog/comment-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';
import { VexModule } from '../@vex/vex.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularMaterialModule } from './core/angular-material/angular-material.module';
import { CustomLayoutModule } from './core/custom-layout/custom-layout.module';
import { PendingChangesGuard } from './core/guards/pending-changes-guard';
import { AuthService } from './core/services/auth.service';
import { AuthorizedHttp } from './core/services/authorized-http.service';
import { AutoSuggestService } from './core/services/auto-suggest.service';
import { BaseFeedbackFilterService } from './core/services/base-feedback-filter.service';
import { CareerMentorService } from './core/services/career-mentor.service';
import { ClientFeedbackService } from './core/services/client-feedback.service';
import { CmFeedbackFilterService } from './core/services/cm-feedback-filter.service';
import { FeedbackFilterService } from './core/services/feedback-filter.service';
import { FeedbackReviewService } from './core/services/feedback-review.service';
import { FeedbackService } from './core/services/feedback.service';
import { MyTeamFeedbackFilterService } from './core/services/my-team-feedback-filter.service';
import { OnlinerService } from './core/services/onliner.service';
import { RemoteWorkConfigService } from './core/services/remote-work-config.service';
import { RemoteWorkRequestFilterService } from './core/services/remote-work-request-filter.service';
import { RemoteWorkRequestService } from './core/services/remote-work-request.service';
import { SnackBarService } from './core/services/snackbar.service';
import { UserService } from './core/services/user.service';
import { UtilityService } from './core/services/utility.service';
import { SessionExpiredDialogComponent } from './shared/components/session-expired-dialog/session-expired-dialog.component';
import { UpdateDialogComponent } from './shared/components/update-dialog/update-dialog.component';
import { SharedModule } from './shared/shared.module';

function loggerCallback(logLevel: LogLevel, message: string) {
  console.log('MSAL Angular: ', message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.webApiClientId,
      authority: environment.cloudInstanceId + environment.tenant,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string> | null>();
  protectedResourceMap.set(
    `${environment.apiEndpoint}/api/assessment/external`,
    null
  ); // skip authentication for public API endpoints.
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'User.Read',
  ]);
  protectedResourceMap.set(environment.apiEndpoint, [
    `${environment.webApiClientId}/.default`,
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['User.Read'],
    },
    loginFailedRoute: '/login-failed',
  };
}

export const declarations: (object[] | Type<object>)[] = [
  ConfirmationDialogComponent,
  CommentDialogComponent,
  UpdateDialogComponent,
  SessionExpiredDialogComponent,
];

export const imports: (
  | object[]
  | Type<object>
  | ModuleWithProviders<object>
)[] = [
  BrowserModule,
  AppRoutingModule,
  BrowserAnimationsModule,
  HttpClientModule,
  // Angular
  CommonModule,
  AngularMaterialModule,
  FormsModule,
  ReactiveFormsModule,
  QuicklinkModule,
  // Vex
  VexModule,
  CustomLayoutModule,
  // MSAL
  MsalModule,
  // Custom
  SharedModule,
  ToastrModule.forRoot(),
];

export const providers: Array<Provider | EnvironmentProviders> = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true,
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory,
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory,
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory,
  },
  MsalService,
  MsalGuard,
  MsalBroadcastService,
  AuthorizedHttp,
  AuthService,
  // AuthGuard,
  PendingChangesGuard,
  FeedbackService,
  ClientFeedbackService,
  FeedbackReviewService,
  OnlinerService,
  CookieService,
  SnackBarService,
  FeedbackFilterService,
  MyTeamFeedbackFilterService,
  CmFeedbackFilterService,
  AutoSuggestService,
  CareerMentorService,
  DatePipe,
  UserService,
  RemoteWorkRequestService,
  RemoteWorkRequestFilterService,
  RemoteWorkConfigService,
  UtilityService,
  { provide: BaseFeedbackFilterService, useExisting: FeedbackFilterService },
];

@NgModule({
  declarations: [AppComponent, ...declarations],
  imports,
  providers,
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
