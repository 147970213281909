
<div
  *ngIf="legacyFeedbackComment?.strengthsAccomplishments || legacyFeedbackComment?.areasForDevelopment ||
        legacyFeedbackComment?.additionalItems || legacyFeedbackComment?.clientResponsibilities ||
        legacyFeedbackComment?.clientAccomplishments || legacyFeedbackComment?.corpContributions ||
        legacyFeedbackComment?.corpAreaForDevelopment || legacyFeedbackComment?.corpObjectives"
  class="form-group row">
  <div class="col-md-12 pl-4 pr-4">
    <span class="label-header">{{legacyFeedbackComment.commentType.commentTypeDescr}}</span>
    <mat-card appearance="outlined">
      <mat-card-content>
        <span [innerHTML]="commentTypeDetail"></span>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div *ngIf="legacyFeedbackComment?.strengthsAccomplishments">
  <div class="row form-group">
    <div class="col-md-12 pl-4 pr-4">
      <span class="label-header">Strengths/Accomplishments</span>
    </div>
    <div class="col-md-12 pl-4 pr-4">
      <mat-card appearance="outlined">
        <mat-card-content>
          {{legacyFeedbackComment.strengthsAccomplishments}}
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="legacyFeedbackComment?.areasForDevelopment">
  <div class="row form-group">
    <div class="col-md-12 pl-4 pr-4">
      <span class="label-header">Areas for Development</span>
    </div>
    <div class="col-md-12 pl-4 pr-4">
      <mat-card appearance="outlined">
        <mat-card-content>
          {{legacyFeedbackComment.areasForDevelopment}}
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="legacyFeedbackComment?.additionalItems">
  <div class="row form-group">
    <div class="col-md-12 pl-4 pr-4">
      <span class="label-header">Action Items</span>
    </div>
    <div class="col-md-12 pl-4 pr-4">
      <mat-card appearance="outlined">
        <mat-card-content>
          {{legacyFeedbackComment.additionalItems}}
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="legacyFeedbackComment?.clientResponsibilities">
  <div class="row form-group">
    <div class="col-md-12 pl-4 pr-4">
      <span class="label-header">Client Responsibilities</span>
    </div>
    <div class="col-md-12 pl-4 pr-4">
      <mat-card appearance="outlined">
        <mat-card-content>
          {{legacyFeedbackComment.clientResponsibilities}}
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="legacyFeedbackComment?.clientAccomplishments">
  <div class="row form-group">
    <div class="col-md-12 pl-4 pr-4">
      <span class="label-header">Client Accomplishments</span>
    </div>
    <div class="col-md-12 pl-4 pr-4">
      <mat-card appearance="outlined">
        <mat-card-content>
          {{legacyFeedbackComment.clientAccomplishments}}
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="legacyFeedbackComment?.corpContributions">
  <div class="row form-group">
    <div class="col-md-12 pl-4 pr-4">
      <span class="label-header">Contributions</span>
    </div>
    <div class="col-md-12 pl-4 pr-4">
      <mat-card appearance="outlined">
        <mat-card-content>
          {{legacyFeedbackComment.corpContributions}}
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="legacyFeedbackComment?.corpAreaForDevelopment">
  <div class="row form-group">
    <div class="col-md-12 pl-4 pr-4">
      <span class="label-header">Areas for Development</span>
    </div>
    <div class="col-md-12 pl-4 pr-4">
      <mat-card appearance="outlined">
        <mat-card-content>
          {{legacyFeedbackComment.corpAreaForDevelopment}}
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="legacyFeedbackComment?.corpObjectives">
  <div class="row form-group">
    <div class="col-md-12 pl-4 pr-4">
      <span class="label-header">Objectives</span>
    </div>
    <div class="col-md-12 pl-4 pr-4">
      <mat-card appearance="outlined">
        <mat-card-content>
          {{legacyFeedbackComment.corpObjectives}}
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
