import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReviewCmfeedbackRoutingModule } from './review-cmfeedback-routing.module';
import { ReviewCmfeedbackComponent } from './review-cmfeedback.component';
import { CmFeedbackDetailModule } from 'src/app/shared/modules/cm-feedback-detail/cm-feedback-detail.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';

@NgModule({
  declarations: [ReviewCmfeedbackComponent],
  imports: [
    CommonModule,
    ReviewCmfeedbackRoutingModule,

    AngularMaterialModule,
    CmFeedbackDetailModule,
    SharedModule,
  ],
})
export class ReviewCmfeedbackModule {}
