import { Component, OnInit } from '@angular/core';
import { CmSurveyService } from 'src/app/core/services/cm-survey.service';
import {
  CMSurveyQuestion,
  QuestionData,
  QuestionDataDisplay,
  QuestionScore,
} from 'src/app/shared/models/cm-survey.model';

interface DynamicScoreColumn {
  matColumnDef: string;
  header: string;
  cell: (s: QuestionData, i: number) => string;
}

@Component({
  selector: 'app-cm-survey-dashboard',
  templateUrl: './cm-survey-dashboard.component.html',
  styleUrls: ['./cm-survey-dashboard.component.scss'],
})
export class CmSurveyDashboardComponent implements OnInit {
  firstYear = 2020;
  currentYear = new Date().getFullYear();
  selectedYear = this.currentYear;
  surveyYears: number[] = [];
  questions!: CMSurveyQuestion[];
  displayRows!: QuestionDataDisplay[];
  menteeRowsDict: { [mentor: string]: QuestionData[] } = {};
  displayedColumns!: string[];
  dynamicColumns!: DynamicScoreColumn[];
  isLoading = true;
  sortedQuestionIds: number[] = [];
  expandedCMRows: Set<string> = new Set();

  constructor(private cmSurveyService: CmSurveyService) {}

  ngOnInit() {
    this.getCMSurvey(this.currentYear);
    this.getSurveyYears();
  }

  getSurveyYears() {
    this.cmSurveyService.getCMFeedbackYears().subscribe(years => {
      this.surveyYears = years;
      this.isLoading = false;
    });
  }

  getCMSurvey = (year: number) => {
    this.cmSurveyService.getCmSurveyByYear(year).subscribe(surveys => {
      this.displayRows = surveys.mentorData.map(d => {
        this.menteeRowsDict[d.mentor.name] = d.mentees;
        return { ...d.mentor, isCMRow: true };
      });

      if (this.displayRows.length > 0) {
        this.ensureSortedQuestions(surveys.questions);
        this.generateDynamicColumnData(
          this.displayRows[0].scores.map(s => s.questionId)
        );
      }
    });
  };

  sortQuestions = (a: CMSurveyQuestion, b: CMSurveyQuestion): number => {
    if (a.optionGroupId < b.optionGroupId) {
      return -1;
    }
    if (a.optionGroupId > b.optionGroupId) {
      return 1;
    }
    if (a.questionId < b.questionId) {
      return -1;
    }
    if (a.questionId > b.questionId) {
      return 1;
    }
    return 0;
  };
  sortQuestionScore = (a: QuestionScore, b: QuestionScore) =>
    this.sortedQuestionIds.indexOf(a.questionId) -
    this.sortedQuestionIds.indexOf(b.questionId);
  sortScoresArray = (a: QuestionScore[]) => a.sort(this.sortQuestionScore);

  getColumnHeaderTip(questionIndex: number) {
    return `Question ${questionIndex + 1} Max Score ${
      this.questions[questionIndex].maxGroupScore
    }`;
  }

  rowClicked(mentor: string) {
    const mentorIx: number = this.displayRows.findIndex(
      r => r.name === mentor && r.isCMRow
    );
    if (mentorIx === -1) {
      return;
    }

    if (this.expandedCMRows.has(mentor)) {
      /* remove */
      const removeTheseNames = this.menteeRowsDict[mentor].map(m => m.name);
      this.displayRows = this.displayRows.filter(
        d => d.isCMRow || !removeTheseNames.includes(d.name)
      );
      this.expandedCMRows.delete(mentor);
    } else {
      /* add */
      this.displayRows =
        mentorIx === this.displayRows.length - 1
          ? /* append to end */ [
              ...this.displayRows,
              ...this.menteeRowsDict[mentor],
            ]
          : /* insert        */ [
              ...this.displayRows.slice(0, mentorIx + 1),
              ...this.menteeRowsDict[mentor],
              ...this.displayRows.slice(mentorIx + 1),
            ];
      this.expandedCMRows.add(mentor);
    }
  }

  private ensureSortedQuestions(questions: CMSurveyQuestion[]) {
    /* sorted Questions */
    this.questions = [...questions].sort(this.sortQuestions);
    /* Ensure all columns match same order of questions */
    this.sortedQuestionIds = this.questions.map(q => q.questionId);
    this.displayRows.forEach(data => {
      this.sortScoresArray(data.scores);
      this.menteeRowsDict[data.name].forEach(mentee =>
        this.sortScoresArray(mentee.scores)
      );
    });
  }

  private generateDynamicColumnData(scoreArr: number[]) {
    this.dynamicColumns = scoreArr.map((_, ix) => ({
      matColumnDef: `Q${ix + 1}`,
      header: `Q${ix + 1} (${this.questions[ix].maxGroupScore})`,
      cell: (s: QuestionData, i: number) =>
        s.scores[i].score !== 0
          ? `${Math.round((s.scores[i].score + Number.EPSILON) * 100) / 100}`
          : 'N/A',
    }));
    this.displayedColumns = [
      'mentor',
      ...this.dynamicColumns.map(c => c.matColumnDef),
    ];
  }
}
