import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyFeedbackRequestsListComponent } from './my-feedback-requests-list.component';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';

@NgModule({
  declarations: [MyFeedbackRequestsListComponent],
  imports: [CommonModule, AngularMaterialModule],
  exports: [MyFeedbackRequestsListComponent],
})
export class MyFeedbackRequestsListModule {}
