// @ts-nocheck
import { mergeDeep } from '../utils/merge-deep';
import { VexConfigName } from './config-name.model';
import { VexConfig } from './vex-config.interface';
import { ColorSchemeName } from './colorSchemeName';
import { colorVariables } from '../components/config-panel/color-variables';

const defaultConfig: VexConfig = {
  id: VexConfigName.apollo,
  name: 'Apollo',
  style: {
    colorScheme: ColorSchemeName.default,
    colors: {
      primary: colorVariables.blue
    },
    borderRadius: {
      value: 0.25,
      unit: 'rem'
    },
    button: {
      borderRadius: undefined
    }
  },
  direction: 'ltr',
  imgSrc: '//vex-landing.visurel.com/assets/img/layouts/apollo.png',
  layout: 'horizontal',
  boxed: false,
  sidenav: {
    title: 'VEX',
    imageUrl: 'assets/img/demo/logo.svg',
    showCollapsePin: true,
    user: {
      visible: true
    },
    search: {
      visible: true
    },
    state: 'expanded'
  },
  toolbar: {
    fixed: true,
    user: {
      visible: true
    }
  },
  navbar: {
    position: 'below-toolbar'
  },
  footer: {
    visible: true,
    fixed: true
  }
};

const obsColors = {
  light: 'rgb(220, 30, 53, .1)',
  default: 'rgb(220, 30, 53)',
  contrast: 'rgb(255, 255, 255)'
};

const obsConfig: VexConfig = mergeDeep({ ...defaultConfig }, {
  imgSrc: '/assets/img/favicon.png',
  style: {
    colors: {
      primary: obsColors
    },
  },
  sidenav: {
    title: '',
    imageUrl: '/assets/img/online-business-systems-logo-w.png',
    showCollapsePin: true,
    user: {
      visible: false
    },
    search: {
      visible: false
    },
    state: 'expanded'
  },
})

const hermesConfig: VexConfig = mergeDeep({ ...obsConfig }, {
  id: VexConfigName.hermes,
  name: 'Hermes',
  imgSrc: '//vex-landing.visurel.com/assets/img/layouts/hermes.png',
  layout: 'vertical',
  boxed: true,
  sidenav: {
    user: {
      visible: false
    },
    search: {
      visible: false
    },
  },
  toolbar: {
    fixed: false
  },
  footer: {
    fixed: false
  },
})

const obsPublicConfig: VexConfig = mergeDeep({ ...hermesConfig } , {
  imgSrc: '/assets/img/OBSlogo_tiny.png',
  style: {
    colors: {
      primary: obsColors
    },
    borderRadius: {
      value: 0,
      unit: 'rem'
    },
  },
  toolbar: {
    fixed: true,
    user: {
      visible: true
    }
  },
  footer: {
    fixed: true
  },
})

export const configs: VexConfig[] = [
  obsConfig,
  obsPublicConfig,
  mergeDeep({ ...defaultConfig }, {
    id: VexConfigName.poseidon,
    name: 'Poseidon',
    imgSrc: '//vex-landing.visurel.com/assets/img/layouts/poseidon.png',
    style: {
      borderRadius: {
        value: 0.5,
        unit: 'rem'
      },
      button: {
        borderRadius: {
          value: 9999,
          unit: 'px'
        }
      }
    },
    sidenav: {
      user: {
        visible: true
      },
      search: {
        visible: true
      }
    },
    toolbar: {
      user: {
        visible: false
      }
    },
    footer: {
      fixed: false
    }
  }),
  mergeDeep({ ...defaultConfig }, {
    id: VexConfigName.ares,
    name: 'Ares',
    imgSrc: '//vex-landing.visurel.com/assets/img/layouts/ares.png',
    sidenav: {
      user: {
        visible: false
      },
      search: {
        visible: false
      },
    },
    toolbar: {
      fixed: false
    },
    navbar: {
      position: 'in-toolbar'
    },
    footer: {
      fixed: false
    },
  }),
  mergeDeep({ ...defaultConfig }, {
    id: VexConfigName.zeus,
    name: 'Zeus',
    imgSrc: '//vex-landing.visurel.com/assets/img/layouts/zeus.png',
    sidenav: {
      state: 'collapsed'
    },
  }),
  mergeDeep({ ...defaultConfig }, {
    id: VexConfigName.ikaros,
    name: 'Ikaros',
    imgSrc: '//vex-landing.visurel.com/assets/img/layouts/ikaros.png',
    layout: 'vertical',
    boxed: true,
    sidenav: {
      user: {
        visible: false
      },
      search: {
        visible: false
      },
    },
    toolbar: {
      fixed: false
    },
    navbar: {
      position: 'in-toolbar'
    },
    footer: {
      fixed: false
    }
  })
];
