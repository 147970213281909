<section class="text-base">
  <div class="p-4 flex justify-center bg-obs-danger">
    <span class="inline text-white dialog-title">Session Expired</span>
  </div>

  <div class="dialog-body py-12 px-8">
    <div class="flex justify-center">
      <button type="button" (click)="logout()" [disabled]="isLoggingOut || isRenewingSession" type="button" class="
      py-1 px-3 bg-obs-gray-20 border-obs-gray-20 text-black mr-1 rounded">
        <span *ngIf="isLoggingOut"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span> Logout
      </button>
      <button type="button" (click)="renewSession()" [disabled]="isRenewingSession || isLoggingOut" class="
      py-1 px-3 border-obs-success text-white bg-obs-success rounded">
      <span *ngIf="isRenewingSession"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span> Renew Session
      </button>
    </div>
    <ng-container *ngIf="errorMessage">
      <div class="mt-2 text-obs-danger text-center">{{errorMessage}}</div>
    </ng-container>
  </div>
</section>
