import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  ActiveFeedbackDetails,
  FeedbackService,
} from 'src/app/core/services/feedback.service';
import {
  RemoteWorkRequestService,
  RemoteWorkRequests,
} from 'src/app/core/services/remote-work-request.service';
import {
  FbSelfAssessment,
  SelfAssessmentService,
} from 'src/app/core/services/self-assessment.service';
import {
  FbWeeklyReport,
  WeeklyReportService,
} from 'src/app/core/services/weekly-report-service';
import { FeedbackStatusEnum } from 'src/app/shared/enums/enums.model';
import { roles } from 'src/app/shared/models/roles.model';

interface Card {
  icon: string;
  iconClass: string;
  label: string;
  helpText?: string;
  value: string | number;
  routeLink?: string;
  roles?: string[];
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
  isLoading = true;
  currentQuote: { quote: string; author: string } | undefined;
  cards: Card[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private datepipe: DatePipe,
    private authService: AuthService,
    private feedbackService: FeedbackService,
    private selfAssessmentService: SelfAssessmentService,
    private weeklyReportService: WeeklyReportService,
    private remoteWorkRequestService: RemoteWorkRequestService
  ) {}

  ngOnInit() {
    this.currentQuote = this.getQuote();

    forkJoin({
      activeFeedback$: this.feedbackService.getMyActiveFeedbacks(),
      weeklyReport$: this.weeklyReportService.getWeeklyReportChecks(
        this.targetWeek.toDateString()
      ),
      pendingFeedback$: this.feedbackService.getmyPendingFeedbackRequests(),
      allActiveFeedbacks$: this.isAdmin
        ? this.feedbackService.getAllActiveFeedbacks()
        : this.isCM
          ? this.feedbackService.getMyTeamActiveFeedbacks()
          : of(null),
      selfAssessment$: this.selfAssessmentService.getRecentSelfAssessment(),
      // remoteWork$:          this.isRemoteWorkReviewer ? this.remoteWorkRequestService.getRequestsForRequestList(true) : of(null)
    }).subscribe({
      next: results => {
        this.cards.push(this.getMyDrafts(results.activeFeedback$));
        this.cards.push(this.getWeeklyReport(results.weeklyReport$));
        this.cards.push(this.getPendingFeedback(results.activeFeedback$));
        this.cards.push(this.getFeedbackRequest(results.pendingFeedback$));

        if (results.allActiveFeedbacks$) {
          this.cards.push(this.getActiveFeedback(results.allActiveFeedbacks$));
        }

        this.cards.push(this.getSelfAssessment(results.selfAssessment$));

        // if(results.remoteWork$) {
        //   this.cards.push(this.getRemoteWork(results.remoteWork$))
        // }
      },
      complete: () => {
        this.isLoading = false;
        this.cd.markForCheck();
      },
    });
  }

  get isAdmin() {
    return this.authService.doesUserHaveRole([roles.admin]);
  }
  get isCM() {
    return this.authService.doesUserHaveRole([roles.cm]);
  }
  get isRemoteWorkReviewer() {
    return (
      this.authService.doesUserHaveRole([roles.peoplecare]) ||
      this.authService.doesUserHaveRole([roles.ceo])
    );
  }

  get targetWeek() {
    const currentDate = new Date();
    let result = new Date();
    const friday = 5; // friday's day of the week, Sunday: 0 - Saturday: 6
    const weekLength = 7;
    const monday = 1;
    const midnight = 23;
    // const sevenDays = 7;
    let dateModifier = 0;
    const timeEquivalent = 86400000;
    // if it's Sunday or Monday before midnight (11:59pm) we want to look at the previous week still
    if (currentDate.getDay() === 0) {
      dateModifier = 2 * timeEquivalent;
    } else if (
      currentDate.getDay() === monday &&
      currentDate.getHours() <= midnight
    ) {
      dateModifier = 3 * timeEquivalent;
    } else if (currentDate.getDay() === 6) {
      dateModifier = timeEquivalent;
    }

    if (dateModifier === 0) {
      result.setDate(
        currentDate.getDate() +
          ((friday + weekLength - currentDate.getDay()) % weekLength)
      );
    } else {
      result = new Date(currentDate.getTime() - dateModifier);
    }

    return result;
  }

  private getMyDrafts(result: ActiveFeedbackDetails): Card {
    return {
      icon: 'mat:description',
      iconClass: '',
      label: 'My Drafts',
      routeLink: '/savedFeedback',
      value: result.savedCount,
    };
  }

  private getWeeklyReport(result: FbWeeklyReport[]): Card {
    const weeklyReportSubmitted = !(
      result[0] === null ||
      (result[0].log.length === 1 && result[0].log[0].statusId === 3)
    );
    return {
      helpText: 'Form Submission',
      icon: 'mat:monitor_heart',
      iconClass: weeklyReportSubmitted ? '' : 'text-obs-warning',
      label: 'My Weekly PulseCheck',
      routeLink: '/weeklyDelivery',
      value: weeklyReportSubmitted ? 'Submitted' : 'Not Submitted',
    };
  }

  private getPendingFeedback(result: ActiveFeedbackDetails): Card {
    return {
      helpText: 'Pending CM Approval',
      icon: 'mat:call_made',
      iconClass: '',
      label: 'My Submissions',
      routeLink: '/feedbackProvided',
      value: result.pendingCount,
    };
  }

  private getFeedbackRequest(result: ActiveFeedbackDetails): Card {
    return {
      icon: 'mat:schedule',
      iconClass: '',
      label: 'My Feedback Requests',
      routeLink: '/feedbackRequests',
      value: result.pendingCount,
    };
  }

  private getActiveFeedback(result: ActiveFeedbackDetails): Card {
    return {
      icon: 'mat:search',
      iconClass: '',
      label: 'Awaiting My Approval',
      routeLink: '/teamFeedback',
      value: result.pendingCount,
      roles: [roles.cm, roles.admin],
    };
  }

  private getSelfAssessment(result: FbSelfAssessment): Card {
    const selfAssessment = result;
    const changedDate = this.datepipe.transform(
      selfAssessment?.log?.find(a => a.statusId === FeedbackStatusEnum.Posted)
        ?.changedDate,
      'yyyy-MM-dd'
    );
    return {
      helpText: 'Submission',
      icon: 'mat:sms',
      iconClass: changedDate ? '' : 'text-obs-warning',
      label: 'Latest Self Assessment',
      // routeLink: selfAssessment ? '/viewYearlyAssessmentReport' : '/selfAssessment',
      value: changedDate ?? 'Not Submitted',
    };
  }

  private getRemoteWork(result: RemoteWorkRequests[]): Card {
    return {
      icon: 'mat:route',
      iconClass: '',
      label: 'Relocation Approval',
      routeLink: `/remoteWorkRequests/${true}`,
      value: result.length,
      roles: [roles.peoplecare, roles.ceo],
    };
  }

  private getQuote(): { quote: string; author: string } {
    const quotes = [
      {
        quote:
          "We all need people who will give us feedback. That's how we improve.",
        author: 'Bill Gates',
      },
      {
        quote:
          "Criticism, like rain, should be gentle enough to nourish a man's growth without destroying his roots.",
        author: 'Frank A. Clark',
      },
      {
        quote: 'Make feedback normal. Not a performance review.',
        author: 'Ed Batista',
      },
      {
        quote:
          "I think it's very important to have a feedback loop, where you're constantly thinking about what you've done and how you could be doing it better.",
        author: 'Elon Musk',
      },
      {
        quote:
          'Whatever words we utter should be chosen with care for people will hear them and be influenced by them for good or ill.',
        author: 'Buddha',
      },
      {
        quote:
          "If you have an important point to make, don't try to be subtle or clever. Use a pile driver. Hit the point once. Then come back and hit it again. Then hit it a third time - a tremendous whack.",
        author: 'Winston Churchill',
      },
      {
        quote:
          'If your actions inspire others to dream more, learn more, do more and become more, you are a leader.',
        author: 'John Quincy Adams',
      },
      {
        quote:
          'All that is valuable in human society depends upon the opportunity for development accorded the individual.',
        author: 'Albert Einstein',
      },
      {
        quote:
          "Ever since I was a child I have had this instinctive urge for expansion and growth. To me, the function and duty of a quality human being is the sincere and honest development of one's potential.",
        author: 'Bruce Lee',
      },
      {
        quote:
          'Constant development is the law of life, and a man who always tries to maintain his dogmas in order to appear consistent drives himself into a false position.',
        author: 'Mahatma Gandhi',
      },
      {
        quote: 'Strive for continuous improvement, instead of perfection.',
        author: 'Kim Collins',
      },
      {
        quote:
          "Excellent firms don't believe in excellence - only in constant improvement and constant change.",
        author: 'Tom Peters',
      },
      {
        quote:
          "There is always space for improvement, no matter how long you've been in the business.",
        author: 'Oscar De La Hoya',
      },
      {
        quote:
          "To become more effective and fulfilled at work, people need a keen understanding of their impact on others and the extent to which they're achieving their goals in their working relationships. Direct feedback is the most efficient way for them to gather this information and learn from it.",
        author: 'Ed Batista',
      },
      {
        quote:
          "We can't just sit back and wait for feedback to be offered, particularly when we're in a leadership role. If we want feedback to take root in the culture, we need to explicitly ask for it.",
        author: 'Ed Batista',
      },
    ];
    return quotes[Math.floor(Math.random() * quotes.length)];
  }
}
