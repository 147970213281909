
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" [hidden]="isLoading || !(dataSource && dataSource.data.length > 0)">

    <ng-container matColumnDef="employeeName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Career Mentor</th>
      <td mat-cell *matCellDef="let value" class="leftAlignCell">
        {{ value.employeeName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="submittedByName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Submitted By</th>
      <td mat-cell *matCellDef="let value" class="leftAlignCell">
        {{ value.submittedByName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="submittedDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Provided</th>
      <td mat-cell *matCellDef="let value" class="leftAlignCell">
        {{ value.submittedDate | date:'MM/dd/yyyy' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="currentStatus">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell class="text-center" *matCellDef="let value" class="leftAlignCell">
        {{ value.currentStatus }}
      </td>
    </ng-container>
    <ng-container matColumnDef="isAnonymous">
      <th mat-header-cell *matHeaderCellDef class="text-center">Anonymous</th>
      <td mat-cell *matCellDef="let value" class="text-center">
        <i class="fas fa-eye-slash" *ngIf="value.isAnonymous"></i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="resultColumnHeaders"></tr>
    <tr mat-row class="mat-row-clickable" *matRowDef="let row; columns: resultColumnHeaders" style="cursor: pointer" (click)="onSelect(row)"></tr>
  </table>
  <div *ngIf="dataSource && dataSource.data.length === 0 && !isLoading">No records found</div>

  <div *ngIf="isLoading" class="grid place-items-center inset-0">
    <mat-spinner></mat-spinner>
  </div>

