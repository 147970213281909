<div class="row m-0 mb-5">
  <div class="component-container" >
    <app-cm-feedback-detail [feedback]="feedback" [feedbackTypeIdParam]="feedbackTypeId" [operationInProgress]="operationInProgress"></app-cm-feedback-detail>
    <div *ifRole="pcRole">
      <div *ngIf="showActionPanel" [ngClass]="isCollapsed ? 'fixed-above-footer fixed-collapsed' : 'fixed-above-footer fixed-expanded'">
          <button type="button" mat-stroked-button color="warn" class="submit-btn mr-3" (click)="unpostFeedback()" [disabled]="operationInProgress !== null || feedback.currentStatusId !== postedStatusId">
            <span *ngIf="operationInProgress === 'unposting'"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>UNPOST
          </button>
          <button type="button" mat-stroked-button color="warn" class="submit-btn mr-3" (click)="reviseFeedback()" [disabled]="operationInProgress !== null || feedback.currentStatusId !== pendingStatusId">
            <span *ngIf="operationInProgress === 'revising'"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>REVISE
          </button>
          <button type="button" mat-stroked-button color="primary" class="submit-btn mr-3" (click)="postFeedback()" [disabled]="operationInProgress !== null || feedback.currentStatusId !== pendingStatusId">
            <span *ngIf="operationInProgress === 'posting'"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>POST
          </button>
      </div>
    </div>
  </div>
</div>
