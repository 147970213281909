import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { QuestionTypeEnum } from 'src/app/shared/enums/enums.model';
import { ApiErrorData } from 'src/app/shared/interfaces/api-error.interface';
import { MatchType } from 'src/app/shared/shared.module';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { AuthorizedHttp } from './authorized-http.service';
import { Question } from './feedback.service';
import { Employee } from './onliner.service';

@Injectable({
  providedIn: 'root',
})
export class WeeklyReportService {
  // data: Observable<any>;
  // userId = this.authService.getUserId().toLowerCase();
  private _apiEndpoint = `${environment.apiEndpoint}/api/weeklyReport`;

  constructor(
    private http: AuthorizedHttp,
    private authService: AuthService
  ) {}

  public getWeeklyReportChecks(
    targetDate: string
  ): Observable<FbWeeklyReport[]> {
    return this.http
      .get<FbWeeklyReport[]>(`${this._apiEndpoint}/checkReports/${targetDate}`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getMyWeeklyReports(): Observable<FbWeeklyReport[]> {
    return this.http
      .get<FbWeeklyReport[]>(`${this._apiEndpoint}/getWeeklyReportsHistory`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getWeeklyReportDetail(
    weeklyReportId: string
  ): Observable<FbWeeklyReport> {
    return this.http
      .get<FbWeeklyReport>(
        `${this._apiEndpoint}/getWeeklyReportDetail/${weeklyReportId}`
      )
      .pipe(
        map(report => {
          const result = MatchType.MatchData(report, FbWeeklyReport);
          result.log = MatchType.MatchDataArray(
            report.log,
            FbWeeklyReportAudit
          );
          result.answers = MatchType.MatchDataArray(
            report.answers,
            FbWeeklyReportAnswer
          );
          return result;
        }),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public saveReport(feedback?: FbWeeklyReport): Observable<FbWeeklyReport> {
    return this.http
      .post<FbWeeklyReport>(`${this._apiEndpoint}/saveWeeklyReport`, feedback)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public submitReport(feedback: FbWeeklyReport): Observable<number> {
    return this.http
      .post<number>(`${this._apiEndpoint}/submitWeeklyReport`, feedback)
      .pipe(
        map(f => f),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getWeeklyReportsSubmissionDates(): Observable<
    FbWeeklyReportSubmissionData[]
  > {
    return this.http
      .get<FbWeeklyReportSubmissionData[]>(
        `${this._apiEndpoint}/getWeeklyReportsSubmissionDates`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getAllWeeklyReportsFromUser(
    userId: string
  ): Observable<FbWeeklyReport[]> {
    return this.http
      .get<FbWeeklyReport[]>(
        `${this._apiEndpoint}/getAllWeeklyReportsFromUser/${userId}`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }
}

export class FbWeeklyReport {
  id!: number;
  answers!: FbWeeklyReportAnswer[];
  submittedByEmployee!: Employee;
  submittedBy!: string;
  submittedDate!: Date;
  log!: FbWeeklyReportAudit[];

  RemoveTimePartFromDate(): void {
    const date = new Date(this.submittedDate);
    this.submittedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }
}

export class FbWeeklyReportAnswer {
  id!: number;
  questionId!: number;
  question!: Question;
  intValue?: number | null;
  stringValue!: string;
  optionId?: number | null;
  option!: FbQuestionOption | null;
}

export class FbWeeklyReportAudit {
  id!: number;
  statusId!: number;
  changedByEmployee!: Employee;
  changedBy!: string;
  changedDate!: Date;
  description!: string;
}

export class FbWeeklyReportSubmissionData {
  id!: number;
  targetDate!: Date;
  cutoffDate!: Date;
}

export class FbQuestion {
  id!: number;
  question!: string;
  isOptional!: boolean;
  isActive!: boolean;
  sortOrder!: number;
  type!: QuestionTypeEnum;
  groupId!: number;
  questionGroupSortOrder!: number;
  questionGroupType!: number;
  optionGroupId!: number;
  optionGroup!: FbQuestionOptionGroup;
}

export class FbQuestionOption {
  id!: number;
  isActive!: boolean;
  value!: string;
  groupId!: number;
  sortOrder!: number;
  score!: number;
  groupLabel!: number;
}

export class FbQuestionOptionGroup {
  id!: number;
  label!: string;
  options!: FbQuestionOption[];
}
