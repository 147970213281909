import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SavedAssessment } from 'src/app/core/services/assessment.service';
import { BaseFeedbackFilterService } from 'src/app/core/services/base-feedback-filter.service';
import { CmFeedbackFilterService } from 'src/app/core/services/cm-feedback-filter.service';
import { FeedbackService } from 'src/app/core/services/feedback.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-cm-feedback-reviewable',
  templateUrl: './cm-feedback-reviewable.component.html',
  styleUrls: ['./cm-feedback-reviewable.component.scss'],
  providers: [
    {
      provide: BaseFeedbackFilterService,
      useExisting: CmFeedbackFilterService,
    },
  ],
})
export class CmFeedbackReviewableComponent implements OnInit {
  cmFeedbacks!: SavedAssessment[];
  filteredCmFeedbacks!: SavedAssessment[];
  filterCriteriaForm!: UntypedFormGroup;
  filterDateDisplay!: string;
  filterCMValue!: string;
  filterFeedbackStatusValue!: string;
  filterAnonymousValue!: string;
  filtersValue!: string;
  isLoading = true;

  constructor(
    private feedbackService: FeedbackService,
    private snackBarService: SnackBarService,
    private feedbackFilterService: CmFeedbackFilterService
  ) {}

  ngOnInit() {
    this.filterCriteriaForm =
      this.feedbackFilterService.getFilterCriteriaForm();
    this.filterCriteriaForm.controls['status'].setValue({
      statusId: 1,
      statusDescription: 'Pending',
    });
    this.getAllReviewableCmFeedbacks();
  }

  filterCmFeedbacks() {
    const searchCriteria = this.feedbackFilterService.getFilterCriteriaModel();
    this.filterDateDisplay =
      this.feedbackFilterService.getFilterDateRangeDisplay(searchCriteria);
    this.filteredCmFeedbacks = this.cmFeedbacks.filter(
      f =>
        this.feedbackFilterService.filterCareerMentor(f, searchCriteria) &&
        this.feedbackFilterService.filterStartDate(f, searchCriteria) &&
        this.feedbackFilterService.filterEndDate(f, searchCriteria) &&
        this.feedbackFilterService.filterFeedbackStatus(f, searchCriteria) &&
        this.feedbackFilterService.filterAnonymous(f, searchCriteria)
    );
    this.getFilterFormValues();
  }

  getFilterFormValues() {
    this.filterCMValue = `${this.filterCriteriaForm.controls.careerMentor.value['firstName']} ${this.filterCriteriaForm.controls.careerMentor.value['lastName']}`;
    this.filterCMValue = this.filterCMValue.trim();
    this.filterFeedbackStatusValue =
      this.filterCriteriaForm.controls.status.value['statusDescription'];
    this.filterAnonymousValue =
      this.filterCriteriaForm.controls.anonymous.value['anonymousDesc'];
    this.filtersValue = `${this.filterFeedbackStatusValue}, ${this.filterCMValue}, ${this.filterAnonymousValue}`;
  }

  private getAllReviewableCmFeedbacks() {
    this.feedbackService.getAllReviewableCmFeedbacks().subscribe(
      data => {
        this.cmFeedbacks = data;
        this.filterCmFeedbacks();
        this.isLoading = false;
      },
      error => {
        this.snackBarService.error(error);
      }
    );
  }
}
