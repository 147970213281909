import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiErrorData } from 'src/app/shared/interfaces/api-error.interface';
import { environment } from 'src/environments/environment';
import { AuthorizedHttp } from './authorized-http.service';

@Injectable()
export class FeedbackReviewService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/feedbackreview`;

  constructor(private http: AuthorizedHttp) {}

  public postFeedback(
    feedbackId: number,
    comments?: string
  ): Observable<number> {
    return this.http
      .post<number>(`${this._apiEndpoint}/post`, {
        FeedbackId: feedbackId,
        Comments: comments,
      })
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public unpostFeedback(feedbackId: number): Observable<number> {
    return this.http
      .postWithNoData<number>(`${this._apiEndpoint}/unpost/${feedbackId}`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public reviseFeedback(
    feedbackId: number,
    comments: string
  ): Observable<number> {
    return this.http
      .post<number>(`${this._apiEndpoint}/revise`, {
        FeedbackId: feedbackId,
        Comments: comments,
      })
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  // public rejectFeedback(feedbackId: number, comment: string): Observable<any> {
  //     return this.http
  //         .post(`${this._apiEndpoint}/reject`, { FeedbackId: feedbackId, Comments: comment })
  //         .pipe(
  //             catchError((errorResponse: any) => throwError(errorResponse?.error?.exceptionMessage || 'Server error'))
  //         );
  // }
}
