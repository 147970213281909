<vex-secondary-toolbar current="Review">
  <vex-breadcrumbs [crumbs]="['Review', 'My Team Feedback']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="row m-0">
  <div class="component-container">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <i class="fas fa-filter fa-lg"></i>
          <span>Filter Submissions ({{filtersValue}})</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-feedback-filter-criteria-form
        [searchCriteriaForm]="filterCriteriaForm"
        [onlinerFilterMode]="isAdmin ? 'AllUsers' : 'MentoringUsers'"
        [currentCareerMentor]="currentCareerMentor!"
        [isOnMyTeamFeedbackPage]="true"
        [isOnlinerFilterVisible]="true"
        [isSubmittedByFilterVisible]="false"
        [isCMDelegate]="isCMDelegate"
        (filterDatesTriggered)="getFeedbacks()"
        (filterFeedbacksTriggered) ="filterFeedbacks($event)">
      </app-feedback-filter-criteria-form>
    </mat-expansion-panel>
<br>
    <h3 class="page-title">My Team Feedback {{filterDateDisplay}}</h3>
    <app-feedback-list *ngIf="!isLoading" [feedbacks]="filteredFeedbacks" [canReview]="true"></app-feedback-list>
  </div>
</div>

