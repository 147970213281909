import { Component, Input } from '@angular/core';
import { DateTime } from 'luxon';
import { scaleInOutAnimation } from 'src/@vex/animations/scale-in-out.animation';

@Component({
  selector: 'app-widget-quick-value-center',
  templateUrl: './widget-quick-value-center.component.html',
  styleUrls: ['./widget-quick-value-center.component.scss'],
  animations: [scaleInOutAnimation],
})
export class WidgetQuickValueCenterComponent {
  @Input()
  icon!: string;
  @Input()
  value!: string | number;
  @Input()
  label!: string;
  @Input()
  change!: number;
  @Input() helpText?: string;
  @Input()
  iconClass!: string;

  getValueClass() {
    if (
      isNaN(Number(this.value)) &&
      !DateTime.fromISO(this.value.toString()).isValid
    ) {
      return this.iconClass;
    }

    return this.iconClass + ' app-widget-quick-heading';
  }
}
