import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { AuthorizedHttp } from 'src/app/core/services/authorized-http.service';
import { RemoteWorkRequestFilterService } from 'src/app/core/services/remote-work-request-filter.service';
import {
  RemoteWorkRequestService,
  RemoteWorkRequests,
} from 'src/app/core/services/remote-work-request.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-remote-work-requests',
  templateUrl: './remote-work-requests.component.html',
  styleUrls: ['./remote-work-requests.component.scss'],
})
export class RemoteWorkRequestsComponent implements OnInit {
  remoteWorkRequests!: RemoteWorkRequests[];
  filteredRemoteWorkRequests!: RemoteWorkRequests[];
  filterCriteriaForm!: UntypedFormGroup;
  filterDateDisplay!: string;
  isAuthorizedToChangeStatus!: boolean;
  filterStartDateValue!: string;
  filterEndDateValue!: string;
  filterRequestTypeValue!: string;
  filterOnlinerValue!: string;
  filterRequestStatusValue!: string;
  filtersValue!: string;
  isRequestApproval!: boolean;
  isAdmin!: boolean;
  isLoading = false;
  constructor(
    private remoteWorkRequestService: RemoteWorkRequestService,
    private snackBarService: SnackBarService,
    private remoteWorkRequestFilterService: RemoteWorkRequestFilterService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private authHttp: AuthorizedHttp
  ) {}

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.remoteWorkRequests = [];
      this.filteredRemoteWorkRequests = [];
      this.isRequestApproval = this.checkIsRequestApproval(
        param['isRequestApproval']
      );
      this.filterCriteriaForm =
        this.remoteWorkRequestFilterService.getRequestedFilterCriteriaForm(
          true
        );
      const userId = this.authService.getUserId();
      this.isAdmin = this.authService.doesUserHaveRole([
        environment.roles.FeedbackAdmin,
      ]);
      this.remoteWorkRequestService
        .userCanChangeStatus(userId)
        .subscribe(result => {
          this.isAuthorizedToChangeStatus = result;
          this.getRequestsForRequestList();
        });
    });
  }

  filterRemoteWorkRequests() {
    const filterCriteriaModel =
      this.remoteWorkRequestFilterService.getRequestedFilterCriteriaModel();
    this.filteredRemoteWorkRequests = this.remoteWorkRequests.filter(f =>
      this.remoteWorkRequestFilterService.filterRequestStatus(
        f,
        filterCriteriaModel
      )
    );

    this.filteredRemoteWorkRequests = this.filteredRemoteWorkRequests.filter(
      f =>
        this.remoteWorkRequestFilterService.filterRequestType(
          f,
          filterCriteriaModel
        )
    );

    this.filteredRemoteWorkRequests = this.filteredRemoteWorkRequests.filter(
      f =>
        this.remoteWorkRequestFilterService.filterStartDate(
          f,
          filterCriteriaModel
        )
    );

    this.filteredRemoteWorkRequests = this.filteredRemoteWorkRequests.filter(
      f =>
        this.remoteWorkRequestFilterService.filterEndDate(
          f,
          filterCriteriaModel
        )
    );
    if (this.isAuthorizedToChangeStatus) {
      this.filteredRemoteWorkRequests = this.filteredRemoteWorkRequests.filter(
        f =>
          this.remoteWorkRequestFilterService.filterOnliner(
            f,
            filterCriteriaModel
          )
      );
    }

    this.getFilterFormValues();
  }

  getFilterFormValues() {
    this.filterStartDateValue = this.filterCriteriaForm.controls.startDate.value
      ? `${this.formatDate(this.filterCriteriaForm.controls.startDate.value)}`
      : '';
    this.filterEndDateValue = this.filterCriteriaForm.controls.endDate.value
      ? `${this.formatDate(this.filterCriteriaForm.controls.endDate.value)}`
      : '';
    this.filterRequestTypeValue = this.filterCriteriaForm.controls
      .remoteWorkRequestType.value
      ? `${this.filterCriteriaForm.controls.remoteWorkRequestType.value.description}`
      : '';
    this.filterOnlinerValue =
      this.isAuthorizedToChangeStatus &&
      this.filterCriteriaForm.controls.onliner.value
        ? `${this.filterCriteriaForm.controls.onliner.value.firstName} ${this.filterCriteriaForm.controls.onliner.value.lastName}`
        : '';
    this.filterRequestStatusValue = this.filterCriteriaForm.controls
      .remoteWorkRequestStatus.value
      ? `${this.filterCriteriaForm.controls.remoteWorkRequestStatus.value.description}`
      : '';
    this.filtersValue = '';
    this.buildFilterString(this.filterStartDateValue);
    this.buildFilterString(this.filterEndDateValue);
    this.buildFilterString(this.filterRequestTypeValue);
    this.buildFilterString(this.filterOnlinerValue);
    this.buildFilterString(this.filterRequestStatusValue);
  }

  formatDate(date: Date) {
    const result = date.toLocaleString('default', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
    return result;
  }

  private getRequestsForRequestList() {
    this.isLoading = true;
    this.remoteWorkRequestService
      .getRequestsForRequestList(this.isRequestApproval)
      .subscribe(
        data => {
          // this.isRequestApproval = this.isRequestApproval;
          this.remoteWorkRequests = data;
          this.filterRemoteWorkRequests();
          this.isLoading = false;
        },
        error => {
          this.snackBarService.error(error);
          this.isLoading = false;
        }
      );
  }

  private buildFilterString(value: string) {
    if (value && !this.filtersValue) {
      this.filtersValue = value;
    } else if (value) {
      this.filtersValue = this.filtersValue + ',' + value;
    }
  }

  private checkIsRequestApproval(val: string) {
    if (val === 'True' || val === 'true' || val === 'TRUE') {
      return true;
    }
    return false;
  }
}
