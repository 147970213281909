import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  CareerMentor,
  CareerMentorService,
} from 'src/app/core/services/career-mentor.service';
import {
  FeedbackService,
  SelfAssessmentDto,
} from 'src/app/core/services/feedback.service';
import { MyTeamFeedbackFilterService } from 'src/app/core/services/my-team-feedback-filter.service';
import { FbSelfAssessmentSchedule } from 'src/app/core/services/self-assessment.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-team-survey',
  templateUrl: './team-survey.component.html',
  styleUrls: ['./team-survey.component.scss'],
})
export class TeamSurveyComponent implements OnInit {
  fiscalYear!: number;
  feedbacks!: SelfAssessmentDto[];
  filteredFeedbacks!: SelfAssessmentDto[];
  currentCareerMentor!: CareerMentor;
  currentCm!: string;
  practice!: string;
  filterCriteriaForm!: UntypedFormGroup;
  filterDateDisplay!: string;
  filterCMValue!: string;
  filtersValue!: string;
  filterPracticeValue!: string;
  filterCompetencyValue!: string;
  isAdmin!: boolean;
  isCareerMentor!: boolean;
  filterSubmitterValue!: string;
  filterYearValue!: string;
  fbSelfAssessmentSched!: FbSelfAssessmentSchedule;
  isLoading = true;

  constructor(
    private feedbackService: FeedbackService,
    private snackBarService: SnackBarService,
    private feedbackFilterService: MyTeamFeedbackFilterService,
    private careerMentorService: CareerMentorService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.isCareerMentor = this.authService.doesUserHaveRole([
      environment.roles.FeedbackCm,
    ]);
    this.isAdmin = this.authService.doesUserHaveRole([
      environment.roles.FeedbackAdmin,
    ]);
    if (this.filterCriteriaForm) {
      this.getAllTeamSurveys();
    } else {
      forkJoin([
        this.feedbackService.GetCurrentFiscalYear(),
        this.careerMentorService.getCareerMentors(),
      ]).subscribe(
        ([currentFiscalYear]) => {
          // this.currentCareerMentor = careerMentors.find(cm => cm.userId === this.authService.getUserId().toLowerCase());
          this.filterCriteriaForm =
            this.feedbackFilterService.getSelfAssessmentFilterCriteriaForm(
              currentFiscalYear,
              this.currentCareerMentor
            ); // set up/create the formGroup to load for the current fiscal year, if there is cached data then that is used
          this.getAllTeamSurveys();
        },
        error => {
          this.snackBarService.error(error);
        }
      );
    }
  }

  public getAllTeamSurveys(dropdownValue?: FbSelfAssessmentSchedule) {
    this.isLoading = true;
    if (
      !dropdownValue &&
      `${this.filterCriteriaForm.controls.fiscalYear.value['fiscalYear']}` !==
        null
    ) {
      dropdownValue = this.filterCriteriaForm.controls.fiscalYear.value;
    }

    this.feedbackService.getAllTeamSurveys(dropdownValue?.id).subscribe(
      data => {
        this.feedbacks = data;
        this.feedbacks.forEach((feedback, i) => {
          if (feedback.id === 0) {
            this.feedbacks.splice(i, 1);
          }
        });
        this.filterFeedbacks();
        this.isLoading = false;
      },
      error => {
        this.snackBarService.error(error);
      }
    );
  }

  filterFeedbacks() {
    const filterCriteriaModel =
      this.feedbackFilterService.getSAFilterCriteriaModel();
    this.filterDateDisplay =
      this.feedbackFilterService.getSAFilterDateRangeDisplay(
        filterCriteriaModel
      );
    if (this.feedbacks !== null) {
      this.filteredFeedbacks = this.feedbacks.filter(
        f =>
          this.feedbackFilterService.filterSASubmitter(
            f,
            filterCriteriaModel
          ) &&
          this.feedbackFilterService.filterSACareerMentor(
            f,
            filterCriteriaModel
          ) &&
          this.feedbackFilterService.filterPractice(f, filterCriteriaModel) &&
          this.feedbackFilterService.filterCompetency(f, filterCriteriaModel)
      );
    }
    this.getFilterFormValues();
  }

  getFilterFormValues() {
    this.filterCMValue = `${this.filterCriteriaForm.controls.careerMentor.value['firstName']} ${this.filterCriteriaForm.controls.careerMentor.value['lastName']}`;
    this.filterCMValue = this.filterCMValue.trim();
    this.filterSubmitterValue = `${this.filterCriteriaForm.controls.submittedBy.value['firstName']} ${this.filterCriteriaForm.controls.submittedBy.value['lastName']}`;
    this.filterSubmitterValue = this.filterSubmitterValue.trim();
    this.filterPracticeValue =
      this.filterCriteriaForm.controls.practice.value['practice'];
    this.filterCompetencyValue =
      this.filterCriteriaForm.controls.competency.value['name'];
    this.filterYearValue = `${this.filterCriteriaForm.controls.fiscalYear.value['fiscalYear']}`;
    this.filtersValue = `${this.filterYearValue}, ${this.filterPracticeValue}, ${this.filterCompetencyValue}, ${this.filterSubmitterValue}, ${this.filterCMValue}`;
  }
}
