import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { FeedbackDetailModule } from 'src/app/shared/modules/feedback-detail/feedback-detail.module';
import { ViewFeedbackRoutingModule } from './view-feedback-routing.module';
import { ViewFeedbackComponent } from './view-feedback.component';

@NgModule({
  declarations: [ViewFeedbackComponent],
  imports: [
    CommonModule,
    ViewFeedbackRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    AngularMaterialModule,

    FeedbackDetailModule,
  ],
  providers: [DatePipe],
})
export class ViewFeedbackModule {}
