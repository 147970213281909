import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmFeedbackDetailComponent } from './cm-feedback-detail.component';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';

@NgModule({
  declarations: [CmFeedbackDetailComponent],
  imports: [CommonModule, AngularMaterialModule],
  exports: [CmFeedbackDetailComponent],
})
export class CmFeedbackDetailModule {}
