import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { filter, map, startWith } from 'rxjs/operators';
import { VexConfigName } from 'src/@vex/config/config-name.model';
import { ConfigService } from '../../../@vex/config/config.service';
import { LayoutService } from '../../../@vex/services/layout.service';
import { checkRouterChildsData } from '../../../@vex/utils/check-router-childs-data';
import { AuthService } from '../services/auth.service';

@UntilDestroy()
@Component({
  selector: 'vex-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.scss'],
})
export class CustomLayoutComponent implements OnInit {
  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(
    map(config => config?.footer.visible)
  );
  isDesktop$ = this.layoutService.isDesktop$;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() =>
      checkRouterChildsData(
        this.router.routerState.root.snapshot,
        data => data.toolbarShadowEnabled || false
      )
    )
  );

  // @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;

  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.configService.setConfig(
      this.authService.isAuthenticated()
        ? VexConfigName.apollo
        : VexConfigName.hermes
    );
    // this.layoutService.configpanelOpen$.pipe(
    //   untilDestroyed(this)
    // ).subscribe(open => open ? this.configpanel.open() : this.configpanel.close());
  }
}
