<vex-secondary-toolbar current="My Feedback">
  <vex-breadcrumbs [crumbs]="['My Feedback', 'Provided']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="row m-0">
  <div class="component-container">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <i class="fas fa-filter fa-lg"></i>
          <span>Filter Submissions ({{filtersValue}})</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-feedback-filter-criteria-form
        [searchCriteriaForm]="filterCriteriaForm"
        [onlinerFilterMode]="'AllUsers'"
        [isOnlinerFilterVisible]="true"
        [isSubmittedByFilterVisible]="false"
        [isOnProvidedPage]="true"
        [isCiaTypeVisible]="isCfrAdmin"
        (filterFeedbacksTriggered)="filterFeedbacks()"
        (filterDatesTriggered)="filterFeedbacks()">
      </app-feedback-filter-criteria-form>
    </mat-expansion-panel>
<br>
    <h3 class="page-title">My Provided Feedback {{filterDateDisplay}}</h3>
    <app-feedback-list *ngIf="!isLoading" [feedbacks]="filteredFeedbacks"></app-feedback-list>
  </div>
</div>
