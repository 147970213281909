import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {
  FbWeeklyReport,
  FbWeeklyReportSubmissionData,
  WeeklyReportService,
} from 'src/app/core/services/weekly-report-service';

@Component({
  selector: 'app-weekly-health-delivery-history',
  templateUrl: './weekly-health-delivery-history.component.html',
  styleUrls: ['./weekly-health-delivery-history.component.scss'],
})
export class WeeklyHealthDeliveryHistoryComponent implements OnInit {
  weeklyReports!: FbWeeklyReport[];
  weeklyReportSubmissionDates!: FbWeeklyReportSubmissionData[];
  isAdmin!: boolean;
  isCfrAdmin!: boolean;
  isLoading!: boolean;
  surveyYears: number[] = [];
  year = new Date().getFullYear();

  constructor(
    private weeklyReportService: WeeklyReportService,
    private snackBarService: SnackBarService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.setUpPage();
  }

  getMonday(targetWeek: Date) {
    const endDate = new Date(targetWeek);
    const prevMonday = new Date(targetWeek).setDate(endDate.getDate() - 4);
    return prevMonday;
  }

  getMyWeeklyReportsData() {
    this.weeklyReportService
      .getAllWeeklyReportsFromUser(this.authService.getUserId().toLowerCase())
      .subscribe(
        data => {
          this.weeklyReports = data;
          this.weeklyReports.sort((a, b) =>
            a.submittedDate < b.submittedDate
              ? 1
              : b.submittedDate < a.submittedDate
              ? -1
              : 0
          );
          this.surveyYears = [
            ...new Set(
              data.map(event => new Date(event.submittedDate).getFullYear())
            ),
          ];
          this.isLoading = false;
        },
        error => {
          this.snackBarService.error(error);
        }
      );
  }

  addNonSubmittedRows(
    wklyReports: FbWeeklyReport[],
    weeklyReportSubmissionDates: FbWeeklyReportSubmissionData[]
  ) {
    if (weeklyReportSubmissionDates) {
      const todaysDate = new Date();
      weeklyReportSubmissionDates.forEach(submissionItem => {
        const endDate = new Date(submissionItem.targetDate);
        const prevMonday = new Date(submissionItem.targetDate);
        prevMonday.setDate(prevMonday.getDate() - 4);
        let alreadyExists = false;
        wklyReports.forEach(report => {
          if (
            new Date(report.submittedDate) >= prevMonday &&
            new Date(report.submittedDate) <= endDate
          ) {
            alreadyExists = true;
          }
        });

        if (!alreadyExists) {
          if (new Date(todaysDate) >= new Date(submissionItem.cutoffDate)) {
            const newRow = new FbWeeklyReport();
            newRow.id = 0;
            newRow.submittedDate = submissionItem.targetDate;
            wklyReports.push(newRow);
          }
        }
      });
    }
    return wklyReports.sort((a, b) =>
      a.submittedDate < b.submittedDate
        ? 1
        : b.submittedDate < a.submittedDate
        ? -1
        : 0
    );
  }

  private setUpPage() {
    this.isLoading = true;
    this.getMyWeeklyReportsData();
  }
}
