import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  AssessmentService,
  ClientFeedbackOnOnline,
} from 'src/app/core/services/assessment.service';
import { FeedbackFilterService } from 'src/app/core/services/feedback-filter.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { ClientFeedbackOnlineListComponent } from './client-feedback-online-list/client-feedback-online-list.component';

@Component({
  selector: 'app-client-feedback-on-online',
  templateUrl: './client-feedback-on-online.component.html',
  styleUrls: ['./client-feedback-on-online.component.scss'],
})
export class ClientFeedbackOnOnlineComponent implements OnInit {
  @ViewChild(ClientFeedbackOnlineListComponent, { static: true })
  list!: ClientFeedbackOnlineListComponent;

  feedbacks!: ClientFeedbackOnOnline[];
  filteredFeedbacks!: ClientFeedbackOnOnline[];
  filterCriteriaForm!: UntypedFormGroup;
  filterDateDisplay!: string;

  filterClientValue!: string;

  filtersValue!: string;
  isLoading = true;

  constructor(
    private assessmentService: AssessmentService,
    private snackBarService: SnackBarService,
    private feedbackFilterService: FeedbackFilterService
  ) {}

  ngOnInit() {
    this.filterCriteriaForm =
      this.feedbackFilterService.getClientFeedbackOnOnlineFilterCriteriaForm();
    this.getClientFeedbackOnOnline();
  }

  filterFeedbacks() {
    const filterCriteriaModel =
      this.feedbackFilterService.getClientFeedbackOnOnlineFilterCriteriaModel();
    this.filterDateDisplay =
      this.feedbackFilterService.getFilterDateRangeDisplay(filterCriteriaModel);
    this.filteredFeedbacks = this.feedbacks.filter(
      f =>
        this.feedbackFilterService.filterClientFeedbackOnOnlineClient(
          f,
          filterCriteriaModel
        ) &&
        this.feedbackFilterService.filterClientFeedbackOnOnlineStartDate(
          f,
          filterCriteriaModel
        ) &&
        this.feedbackFilterService.filterClientFeedbackOnOnlineEndDate(
          f,
          filterCriteriaModel
        )
    );
    this.getFilterFormValues();
  }

  getFilterFormValues() {
    this.filterClientValue = `${this.filterCriteriaForm.controls.client.value['clientName']}`;
    this.filtersValue = `${this.filterClientValue}`;
  }

  downloadCSV() {
    this.list.downloadCSV(this.filterDateDisplay.replace(/,/g, ''));
  }

  private getClientFeedbackOnOnline() {
    this.assessmentService.getClientFeedbackOnOnline().subscribe(
      data => {
        this.feedbacks = data;
        this.filterFeedbacks();
        this.isLoading = false;
      },
      error => {
        this.snackBarService.error(error);
      }
    );
  }
}
