import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { AutoSuggestService } from 'src/app/core/services/auto-suggest.service';
import { Client } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-request-client-feedback-form',
  templateUrl: './request-client-feedback-form.component.html',
  styleUrls: ['./request-client-feedback-form.component.scss'],
})
export class RequestClientFeedbackFormComponent implements OnInit {
  @Input()
  requestFeedbackForm!: UntypedFormGroup;
  @Input()
  clients!: Client[];
  @Input()
  showDelete!: boolean;
  @Output() removeForm: EventEmitter<UntypedFormGroup> =
    new EventEmitter<UntypedFormGroup>();

  get clientControl() {
    return this.requestFeedbackForm?.controls['client'];
  }
  filteredClients!: Observable<Client[]>;

  constructor(private autoSuggestService: AutoSuggestService) {}

  ngOnInit() {
    this.setClientFilter();
  }

  resetForm() {
    this.requestFeedbackForm?.reset();
  }

  clientDisplay = (option?: Client): string =>
    this.autoSuggestService.clientDisplay(option);

  setClientFilter() {
    this.filteredClients = this.autoSuggestService.setClientFilter(
      this.clientControl,
      this.clients
    );
  }

  deleteForm() {
    this.removeForm.emit(this.requestFeedbackForm);
  }
}
