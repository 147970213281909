import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, forkJoin } from 'rxjs';
import { LayoutService } from 'src/@vex/services/layout.service';
import {
  AssessmentService,
  OnlinerFeedbackRequest,
} from 'src/app/core/services/assessment.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { AutoSuggestService } from 'src/app/core/services/auto-suggest.service';
import {
  CareerMentor,
  CareerMentorService,
  SavedCMDelegate,
} from 'src/app/core/services/career-mentor.service';
import { Client, CommonService } from 'src/app/core/services/common.service';
import {
  Employee,
  OnlinerService,
} from 'src/app/core/services/onliner.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { FeedbackTypeEnum } from 'src/app/shared/enums/enums.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-request-onliner-feedback-form',
  templateUrl: './request-onliner-feedback-form.component.html',
  styleUrls: ['./request-onliner-feedback-form.component.scss'],
})
export class RequestOnlinerFeedbackFormComponent implements OnInit, OnChanges {
  @Input()
  requestFeedbackForm!: UntypedFormGroup;
  @Input() isCMRequest = false;
  @ViewChild('autosize')
  autosize!: CdkTextareaAutosize;
  isCollapsed = true;
  onliners!: Employee[];
  clients!: Client[];
  delegates!: SavedCMDelegate[];
  careerMentors!: Employee[];

  filteredOnliners!: Observable<Employee[]> | Observable<CareerMentor[]>;
  filteredClients!: Observable<Client[]>;
  filteredCareerMentors!: Observable<Employee[]> | Observable<CareerMentor[]>;

  selectedOnliners!: Employee[];
  selectedCM: Employee | null = null;
  cmAssigned = false;
  defaultOnlineBusinessSystems?: Client;
  cmList!: Employee[];

  isLoading!: boolean;
  isSubmitting!: boolean;
  tooltipItems!: string[];
  toolTip!: string;
  isCM = false;
  isCMDelegate = false;

  constructor(
    private assessmentService: AssessmentService,
    private commonService: CommonService,
    private onlinerService: OnlinerService,
    private snackBarService: SnackBarService,
    private autoSuggestService: AutoSuggestService,
    private authService: AuthService,
    private careerMentorService: CareerMentorService,
    private layoutService: LayoutService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.isCM = this.authService.doesUserHaveRole([
      environment.roles.FeedbackCm,
    ]);

    forkJoin([
      this.onlinerService.getOnlinersExcludingDefault(),
      this.commonService.getClientsExcludingDefault(),
      this.careerMentorService.getDelegates(),
    ]).subscribe(
      ([onliners, clients, delegates]) => {
        this.onliners = onliners;
        this.setOnlinerFilter();

        this.careerMentors = onliners.filter(d => d.isCM);

        this.delegates = delegates;
        this.isCMDelegate =
          this.delegates.findIndex(
            d => d.delegateId === this.authService.getUserId().toLowerCase()
          ) > -1;
        this.careerMentorService.isDelegated(this.isCMDelegate);

        this.setCMFilter();

        this.clients = clients;
        this.defaultOnlineBusinessSystems = this.clients.find(
          c => c.clientId === 326
        );
        this.setClientFilter();
        this.setClientValidators();
        this.setDefaultMessage();

        this.tooltipItems = [
          'Choose "Other" if the client is not in the list.',
          'Choose "Online Business Systems" if the Onliner is in a non-billable role.',
        ];
        this.toolTip = this.tooltipItems.join('\r\n');

        this.selectedOnliners = [];

        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        this.snackBarService.error(error);
      }
    );
    this.layoutService.sidenavCollapsed$.subscribe(collapsed => {
      this.isCollapsed = collapsed;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    let cmRequestUpdate = this.isCMRequest;

    for (const propName in changes) {
      if (propName === 'isCMRequest') {
        cmRequestUpdate = changes[propName].currentValue;
        if (cmRequestUpdate) {
          this.requestFeedbackForm.controls['careerMentor'].enable();

          if (
            !this.cmAssigned &&
            this.requestFeedbackForm.get('careerMentor')?.status === 'INVALID'
          ) {
            this.requestFeedbackForm.controls['onliner'].disable();
          }

          this.requestFeedbackForm.controls['client'].setValue(
            this.defaultOnlineBusinessSystems
          );
          this.setClientValidators();
          this.setCMFilter();
          this.setDefaultMessage();
          this.selectedOnliners = [];
        } else {
          this.requestFeedbackForm.controls['onliner'].enable();
          this.resetForm();
          this.setDefaultMessage();
        }
      }
    }

    this.isCMRequest = cmRequestUpdate;
  }

  get onlinerControl() {
    return this.requestFeedbackForm.controls['onliner'];
  }
  get cmControl() {
    return this.requestFeedbackForm.controls['careerMentor'];
  }
  get clientControl() {
    return this.requestFeedbackForm.controls['client'];
  }

  setDefaultMessage(): void {
    let message = '';

    if (this.isCMRequest) {
      message =
        'You are receiving this request form because I feel you can provide insights that will contribute to my ongoing growth and development as a Career Mentor. Please provide feedback and include examples if possible that outline my strengths, accomplishments, and/or areas for development.';
    } else {
      message =
        'You are receiving this request form because I feel you can provide insights that will contribute to my ongoing growth and development. Please provide feedback and include examples if possible that outline my strengths, accomplishments, and/or areas for development. Thank you.';
    }

    this.requestFeedbackForm.controls['message'].setValue(message);
  }

  noEventCMSelected(emp: Employee) {
    if (emp) {
      this.cmAssigned = true;
      this.selectedCM = emp;
      this.requestFeedbackForm.controls['onliner'].enable();
      this.setOnlinerFilter([emp]);
    }
  }

  cmSelected(e: MatAutocompleteSelectedEvent) {
    const value = e.option.value;

    if ((e.source && value) || value === '') {
      this.cmAssigned = true;
      this.selectedCM = value;
      this.requestFeedbackForm.controls['onliner'].enable();
      this.setOnlinerFilter([value]);
    }
  }

  resetForm() {
    this.selectedOnliners = [];

    if (this.isCMRequest) {
      this.requestFeedbackForm.controls['careerMentor'].enable();

      if (
        !this.cmAssigned &&
        this.requestFeedbackForm.get('careerMentor')?.status === 'INVALID'
      ) {
        this.requestFeedbackForm.controls['onliner'].disable();
      }

      this.requestFeedbackForm.controls['client'].setValue(
        this.defaultOnlineBusinessSystems
      );
      this.setClientValidators();
      this.setCMFilter();
      this.setDefaultMessage();
      this.selectedOnliners = [];
    } else {
      this.cmAssigned = false;
      this.setOnlinerFilter();
      this.setCMFilter();
      this.requestFeedbackForm.reset();
      if (this.isCMRequest) {
        this.requestFeedbackForm.controls['client'].setValue(
          this.defaultOnlineBusinessSystems
        );
      }
      this.setDefaultMessage();
    }
  }

  validateClient(): ValidatorFn {
    return (clientControl: AbstractControl) => {
      this.requestFeedbackForm.controls['otherClient'].updateValueAndValidity();
      if (!clientControl.value || !clientControl.value.clientName) {
        const temp: ValidationErrors = {};
        temp['required'] = true;
        return temp;
      }
      return null;
    };
  }

  validateOtherClient(): ValidatorFn {
    return (otherClientControl: AbstractControl) => {
      const isOtherClient =
        this.requestFeedbackForm.controls['client'].value &&
        this.requestFeedbackForm.controls['client'].value.clientName ===
          'Other';
      if (
        (!otherClientControl.value ||
          otherClientControl.value.trim().length <= 0) &&
        isOtherClient
      ) {
        const temp: ValidationErrors = {};
        temp['required'] = true;
        return temp;
      }
      return null;
    };
  }

  onlinerDisplay = (option?: Employee | CareerMentor): string =>
    this.autoSuggestService.onlinerDisplay(option);

  clientDisplay = (option?: Client): string =>
    this.autoSuggestService.clientDisplay(option);

  setOnlinerFilter(cmList: Employee[] | null = null) {
    if (this.onliners) {
      this.onlinerControl.reset();
      let onlinersExceptExisted = this.onliners;

      if (!this.isCMRequest) {
        if (this.selectedOnliners) {
          onlinersExceptExisted = this.onliners.filter(
            f => !this.selectedOnliners.some(x => x.userId === f.userId)
          );
        }
      } else {
        if (cmList !== null) {
          onlinersExceptExisted = [];
          cmList.forEach(cm => {
            if (
              this.onliners.findIndex(
                o => o.careerManager === cm.userId.toLowerCase()
              ) > -1
            ) {
              this.onliners
                .filter(o => o.careerManager === cm.userId.toLowerCase())
                .forEach(on => {
                  if (this.selectedOnliners) {
                    if (
                      this.selectedOnliners.findIndex(
                        o => o.userId === on.userId
                      ) < 0
                    ) {
                      onlinersExceptExisted.unshift(on);
                    }
                  } else {
                    onlinersExceptExisted.unshift(on);
                  }
                });
            }
          });
        }
      }

      this.filteredOnliners = this.autoSuggestService.setOnlinerFilter(
        this.onlinerControl,
        onlinersExceptExisted
      );

      if (onlinersExceptExisted.length === 0) {
        this.requestFeedbackForm.controls['onliner'].disable();
      } else if (!(this.isCMRequest && !this.cmAssigned)) {
        this.requestFeedbackForm.controls['onliner'].enable();
      }
    }
  }

  setCMFilter() {
    if (this.careerMentors) {
      if (this.isCMRequest) {
        this.cmControl.reset();
        let cmList: Employee[] = [];
        // let employeeFromDelegate: Employee;
        const loggedInUser = this.onliners.find(
          f => f.userId === this.authService.getUserId().toLowerCase()
        );

        // if the user is a delegate
        if (loggedInUser && loggedInUser.isCMDelegate && this.delegates) {
          this.delegates = this.delegates.filter(
            d => d.delegateId === loggedInUser.userId.toLowerCase()
          );
          const cmEmployee = this.onliners.find(
            f => f.userId === this.delegates[0].careerManagerId.toLowerCase()
          );
          this.requestFeedbackForm.controls['careerMentor'].setValue(
            cmEmployee
          );

          if (cmEmployee) {
            this.noEventCMSelected(cmEmployee);
          }

          this.delegates.forEach(delegate => {
            const onliner = this.onliners.find(
              f => f.userId === delegate.careerManagerId.toLowerCase()
            );
            if (onliner) {
              cmList.push(onliner);
            }

            // if the user is both a CM and a delegate
            if (
              loggedInUser &&
              loggedInUser.isCM &&
              cmList.findIndex(
                cm => cm.userId === loggedInUser.userId.toLowerCase()
              ) === -1
            ) {
              cmList.push(loggedInUser);
              this.requestFeedbackForm.controls['careerMentor'].setValue(
                loggedInUser
              );
              this.noEventCMSelected(loggedInUser);
            }

            this.filteredCareerMentors =
              this.autoSuggestService.setCareerMentorFilter(
                this.cmControl,
                cmList
              );
            this.setOnlinerFilter([
              this.requestFeedbackForm.controls['careerMentor'].value,
            ]);
          });
        } else if (loggedInUser && loggedInUser.isCM) {
          // if the user is only a CM
          cmList = [loggedInUser];
          this.filteredCareerMentors =
            this.autoSuggestService.setCareerMentorFilter(
              this.cmControl,
              cmList
            );
          this.requestFeedbackForm.controls['careerMentor'].setValue(
            loggedInUser
          );
          this.noEventCMSelected(loggedInUser);
          this.requestFeedbackForm.controls['careerMentor'].disable();
          this.setOnlinerFilter([
            this.requestFeedbackForm.controls['careerMentor'].value,
          ]);
        }
      }
    }
  }

  setClientFilter() {
    this.filteredClients = this.autoSuggestService.setClientFilter(
      this.clientControl,
      this.clients
    );
  }

  submitOnlinerFeedbackRequest() {
    this.isSubmitting = true;
    this.requestFeedbackForm.disable(); // Ideally you want to bind the disable property of the control to a function/property that will determine when to disable/enable the form.
    const onlinerFeedbackRequests: OnlinerFeedbackRequest[] = [];

    for (const onliner of this.selectedOnliners) {
      const onlinerRequest = this.createRequest(onliner);
      onlinerFeedbackRequests.push(onlinerRequest);
    }

    this.assessmentService
      .requestOnlinerFeedback(onlinerFeedbackRequests)
      .subscribe(
        () => {
          this.snackBarService.message('Request successfully submitted');
          this.isSubmitting = false;
          this.requestFeedbackForm.enable();
          this.resetForm();
        },
        error => {
          this.isSubmitting = false;
          this.requestFeedbackForm.enable();
          this.snackBarService.error(error);
        }
      );
  }

  isOnlinerBoxNotEmpty(): boolean {
    return this.selectedOnliners && this.selectedOnliners.length > 0;
  }

  isOnlinerSelected(): boolean {
    return this.requestFeedbackForm.controls['onliner'].valid;
  }

  isRequestFormValid(): boolean {
    return (
      this.requestFeedbackForm.controls['client'].valid &&
      this.requestFeedbackForm.controls['otherClient'].valid &&
      this.requestFeedbackForm.controls['message'].valid &&
      this.isOnlinerBoxNotEmpty()
    );
  }

  addRequestFrom() {
    this.selectedOnliners.push(
      this.requestFeedbackForm.controls['onliner'].value
    );

    if (this.isCMRequest) {
      if (this.selectedCM) {
        this.setOnlinerFilter([this.selectedCM]);
      }
      if (this.selectedOnliners.length === 1) {
        this.requestFeedbackForm.controls['careerMentor'].disable();
      }
    } else {
      this.setOnlinerFilter();
    }
  }

  removeOnlinerFromBox(onliner: Employee) {
    const index = this.selectedOnliners.indexOf(onliner);

    if (index > -1) {
      this.selectedOnliners.splice(index, 1);
    }

    if (this.isCMRequest) {
      if (this.selectedCM) {
        this.setOnlinerFilter([this.selectedCM]);
      }
      if (this.selectedOnliners.length === 0) {
        this.requestFeedbackForm.controls['careerMentor'].enable();
      }
    } else {
      this.setOnlinerFilter();
    }
  }

  private setClientValidators() {
    this.requestFeedbackForm.controls['client'].setValidators(
      this.validateClient()
    );
    this.requestFeedbackForm.controls['otherClient'].setValidators(
      this.validateOtherClient()
    );
  }
  private createRequest(onliner: Employee): OnlinerFeedbackRequest {
    const feedbackRequest = new OnlinerFeedbackRequest();
    feedbackRequest.requestTo = onliner.userId;
    feedbackRequest.clientId = this.requestFeedbackForm.value.client
      ? this.requestFeedbackForm.value.client.clientId
      : null;
    const currentUserId = this.authService.getUserId().toLowerCase();

    if (this.isCMRequest) {
      feedbackRequest.feedbackTypeId = FeedbackTypeEnum.CareerMentorFeedback;
      const targetCM =
        this.requestFeedbackForm.value.careerMentor.userId.toLowerCase();
      feedbackRequest.requestFrom = targetCM;

      if (this.isCMDelegate && targetCM !== currentUserId) {
        feedbackRequest.requestDelegate = currentUserId;
      }
    } else {
      feedbackRequest.feedbackTypeId = FeedbackTypeEnum.AdhocFeedback;
    }

    const requestMessage = this.requestFeedbackForm.value.message.replace(
      /\r?\n/g,
      '<br>'
    );
    feedbackRequest.requestContext = requestMessage ? requestMessage : null;

    feedbackRequest.otherClient = this.requestFeedbackForm.value.otherClient
      ? this.requestFeedbackForm.value.otherClient
      : null;
    feedbackRequest.requestDate = new Date();
    return feedbackRequest;
  }
}
