import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackListComponent } from './feedback-list.component';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';

@NgModule({
  declarations: [FeedbackListComponent],
  imports: [CommonModule, AngularMaterialModule],
  exports: [FeedbackListComponent],
})
export class FeedbackListModule {}
