import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RemoteWorkRequestsRoutingModule } from './remote-work-requests-routing.module';
import { RemoteWorkRequestsComponent } from './remote-work-requests.component';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RemoteWorkRequestCriteriaFormComponent } from './remote-work-request-criteria-form/remote-work-request-criteria-form.component';
import { RemoteWorkRequestListComponent } from './remote-work-request-list/remote-work-request-list.component';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';

@NgModule({
  declarations: [
    RemoteWorkRequestsComponent,

    RemoteWorkRequestCriteriaFormComponent,
    RemoteWorkRequestListComponent,
  ],
  imports: [
    CommonModule,
    RemoteWorkRequestsRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class RemoteWorkRequestsModule {}
