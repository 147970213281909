import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CmFeedbackFormComponent } from './cm-feedback-form/cm-feedback-form.component';
import { ConfidentialFeedbackFormComponent } from './confidential-feedback-form/confidential-feedback-form.component';
import { FeedbackFormComponent } from './feedback-form/feedback-form.component';
import { FeedbackRoutingModule } from './feedback-routing.module';
import { FeedbackComponent } from './feedback.component';
import { SelfAssessmentComponent } from './self-assessment/self-assessment.component';
import { WeeklyHealthDeliveryFormComponent } from './weekly-health-delivery-form/weekly-health-delivery-form.component';

@NgModule({
  declarations: [
    FeedbackComponent,
    FeedbackFormComponent,
    CmFeedbackFormComponent,
    WeeklyHealthDeliveryFormComponent,
    SelfAssessmentComponent,
    ConfidentialFeedbackFormComponent,
  ],
  imports: [
    CommonModule,
    FeedbackRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,

    SharedModule,
  ],
})
export class FeedbackModule {}
