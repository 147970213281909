<div *ngIf="!isLoading">
  <mat-form-field class="col-lg-4 col-md-12 col-sm-12 col-xs-12 p-0" appearance="outline">
      <mat-label>Form Type</mat-label>
      <mat-select name="feedbackType" [(value)]="selectedForm" (selectionChange)="formSelected()">
        <mat-option *ngFor="let form of formTypes" [value]="form">{{ form.typeDescr }}</mat-option>
      </mat-select>
  </mat-form-field>
  <span>
      <button mat-stroked-button class="m-2"(click)="editFeedbackType()" [hidden]="isLoadingQuestionGroups">
          EDIT
      </button>
  </span>
</div>

<div *ngIf="!isLoadingQuestionGroups" class="table-header">Available Question Groups
  <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 table-container mat-elevation-z8">
      <table cdkDropList (cdkDropListDropped)="drop($event)" mat-table [dataSource]="questionGroups">
          <ng-container matColumnDef="dragHandle">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell class="px-3 d-block" *matCellDef="let answer">
                  <mat-icon
                      class="fas fa-sort d-flex align-items-center"
                      cdkDragHandle
                      (touchstart)="dragDisabled = false"
                      (touchend)="dragDisabled = true"
                      (mousedown)="dragDisabled = false"
                      (mouseup)="dragDisabled = true"
                      matTooltip="{{dragHandleTooltip}}">
                  </mat-icon>
              </mat-cell>
          </ng-container>

          <ng-container matColumnDef="active">
              <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
              <mat-cell *matCellDef="let questionGroup; let i = index">
                  <form [formGroup]="activeFormGroups[i]">
                      <mat-slide-toggle formControlName="activeControl"></mat-slide-toggle>
                  </form>
              </mat-cell>
          </ng-container>

          <ng-container matColumnDef="questionGroup">
              <mat-header-cell *matHeaderCellDef>Question Group</mat-header-cell>
              <mat-cell *matCellDef="let questionGroup" (click)="editQuestionGroup(questionGroup)">
                  {{questionGroup.name}}
              </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="questionGroupColumns"></mat-header-row>
          <mat-row
              class="mat-row-clickable"
              *matRowDef="let row; let i = index; columns: questionGroupColumns;"
              style="cursor: pointer"
              cdkDrag
              (cdkDragStarted)="start($event,i)"
              [cdkDragDisabled]="dragDisabled"
              (cdkDragReleased)="dragDisabled = true">
          </mat-row>
      </table>
      <button mat-stroked-button class="questions-add-button m-2" (click)="addQuestionGroup()">ADD</button>
  </div>
  <div *ngIf="hasNoActive">Warning: There are no active question groups. No questions will appear in the form {{selectedForm?.typeDescr || ''}}</div>
  <button mat-stroked-button class="save-btn mt-4" (click)="save()" [disabled]="isSaveDisabled">SAVE</button>
</div>
