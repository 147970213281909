
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" [hidden]="!(dataSource && dataSource.data.length > 0)">

    <ng-container matColumnDef="employeeName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Onliner</th>
      <td mat-cell *matCellDef="let value" class="leftAlignCell">
        {{ value.employeeName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="practice">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Practice</th>
      <td mat-cell *matCellDef="let value" class="leftAlignCell">
        {{ value.practice }}
      </td>
    </ng-container>

    <ng-container matColumnDef="submittedByName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Submitted By</th>
      <td mat-cell class="text-center" *matCellDef="let value" class="leftAlignCell">
        {{ value.submittedByName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="submittedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Submitted On</th>
        <td mat-cell *matCellDef="let value" class="leftAlignCell">
          {{ value.submittedDate | date:'MM/dd/yyyy' }}
        </td>
      </ng-container>

    <tr mat-header-row *matHeaderRowDef="resultColumnHeaders"></tr>
    <tr mat-row class="mat-row-clickable" *matRowDef="let row; columns: resultColumnHeaders" style="cursor: pointer" (click)="onSelect(row)"></tr>
  </table>
  <div *ngIf="dataSource && dataSource.data.length === 0">No records found</div>

