import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SavedFbConfidential } from 'src/app/core/services/assessment.service';
import { FeedbackService } from 'src/app/core/services/feedback.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-view-confidential-feedback',
  templateUrl: './view-confidential-feedback.component.html',
  styleUrls: ['./view-confidential-feedback.component.scss'],
})
export class ViewConfidentialFeedbackComponent implements OnInit {
  feedbackId!: number;

  feedback!: SavedFbConfidential;
  operationInProgress: string | null = null;
  showActionPanel = true;

  constructor(
    private feedbackService: FeedbackService,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.feedbackId = +params['id'];
      this.operationInProgress = 'loading';
      this.getFeedbackDetail(this.feedbackId);
    });
  }

  private getFeedbackDetail(feedbackId: number) {
    this.feedbackService.getConfidentialFeedback(feedbackId, false).subscribe(
      data => {
        this.feedback = data;
        this.operationInProgress = null;
      },
      error => {
        this.snackBarService.error(error);
        this.operationInProgress = null;
      }
    );
  }
}
