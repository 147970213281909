import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SavedFbConfidential } from 'src/app/core/services/assessment.service';

@Component({
  selector: 'app-confidential-feedback-list',
  templateUrl: './confidential-feedback-list.component.html',
  styleUrls: ['./confidential-feedback-list.component.scss'],
})
export class ConfidentialFeedbackListComponent implements OnChanges {
  @Input() set feedbacks(feedback: SavedFbConfidential[]) {
    this.dataSource = new MatTableDataSource<SavedFbConfidential>(feedback);
    if (!feedback) {
      this.dataSource = new MatTableDataSource<SavedFbConfidential>();
    }
  }

  @ViewChild(MatSort, { static: true })
  sort!: MatSort;

  selected = [];
  dataSource!: MatTableDataSource<SavedFbConfidential>;

  resultColumnHeaders: string[] = [
    'employeeName',
    'practice',
    'submittedByName',
    'submittedDate',
  ];

  constructor(private router: Router) {}

  ngOnChanges() {
    this.dataSource.sort = this.sort;
  }

  onSelect(selected: SavedFbConfidential) {
    const routeToNavigate = 'viewConfidentialNotes';
    this.router.navigate([routeToNavigate, selected.id]);
  }
}
