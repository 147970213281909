<vex-secondary-toolbar current="Review">
  <vex-breadcrumbs [crumbs]="['Review', 'Confidential Notes']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div class="row m-0">
  <div class="component-container">
      <mat-expansion-panel>
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <i class="fas fa-filter fa-lg"></i>
                  <span>Filter Submissions ({{filtersValue}})</span>
              </mat-panel-title>
          </mat-expansion-panel-header>
          <app-feedback-filter-criteria-form
              [searchCriteriaForm]="filterCriteriaForm"
              onlinerFilterMode="AllUsers"
              [isConfidentialFeedbackPage] = "true"
              [isOnlinerFilterVisible]="true"
              [isSubmissionTypeFilterVisible] = "false"
              [isSubmittedByFilterVisible]="true"
              [isClientFilterVisible] = "false"
              (filterFeedbacksTriggered)="filterConFeedbacks()"
              (filterDatesTriggered)="filterDates()">
          </app-feedback-filter-criteria-form>
      </mat-expansion-panel>
      <br>
      <h3 class="page-title">Confidential Notes {{filterDateDisplay}}</h3>
      <app-confidential-feedback-list *ngIf="!isLoading" [feedbacks]="filteredConFeedbacks"> </app-confidential-feedback-list>

      <div *ngIf="isLoading" class="grid place-items-center inset-0">
        <mat-spinner></mat-spinner>
      </div>
  </div>
</div>
