<vex-secondary-toolbar current="Request">
  <vex-breadcrumbs [crumbs]="['Request', 'Client']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="row m-0 component-container">
  <div class="mb-3 col-md-12 col-sm-12 col-xs-12 m-0 p-0">
    <h3 class="page-title">Request Client Feedback</h3>
  </div>

  <div class="row m-0">
    <div class="col-md-8 col-sm-12 col-xs-12 m-0 p-0">
      <div *ngFor="let requestForm of clientFeedbackForms.controls"
        class="feedback-form-div">
        <app-request-client-feedback-form
          [requestFeedbackForm]="asUntypedFormGroup(requestForm)" [clients]="clients"
          [showDelete]="clientFeedbackForms.controls.length > 1 && !isSubmitting"
          (removeForm)="removeForm($event)">
        </app-request-client-feedback-form>
        <br>
      </div>
      <div *ngIf="clientFeedbackForms.controls.length > 0"
        [ngClass]="isCollapsed ? 'fixed-above-footer fixed-collapsed' : 'fixed-above-footer fixed-expanded'"
        class="row form-group m-0 pt-1 feedback-detail-container">
        <button type="button" mat-stroked-button
          class="reset-btn"
          color="basic"
          (click)="addClientForm()"
          [disabled]="!clientFeedbackForms.valid || isSubmitting">ADD CLIENT</button>
        <button type="button" mat-stroked-button
          class="submit-btn"
          color="primary"
          (click)="submitRequest()"
          [disabled]="!clientFeedbackForms.valid || isSubmitting">
          <span *ngIf="isSubmitting"><i class="fa fa-spinner fa-spin"
              aria-hidden="true"></i></span>SUBMIT
        </button>
      </div>
      <br>
    </div>

    <div class="col-md-4 col-sm-12 col-xs-12" fit-content-height>

      <mat-expansion-panel class="no-border-radius" expanded=true>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h4><i class="fas fa-info-circle" title="Tips for Feedback"></i>
              Tips for Feedback</h4>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <p>This form is used for requesting feedback from clients. Please select
          or enter the client company name, and enter the name and email of the
          specific individual you want to request feedback from.</p>
        <p>Click on <strong>ADD CLIENT</strong> to add another client feedback
          request.</p>

        <p>Your request will be submitted to People Care, who will reach out to
          the client(s) on your behalf.</p>
      </mat-expansion-panel>
    </div>
  </div>
</div>
