import { Component, HostListener, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';
import { ClientFeedbackService } from 'src/app/core/services/client-feedback.service';
import {
  Question,
  QuestionGroup,
  QuestionOption,
  QuestionOptionGroup,
} from 'src/app/core/services/feedback.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {
  FeedbackTypeEnum,
  QuestionTypeEnum,
} from 'src/app/shared/enums/enums.model';
import { QuestionType } from 'src/app/shared/models/questions.model';

@Component({
  selector: 'app-client-feedback',
  templateUrl: './client-feedback.component.html',
  styleUrls: ['./client-feedback.component.scss'],
})
export class ClientFeedbackComponent implements OnInit {
  @Input()
  feedbackId!: string;

  isCollapsed = true;
  onlinerHeading!: string;
  feedbackForm!: UntypedFormGroup;
  assessment!: PublicAssessment;
  questions!: Question[];
  questionGroups!: QuestionGroup[];
  questionOptions!: QuestionOption[];
  questionOptionGroups!: QuestionOptionGroup[];
  contactName!: string;

  QuestionType = QuestionType;
  feedbackTypeId!: number;
  questionGroupIDs!: number[];
  questionOptionGroupIDs!: number[];
  OnlinerName!: string;
  bannerDisabled = true;
  cookieDomain!: string;
  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private snackBarService: SnackBarService,
    private clientFeedbackService: ClientFeedbackService,
    private cookieService: CookieService,
    private toastrService: ToastrService
  ) {}

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    return !this.feedbackForm.dirty;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.feedbackId = params['id'];
    });

    forkJoin([
      this.clientFeedbackService.getFeedback(this.feedbackId),
      this.clientFeedbackService.getClientAssessmentInfo(this.feedbackId),
    ]).subscribe(
      ([feedback, clientAssessmentInfo]) => {
        this.assessment = feedback;
        this.questions = [...clientAssessmentInfo.questions];
        this.questionGroups = [...clientAssessmentInfo.questionGroups];
        this.questionOptions = [...clientAssessmentInfo.questionOptions];
        this.questionOptionGroups = [
          ...clientAssessmentInfo.questionOptionGroups,
        ];
        this.contactName = clientAssessmentInfo.contactName;

        this.OnlinerName = this.assessment.onliner;
        this.feedbackTypeId = FeedbackTypeEnum.ClientFeedback;
        this.questionOptionGroupIDs = [];
        this.questionGroupIDs = [];

        const groups = this.questionGroups.filter(
          qg => qg.type === this.feedbackTypeId
        );
        if (groups) {
          groups.forEach(group => {
            this.questionGroupIDs.push(group.id);
            this.populateQuestionOptionGroupIDs(group);
          });

          this.createFeedbackForm();
        }
      },
      error => {
        this.snackBarService.error(error);
        this.isLoading = false;
      }
    );
  }

  extractHostname(url: string): string {
    let hostname;

    if (url.indexOf('//') > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }

    if (url.indexOf('www.') > -1) {
      hostname = url.split('www.')[1];
    }

    hostname = hostname.split(':')[0];
    hostname = hostname.split('?')[0];

    return hostname;
  }

  populateQuestionOptionGroupIDs(group: QuestionGroup) {
    this.questions.forEach(q => {
      if (q.optionGroupId && q.groupId === group.id) {
        if (this.questionOptionGroupIDs.length > 0) {
          if (!this.questionOptionGroupIDs.some(qo => qo === q.optionGroupId)) {
            this.questionOptionGroupIDs.push(q.optionGroupId);
          }
        } else {
          this.questionOptionGroupIDs.push(q.optionGroupId);
        }
      }
    });
  }

  createFeedbackForm() {
    const group: {
      [key: string]: AbstractControl<unknown, unknown>;
    } = {};
    let questions: Question[];

    for (const qGroupId of this.questionGroupIDs) {
      questions = [...this.questions.filter(qu => qu.groupId === qGroupId)];
      for (const question of questions) {
        if (question !== null) {
          if (question.isOptional) {
            group[`${question.id}`] = new UntypedFormControl('');
          } else {
            group[`${question.id}`] = new UntypedFormControl(
              '',
              Validators.required
            );
          }
          if (question.type.toString() === 'Radio_Text') {
            group[`${question.id}_text`] = new UntypedFormControl('');
          }
        }
      }
    }

    this.feedbackForm = new UntypedFormGroup(group);
    this.isLoading = false;
  }

  get isLargeWindow() {
    const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    return windowWidth >= 768;
  }

  private addFormControls(form: UntypedFormGroup, groups: QuestionGroup[]) {
    let value: string;

    // partially sets questions as well.. Need to make sure this is carried over in the form component
    for (const group of groups) {
      for (const question of group.questions) {
        value = this.cookieService.get(String(question.id));

        const stringValue = this.cookieService.get(String(question.id));
        const intValue = parseInt(value, 10) || 0;
        let control: UntypedFormControl = new UntypedFormControl();
        switch (question.type) {
          case QuestionTypeEnum.Select:
          case QuestionTypeEnum.Radio:
          case QuestionTypeEnum.Scale:
            control = new UntypedFormControl(intValue);
            break;
          case QuestionTypeEnum.Text:
            control = new UntypedFormControl(stringValue);
            break;
        }
        if (question.isOptional) {
          control.setValidators(Validators.required);
        }
        form.addControl(String(question.id), control);
      }
    }
  }
}

export class PublicAssessment {
  onliner!: string;
}

export class FormType {
  Id!: number;
  name!: string;
}
