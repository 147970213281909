import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  PopupRequest,
} from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { interceptAndRetry } from '../utilities/retryUtil';

@Injectable()
export class AuthService {
  sessionExpiredDialogIsOpened = new EventEmitter<boolean>(false);
  public renewIdTokenRequest = {
    scopes: [environment.webApiClientId + '/.default'],
    // caches: CacheLookupPolicy.AccessToken,
    account: this.getUserProfile() || undefined,
  };

  constructor(
    private msalAuthService: MsalService,
    private http: HttpClient
  ) {}

  logout(): void {
    const currentAccount = this.msalAuthService.instance.getActiveAccount();
    const url =
      environment.apiEndpoint +
      '/api/authorization/invalidate/' +
      this.getUserId();
    const handleLogout = () => {
      this.msalAuthService.logout({
        account: currentAccount,
      });
    };

    this.http
      .post(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      })
      .subscribe({
        complete: handleLogout,
        error: handleLogout,
      });
  }

  isAuthenticated(): boolean {
    return !!this.getUserProfile();
  }

  getUserId(): string {
    return (
      this.getUserProfile()?.idTokenClaims?.preferred_username || ''
    ).split('@')[0];
  }

  getUserProfile(): AccountInfo | null {
    return this.msalAuthService.instance.getActiveAccount();
  }

  getUserRoles(): string[] {
    return this.getUserProfile()?.idTokenClaims?.roles || [];
  }

  doesUserHaveRole(roles: string[]): boolean {
    if (!roles || roles.length === 0) {
      return true;
    }

    if (!this.isAuthenticated()) {
      return false;
    }

    const userRoles = this.getUserProfile()?.idTokenClaims?.roles;
    if (!roles || roles.length <= 0 || !userRoles || userRoles.length <= 0) {
      return false;
    }

    let userHaveRole = false;

    userRoles.forEach(userRole => {
      if (roles.includes(userRole)) {
        userHaveRole = true;
        return true;
      }
      return false;
    });

    return userHaveRole;
  }

  acquireToken(
    type: 'popup' | 'redirect' | 'silent',
    totalNumAttempts?: number
  ): Observable<AuthenticationResult | void> {
    // In Edge v120, `acquireTokenPopup` without `prompt` will cause the app hang.
    // Adding a 'login' promot to force user retype password will solve this issue.
    const popupRequest: PopupRequest = /edg\//i.test(window.navigator.userAgent)
      ? { ...this.renewIdTokenRequest, prompt: 'login' }
      : this.renewIdTokenRequest;

    switch (type) {
      case 'popup':
        return interceptAndRetry(
          () => this.msalAuthService.acquireTokenPopup(popupRequest),
          250,
          totalNumAttempts ?? 7
        );

      case 'redirect':
        return interceptAndRetry(
          () =>
            this.msalAuthService.acquireTokenRedirect(this.renewIdTokenRequest),
          250,
          7
        );

      case 'silent':
      default:
        return interceptAndRetry(
          () =>
            this.msalAuthService.acquireTokenSilent(this.renewIdTokenRequest),
          250,
          7
        );
    }
  }
}
