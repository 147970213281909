import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export class ConfirmDialog {
  title!: string;
  message!: string;
  okButtonTitle!: string;
  cancelButtonTitle!: string;
  theme!: string;
  onClickHandler?: () => void;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  confirmDialog: ConfirmDialog;
  public data: ConfirmDialog = inject(MAT_DIALOG_DATA);

  constructor() {
    this.confirmDialog = this.data;
    if (!this.confirmDialog.theme || this.confirmDialog.theme === '') {
      this.confirmDialog.theme = 'success';
    }
    if (
      !!this.confirmDialog.onClickHandler &&
      this.confirmDialog.onClickHandler instanceof Function
    ) {
      this.onClickHandler = this.confirmDialog.onClickHandler;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClickHandler: () => void = () => {};
}
