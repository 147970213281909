export enum CommentTypeEnum {
  Technical = 1,
  Business = 2,
  Management = 3,
  CoreConsultantCompetencies = 4,
  ProjectOverview = 5,
  CareerManager = 6,
  AnnualSelfEvaluation = 7,
  AnnualReviewSummaryEmployeeComments = 8,
  _3MonthReviewSummaryEmployeeComments = 9,
  Work = 10,
  Personal = 11,
  Interpersonal = 12,
  Leadership = 13,
  AnnualSelfEvaluationGRT = 14,
  CorporateCitizenship = 15,
  _2006NonGRTSelfEval = 16,
}

export enum FeedbackTypeEnum {
  AdhocFeedback = 9,
  ClientFeedback = 16,
  CultureInAction = 17,
  CareerMentorFeedback = 18,
  WeeklyHealthDelivery = 20,
  ThirdPartyFeedback = 21,
  SelfAssessment = 22,
  RemoteWorkRequest = 23,
  ConfidentialFeedback = 24,
}

export enum ClientIdEnum {
  OnlineBusinessSystems = 326,
}

export enum FeedbackStatusEnum {
  Posted = 0,
  Pending = 1,
  Rejected = 2,
  Draft = 3,
  Acknowledged = 4,
  Reviewed = 5,
  Reviewed_Updated = 7,
  SavedPosted = 9,
  Requested = 10,
}

export enum QuestionTypeEnum {
  Text = 1,
  Scale = 2,
  Select = 3,
  Radio = 4,
  Radio_Text = 5,
  Date_Input = 6,
}

export enum QuestionGroupType {
  Onliner = 1,
  Online = 2,
}

export enum RemoteWorkRequestStatusEnum {
  None = 0,
  DeniedByPeopleCare = 1,
  Complete = 2,
  ApprovedByCEO = 3,
  DeniedByCEO = 4,
  Pending = 5,
  ApprovedByPeopleCare = 6,
  Draft = 7,
  ExtensionPending = 8,
  ExtensionApproved = 9,
  ExtensionDenied = 10,
}

export enum RemoteWorkRequestTypeEnum {
  None = 0,
  Temporary = 1,
  Permanent = 2,
}

export enum LaptopUsageTypeEnum {
  OnlineLaptop = 0,
  ClientLaptop = 1,
  Both = 2,
}

export enum ConfidentialQuestionIds {
  SourceOfFeedback = 77,
  PurposeNatureOfFeedback = 78,
  FeedbackShared = 79,
  Description = 80,
  SourceOfFeedbackComment = 81,
  PurposeNatureOfFeedbackCommment = 82,
}

export enum GoalTrackerStatusEnum {
  Active = 0,
  OnHold = 1,
  Achieved = 2,
  Cancelled = 3,
}
