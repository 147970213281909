import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  AfterViewInit,
  Component,
  Input,
  NgZone,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import {
  Question,
  QuestionOption,
  ReviewerQuestion,
} from 'src/app/core/services/feedback.service';

@Component({
  selector: 'app-self-assessment-question',
  templateUrl: './self-assessment-question.component.html',
  styleUrls: ['./self-assessment-question.component.scss'],
})
export class SelfAssessmentQuestionComponent implements OnInit, AfterViewInit {
  @Input()
  question!: Question | ReviewerQuestion;
  @Input()
  reportForm!: UntypedFormGroup;
  @Input()
  control!: UntypedFormControl;
  @Input()
  questionOptions!: QuestionOption[];
  @Input()
  customLabel!: string;
  @Input()
  isReviewedForThisYear!: boolean;
  @Input()
  isEditorReviewer!: boolean;
  @ViewChildren('autosize')
  textareas!: QueryList<CdkTextareaAutosize>;

  textarea!: CdkTextareaAutosize;

  constructor(private _ngZone: NgZone) {}

  ngOnInit() {
    if (this.isReviewedForThisYear && !this.isEditorReviewer) {
      this.control.disable();
    }
  }

  ngAfterViewInit() {
    if (this.textareas) {
      this.textarea = this.textareas.first;
      this.triggerResize();
    }
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    if (this.textarea) {
      this._ngZone.onStable
        .pipe(take(1))
        .subscribe(() => this.textarea.resizeToFitContent(true));
    }
  }

  validQuestionOptions(question: Question | ReviewerQuestion) {
    return this.questionOptions.filter(
      qo => qo.groupId === question.optionGroupId
    );
  }
}
