import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RemoteWorkRequestTypeEnum } from 'src/app/shared/enums/enums.model';
import {
  RemoteWorkRequests,
  defaultRemoteWorkRequestStatus,
  defaultRemoteWorkRequestType,
} from '../services/remote-work-request.service';
import { defaultOnliner } from './onliner.service';

@Injectable()
export class RemoteWorkRequestFilterService {
  _requestedCriteriaForm!: UntypedFormGroup | null;
  firstDayOfTheYear = new Date(new Date().getFullYear(), 0, 1);
  lastDayOfTheYear = new Date(new Date().getFullYear(), 11, 31);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe
  ) {}
  resetRequestForm() {
    this._requestedCriteriaForm?.reset({
      onliner: defaultOnliner,
      remoteWorkRequestType: defaultRemoteWorkRequestType,
      remoteWorkRequestStatus: defaultRemoteWorkRequestStatus,
      startDate: this.firstDayOfTheYear,
      endDate: this.lastDayOfTheYear,
    });
  }

  getRequestedFilterCriteriaForm(resetForm = false): UntypedFormGroup {
    if (resetForm) {
      this._requestedCriteriaForm = null;
    }
    return this._requestedCriteriaForm
      ? this._requestedCriteriaForm
      : this.createRequestFilterCriteriaForm();
  }

  getRequestedFilterCriteriaModel(): RemoteWorkRequestFilterCriteria {
    const requestedFilterCriteria = new RemoteWorkRequestFilterCriteria();
    requestedFilterCriteria.requestedBy = this._requestedCriteriaForm?.controls[
      'onliner'
    ].value
      ? this._requestedCriteriaForm.controls['onliner'].value.userId
      : defaultOnliner.userId;
    requestedFilterCriteria.status = this._requestedCriteriaForm?.controls[
      'remoteWorkRequestStatus'
    ].value
      ? this._requestedCriteriaForm.controls['remoteWorkRequestStatus'].value
          .description
      : defaultRemoteWorkRequestStatus.description;
    requestedFilterCriteria.startDate = this._requestedCriteriaForm?.controls[
      'startDate'
    ].value
      ? this._requestedCriteriaForm.controls['startDate'].value
      : this.firstDayOfTheYear;
    requestedFilterCriteria.endDate = this._requestedCriteriaForm?.controls[
      'endDate'
    ].value
      ? this._requestedCriteriaForm.controls['endDate'].value
      : new Date();
    requestedFilterCriteria.requestTypeId = this._requestedCriteriaForm
      ?.controls['remoteWorkRequestType'].value
      ? this._requestedCriteriaForm.controls['remoteWorkRequestType'].value.id
      : defaultRemoteWorkRequestType.id;
    return requestedFilterCriteria;
  }

  filterOnliner(
    remoteWorkRequest: RemoteWorkRequests,
    filterCriteria: RemoteWorkRequestFilterCriteria
  ): boolean {
    return (
      filterCriteria.requestedBy === defaultOnliner.userId ||
      remoteWorkRequest.requestedBy === filterCriteria.requestedBy
    );
  }

  filterStartDate(
    remoteWorkRequest: RemoteWorkRequests,
    filterCriteria: RemoteWorkRequestFilterCriteria
  ): boolean {
    return (
      filterCriteria.startDate <= new Date(remoteWorkRequest.requestedDate)
    );
  }

  filterEndDate(
    remoteWorkRequest: RemoteWorkRequests,
    filterCriteria: RemoteWorkRequestFilterCriteria
  ): boolean {
    return filterCriteria.endDate >= new Date(remoteWorkRequest.requestedDate);
  }

  filterRequestType(
    remoteWorkRequest: RemoteWorkRequests,
    filterCriteria: RemoteWorkRequestFilterCriteria
  ): boolean {
    return (
      filterCriteria.requestTypeId === defaultRemoteWorkRequestType.id ||
      this.getRequestTypeIdFromQuestionText(
        remoteWorkRequest.requestTypeName
      ) === filterCriteria.requestTypeId
    );
  }

  filterRequestStatus(
    remoteWorkRequest: RemoteWorkRequests,
    filterCriteria: RemoteWorkRequestFilterCriteria
  ): boolean {
    return (
      filterCriteria.status === defaultRemoteWorkRequestStatus.description ||
      remoteWorkRequest.requestStatusDescription === filterCriteria.status
    );
  }

  private getRequestTypeIdFromQuestionText(questionText: string): number {
    if (questionText === 'Temporary') {
      return RemoteWorkRequestTypeEnum.Temporary;
    } else if (questionText === 'Permanent') {
      return RemoteWorkRequestTypeEnum.Permanent;
    }
    return RemoteWorkRequestTypeEnum.None;
  }

  private createRequestFilterCriteriaForm(): UntypedFormGroup {
    return (this._requestedCriteriaForm = this.formBuilder.group({
      onliner: defaultOnliner,
      remoteWorkRequestType: defaultRemoteWorkRequestType,
      remoteWorkRequestStatus: defaultRemoteWorkRequestStatus,
      startDate: [this.firstDayOfTheYear],
      endDate: [this.lastDayOfTheYear],
    }));
    // [defaultRemoteWorkRequestStatus, formValidator.validateRemoteWorkRequestStatus()],
  }
}

class RemoteWorkRequestFilterCriteria {
  requestedBy!: string;
  startDate!: Date;
  endDate!: Date;
  requestTypeId!: number;
  status!: string;
}
