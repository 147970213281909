<vex-secondary-toolbar current="My CM Feedback">
  <vex-breadcrumbs [crumbs]="['My CM Feedback', 'My Team Surveys']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div class="row m-0">
  <div class="component-container">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <i class="fas fa-filter fa-lg"></i>
          <span>Filter Submissions ({{filtersValue}})</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-team-survey-filter-criteria-form [searchCriteriaForm]="filterCriteriaForm"
                                            (filterFeedbacksTriggered)="filterFeedbacks()"
                                            (filterYearTriggered)="getAllTeamSurveys($event)">
      </app-team-survey-filter-criteria-form>
    </mat-expansion-panel>
    <br>
    <h3 class="page-title">My Team Survey </h3>
    <app-team-survey-list *ngIf="!isLoading" [feedbacks]="filteredFeedbacks"></app-team-survey-list>

    <div *ngIf="isLoading" class="grid place-items-center inset-0">
      <mat-spinner></mat-spinner>
    </div>

  </div>
</div>

