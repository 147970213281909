import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AssessmentService,
  SavedAssessment,
} from 'src/app/core/services/assessment.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { FeedbackTypeEnum } from 'src/app/shared/enums/enums.model';

@Component({
  selector: 'app-view-cmfeedback',
  templateUrl: './view-cmfeedback.component.html',
  styleUrls: ['./view-cmfeedback.component.scss'],
})
export class ViewCmfeedbackComponent implements OnInit {
  feedbackId!: number;
  feedbackTypeId = FeedbackTypeEnum.CareerMentorFeedback;
  feedback!: SavedAssessment;
  operationInProgress: string | null = null;
  isFeedbackReceived!: boolean;
  isLoading = true;

  constructor(
    private assessmentService: AssessmentService,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.feedbackId = +params['id'];

      this.operationInProgress = 'loading';
      this.getFeedbackDetail(this.feedbackId);
    });
  }

  private getFeedbackDetail(feedbackId: number) {
    this.assessmentService.getFeedbackDetail(feedbackId).subscribe(
      data => {
        this.feedback = data;
        this.isFeedbackReceived =
          this.authService.getUserId().toLowerCase() ===
          this.feedback.employeeId;
        this.operationInProgress = null;
        this.isLoading = false;
      },
      error => {
        this.snackBarService.error(error);
        this.operationInProgress = null;
      }
    );
  }
}
