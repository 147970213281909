<vex-secondary-toolbar current="Career Mentor Delegation Report">
  <vex-breadcrumbs [crumbs]="['Career Mentor Delegation Report']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="row m-0">
  <div class="component-container">
    <h3 class="page-title mb-5">Career Mentor Delegation Report</h3>
    <app-cm-delegation-list [cmDelegates]="delegationViewModel"></app-cm-delegation-list>
  </div>
</div>
