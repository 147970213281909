
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 mt-3" [hidden]="isLoading || !(dataSource && dataSource.data.length > 0)">

    <ng-container matColumnDef="clientName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Client</mat-header-cell>
      <mat-cell *matCellDef="let value">
        {{ value.clientName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="submittedDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Submitted Date</mat-header-cell>
      <mat-cell *matCellDef="let value">
        {{ value.submittedDate | date:'MM/dd/yyyy'}}
      </mat-cell>
    </ng-container>

    <ng-container *ngFor="let q of displayedQuestions" [matColumnDef]="q.question">

      <div *ngIf="q.id === 45">
        <mat-header-cell *matHeaderCellDef mat-sort-header [matTooltip]="q.tooltip" [matTooltipDisabled]="q.isTooltipHidden" matTooltipPosition="above" class="p-3">{{ q.question }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="p-3">{{ displayAnswer(row.answers, q.id) }}</mat-cell>
      </div>

      <div *ngIf="q.id !== 45">
        <mat-header-cell  *matHeaderCellDef [matTooltip]="q.tooltip" [matTooltipDisabled]="q.isTooltipHidden" matTooltipPosition="above" class="p-3">{{ q.question }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="p-3">{{ displayAnswer(row.answers, q.id) }}</mat-cell>
      </div>

    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </table>
<div *ngIf="!isLoading && dataSource && dataSource.data.length === 0" >No records found</div>

<div *ngIf="isLoading" class="grid place-items-center inset-0">
  <mat-spinner></mat-spinner>
</div>

