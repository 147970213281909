import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiErrorData } from 'src/app/shared/interfaces/api-error.interface';
import { environment } from 'src/environments/environment';
import { AuthorizedHttp } from './authorized-http.service';
import {
  FeedbackAnswer,
  Question,
  QuestionGroup,
  QuestionOption,
  QuestionOptionGroup,
} from './feedback.service';

@Injectable()
export class ClientFeedbackService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/assessment/external`;
  private _apiClientFeedbackSurvey = `${environment.apiEndpoint}/api/survey/16`;

  private feedbackSubmittedSource = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public feedbackSubmitted$ = this.feedbackSubmittedSource.asObservable();

  constructor(private http: AuthorizedHttp) {}

  public feedbackSubmitted = () => this.feedbackSubmittedSource.next();

  public getFeedback(feedbackId: string): Observable<AssessmentInfo> {
    return this.http
      .get<AssessmentInfo>(`${this._apiEndpoint}/${feedbackId}`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getClientAssessmentInfo(
    feedbackId: string
  ): Observable<ClientAssessmentInfo> {
    return this.http
      .get<ClientAssessmentInfo>(
        `${this._apiEndpoint}/assessmentInfo/${feedbackId}`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getClientFeedbackForm(): Observable<QuestionGroup[]> {
    return this.http
      .get<QuestionGroup[]>(`${this._apiClientFeedbackSurvey}`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public submitFeedback(
    feedbackId: string,
    ans: FeedbackAnswer[],
    onlineFeedback: boolean
  ): Observable<SavedClientFeedback> {
    const data: ClientSubmission = {
      answers: ans,
      areOnlineQuestionsActive: onlineFeedback,
    };
    return this.http
      .post<SavedClientFeedback>(`${this._apiEndpoint}/${feedbackId}`, data)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }
}

export class SavedClientFeedback {}

export class ClientSubmission {
  answers!: FeedbackAnswer[];
  areOnlineQuestionsActive!: boolean;
}

export class ClientAssessmentInfo {
  questions!: Question[];
  questionGroups!: QuestionGroup[];
  questionOptions!: QuestionOption[];
  questionOptionGroups!: QuestionOptionGroup[];
  contactName!: string;
}

export class AssessmentInfo {
  onliner!: string;
}

export class QuestionAnswerDict {
  [id: number]: FeedbackAnswer;
}
