import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  AssessmentService,
  SavedAssessment,
} from 'src/app/core/services/assessment.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { FeedbackFilterService } from 'src/app/core/services/feedback-filter.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-feedback-received',
  templateUrl: './feedback-received.component.html',
  styleUrls: ['./feedback-received.component.scss'],
})
export class FeedbackReceivedComponent implements OnInit {
  feedbacks!: SavedAssessment[];
  filteredFeedbacks!: SavedAssessment[];
  filterCriteriaForm!: UntypedFormGroup;
  filterDateDisplay!: string;
  isAdmin!: boolean;
  filterSubmitterValue!: string;
  filterCMValue!: string;
  filterSubmissionTypeValue!: string;
  filterClientValue!: string;
  filterFeedbackStatusValue!: string;
  filtersValue!: string;
  isLoading = true;

  constructor(
    private assessmentService: AssessmentService,
    private snackBarService: SnackBarService,
    private feedbackFilterService: FeedbackFilterService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.filterCriteriaForm =
      this.feedbackFilterService.getFilterCriteriaForm();
    this.getMyReceivedFeedbacks();
    this.isAdmin = this.authService.doesUserHaveRole([
      environment.roles.FeedbackAdmin,
    ]);
  }

  filterFeedbacks() {
    const filterCriteriaModel =
      this.feedbackFilterService.getFilterCriteriaModel();
    this.filterDateDisplay =
      this.feedbackFilterService.getFilterDateRangeDisplay(filterCriteriaModel);
    this.filteredFeedbacks = this.feedbacks.filter(
      f =>
        this.feedbackFilterService.filterSubmitter(f, filterCriteriaModel) &&
        this.feedbackFilterService.filterCareerMentor(f, filterCriteriaModel) &&
        this.feedbackFilterService.filterSubmissionType(
          f,
          filterCriteriaModel
        ) &&
        this.feedbackFilterService.filterClient(f, filterCriteriaModel) &&
        this.feedbackFilterService.filterStartDate(f, filterCriteriaModel) &&
        this.feedbackFilterService.filterEndDate(f, filterCriteriaModel) &&
        this.feedbackFilterService.filterFeedbackType(f, filterCriteriaModel) &&
        this.feedbackFilterService.filterFeedbackStatus(f, filterCriteriaModel)
    );
    this.getFilterFormValues();
  }

  getFilterFormValues() {
    this.filterClientValue = `${this.filterCriteriaForm.controls.client.value['clientName']}`;
    this.filterCMValue = `${this.filterCriteriaForm.controls.careerMentor.value['firstName']} ${this.filterCriteriaForm.controls.careerMentor.value['lastName']}`;
    this.filterCMValue = this.filterCMValue.trim();
    this.filterSubmitterValue = `${this.filterCriteriaForm.controls.submittedBy.value['firstName']} ${this.filterCriteriaForm.controls.submittedBy.value['lastName']}`;
    this.filterSubmitterValue = this.filterSubmitterValue.trim();
    this.filterSubmissionTypeValue = `${this.filterCriteriaForm.controls.submissionType.value['name']}`;
    this.filterFeedbackStatusValue = `${this.filterCriteriaForm.controls.status.value['statusDescription']}`;

    this.filtersValue = `${this.filterClientValue}, ${this.filterSubmitterValue}, ${this.filterSubmissionTypeValue}, ${this.filterFeedbackStatusValue}, ${this.filterCMValue}`;
  }

  private getMyReceivedFeedbacks() {
    this.assessmentService.getMyReceivedAssessments().subscribe(
      data => {
        this.feedbacks = data;
        this.filterFeedbacks();
        this.isLoading = false;
      },
      error => {
        this.snackBarService.error(error);
      }
    );
  }
}
