<div class="w-full p-gutter text-center">
  <img alt="not found" class="select-none pointer-events-none filter grayscale w-full max-w-lg mx-auto mb-6" src="assets/img/illustrations/data_center.svg">

  <h1 class="display-3 font-medium m-0">404</h1>
  <h2 class="headline m-0">Page not found.</h2>
  <div class="row">&nbsp;</div>
  <p class="text-center">Sorry we could not find this page for you.</p>
  <p class="text-center">Please contact your system administrator.</p>
  <!-- <div class="bg-foreground rounded-full overflow-hidden relative ltr:pl-5 rtl:pr-5 h-12 max-w-md w-full shadow-xl mx-auto mt-6 flex items-center">
    <mat-icon class="text-secondary flex-none" svgIcon="mat:search"></mat-icon>
    <input class="border-0 h-12 outline-none ltr:pl-4 rtl:pr-4 placeholder:text-secondary bg-foreground flex-auto"
           placeholder="Search for other pages ..."
           type="text">
  </div> -->
</div>
