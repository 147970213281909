<div class="position-relative h-100 p-4">
    <div class="p-2 questions-dialog-header">
        <span class="questions-dialog-title d-inline">{{config.title}}</span>
        <span class="float-right">
            <form [formGroup]="dialogForm">
                <div class="d-flex justify-content-end">
                  Active
                  <mat-slide-toggle formControlName="activeControl"></mat-slide-toggle>
                </div>
            </form>
        </span>
    </div>

    <div class="questions-dialog-body">
        <div class="flex-auto pr-8">
            <mat-form-field class="question-input" appearance="outline" [formGroup]="dialogForm">
                <mat-label>Name</mat-label>
                <input matInput formControlName="answerControl" placeholder="Option Name">
            </mat-form-field>
        </div>
        <div class="flex-initial">
            <mat-form-field appearance="fill" class="score-input" [formGroup]="dialogForm">
                <mat-label>Score</mat-label>
                <input matInput type="number" formControlName="scoreControl" placeholder="Enter a number">
            </mat-form-field>
        </div>
    </div>

    <mat-dialog-actions class="position-absolute">
        <button class="btn btn-action-dialog success-button mr-3" (click)="close()">CANCEL</button>
        <button class="btn btn-action-dialog success-button" (click)="save()" [disabled]="dialogForm.pristine">OK</button>
    </mat-dialog-actions>
</div>
