<vex-secondary-toolbar current="Request">
  <vex-breadcrumbs [crumbs]="['Request', 'Relocation']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="m-0 component-container">
  <div class="mb-3">
      <h3 class="page-title">Relocation Request</h3>
  </div>
  <p class="mandatory-text">* All questions marked with an asterisk are mandatory. They will need to be answered
      before the request can be submitted.</p>
  <div class="mb-3" *ngIf="name">
      <table class="mt-2 mb-3">
        <tr>
          <td class="col-md-6 font-weight-bold"><span>Name:</span></td>
          <td><span>{{name}}</span></td>
        </tr>
        <tr>
          <td class="col-md-6 font-weight-bold"><span>Request Status:</span></td>
          <td><span>{{requestStatus}}</span></td>
        </tr>
        <tr>
          <td class="col-md-6 font-weight-bold"><span>Practice/Service:</span></td>
          <td><span>{{practice}} </span></td>
        </tr>
        <tr>
          <td class="col-md-6 font-weight-bold"><span>Country:</span></td>
          <td><span>{{country}}</span></td>
        </tr>
      </table>
  <div class="m-0">
      <div class="col-md-8 col-sm-12 col-xs-12 m-0 p-0">
          <app-request-remote-work-workflow-form
              *ngIf="remoteWorkRequestForm && user && userCanChangeStatus !== null"
              [remoteWorkRequestForm]="remoteWorkRequestForm"
              [remoteWorkRequestId]="remoteWorkRequestId"
              [questionGroupIDs]="questionGroupIDs"
              [questions]="questions"
              [questionOptions]="questionOptions"
              [questionOptionGroupIDs]="questionOptionGroupIDs"
              [userCanChangeStatus]="userCanChangeStatus!"
              [(requestStatus)]="requestStatus"
          >
          </app-request-remote-work-workflow-form>
      </div>
  </div>
  <div class="col-md-4 component-container"></div>
</div>
