import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CmDelegationComponent } from './cm-delegation.component';

const routes: Routes = [
  {
    path: '',
    component: CmDelegationComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CmDelegationRoutingModule {}
