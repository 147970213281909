<div *ngIf="!isLoading && !isLoadingQuestions">
  <div class="detail-title">{{title}}</div>

  <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 questions-config-body mat-elevation-z3">
      <mat-form-field class="text-input" appearance="outline" [formGroup]="questionGroupForm">
          <mat-label>Question Group</mat-label>
          <input matInput formControlName="questionGroupControl" placeholder="Question Group">
      </mat-form-field>

      <div class="table-header">Available Questions</div>
      <div class="table-container mat-elevation-z3">
          <table cdkDropList (cdkDropListDropped)="drop($event)" mat-table [dataSource]="questions">
              <ng-container matColumnDef="dragHandle">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell class="px-3 block" *matCellDef="let answer">
                      <mat-icon
                          class="fas fa-sort d-flex align-items-center"
                          cdkDragHandle
                          (touchstart)="dragDisabled = false"
                          (touchend)="dragDisabled = true"
                          (mousedown)="dragDisabled = false"
                          (mouseup)="dragDisabled = true"
                          matTooltip="{{dragHandleTooltip}}">
                      </mat-icon>
                  </mat-cell>
              </ng-container>

              <ng-container matColumnDef="active">
                  <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
                  <mat-cell *matCellDef="let question; let i = index">
                      <form [formGroup]="formGroups[i]">
                          <mat-slide-toggle formControlName="activeControl" (change)="toggleActive(question)"></mat-slide-toggle>
                      </form>
                  </mat-cell>
              </ng-container>

              <ng-container matColumnDef="question">
                  <mat-header-cell *matHeaderCellDef>Question</mat-header-cell>
                  <mat-cell *matCellDef="let question" (click)="editQuestion(question)">
                      {{question.question}}
                  </mat-cell>
              </ng-container>

              <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                  <mat-cell *matCellDef="let question" (click)="editQuestion(question)">
                      {{question.type}}
                  </mat-cell>
              </ng-container>

              <ng-container matColumnDef="optional">
                  <mat-header-cell *matHeaderCellDef>Optional</mat-header-cell>
                  <mat-cell *matCellDef="let question; let i = index">
                  <form [formGroup]="formGroups[i]">
                      <mat-slide-toggle formControlName="optionalControl" (change)="toggleOptional(question)"></mat-slide-toggle>
                  </form>
                  </mat-cell>
              </ng-container>

              <ng-container matColumnDef="optionGroup">
                  <mat-header-cell *matHeaderCellDef>Option Group</mat-header-cell>
                  <mat-cell *matCellDef="let question" (click)="editQuestion(question)">
                      <div *ngIf="question.optionGroup">
                          {{question.optionGroup.label}}
                      </div>
                  </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="questionColumns"></mat-header-row>
              <mat-row
                  class="mat-row-clickable"
                  *matRowDef="let row; let i = index; columns: questionColumns;"
                  style="cursor: pointer"
                  cdkDrag
                  (cdkDragStarted)="start($event,i)"
                  [cdkDragDisabled]="dragDisabled"
                  (cdkDragReleased)="dragDisabled = true">
              </mat-row>
          </table>
          <span [matTooltip]="isNewQuestionGroup ? addQuestionTooltip : ''">
          <button mat-stroked-button class="questions-add-button m-2"
              [disabled]="isNewQuestionGroup"
              (click)="addQuestion()">
              ADD
          </button>
          </span>
      </div>
  </div>

  <div class="d-flex justify-content-start mt-4">
      <button mat-stroked-button class="cancel-btn" (click)="close()">CANCEL</button>
      <button mat-stroked-button class="save-btn ml-4" (click)="save()" [disabled]="isSaveDisabled">SAVE</button>
  </div>
</div>
