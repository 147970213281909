<div *ngIf="isRequestApproval">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" [hidden]="isLoading || !(dataSource && dataSource.data.length > 0)">
      <ng-container matColumnDef="employeeName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Onliner</th>
          <td mat-cell *matCellDef="let value" class="leftAlignCell" (click)="onSelect(value)">
            {{ value.requestedByName }}
          </td>
      </ng-container>
      <ng-container matColumnDef="requestedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Requested Date</th>
        <td mat-cell *matCellDef="let value" (click)="onSelect(value)">
          {{ value.requestedDate | date:'MM/dd/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="requestTypeName" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Request Type</th>
        <td mat-cell *matCellDef="let value" (click)="onSelect(value)">
          {{ value.requestTypeName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="expectedTravelDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
          <td mat-cell *matCellDef="let value" (click)="onSelect(value)">
            {{ value.expectedTravelDate | date:'MM/dd/yyyy' }}
          </td>
      </ng-container>
      <ng-container matColumnDef="expectedReturnDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
          <td mat-cell *matCellDef="let value" (click)="onSelect(value)" >
            {{ value.expectedReturnDate | date:'MM/dd/yyyy' }}
          </td>
      </ng-container>
      <ng-container matColumnDef="requestStatusId" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Request Status</th>
        <td mat-cell *matCellDef="let value" (click)="onSelect(value)">
          {{isAwaiting(value.requestTypeId, value.requestStatusId, value.requestStatusDescription, value.expectedTravelDate)}}
        </td>
      </ng-container>
      <ng-container matColumnDef="approvedDate" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Approved Date</th>
        <td mat-cell *matCellDef="let value" (click)="onSelect(value)">
          {{ value.approvedDate | date:'MM/dd/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="approvedByName" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Approved by</th>
        <td mat-cell *matCellDef="let value" (click)="onSelect(value)">
          {{ value.approvedByName }}
        </td>
      </ng-container>

    <tr mat-header-row *matHeaderRowDef="resultColumnHeadersApproval"></tr>
    <tr mat-row class="mat-row-clickable" *matRowDef="let row; columns: resultColumnHeadersApproval" style="cursor: pointer"></tr>
  </table>
</div>


<div *ngIf="!isRequestApproval">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" [hidden]="isLoading || !(dataSource && dataSource.data.length > 0)">
      <ng-container matColumnDef="employeeName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Onliner</th>
          <td mat-cell *matCellDef="let value" class="leftAlignCell" (click)="onSelect(value)">
            {{ value.requestedByName }}
          </td>
      </ng-container>
      <ng-container matColumnDef="requestedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Requested Date</th>
        <td mat-cell *matCellDef="let value" (click)="onSelect(value)">
          {{ value.requestedDate | date:'MM/dd/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="requestTypeName" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Request Type</th>
        <td mat-cell *matCellDef="let value" (click)="onSelect(value)">
          {{ value.requestTypeName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="expectedTravelDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
          <td mat-cell *matCellDef="let value" (click)="onSelect(value)">
            {{ value.expectedTravelDate | date:'MM/dd/yyyy' }}
          </td>
      </ng-container>
      <ng-container matColumnDef="expectedReturnDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
          <td mat-cell *matCellDef="let value" (click)="onSelect(value)" >
            {{ value.expectedReturnDate | date:'MM/dd/yyyy' }}
          </td>
      </ng-container>
      <ng-container matColumnDef="requestStatusId" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Request Status</th>
        <td mat-cell *matCellDef="let value" (click)="onSelect(value)">
          {{isAwaiting(value.requestTypeId, value.requestStatusId, value.requestStatusDescription, value.expectedTravelDate)}}
        </td>
      </ng-container>
      <ng-container matColumnDef="approvedDate" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Approved Date</th>
        <td mat-cell *matCellDef="let value" (click)="onSelect(value)">
          {{ value.requestStatusId !== remoteWorkRequestStatus.ExtensionPending ? (value.approvedDate | date:'MM/dd/yyyy') : '' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="approvedByName" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Approved by</th>
        <td mat-cell *matCellDef="let value" (click)="onSelect(value)">
          {{ value.requestStatusId !== remoteWorkRequestStatus.ExtensionPending ? value.approvedByName : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="cancel">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let value" class="text-center">
          <div *ngIf="showCancelIcon(value)"><button class="btn btn-link control" (click)="openCancelConfirmation(value)"><i class="fas fa-trash-alt"></i></button></div>

        </td>
      </ng-container>

      <tr  mat-header-row *matHeaderRowDef="resultColumnHeaders"></tr>
      <tr  mat-row class="mat-row-clickable" *matRowDef="let row; columns: resultColumnHeaders" style="cursor: pointer"></tr>
  </table>
</div>

<div *ngIf="dataSource && dataSource.data.length === 0 && !isLoading">No records found</div>

<div *ngIf="isLoading" class="grid place-items-center inset-0">
  <mat-spinner></mat-spinner>
</div>
