import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  ExtendRemoteWorkRequest,
  RemoteWorkRequestService,
  RemoteWorkRequests,
} from 'src/app/core/services/remote-work-request.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import {
  UpdateDialog,
  UpdateDialogComponent,
} from 'src/app/shared/components/update-dialog/update-dialog.component';
import {
  RemoteWorkRequestStatusEnum,
  RemoteWorkRequestTypeEnum,
} from 'src/app/shared/enums/enums.model';

@Component({
  selector: 'app-remote-work-request-list',
  templateUrl: './remote-work-request-list.component.html',
  styleUrls: ['./remote-work-request-list.component.scss'],
})
export class RemoteWorkRequestListComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild(MatSort, { static: true })
  sort!: MatSort;
  @Input()
  canChangeStatus!: boolean;
  @Input()
  isRequestApproval!: boolean;
  @Input()
  remoteWorkRequests!: RemoteWorkRequests[];
  @Output() isRequestApprovalChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  resultColumnHeaders: string[] = [
    'employeeName',
    'expectedTravelDate',
    'expectedReturnDate',
    'requestedDate',
    'requestTypeName',
    'requestStatusId',
    'approvedDate',
    'approvedByName',
    'cancel',
  ];

  resultColumnHeadersApproval: string[] = [
    'employeeName',
    'expectedTravelDate',
    'expectedReturnDate',
    'requestedDate',
    'requestTypeName',
    'requestStatusId',
    'approvedDate',
    'approvedByName',
  ];

  isLoading = true;
  selected = [];
  dataSource!: MatTableDataSource<RemoteWorkRequests>;
  loading = true;

  userRequests!: RemoteWorkRequests[];
  userRequest?: RemoteWorkRequests;
  requestStartDate!: Date;
  requestEndDate!: Date;
  remoteWorkRequestStatus: typeof RemoteWorkRequestStatusEnum =
    RemoteWorkRequestStatusEnum;
  updateInfo!: ExtendRemoteWorkRequest;
  expctdStartDate!: Date | null;
  expctdReturnDate!: Date | null;
  updateRef!: MatDialogRef<UpdateDialogComponent, UpdateDialog>;
  dialogRef!: MatDialogRef<ConfirmationDialogComponent, string>;
  initSubscription!: Subscription;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private remoteWorkRequestService: RemoteWorkRequestService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit() {
    this.initSubscription = this.remoteWorkRequestService
      .getRequestsForRequestList(this.isRequestApproval)
      .subscribe(
        data => {
          if (!this.isRequestApproval) {
            this.userRequests = data;

            this.userRequest = this.userRequests.find(
              o =>
                o.requestTypeId === RemoteWorkRequestTypeEnum.Temporary &&
                (o.requestStatusId ===
                  RemoteWorkRequestStatusEnum.ApprovedByPeopleCare ||
                  o.requestStatusId ===
                    RemoteWorkRequestStatusEnum.ExtensionApproved) &&
                o.expectedTravelDate &&
                new Date(o.expectedTravelDate) <= new Date()
            );

            if (this.userRequest) {
              this.expctdStartDate = this.userRequest.expectedTravelDate;
              this.expctdReturnDate = this.userRequest.expectedReturnDate;
              this.returnConfirmation();
            }
          }

          this.isRequestApprovalChange.emit(this.isRequestApproval);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  ngOnChanges() {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    this.initSubscription.unsubscribe();
  }

  isAwaiting(
    typeId: number,
    statusId: number,
    requestStatus: string,
    startDate: string
  ): string {
    let status = requestStatus;
    if (
      typeId === RemoteWorkRequestTypeEnum.Temporary &&
      (statusId === RemoteWorkRequestStatusEnum.ApprovedByPeopleCare ||
        statusId === RemoteWorkRequestStatusEnum.ExtensionApproved) &&
      new Date(startDate) <= new Date()
    ) {
      status = 'Awaiting Return';
    }

    return status;
  }

  onSelect(selected: RemoteWorkRequests) {
    const requestAdminUrl = 'requestRemoteWorkWorkflow';
    this.router.navigate([requestAdminUrl, selected.id, this.canChangeStatus]);
  }

  openCancelConfirmation(request: RemoteWorkRequests) {
    const confirmDialog = new ConfirmDialog();

    confirmDialog.title = 'Cancel Request?';
    confirmDialog.message =
      'Do you want to cancel the ' +
      request.requestType +
      ' Relocation Request with a start date of ' +
      this.formatDate(request.expectedTravelDate);
    confirmDialog.okButtonTitle = 'Yes';
    confirmDialog.cancelButtonTitle = 'No';
    confirmDialog.theme = 'danger';

    this.dialogRef = this.dialog.open<
      ConfirmationDialogComponent,
      ConfirmDialog
    >(ConfirmationDialogComponent, {
      width: '350px',
      data: confirmDialog,
      disableClose: true,
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.deleteRequest(request);
      }
    });
  }

  formatDate(date?: Date | null) {
    if (!date) return '';
    date = new Date(date);
    const result = date.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    return result;
  }

  showCancelIcon(request: RemoteWorkRequests): boolean {
    if (request && !this.isRequestApproval) {
      if (
        !(
          request.requestStatusId === RemoteWorkRequestStatusEnum.DeniedByCEO ||
          request.requestStatusId ===
            RemoteWorkRequestStatusEnum.ExtensionDenied ||
          request.requestStatusId ===
            RemoteWorkRequestStatusEnum.DeniedByPeopleCare ||
          request.requestStatusId === RemoteWorkRequestStatusEnum.Complete
        )
      ) {
        return true;
      }
    }
    return false;
  }

  deleteRequest(request: RemoteWorkRequests) {
    if (this.showCancelIcon(request)) {
      this.remoteWorkRequestService
        .deleteRemoteWorkRequest(request.id)
        .subscribe(
          () => {
            this.snackBarService.message('Request Cancelled');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate(['/remoteWorkRequests/false']));
          },
          () => {
            this.snackBarService.message(
              'an error occured when deleting the request'
            );
          }
        );
    }
  }

  returnConfirmation() {
    const updateDialog = new UpdateDialog();

    updateDialog.title1 = 'Relocation Return Confirmation';
    updateDialog.title2 = 'Relocation Request Extension';
    updateDialog.question1 = 'Have you returned from your destination?';
    updateDialog.question2A = 'Choose the new return date';
    updateDialog.question2B = 'Confirm return date';
    updateDialog.question3 =
      'Do you want to extend your stay at your destination?';
    updateDialog.okButtonTitle = 'SUBMIT';
    updateDialog.expctdStartDate = this.expctdStartDate;
    updateDialog.expctdReturnDate = this.expctdReturnDate;

    this.updateRef = this.dialog.open<UpdateDialogComponent, UpdateDialog>(
      UpdateDialogComponent,
      {
        width: '480px',
        height: '400px',
        data: updateDialog,
        disableClose: true,
      }
    );

    this.updateRef.afterClosed().subscribe(data => {
      if (data) {
        this.confirmReturn(data.returning, data.extending, data.returnDate);
      }
    });
  }

  private confirmReturn(
    returning: boolean,
    extending: boolean,
    returnDate: Date
  ) {
    this.updateInfo = new ExtendRemoteWorkRequest();
    if (this.userRequest?.id) {
      this.updateInfo.requestId = this.userRequest.id;
    }
    this.updateInfo.isReturning = returning;
    this.updateInfo.endDate = returnDate;

    // TODO: why we need local variable listedRequest if it does not affect anything outside this function?
    const listedRequest: RemoteWorkRequests =
      this.remoteWorkRequests.find(
        result => result.id === this.userRequest?.id
      ) || new RemoteWorkRequests();

    if (returning) {
      listedRequest.requestStatusId = RemoteWorkRequestStatusEnum.Complete;
      listedRequest.requestStatusDescription = 'Complete';
    } else if (!returning && extending) {
      listedRequest.requestStatusId =
        RemoteWorkRequestStatusEnum.ExtensionPending;
      listedRequest.requestStatusDescription = 'Extension Pending';
    }

    if (returning || extending) {
      this.remoteWorkRequestService.updateRequest(this.updateInfo).subscribe(
        () => {
          listedRequest.expectedReturnDate = returnDate;
          this.snackBarService.message(
            'Relocation request updated successfully'
          );
        },
        error => {
          this.snackBarService.error(error);
        }
      );
    }
  }

  private checkIsRequestApproval(val: string) {
    if (val === 'True' || val === 'true' || val === 'TRUE') {
      return true;
    }
    return false;
  }

  @Input() set setRemoteWorkRequests(requests: RemoteWorkRequests[]) {
    this.dataSource = new MatTableDataSource<RemoteWorkRequests>(requests);
    if (requests) {
      this.isLoading = false;
    } else {
      this.dataSource = new MatTableDataSource<RemoteWorkRequests>();
    }
  }
}
