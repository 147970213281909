<div class="component-container">
    <div class="row m-0">
        <section aria-label="Main content">
            <div class="mb-3">
                <h3 class="page-title col-md-12 col-sm-12 col-xs-12">Web tracking with cookies</h3>
            </div>
            <div class="col-md-12">
                <h4 class="page-title">Cookies</h4>
                <p>When you visit a website, not only are you offered information or services, but your computer may also be offered a “cookie.” A cookie is a small file that is passed from a website to an end user’s (your) computer, often without your knowledge or consent. The cookie is used to save information about the interaction between you and the site, such as login credentials, preferences, and any work in progress. The cookie file is automatically stored by your browser (e.g., Internet Explorer or Firefox) on the local hard drive, and it can later be retrieved by the website.</p>
                <p>Cookies were invented in 1994 so that information could be saved between visits to a website. This lets you avoid logging in for every visit, and cookies are also used to keep track of preferences and works in progress (such as items in an online shopping cart). Today, just about all of the top websites use cookies for one purpose or another. Cookies are a very useful feature of the web and, without them, web sessions would have no history; you would have to enter your information over and over.</p>
                <h4 class="page-title">Third-Party Cookies</h4>
                <p>Initially, cookies were only shared between the website (the “first party” in the transaction) and the user (the “second party”). Soon after cookies were invented, however, their use was expanded to third parties—organizations not directly involved in the interaction—such as advertising companies displaying ads on certain websites.</p>
                <p>When an advertisement is on a web page supplied by a first party, the advertising content and a cookie are passed from the advertising company (the third party) to the end user’s (your) computer. Later, when you revisit that same first-party website, or another site that uses the same advertising company, the third-party cookie can be retrieved by the advertising company. If the cookie contains a unique identifier, then information about your visits to different websites can be linked together.</p>
                <p>Further, if any of the sites (such as social networking sites) collect personal information, this information might also be collected by the advertisers. In this way, advertising companies are able to track the websites that you visit and build up detailed personal profiles, which may then be used to target further advertising to you.</p>
                <p>Third-party cookies raise privacy concerns because the transactions typically involve unknown third parties and are conducted without your knowledge or consent. Unless you pay attention to an often-confusing set of options in your browser software, the cookies are created and used invisibly, and the information that is gathered may be stored forever. In addition, the tracking and profiling done by advertising companies can be extensive; it is common for your computer to collect dozens of third-party tracking cookies.</p>
                <h4 class="page-title">Flash Cookies</h4>
                <p>Flash cookies (also called Local Shared Objects or LSOs) are created by Adobe’s popular Flash browser add-on for multimedia. Like traditional cookies, Flash cookies can be used to save state information, as well as preferences, between sessions.&nbsp; They are also used to track the websites that you visit. These cookies are normally not visible to you, the end user, and options to control or delete them are usually absent or very difficult to find. Flash cookies are frequently found on websites, and they are often used along with traditional web cookies. In fact, even if you delete web cookies, Flash cookies can be used to recreate them.</p>
                <p>Flash cookies raise additional privacy concerns because they are more hidden than traditional web cookies, so you have to take extraordinary measures to remove them. Also, many privacy policies that describe the use of web cookies fail to mention Flash cookies, and procedures to opt out of web cookies often have no effect on Flash cookies.</p>
                <h4 class="page-title">Super Cookies</h4>
                <p>A third type of cookie, called “super cookies,” is also emerging. Super cookies use new storage locations built into browsers to save information about you.&nbsp; For example, the Internet Explorer browser has “userData” storage, while Firefox has “DOM” storage”. The emerging HTML 5 specifications also set aside web storage that can last either for a browser session or permanently (until deleted). These storage mechanisms are larger and more flexible than traditional cookies so more information can be stored. Like web cookies and Flash cookies, you, as a user, are often unaware that super cookies exist.&nbsp; You, as the user, are often not provided with tools to control the information that is stored.</p>
                <h4 class="page-title">Cookie-Less Tracking</h4>
                <p>A person’s browsing habits can also be tracked without cookies. One such method involves “web bugs”, which are small, invisible image files placed on a web page or hidden in an e-mail message. When you view the page or message, the image is downloaded from a server that can keep detailed logs.&nbsp; These logs record such information as your location, Internet address, the page or message you are reading, and the current date and time.</p>
                <p>When people view web pages, their browsers can reveal a lot of information. The browsers can be queried to determine their detailed characteristics, including version number, window size, settings, add-ons, and customizations. The combination of information, often called “device fingerprinting,” can be quite specific to an individual machine.&nbsp; An <a href="https://panopticlick.eff.org">experiment conducted by the Electronic Frontier Foundation</a> suggests that this information may be unique to about one in one million people.</p>
                <h4 class="page-title">Web Privacy Tools</h4>
                <p>Unfortunately, protecting privacy while browsing the web is not an easy task. Web browsers provide some tools for storing and clearing cookies.&nbsp; However, the default is to store all cookies indefinitely and the privacy tools are often hard to find and use. &nbsp;Browsers can be set to block cookies, but many websites require that you, as a user, allow cookies to use the service. Even blocking third-party cookies can impair the experience of some services, so users are faced with the onerous task of allowing some cookies and not others. If you do configure your browser to delete stored cookies, this often only clears traditional cookies, without removing super cookies and Flash cookies.</p>
                <p>Some browsers have recently implemented a “private browsing mode,” designed to protect privacy. In Firefox, for example, web cookies are deleted when a private browsing session is ended. Unfortunately, super cookies and Flash cookies are not always affected by these settings, so they are still stored during private browsing sessions. In order to clear all the different forms of cookies and web storage, you generally have to install and use special add-on applications. Some popular tools for Firefox, for example, are the BetterPrivacy, NoScript, and Targeted Advertising Cookie Opt-Out (TACO) plug-ins.</p>
                <h4 class="page-title">Conclusion</h4>
                <p>Cookies are powerful tools that give the web a memory, making for a better user experience. They do, however, also pose privacy concerns because they are often used without your knowledge or consent, and can be used to track your web habits and build detailed personal profiles about you.</p>
                <p>To protect your privacy on the web, you need to learn about the cookie controls provided in your browser.&nbsp; You should also investigate some specialized tools that can control all cookie types. Unfortunately, however, even if you do make the effort to control cookies, there is little that you can currently do to protect against cookie-less tracking methods.</p>               
            </div>
        </section>
        <div class="col-md-4 pull-left mrgn-tp-md">            
            <div class="hidden-print"></div>            
        </div>
    </div>
</div>        