<form *ngIf="!isLoading" [formGroup]="searchCriteriaForm" novalidate [style.fontSize.px]="18">
  <div class="row form-group">
    <mat-form-field class="mr-3 col-md-3 search">
      <mat-label for="practice">Practice: </mat-label>
      <input matInput name="practice" id="practice" [matAutocomplete]="practiceauto" formControlName="practice" required/>
      <mat-error *ngIf="searchCriteriaForm.get('practice')?.hasError('required') && searchCriteriaForm.get('practice')?.touched">
        Please select a Practice
      </mat-error>
      <mat-autocomplete #practiceauto="matAutocomplete" [displayWith]="practiceTypeDisplay" autoActiveFirstOption
                        (optionSelected)="filterFeedbacks()"
      panelWidth="280px">
        <mat-option class="options" *ngFor="let option of filteredPractices | async" [value]="option">
          {{ practiceTypeDisplay(option) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="mr-3 col-md-3 search">
      <mat-label for="competency">Competency: </mat-label>
      <input matInput name="competency" id="competency" [matAutocomplete]="competencyauto" formControlName="competency" required/>
      <mat-error *ngIf="searchCriteriaForm.get('competency')?.hasError('required') && searchCriteriaForm.get('competency')?.touched">
        Please select a Competency
      </mat-error>
      <mat-autocomplete #competencyauto="matAutocomplete" [displayWith]="competencyDisplay" autoActiveFirstOption
                        (optionSelected)="filterFeedbacks()" panelWidth="380px">
        <mat-option class="options" *ngFor="let option of filteredCompetencies | async" [value]="option">
          {{ competencyDisplay(option) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div class="row form-group" [formGroup]="searchCriteriaForm">
    <mat-form-field class="mr-3 col-md-3 search">
      <mat-label for="fiscalYear">Fiscal Year: </mat-label>
      <input matInput name="fiscalYear" id="fiscalYear" [matAutocomplete]="fiscalYearauto" formControlName="fiscalYear" required />
      <mat-error *ngIf="searchCriteriaForm.get('fiscalYear')?.hasError('required') && searchCriteriaForm.get('fiscalYear')?.touched">
        Please select Fiscal Year.
      </mat-error>
      <mat-autocomplete #fiscalYearauto="matAutocomplete"  autoActiveFirstOption (optionSelected)="filterYears($event)" [displayWith]="yearDisplay"
      panelWidth="280px">
        <mat-option class="options" *ngFor="let option of selfAssessmentSchedules" [value]="option">
          {{option.fiscalYear}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="mr-3 col-md-3 search">
      <mat-label for="submittedBy">Onliner: </mat-label>
      <input matInput name="submittedBy" id="submittedBy" [matAutocomplete]="submittedbyauto" formControlName="submittedBy" required/>
      <mat-error *ngIf="searchCriteriaForm.get('submittedBy')?.hasError('required') && searchCriteriaForm.get('submittedBy')?.touched">
        Please enter the name of the Submitter.
      </mat-error>
      <mat-autocomplete #submittedbyauto="matAutocomplete" [displayWith]="onlinerDisplay" autoActiveFirstOption
                        (optionSelected)="filterFeedbacks()" panelWidth="300px">
        <mat-option class="options" *ngFor="let option of filteredSubmittedBy | async" [value]="option">
          {{ onlinerDisplay(option) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div class="row form-group">
    <mat-form-field class="mr-3 col-md-3 search" >
      <mat-label for="careerMentor">Career Mentor: </mat-label>
      <input matInput name="careerMentor" id="careerMentor" [matAutocomplete]="careerMentorAuto"
             formControlName="careerMentor" required/>
      <mat-error *ngIf="searchCriteriaForm.get('careerMentor')?.hasError('required') && searchCriteriaForm.get('careerMentor')?.touched">
        Please enter the name of the Career Mentor.
      </mat-error>
      <mat-autocomplete #careerMentorAuto="matAutocomplete" [displayWith]="careerMentorDisplay" autoActiveFirstOption
                        (optionSelected)="filterFeedbacks()" panelWidth="300px">
        <mat-option class="options" *ngFor="let option of filteredCareerMentors | async" [value]="option">
          {{ careerMentorDisplay(option) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class="col-md-3">
      <div class="row">
      </div>
    </div>
  </div>
  <div class="row form-group">
    <div class="col-md-3 padding-left-15">
      <button mat-stroked-button color="warn" type="button" class="reject-btn" (click)="resetForm()" [disabled]="!searchCriteriaForm.dirty">RESET</button>
    </div>
  </div>

</form>

<div *ngIf="isLoading" class="grid place-items-center inset-0">
  <mat-spinner></mat-spinner>
</div>

