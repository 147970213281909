<section class="text-base">
<div class="p-3 flex justify-between" [ngClass]="confirmDialog.theme==='success' ? 'bg-obs-primary' : (confirmDialog.theme==='danger' ? 'bg-obs-danger' : '')">
  <span class="inline text-white dialog-title">{{confirmDialog.title}}</span>
  <button mat-dialog-close="cancel" type="button" class="hover:font-bold focus:no-underline leading-none text-white" aria-label="Close">
    <mat-icon aria-hidden="true" svgIcon="mat:close"></mat-icon>
  </button>
</div>

<div class="dialog-body py-12 px-8">
  <div *ngIf="confirmDialog.message" class="text-center mb-4">
    <span [innerHTML]="confirmDialog.message"></span>
  </div>
  <div class="">
      <div class="flex justify-center">
        <button *ngIf="confirmDialog.cancelButtonTitle" type="button" class="
        py-1 px-3 bg-obs-gray-20 border-obs-gray-20 text-black rounded
         mr-1" mat-dialog-close="cancel">{{confirmDialog.cancelButtonTitle}}</button>
        <button *ngIf="confirmDialog.okButtonTitle" type="button" class="
        py-1 px-3 bg-obs-danger border-obs-danger text-white rounded
        ml-1" [ngClass]="confirmDialog.theme==='success' ? 'bg-obs-success' : (confirmDialog.theme==='danger' ? 'bg-obs-danger': '')"  mat-dialog-close="ok" (click)="onClickHandler()">
          {{confirmDialog.okButtonTitle}}
        </button>
      </div>
  </div>
</div>
</section>
