<vex-secondary-toolbar current="Drafts">
  <vex-breadcrumbs [crumbs]="['Drafts']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="component-container tailwind">
  <section class="mb-3 grid grid-cols-12 gap-0">
    <h3 class="pt-3 col-span-12 text-2xl font-medium text-obs-maroon sm:col-span-2">Drafts</h3>
    <mat-form-field class="col-span-12 sm:col-span-6" appearance="outline">
      <mat-label>Feedback Type</mat-label>
      <mat-select name="feedbackType" [(value)]="selectedType" (selectionChange)="getSelectedFeedbacks()">
        <mat-option *ngFor="let feedbackTypeDetail of feedbackTypeDetails" [value]="feedbackTypeDetail">{{ feedbackTypeDetail.name + " (" + feedbackTypeDetail.size + ")" }}</mat-option>
      </mat-select>
    </mat-form-field>
  </section>

  <section *ngIf="selectedFeedbacks && selectedFeedbacks.length > 0" class="pb-6">
    <ul *ngIf="selectedFeedbacks && selectedFeedbacks.length > 0">
      <li *ngFor="let feedback of selectedFeedbacks" class="p-0">
        <mat-card appearance="outlined" class="p-3 mb-1 text-transparent hover:text-black hover:bg-black/[.12] transition-all cursor-pointer">
          <div class="flex">
            <div class="grow text-black" (click)="editFeedback(feedback)" (keyup.enter)="editFeedback(feedback)" [tabindex]="0">
              <div *ngIf="feedback.feedbackTypeId !== weeklyReportTypeId"><strong>{{feedback.feedbackTypeId !== cmFeedbackTypeId ? "Onliner: " : "Career Mentor: "}}</strong> {{feedback.feedbackTypeId !== weeklyReportTypeId ? feedback.employeeName: feedback.submittedByName }} <strong class="inline text-obs-maroon">{{displayRevisionAndRequestedMessage(feedback)}}</strong></div>
              <div *ngIf="feedback.feedbackTypeId === weeklyReportTypeId"><strong>Weekly PulseCheck Report</strong></div>
              <div *ngIf="feedback.feedbackTypeId !== cmFeedbackTypeId && feedback.feedbackTypeId !== weeklyReportTypeId"> <strong> Client:</strong> {{feedback.clientName}} </div>
              <div><strong> Last Modified:</strong> {{feedback.submittedDate | date:'MM/dd/yyyy'}} at {{feedback.submittedDate | date:'hh:mma'}}</div>
            </div>
            <div *ngIf="feedback.requestedById === null" class="p-2 flex-none flex items-center hover:text-obs-maroon">
              <button class="" (click)="openDeleteConfirmation(feedback)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </mat-card>
      </li>
    </ul>
    <div *ngIf="!isLoading && (!feedbacks || feedbacks.length === 0)">
      <div class="text-center mt-5">
        <p><i>No feedback saved by you just yet!</i><p>
      </div>
    </div>
  </section>

  <section *ngIf="isFeedbackLead" class="pb-6">
    <h3 class="pb-4 col-span-12 text-2xl font-medium text-obs-maroon sm:col-span-2">Confidential Drafts</h3>
    <ul *ngIf="confidentialFeedbacks">
      <li *ngFor="let feedback of confidentialFeedbacks" class="p-0">
        <mat-card appearance="outlined" class="p-3 mb-1 text-transparent hover:text-black hover:bg-black/[.12] transition-all cursor-pointer">
          <div class="flex">
            <div class="grow text-black" (click)="editConfidentialFeedback(feedback)" (keyup.enter)="editConfidentialFeedback(feedback)" [tabindex]="0">
              <div><strong>Onliner:</strong> {{feedback.employeeName}} </div>
              <div><strong> Last Modified:</strong> {{feedback.submittedDate | date:'MM/dd/yyyy'}} at {{feedback.submittedDate | date:'hh:mma'}}</div>
            </div>
            <div class="p-2 flex-none flex items-center hover:text-obs-primary">
              <button class="" (click)="openDeleteConfirmationConfidential(feedback)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </mat-card>
      </li>
    </ul>
    <div *ngIf="(!confidentialFeedbacks || confidentialFeedbacks.length === 0)  && !isLoading">
      <div class="text-center mt-5">
        <p><i>No confidential notes are saved by you just yet!</i><p>
      </div>
    </div>
  </section>
</div>

