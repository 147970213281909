import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ViewCmfeedbackRoutingModule } from './view-cmfeedback-routing.module';
import { ViewCmfeedbackComponent } from './view-cmfeedback.component';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { CmFeedbackDetailModule } from 'src/app/shared/modules/cm-feedback-detail/cm-feedback-detail.module';

@NgModule({
  declarations: [ViewCmfeedbackComponent],
  imports: [
    CommonModule,
    ViewCmfeedbackRoutingModule,

    AngularMaterialModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    CmFeedbackDetailModule,
  ],
})
export class ViewCmfeedbackModule {}
