/* eslint-disable @typescript-eslint/no-explicit-any */
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { decode } from 'html-entities';
import { sumBy } from 'lodash';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Content } from 'pdfmake/interfaces';
import { lastValueFrom } from 'rxjs';
import { OnlinerPersonalInfo } from 'src/app/shared/models/onliner-personal-info.model';
import { SelfAssessmentDto } from './feedback.service';
import {
  FbFeedbacksSelfAssessment,
  FbSelfAssessment,
  FbSelfAssessmentFeedback,
  FocalReviewBaseCamp,
  FocalReviewDetails,
  FocalReviewRevenue,
  FocalSickTime,
  FocalUtilization,
  FocalWorkHistory,
  PDBudgetSummary,
  PDDeductionSummary,
  SelfAssessmentService,
} from './self-assessment.service';

@Injectable()
export class SelfAssessmentPdfBuilderService {
  [x: string]: any;
  private previousYear!: number;
  private budgetYearCurrent!: string;
  private PDBudgetSummaryCurrent!: PDBudgetSummary;
  private PDBudgetSummaryPrevious!: PDBudgetSummary;
  private PDDeductionSummaryUnitsCurrent: PDDeductionSummary[] = [];
  private PDDeductionSummaryHoursCurrent: PDDeductionSummary[] = [];
  private focalReviewDetails!: FocalReviewDetails;
  private focalReviewBaseCamp!: FocalReviewBaseCamp;
  private focalReviewRevenue!: FocalReviewRevenue;
  private focalSickTime!: FocalSickTime;

  // feedback section variables
  private feedbacks: FbSelfAssessmentFeedback[] = [];
  private submitterName!: string;
  private feedbackType: string[] = [];
  private feedbackTypeDescr!: string;
  private feedbackQuestions: string[] = [];
  private feedbackAnswers: string[] = [];
  private feedbackId: number[] = [];
  private feedbackQuestionType: string[] = [];
  private contentYPadding = 8;
  private contentXPadding = 16;
  private tableHeaderFillColor = '#E9ECEF';
  private tableHeaderContentTopMargin = 2;
  private tableHeaderContentLeftMargin = 8;
  private nestedChildtableXMargin = 8;
  private nestedChildtableYMargin = 12;
  private questionEnumToGroupTitle = new Map<string, string>([
    ['CoreValueSA', 'CORE VALUES'],
    ['CorpCitizenshipSA', 'CORPORATE CITIZENSHIP'],
    ['GrowthDevSA', 'GROWTH & DEVELOPMENT'],
    ['DeliverySA', 'DELIVERY'],
  ]);

  constructor(
    private selfAssessmentService: SelfAssessmentService,
    private httpClient: HttpClient
  ) {}

  public async open(
    onlinerPersonalInfo: OnlinerPersonalInfo,
    selfAssessment: SelfAssessmentDto
  ) {
    await this.executeOperation(onlinerPersonalInfo, selfAssessment, 'open');
  }

  public async print(
    onlinerPersonalInfo: OnlinerPersonalInfo,
    selfAssessment: SelfAssessmentDto
  ) {
    await this.executeOperation(onlinerPersonalInfo, selfAssessment, 'print');
  }

  public async download(
    onlinerPersonalInfo: OnlinerPersonalInfo,
    selfAssessment: SelfAssessmentDto
  ) {
    await this.executeOperation(
      onlinerPersonalInfo,
      selfAssessment,
      'download'
    );
  }

  private async executeOperation(
    onlinerPersonalInfo: OnlinerPersonalInfo,
    selfAssessment: SelfAssessmentDto,
    action = 'open'
  ) {
    let fbSelfAssessment: FbSelfAssessment;
    let fbRequestCountProvidedForOnliners = 0;
    let fbRequestCountRequestedByOnliners = 0;
    let fbRequestCountRequestedFromClients = 0;
    let fbRequestCountRequestedFromOnliners = 0;
    let resumeUpdateDate = '';
    let countLateTimeSheetSubmission = '';
    let countWeeklyHealthSubmission = '';
    const uniqueFeedbacks: FbFeedbacksSelfAssessment[] = [];
    let pDDeductionSummaryUnitsPrevious: PDDeductionSummary[] = [];
    let pDDeductionSummaryHoursPrevious: PDDeductionSummary[] = [];
    let focalUtilization: FocalUtilization[] = [];
    let focalWorkHistory: FocalWorkHistory[] = [];
    let budgetYearPrevious: string;
    let deliveryDisclaimerMonthYear: string;
    let assessmentYear = 0;
    let previousHours = 0;
    let currentHours = 0;
    let previousBillableHours = 0;
    let currentBillableHours = 0;
    let previousSickHours = 0;
    let currentSickHours = 0;

    await lastValueFrom(
      this.selfAssessmentService.getSelfAssessmentDetail(selfAssessment.id)
    ).then(async (data: FbSelfAssessment) => {
      assessmentYear = data.fiscalYear;
      fbSelfAssessment = data;

      await lastValueFrom(
        this.selfAssessmentService.GetCountOfFeedbacks(
          fbSelfAssessment?.fiscalYear || 0,
          true,
          fbSelfAssessment?.submittedBy || ''
        )
      ).then((data: Array<number>) => {
        fbRequestCountProvidedForOnliners = data[0];
        fbRequestCountRequestedByOnliners = data[1];
        fbRequestCountRequestedFromClients = data[2];
        fbRequestCountRequestedFromOnliners = data[3];
      });

      await lastValueFrom(
        this.selfAssessmentService.getCorporateCitizenship(
          fbSelfAssessment?.fiscalYear || 0,
          true,
          fbSelfAssessment?.submittedBy || ''
        )
      ).then(data => {
        resumeUpdateDate = data[2];
        countLateTimeSheetSubmission = data[0];
        countWeeklyHealthSubmission = data[1];
      });

      await lastValueFrom(
        this.selfAssessmentService.GetFocalReviewDetails(
          fbSelfAssessment?.fiscalYear || 0,
          true,
          fbSelfAssessment?.submittedBy || ''
        )
      ).then(data => {
        this.focalReviewDetails = data;
        if (data) {
          this.focalReviewBaseCamp = data.focalReviewBaseCamp;
          previousHours = data.focalReviewBaseCamp.previousHours;
          currentHours = data.focalReviewBaseCamp.currentHours;
          this.focalReviewRevenue = data.focalReviewRevenue;
          previousBillableHours = data.focalReviewRevenue.previousBillableHours;
          currentBillableHours = data.focalReviewRevenue.currentBillableHours;
          focalUtilization = data.focalUtilizations.slice(0, 2);
          this.focalSickTime = data.focalSickTime;
          previousSickHours = data.focalSickTime.previousSickHours;
          currentSickHours = data.focalSickTime.currentSickHours;
          focalWorkHistory = data.focalWorkHistory;
          if (focalUtilization.length > 0) {
            deliveryDisclaimerMonthYear =
              focalUtilization[0].monthYear === ''
                ? 'NA'
                : focalUtilization[0].monthYear;
          }
        }
      });

      // Unique Feedbacks
      await lastValueFrom(
        this.selfAssessmentService.GetFeedbackData(
          fbSelfAssessment?.fiscalYear || 0,
          true,
          fbSelfAssessment?.submittedBy || ''
        )
      ).then(data => {
        if (data && data.length !== 0) {
          this.feedbacks = data;
          let index = 0;
          let currentAssessmentId: number;
          if (this.feedbacks.length !== 0) {
            currentAssessmentId = this.feedbacks[0].id; // the first assessments id
          }
          this.feedbacks.forEach(feedback => {
            if (feedback.id !== currentAssessmentId) {
              // move to the next assessment (it's not reading the last assessment)
              const assessment: FbFeedbacksSelfAssessment =
                new FbFeedbacksSelfAssessment();
              assessment.answers = this.feedbackAnswers;
              assessment.questions = this.feedbackQuestions;
              assessment.submitterName = this.submitterName;
              assessment.questionType = this.feedbackType;
              assessment.typeDescr = this.feedbackTypeDescr;
              uniqueFeedbacks[index] = assessment;

              index++; // next index/corresponding assessment
              currentAssessmentId = feedback.id;

              // reset the values and go again
              this.feedbackAnswers = [];
              this.feedbackQuestions = [];
              this.feedbackType = [];
              this.submitterName = '';
            }
            this.feedbackType.push(feedback.type);
            this.submitterName = feedback.submitterId;
            this.feedbackTypeDescr = feedback.typeDescr;
            this.feedbackQuestions.push(feedback.question);
            this.feedbackId.push(feedback.id);
            this.feedbackAnswers.push(decode(feedback.answer));
          });

          // the last assessment
          const lastAssessment: FbFeedbacksSelfAssessment =
            new FbFeedbacksSelfAssessment();
          lastAssessment.answers = this.feedbackAnswers;
          lastAssessment.questions = this.feedbackQuestions;
          lastAssessment.questionType = this.feedbackType;
          lastAssessment.typeDescr = this.feedbackTypeDescr;
          lastAssessment.submitterName = this.submitterName;
          uniqueFeedbacks[index] = lastAssessment;
        }
      });

      // PD History
      await lastValueFrom(
        this.selfAssessmentService.GetPDHistory(
          fbSelfAssessment?.fiscalYear || 0,
          true,
          false,
          fbSelfAssessment?.submittedBy || ''
        )
      ).then(data => {
        this.PDBudgetSummaryCurrent = data;
        if (
          this.PDBudgetSummaryCurrent !== null &&
          this.PDBudgetSummaryCurrent.pdDeductionSummary !== null
        ) {
          this.PDDeductionSummaryHoursCurrent =
            this.PDBudgetSummaryCurrent.pdDeductionSummary.filter(
              deduction => deduction.deductionTypeId === 'H'
            );
          this.budgetYearCurrent = this.PDBudgetSummaryCurrent.budgetYear;

          this.PDDeductionSummaryUnitsCurrent =
            this.PDBudgetSummaryCurrent.pdDeductionSummary.filter(
              deduction => deduction.deductionTypeId === 'U'
            );
        }
      });

      await lastValueFrom(
        this.selfAssessmentService.GetPDHistory(
          fbSelfAssessment?.fiscalYear - 1 || 0,
          true,
          false,
          fbSelfAssessment?.submittedBy || ''
        )
      ).then(data => {
        this.PDBudgetSummaryPrevious = data;
        if (
          this.PDBudgetSummaryPrevious !== null &&
          this.PDBudgetSummaryPrevious.pdDeductionSummary !== null
        ) {
          pDDeductionSummaryHoursPrevious =
            this.PDBudgetSummaryPrevious.pdDeductionSummary.filter(
              (deduction: PDDeductionSummary) =>
                deduction.deductionTypeId === 'H'
            );
          budgetYearPrevious = this.PDBudgetSummaryPrevious.budgetYear;

          pDDeductionSummaryUnitsPrevious =
            this.PDBudgetSummaryPrevious.pdDeductionSummary.filter(
              deduction => deduction.deductionTypeId === 'U'
            );
        }
      });

      const documentDefinition = await this.buildDocumentObject(
        onlinerPersonalInfo,
        fbSelfAssessment,
        fbRequestCountProvidedForOnliners,
        fbRequestCountRequestedByOnliners,
        fbRequestCountRequestedFromClients,
        fbRequestCountRequestedFromOnliners,
        resumeUpdateDate,
        countLateTimeSheetSubmission,
        countWeeklyHealthSubmission,
        uniqueFeedbacks,
        pDDeductionSummaryUnitsPrevious,
        pDDeductionSummaryHoursPrevious,
        focalUtilization,
        focalWorkHistory,
        budgetYearPrevious,
        deliveryDisclaimerMonthYear,
        assessmentYear,
        previousHours,
        currentHours,
        previousBillableHours,
        currentBillableHours,
        previousSickHours,
        currentSickHours
      );
      const pdf = pdfMake;
      pdf.vfs = pdfFonts.pdfMake.vfs;

      switch (action) {
        case 'open':
          pdf.createPdf(documentDefinition).open();
          break;
        case 'print':
          pdf.createPdf(documentDefinition).print();
          break;
        case 'download':
          pdfMake
            .createPdf(documentDefinition)
            .download(
              this.getFileName(assessmentYear, onlinerPersonalInfo, 'pdf')
            );
          break;
        default:
          pdf.createPdf(documentDefinition).open();
          break;
      }
    });
  }

  private async buildDocumentObject(
    onlinerPersonalInfo: OnlinerPersonalInfo,
    fbSelfAssessment: FbSelfAssessment,
    fbRequestCountProvidedForOnliners: number,
    fbRequestCountRequestedByOnliners: number,
    fbRequestCountRequestedFromClients: number,
    fbRequestCountRequestedFromOnliners: number,
    resumeUpdateDate: string,
    countLateTimeSheetSubmission: string,
    countWeeklyHealthSubmission: string,
    uniqueFeedbacks: FbFeedbacksSelfAssessment[],
    pDDeductionSummaryUnitsPrevious: PDDeductionSummary[],
    pDDeductionSummaryHoursPrevious: PDDeductionSummary[],
    focalUtilization: FocalUtilization[],
    focalWorkHistory: FocalWorkHistory[],
    budgetYearPrevious: string,
    deliveryDisclaimerMonthYear: string,
    assessmentYear: number,
    previousHours: number,
    currentHours: number,
    previousBillableHours: number,
    currentBillableHours: number,
    previousSickHours: number,
    currentSickHours: number
  ) {
    const obsLogo = await this.getObsLogo();
    const obsLogoBlobUrl: any = await this.readFileAsDataURL(obsLogo);

    return {
      content: [
        {
          image: obsLogoBlobUrl,
          width: 120,
          marginBottom: this.contentXPadding,
        },
        this.buildOnlinerProfile(onlinerPersonalInfo),
        this.buildCoreValue(fbSelfAssessment),
        this.buildCorpCitizeship(
          fbSelfAssessment,
          fbRequestCountProvidedForOnliners,
          fbRequestCountRequestedByOnliners,
          fbRequestCountRequestedFromClients,
          fbRequestCountRequestedFromOnliners,
          resumeUpdateDate,
          countLateTimeSheetSubmission,
          countWeeklyHealthSubmission,
          uniqueFeedbacks
        ),
        this.buildGrowthDev(
          fbSelfAssessment,
          pDDeductionSummaryUnitsPrevious,
          pDDeductionSummaryHoursPrevious,
          budgetYearPrevious
        ),
        this.buildDelivery(
          fbSelfAssessment,
          focalUtilization,
          focalWorkHistory,
          deliveryDisclaimerMonthYear,
          assessmentYear,
          previousHours,
          currentHours,
          previousBillableHours,
          currentBillableHours,
          previousSickHours,
          currentSickHours
        ),
      ],
      defaultStyle: {
        columnGap: 20,
        fontSize: 12,
        lineHeigh: 20,
      },
      styles: {
        questionGroupCatHeader: {
          fontSize: 16,
          bold: true,
          marginTop: 25,
          marginBottom: 10,
        },
        cellTextMargin: {
          marginBottom: 8,
        },
        captionText: {
          fontSize: 10,
        },
        tableHeaderTitle: {
          fontSize: 14,
          marginTop: this.tableHeaderContentTopMargin,
          marginLeft: this.tableHeaderContentLeftMargin,
          fillColor: this.tableHeaderFillColor,
        },
        nestedChildTableMargin: {
          marginLeft: this.nestedChildtableXMargin,
          marginRight: this.nestedChildtableXMargin,
          marginTop: this.nestedChildtableYMargin,
          marginBottom: this.nestedChildtableYMargin,
        },
      },
    };
  }

  private buildOnlinerProfile(onlinerPersonalInfo: OnlinerPersonalInfo) {
    const _startDate =
      onlinerPersonalInfo !== null
        ? new Date(onlinerPersonalInfo.startDate)
        : new Date();

    const month = _startDate.toLocaleString('en-US', {
      month: 'long',
    });

    return {
      table: {
        heights: 20,
        widths: [200, '*'],
        body: [
          [
            { text: 'Onliner', bold: true },
            onlinerPersonalInfo.firstName + ' ' + onlinerPersonalInfo.lastName,
          ],
          [
            { text: 'Start Date', bold: true },
            `${month} ${_startDate.getDate()}, ${_startDate.getFullYear()}`,
          ],
          [
            { text: 'Primary Competency', bold: true },
            onlinerPersonalInfo?.primaryCompetency || '',
          ],
          [
            { text: 'Consultant Competency', bold: true },
            onlinerPersonalInfo?.consultantCompetency || '',
          ],
          [
            { text: 'Practice', bold: true },
            onlinerPersonalInfo?.practice || '',
          ],
        ],
      },
      layout: 'noBorders',
    };
  }

  private buildCoreValue(fbSelfAssessment: FbSelfAssessment) {
    const coreValueAns = fbSelfAssessment?.answers.find(
      s => s.question.questionEnum === 'CoreValueSA'
    );
    if (!coreValueAns) return '';

    return [
      {
        text:
          this.questionEnumToGroupTitle.get(
            coreValueAns.question.questionEnum
          ) || '',
        style: 'questionGroupCatHeader',
      },
      {
        ul: [
          'CARING & EMPATHY - We look out for each other',
          'PASSION - We are energized by what we do',
          "INTEGRITY - We do what's right",
          'FORWARD THINKING - We are always evolving',
          'COMMITMENT & PERSEVERANCE - We finish what we start',
        ],
        marginTop: this.contentYPadding,
        marginLeft: this.contentXPadding,
      },
      {
        text: [
          {
            text: `${coreValueAns.question.question}\n\n`,
            bold: true,
          },
          {
            text: 'Onliner Evaluation\n',
            bold: true,
          },
          {
            text: `${coreValueAns.stringValue}\n\n`,
          },
        ],
        marginTop: this.contentXPadding,
        marginLeft: this.contentXPadding,
      },
      {
        text: [
          '\n',
          {
            text: [
              { text: 'Developing', bold: true },
              ' - Not currently achieving consistent results but in the process of developing/working towards a more consistent outcome.\n',
            ],
          },
          {
            text: [
              { text: 'Meeting Expectations', bold: true },
              ' - Consistently meeting expectations, possibly exceeding expectations at times.\n',
            ],
          },
          {
            text: [
              { text: 'Exceeding Expectations', bold: true },
              ' - Consistently exceeding expectations.\n',
            ],
          },
        ],
        marginTop: this.contentXPadding,
      },
    ];
  }

  private buildCorpCitizeship(
    fbSelfAssessment: FbSelfAssessment,
    fbRequestCountProvidedForOnliners: number,
    fbRequestCountRequestedByOnliners: number,
    fbRequestCountRequestedFromClients: number,
    fbRequestCountRequestedFromOnliners: number,
    resumeUpdateDate: string,
    countLateTimeSheetSubmission: string,
    countWeeklyHealthSubmission: string,
    uniqueFeedbacks: FbFeedbacksSelfAssessment[]
  ) {
    const corpCitenzenshipAns = fbSelfAssessment?.answers.filter(
      s => s.question.questionEnum === 'CorpCitizenshipSA'
    );
    const mainQuestion = corpCitenzenshipAns && corpCitenzenshipAns[0];
    const mainQuestionComment = corpCitenzenshipAns && corpCitenzenshipAns[1];
    if (!corpCitenzenshipAns) return '';

    return [
      {
        table: {
          heights: 20,
          widths: ['100%'],
          headerRows: 1,
          keepWithHeaderRows: 1,
          body: [
            [
              {
                text:
                  this.questionEnumToGroupTitle.get('CorpCitizenshipSA') || '',
                style: 'questionGroupCatHeader',
              },
            ],
            [
              {
                text: [
                  "Corporate Citizenship at Online includes being responsive to internal requests, sharing your knowledge/experience with others, providing feedback for team members, and taking responsibility to know what's happening within your practice/business unit/company and how you can contribute.",
                  '\n\nHere is your annual synopsis\n\n',
                ],
                marginLeft: this.contentXPadding,
              },
            ],
            [
              {
                table: {
                  heights: 20,
                  widths: ['100%'],
                  headerRows: 1,
                  keepWithHeaderRows: 1,
                  body: [
                    [
                      {
                        text: 'Feedback',
                        style: 'tableHeaderTitle',
                      },
                    ],
                    [
                      {
                        table: {
                          heights: 20,
                          dontBreakRows: true,
                          widths: ['35.5%', '23%', '38.5%', '3%'],
                          body: [
                            [
                              {
                                text: '# Requested From Onliners',
                                bold: true,
                              },
                              fbRequestCountRequestedFromOnliners || 0,
                              { text: '# Requested By Onliners', bold: true },
                              fbRequestCountRequestedByOnliners || 0,
                            ],
                            [
                              {
                                text: '# Requested From Clients',
                                bold: true,
                              },
                              fbRequestCountRequestedFromClients || 0,
                              { text: '# Provided For Onliners', bold: true },
                              fbRequestCountProvidedForOnliners || 0,
                            ],
                            [
                              { text: 'Resume Updated Date', bold: true },
                              resumeUpdateDate || '',
                              {
                                text: '# Late Time Sheet Submissions',
                                bold: true,
                              },
                              countLateTimeSheetSubmission || 0,
                            ],
                            [
                              {
                                text: 'Weekly Health/Delivery Submissions',
                                bold: true,
                              },
                              countWeeklyHealthSubmission || 0,
                              '',
                              '',
                            ],
                          ],
                        },
                        layout: 'noBorders',
                        style: 'nestedChildTableMargin',
                      },
                    ],
                    [
                      {
                        table: {
                          heights: 20,
                          widths: ['100%'],
                          body: [[this.buildFeedbackList(uniqueFeedbacks)]],
                        },
                        layout: 'noBorders',
                      },
                    ],
                    [
                      {
                        table: {
                          heights: 5,
                          widths: ['100%'],
                          body: [
                            [
                              {
                                text: '',
                                border: [false, true, false, false],
                                marginLeft: this.tableHeaderContentLeftMargin,
                              },
                            ],
                          ],
                        },
                      },
                    ],
                    [
                      {
                        table: {
                          heights: 20,
                          widths: ['100%'],
                          dontBreakRows: true,
                          body: [
                            [
                              {
                                text: [
                                  {
                                    text: `${mainQuestion?.question.question}\n\n`,
                                  },
                                  {
                                    text: 'Please evaluate yourself based on the above expectation description and provide comments to support\n\n',
                                    bold: true,
                                  },
                                  {
                                    text: 'Onliner Evaluation\n',
                                    bold: true,
                                  },
                                  {
                                    text: `${mainQuestion?.stringValue}\n\n`,
                                  },
                                  {
                                    text: 'Comments\n',
                                    bold: true,
                                  },
                                  {
                                    text: `${mainQuestionComment?.stringValue}\n\n`,
                                  },
                                ],
                                marginTop: this.contentXPadding,
                                marginLeft: this.contentXPadding,
                              },
                            ],
                          ],
                        },
                        layout: 'noBorders',
                      },
                    ],
                  ],
                },
                style: 'nestedChildTableMargin',
                layout: 'noBorders',
              },
            ],
          ],
        },
        layout: 'noBorders',
      },
    ];
  }

  private buildFeedbackList(uniqueFeedbacks: FbFeedbacksSelfAssessment[]) {
    let response: Array<Content> = [];
    if (uniqueFeedbacks.length === 0) return '';

    uniqueFeedbacks.map((f: FbFeedbacksSelfAssessment) => {
      response = [
        ...response,
        {
          table: {
            heights: 15,
            headerRows: 1,
            dontBreakRows: true,
            keepWithHeaderRows: 1,
            widths: ['20%', '51.5%', '23.5%'],
            body: [
              [
                {
                  text: [
                    { text: f.submitterName, bold: true },
                    '\n',
                    `(${f.typeDescr})`,
                  ],
                  colSpan: 3,
                },
                '',
                '',
              ],
              ...f.questions.map((question, i) => {
                if (f.questionType[i].toLowerCase() === 'radio') {
                  return [
                    {
                      table: {
                        heights: 15,
                        widths: ['100%'],
                        body: [
                          [
                            {
                              text: [
                                {
                                  text: `${question}\n`,
                                  bold: true,
                                  style: 'cellTextMargin',
                                },
                                {
                                  text: f.answers[i],
                                  style: 'cellTextMargin',
                                },
                              ],
                            },
                          ],
                        ],
                      },
                      colSpan: 3,
                      marginLeft: this.contentXPadding,
                      layout: 'noBorders',
                    },
                    '',
                    '',
                  ];
                } else {
                  return [
                    {
                      text: [
                        { text: question, bold: true },
                        '\n',
                        {
                          text: f.answers[i],
                          style: 'cellTextMargin',
                        },
                      ],
                      colSpan: 3,
                      style: 'cellTextMargin',
                      marginLeft: this.contentXPadding,
                    },
                    '',
                    '',
                  ];
                }
              }),
            ],
          },
          layout: 'noBorders',
          style: 'nestedChildTableMargin',
        },
      ];
    });

    return response;
  }

  private buildGrowthDev(
    fbSelfAssessment: FbSelfAssessment,
    pDDeductionSummaryUnitsPrevious: PDDeductionSummary[],
    pDDeductionSummaryHoursPrevious: PDDeductionSummary[],
    budgetYearPrevious: string
  ) {
    const growthDevAns = fbSelfAssessment?.answers.filter(
      s => s.question.questionEnum === 'GrowthDevSA'
    );
    const mainQuestion = growthDevAns && growthDevAns[0];
    const mainQuestionComment = growthDevAns && growthDevAns[1];
    return [
      {
        table: {
          heights: 20,
          widths: ['100%'],
          headerRows: 1,
          keepWithHeaderRows: 1,
          body: [
            [
              {
                text: this.questionEnumToGroupTitle.get('GrowthDevSA') || '',
                style: 'questionGroupCatHeader',
              },
            ],
            [
              {
                table: {
                  heights: 20,
                  widths: ['40%', '40%', '20%'],
                  headerRows: 1,
                  dontBreakRows: true,
                  keepWithHeaderRows: 1,
                  body: [
                    [
                      {
                        text: 'PD History',
                        style: 'tableHeaderTitle',
                        colSpan: 3,
                      },
                      '',
                      '',
                    ],
                    [
                      {
                        colSpan: 3,
                        text: [
                          {
                            text: 'Units CAD',
                            bold: true,
                          },
                          {
                            ...(pDDeductionSummaryUnitsPrevious.length !==
                              0 && {
                              text: `\n${budgetYearPrevious || ''}`,
                            }),
                          },
                        ],
                        marginTop: this.tableHeaderContentTopMargin,
                        marginLeft: this.tableHeaderContentLeftMargin,
                      },
                      '',
                      '',
                    ],
                    ...pDDeductionSummaryUnitsPrevious.map(
                      (pd: PDDeductionSummary) => {
                        return [
                          { text: pd.deductionDesc, marginLeft: 50 },
                          { text: pd.comment },
                          {
                            text: new CurrencyPipe('en-US').transform(
                              pd.deductionValue
                            ),
                            alignment: 'right',
                          },
                        ];
                      }
                    ),
                    [
                      {
                        colSpan: 3,
                        text: `${new CurrencyPipe('en-US').transform(
                          sumBy(
                            pDDeductionSummaryUnitsPrevious,
                            'deductionValue'
                          )
                        )}`,
                        alignment: 'right',
                        bold: true,
                      },
                      '',
                      '',
                    ],
                    [
                      {
                        colSpan: 3,
                        text: [
                          { text: 'Hours', bold: true },
                          {
                            ...(pDDeductionSummaryHoursPrevious.length !==
                              0 && {
                              text: `\n${budgetYearPrevious}`,
                            }),
                          },
                        ],
                        marginTop: this.tableHeaderContentTopMargin,
                        marginLeft: this.tableHeaderContentLeftMargin,
                      },
                      '',
                      '',
                    ],
                    ...pDDeductionSummaryHoursPrevious.map(
                      (pd: PDDeductionSummary) => {
                        return [
                          { text: pd.deductionDesc, marginLeft: 50 },
                          { text: pd.comment },
                          { text: pd.deductionValue, alignment: 'right' },
                        ];
                      }
                    ),
                    [
                      {
                        colSpan: 3,
                        text: `${sumBy(
                          pDDeductionSummaryHoursPrevious,
                          'deductionValue'
                        )}`,
                        alignment: 'right',
                        bold: true,
                      },
                      '',
                      '',
                    ],
                  ],
                },
                layout: 'noBorders',
                style: 'nestedChildTableMargin',
              },
            ],
            [
              {
                table: {
                  heights: 20,
                  widths: ['100%'],
                  body: [
                    [
                      {
                        text: '',
                        border: [false, true, false, false],
                        marginLeft: this.tableHeaderContentLeftMargin,
                      },
                    ],
                  ],
                },
                style: 'nestedChildTableMargin',
              },
            ],
            [
              {
                table: {
                  heights: 5,
                  widths: ['100%'],
                  dontBreakRows: true,
                  body: [
                    [
                      {
                        text: [
                          { text: `${mainQuestion?.question.question}\n\n` },
                          {
                            text: 'Please evaluate yourself based on the above expectation description and provide comments to support\n\n',
                            bold: true,
                          },
                          { text: 'Onliner Evaluation\n', bold: true },
                          { text: `${mainQuestion?.stringValue}\n\n` },
                          { text: 'Comments\n', bold: true },
                          { text: `${mainQuestionComment?.stringValue}\n\n` },
                        ],
                        marginTop: this.contentXPadding,
                        marginLeft: this.contentXPadding,
                      },
                    ],
                  ],
                },
                layout: 'noBorders',
              },
            ],
          ],
        },
        layout: 'noBorders',
      },
    ];
  }

  private buildDelivery(
    fbSelfAssessment: FbSelfAssessment,
    focalUtilization: FocalUtilization[],
    focalWorkHistory: FocalWorkHistory[],
    deliveryDisclaimerMonthYear: string,
    assessmentYear: number,
    previousHours: number,
    currentHours: number,
    previousBillableHours: number,
    currentBillableHours: number,
    previousSickHours: number,
    currentSickHours: number
  ) {
    const deliverySaAns = fbSelfAssessment?.answers.filter(
      s => s.question.questionEnum === 'DeliverySA'
    );
    const mainQuestion = deliverySaAns && deliverySaAns[0];
    const mainQuestionComment = deliverySaAns && deliverySaAns[1];
    return [
      {
        table: {
          heights: 20,
          widths: ['100%'],
          headerRows: 1,
          dontBreakRows: true,
          keepWithHeaderRows: 1,
          body: [
            [
              {
                text: this.questionEnumToGroupTitle.get('DeliverySA') || '',
                style: 'questionGroupCatHeader',
              },
            ],
            [
              {
                table: {
                  heights: 20,
                  widths: ['40%', '30%', '30%'],
                  headerRows: 2,
                  dontBreakRows: true,
                  keepWithHeaderRows: 2,
                  body: [
                    [
                      {
                        text: `Data in this section is accurate as of the end of ${deliveryDisclaimerMonthYear}`,
                        style: 'tableHeaderTitle',
                        colSpan: 3,
                      },
                      '',
                      '',
                    ],
                    [
                      '',
                      {
                        text: this.fiscalYearForFRDetails(
                          false,
                          assessmentYear
                        ),
                        bold: true,
                        alignment: 'center',
                        marginTop: this.tableHeaderContentTopMargin,
                      },
                      {
                        text: this.fiscalYearForFRDetails(true, assessmentYear),
                        bold: true,
                        alignment: 'center',
                        marginTop: this.tableHeaderContentTopMargin,
                      },
                    ],
                    [
                      {
                        text: 'Base Camp Hours',
                        bold: true,
                        marginLeft: this.tableHeaderContentLeftMargin,
                      },
                      { text: previousHours || 0, alignment: 'right' },
                      { text: currentHours || 0, alignment: 'right' },
                    ],
                    [
                      {
                        text: 'Total Billable Hours',
                        bold: true,
                        marginLeft: this.tableHeaderContentLeftMargin,
                      },
                      {
                        text: previousBillableHours || 0,
                        alignment: 'right',
                      },
                      {
                        text: currentBillableHours || 0,
                        alignment: 'right',
                      },
                    ],
                    [
                      {
                        text: 'Utilization %',
                        bold: true,
                        marginLeft: this.tableHeaderContentLeftMargin,
                      },
                      {
                        text: this.checkUtilizationAvailability(
                          'previous year',
                          assessmentYear,
                          focalUtilization
                        )
                          ? new PercentPipe('en-US').transform(
                              this.getUtilization(
                                'previous year',
                                assessmentYear,
                                focalUtilization
                              ),
                              '1.2'
                            )
                          : 'Not Available',
                        alignment: 'right',
                      },
                      {
                        text: this.checkUtilizationAvailability(
                          'current year',
                          assessmentYear,
                          focalUtilization
                        )
                          ? new PercentPipe('en-US').transform(
                              this.getUtilization(
                                'current year',
                                assessmentYear,
                                focalUtilization
                              ),
                              '1.2'
                            )
                          : 'Not Available',
                        alignment: 'right',
                      },
                    ],
                    [
                      {
                        text: 'Wellness Hours Taken',
                        bold: true,
                        marginLeft: this.tableHeaderContentLeftMargin,
                      },
                      { text: previousSickHours || 0, alignment: 'right' },
                      { text: currentSickHours || 0, alignment: 'right' },
                    ],
                  ],
                },
                style: 'nestedChildTableMargin',
                layout: 'noBorders',
              },
            ],
            [
              {
                table: {
                  heights: 20,
                  headerRows: 1,
                  dontBreakRows: true,
                  keepWithHeaderRows: 1,
                  widths: ['100%'],
                  ...(focalWorkHistory.length !== 0
                    ? {
                        body: [
                          [
                            {
                              text: 'Work History (YTD)',
                              style: 'tableHeaderTitle',
                            },
                          ],
                          ...focalWorkHistory.map((f: FocalWorkHistory, i) => {
                            return [
                              {
                                marginTop:
                                  i === 0 ? this.nestedChildtableYMargin : 0,
                                text: f.companyName,
                                marginLeft: this.nestedChildtableXMargin,
                              },
                            ];
                          }),
                        ],
                      }
                    : {
                        body: [
                          [
                            {
                              text: 'Work History (YTD)',
                              style: 'tableHeaderTitle',
                            },
                          ],
                          [
                            {
                              text: 'Not Available',
                              marginTop: this.nestedChildtableYMargin,
                              marginLeft: this.nestedChildtableXMargin,
                            },
                          ],
                        ],
                      }),
                },
                style: 'nestedChildTableMargin',
                layout: 'noBorders',
                marginBottom: this.contentXPadding,
              },
            ],
            [
              {
                table: {
                  heights: 5,
                  widths: ['100%'],
                  body: [
                    [
                      {
                        text: '',
                        border: [false, true, false, false],
                        marginLeft: this.tableHeaderContentLeftMargin,
                      },
                    ],
                  ],
                },
                style: 'nestedChildTableMargin',
              },
            ],
            [
              {
                table: {
                  heights: 20,
                  widths: ['100%'],
                  dontBreakRows: true,
                  body: [
                    [
                      {
                        text: [
                          { text: `${mainQuestion?.question.question}\n\n` },
                          {
                            text: 'Please evaluate yourself based on the above expectation description and provide comments to support\n\n',
                            bold: true,
                          },
                          { text: 'Onliner Evaluation\n', bold: true },
                          { text: `${mainQuestion?.stringValue}\n\n` },
                          { text: 'Comments\n', bold: true },
                          { text: `${mainQuestionComment?.stringValue}\n\n` },
                        ],
                        marginTop: this.contentXPadding,
                        marginLeft: this.contentXPadding,
                      },
                    ],
                  ],
                },
                layout: 'noBorders',
              },
            ],
          ],
        },
        layout: 'noBorders',
      },
    ];
  }

  private fiscalYearForFRDetails(
    isCurrentYear: boolean,
    assessmentYear: number
  ) {
    const currentYear = assessmentYear;
    const previousYear = assessmentYear - 1;

    return isCurrentYear ? currentYear : previousYear;
  }

  private checkUtilizationAvailability(
    yearString: string,
    assessmentYear: number,
    focalUtilization: FocalUtilization[]
  ) {
    let utilization: FocalUtilization[] = [];
    if (yearString === 'previous year') {
      utilization = focalUtilization.filter(
        u => u.fiscalYear === assessmentYear - 1
      );
    } else if (yearString === 'current year') {
      utilization = focalUtilization.filter(
        u => u.fiscalYear === assessmentYear
      );
    }
    return utilization.length === 0 ? false : true;
  }

  getUtilization(
    yearString: string,
    assessmentYear: number,
    focalUtilization: FocalUtilization[]
  ): number {
    let utilization: FocalUtilization[] = [];
    if (yearString === 'previous year') {
      utilization = focalUtilization.filter(
        u => u.fiscalYear === assessmentYear - 1
      );
    } else if (yearString === 'current year') {
      utilization = focalUtilization.filter(
        u => u.fiscalYear === assessmentYear
      );
    }
    return utilization[0].utilization;
  }

  private getObsLogo(): Promise<any> {
    return new Promise(resolve => {
      this.httpClient
        .get('/assets/img/OBSlogo_tiny.png', { responseType: 'blob' })
        .subscribe(res => {
          resolve(res);
        });
    });
  }

  protected readFileAsDataURL = (res: Blob) => {
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new DOMException('Problem parsing file.'));
      };

      fileReader.onloadend = () => {
        resolve(fileReader.result);
      };

      fileReader.readAsDataURL(res);
    });
  };

  protected getFileName(
    assessmentYear: number,
    onlinerPersonalInfo: OnlinerPersonalInfo,
    extension: string
  ): string {
    if (!onlinerPersonalInfo) return 'onliner.pdf';

    const _competency = onlinerPersonalInfo?.consultantCompetency.toLowerCase();
    const _practice = onlinerPersonalInfo?.practice.toLowerCase();

    const practice = `${
      _practice !== 'not available' &&
      _practice !== 'not applicable' &&
      _practice !== '' &&
      _practice !== null
        ? `${this.removeUnwantedChar(_practice)}-`
        : ''
    }`;

    const competency = `${
      _competency !== 'not available' &&
      _competency !== 'not applicable' &&
      _competency !== '' &&
      _competency !== null
        ? `${this.removeUnwantedChar(_competency)}-`
        : ''
    }`;

    const fname = this.removeUnwantedChar(onlinerPersonalInfo.firstName);
    const lname = this.removeUnwantedChar(onlinerPersonalInfo.lastName);

    return `${assessmentYear}-${practice}${competency}${fname}-${lname}.${extension}`;
  }

  private removeUnwantedChar(data: string) {
    return data.replace(/\s+/g, '').trim().toLowerCase();
  }
}
