import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as formValidator from '../../shared/validators/form-validator';
import { SavedFbConfidential } from './assessment.service';
import { defaultOnliner } from './onliner.service';

@Injectable({
  providedIn: 'root',
})
export class ConfidentialFeedbackFilterService {
  _filterCriteriaForm!: UntypedFormGroup;
  firstDayOfTheYear = new Date(new Date().getFullYear(), 0, 1);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe
  ) {}

  resetForm() {
    this._filterCriteriaForm.reset({
      onliner: defaultOnliner,
      submittedBy: defaultOnliner,
      startDate: this.firstDayOfTheYear,
      endDate: new Date(),
      practice: 'All',
    });
  }

  getFilterCriteriaForm(): UntypedFormGroup {
    if (this._filterCriteriaForm) {
      return this._filterCriteriaForm;
    } else {
      return this.createFilterCriteriaForm();
    }
  }

  getFilterCriteriaModel(): FeedbackFilterCriteria {
    const filterCriteria = new FeedbackFilterCriteria();
    filterCriteria.onlinerId = this._filterCriteriaForm.controls['onliner']
      .value
      ? this._filterCriteriaForm.controls['onliner'].value.userId
      : defaultOnliner.userId;
    filterCriteria.practice = this._filterCriteriaForm.controls['practice']
      .value
      ? this._filterCriteriaForm.controls['practice'].value
      : 'N/A';
    filterCriteria.submittedById = this._filterCriteriaForm.controls[
      'submittedBy'
    ].value
      ? this._filterCriteriaForm.controls['submittedBy'].value.userId
      : defaultOnliner.userId;
    filterCriteria.startDate = this._filterCriteriaForm.controls['startDate']
      .value
      ? this._filterCriteriaForm.controls['startDate'].value
      : this.firstDayOfTheYear;
    filterCriteria.endDate = this._filterCriteriaForm.controls['endDate'].value
      ? this._filterCriteriaForm.controls['endDate'].value
      : new Date();
    // Set the endDate to the end of that day, otherwise the filter service will filter out any feedback
    // created after this service was created or created past this time on that date selected.
    filterCriteria.endDate.setHours(23);
    filterCriteria.endDate.setMinutes(59);
    filterCriteria.endDate.setSeconds(59);

    return filterCriteria;
  }

  getFilterDateRangeDisplay(model: FeedbackFilterCriteria): string {
    if (!model) {
      model = this.getFilterCriteriaModel();
    }
    return `(${this.datePipe.transform(
      model.startDate,
      'longDate'
    )} - ${this.datePipe.transform(model.endDate, 'longDate')})`;
  }

  filterSubmitter(
    feedback: SavedFbConfidential,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return (
      filterCriteria.submittedById === defaultOnliner.userId ||
      feedback.submittedById === filterCriteria.submittedById
    );
  }

  filterOnliner(
    feedback: SavedFbConfidential,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return (
      filterCriteria.onlinerId === defaultOnliner.userId ||
      feedback.employeeId === filterCriteria.onlinerId
    );
  }

  filterStartDate(
    feedback: SavedFbConfidential,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return filterCriteria.startDate <= new Date(feedback.submittedDate);
  }

  filterEndDate(
    feedback: SavedFbConfidential,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return filterCriteria.endDate >= new Date(feedback.submittedDate);
  }

  filterPractice(
    feedback: SavedFbConfidential,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return (
      filterCriteria.practice === 'All' ||
      feedback.practice === filterCriteria.practice
    );
  }

  private createFilterCriteriaForm(): UntypedFormGroup {
    return (this._filterCriteriaForm = this.formBuilder.group({
      onliner: [defaultOnliner, formValidator.validateOnliner()],
      practice: ['All'],
      submittedBy: [defaultOnliner, formValidator.validateOnliner()],
      startDate: [this.firstDayOfTheYear],
      endDate: [new Date()],
    }));
  }
}

class FeedbackFilterCriteria {
  onlinerId!: string;
  practice!: string;
  submittedById!: string;
  startDate!: Date;
  endDate!: Date;
}
