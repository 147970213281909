<div class="dropdown">
  <div class="dropdown-content">
    <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="flex-start">
      <a [href]="goalTrackerLink" target="_blank" (click)="close()" class="notification flex" color="primary" mat-button>
        <div class="flex">
          <img src="/assets/img/power-apps.png" class="notification-icon mr-3"/>
          <div class="notification-label">Goal Tracker App</div>
        </div>
      </a>
    </div>
    <div *ngFor="let item of items" (click)="close()" class="dropdown-footer" fxLayout="row" fxLayoutAlign="flex-start">
      <a [routerLink]="item.route" class="notification flex justify-start" color="primary" mat-button>
        <div >
          <div class="notification-label">{{ item.label }}</div>
        </div>
      </a>
    </div>
    <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="flex-start">
      <a (click)="logout()" class="notification flex justify-start cursor-pointer" color="primary" mat-button>
        <div>
          <div class="notification-label">Log Out</div>
        </div>
      </a>
    </div>
  </div>
</div>
