<vex-secondary-toolbar current="Question Configuration">
  <ng-container [ngSwitch]="configMode">
    <vex-breadcrumbs [crumbs]="['Question Configuration']" class="flex-auto" *ngSwitchCase="QuestionConfigMode.FeedbackTypeConfig"></vex-breadcrumbs>
    <vex-breadcrumbs [crumbs]="['Question Configuration', 'Question Group']" class="flex-auto" *ngSwitchCase="QuestionConfigMode.QuestionGroupConfig"></vex-breadcrumbs>
    <vex-breadcrumbs [crumbs]="['Question Configuration', 'Question']" class="flex-auto" *ngSwitchCase="QuestionConfigMode.QuestionConfig"></vex-breadcrumbs>
    <vex-breadcrumbs [crumbs]="['Question Configuration', 'Option Group']" class="flex-auto" *ngSwitchCase="QuestionConfigMode.OptionGroupConfig"></vex-breadcrumbs>
  </ng-container>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="row m-0">
    <div class = "component-container">
        <div class="mb-5">
            <h3 class="page-title">Questions Configuration</h3>
        </div>

        <div class="p-0 pr-5">
            <div *ngIf="!isLoading" novalidate [style.fontSize.px]="18">
                <div [ngSwitch]="configMode">
                    <app-feedback-type-detail *ngSwitchCase="QuestionConfigMode.FeedbackTypeConfig"
                        [selectedForm]="selectedForm"
                        (selectedQuestionGroup)="configQuestionGroup($event)">
                    </app-feedback-type-detail>

                    <app-question-group-detail
                        *ngSwitchCase="QuestionConfigMode.QuestionGroupConfig"
                        [selectedForm]="selectedForm"
                        [questionGroup]="selectedQuestionGroup!"
                        [title]="questionGroupConfigTitle!"
                        (selectedQuestion)="configQuestion($event)"
                        (return)="returnQuestionGroup()">
                    </app-question-group-detail>

                    <app-question-detail
                        *ngSwitchCase="QuestionConfigMode.QuestionConfig"
                        [selectedQuestion]="selectedQuestion!"
                        [title]="questionConfigTitle!"
                        [selectedQuestionGroup]="selectedQuestionGroup!"
                        [numQuestions]="numQuestions"
                        (configOptionGroup)="configOptionGroup($event)"
                        (return)="returnQuestion()">
                    </app-question-detail>

                    <app-option-group-detail
                        *ngSwitchCase="QuestionConfigMode.OptionGroupConfig"
                        [selectedOptionGroup]="selectedOptionGroup!"
                        [title]="optionGroupConfigTitle!"
                        (return)="returnOptionGroup()">
                    </app-option-group-detail>
                </div>
            </div>
        </div>
    </div>
</div>
