import { Component, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class FeedbackTypeDialogData {
  typeDescr!: string;
  title!: string;
}

@Component({
  selector: 'app-feedback-type-dialog',
  templateUrl: './feedback-type-dialog.component.html',
  styleUrls: ['./feedback-type-dialog.component.scss'],
})
export class FeedbackTypeDialogComponent implements OnInit {
  labelControl!: FormControl;
  public config: FeedbackTypeDialogData = inject(MAT_DIALOG_DATA);

  constructor(public dialogRef: MatDialogRef<FeedbackTypeDialogComponent>) {}

  ngOnInit() {
    this.labelControl = new FormControl(this.config.typeDescr);
  }

  save() {
    if (this.labelControl.dirty) {
      this.dialogRef.close(this.labelControl.value);
    } else {
      this.dialogRef.close();
    }
  }

  close() {
    this.dialogRef.close();
  }
}
