import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
// import { SnackBarService } from './../services/snackbar.service';
import { FeedbackService } from 'src/app/core/services/feedback.service';
// import { FeedbackService } from './../services/feedback.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { FeedbackTypeEnum } from 'src/app/shared/enums/enums.model';
// import { FeedbackTypeEnum } from '../shared/Enums/enums.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';
// import { AuthService } from 'src/app/services/auth.service';
import {
  AssessmentService,
  SavedAssessment,
  SavedFbConfidential,
} from 'src/app/core/services/assessment.service';
// import { SavedAssessment, AssessmentService, SavedFbConfidential } from '../services/assessment.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-saved-feedback',
  templateUrl: './saved-feedback.component.html',
  styleUrls: ['./saved-feedback.component.scss'],
})
export class SavedFeedbackComponent implements OnInit {
  feedbacks!: SavedAssessment[];

  unsolicitedFeedbacks!: SavedAssessment[];
  requestedFeedbacks!: SavedAssessment[];
  ciaFeedbacks!: SavedAssessment[];
  cmFeedbacks!: SavedAssessment[];
  weeklyReports!: SavedAssessment[];
  confidentialFeedbacks!: SavedFbConfidential[];
  selectedFeedbacks!: SavedAssessment[];

  isLoading!: boolean;
  isCfrAdmin!: boolean;
  isFeedbackLead!: boolean;

  feedbackTypeDetails: feedbackTypeDetail[] = [];
  selectedType?: feedbackTypeDetail;

  cmFeedbackTypeId: number = FeedbackTypeEnum.CareerMentorFeedback;
  weeklyReportTypeId: number = FeedbackTypeEnum.WeeklyHealthDelivery;

  constructor(
    private assessmentService: AssessmentService,
    private feedbackService: FeedbackService,
    private snackBarService: SnackBarService,
    private router: Router,
    public dialog: MatDialog,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.isCfrAdmin = this.authService.doesUserHaveRole([
      environment.roles.FeedbackCFRAdmin,
    ]);
    this.isFeedbackLead = this.authService.doesUserHaveRole([
      environment.roles.FeedbackLead,
    ]);
    this.setupFeedbackTypes();
    this.setupPage();
  }

  getSelectedFeedbacks() {
    switch (this.selectedType?.name) {
      case 'All':
        this.selectedFeedbacks = this.feedbacks;
        break;

      case 'Unsolicited':
        this.selectedFeedbacks = this.unsolicitedFeedbacks;
        break;

      case 'Requested':
        this.selectedFeedbacks = this.requestedFeedbacks;
        break;

      case 'CM Feedback':
        this.selectedFeedbacks = this.cmFeedbacks;
        break;

      case 'Culture In Action':
        this.selectedFeedbacks = this.ciaFeedbacks;
        break;

      // case 'Weekly Report':
      //   this.selectedFeedbacks = this.weeklyReports;
      //   break;
    }
  }

  displayRevisionAndRequestedMessage(feedback: SavedAssessment): string {
    feedback.log.sort((a, b) =>
      a.changedDate < b.changedDate ? 1 : b.changedDate < a.changedDate ? -1 : 0
    );
    const description = feedback.log[0].description;
    let message =
      description !== null && description.startsWith('Reason for return')
        ? '* Revision Required *\xa0\xa0'
        : '';
    message += feedback.requestedById !== null ? '* Requested *' : '';

    return message;
  }

  editFeedback(feedback: SavedAssessment) {
    const isFeedbackRequested = this.isFeedbackRequested(feedback);
    if (feedback.feedbackTypeId === FeedbackTypeEnum.CareerMentorFeedback) {
      this.router.navigate(['cmFeedback', feedback.id]);
      // eslint-disable-next-line brace-style
    }
    // else if (feedback.feedbackTypeId == FeedbackTypeEnum.WeeklyHealthDelivery) {
    //   this.router.navigate(['weeklyDelivery']);
    // }
    else {
      this.router.navigate([
        'feedback',
        feedback.id,
        feedback.feedbackTypeId,
        isFeedbackRequested,
      ]);
    }
  }

  editConfidentialFeedback(feedback: SavedFbConfidential) {
    this.router.navigate(['confidentialNotes', feedback.id]);
  }

  openDeleteConfirmation(feedback: SavedAssessment) {
    const confirmDialog = new ConfirmDialog();

    confirmDialog.title = 'Remove Feedback?';
    confirmDialog.okButtonTitle = 'Yes';
    confirmDialog.cancelButtonTitle = 'No';
    confirmDialog.theme = 'danger';

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: confirmDialog,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.deleteFeedback(feedback);
      }
    });
  }

  openDeleteConfirmationConfidential(feedback: SavedFbConfidential) {
    const confirmDialog = new ConfirmDialog();

    confirmDialog.title = 'Remove Confidential Note?';
    confirmDialog.okButtonTitle = 'Yes';
    confirmDialog.cancelButtonTitle = 'No';
    confirmDialog.theme = 'danger';

    const dialogRef: MatDialogRef<ConfirmationDialogComponent, string> =
      this.dialog.open<ConfirmationDialogComponent, ConfirmDialog>(
        ConfirmationDialogComponent,
        {
          width: '350px',
          data: confirmDialog,
          disableClose: true,
        }
      );

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.deleteConfidentialFeedback(feedback);
      }
    });
  }

  deleteFeedback(feedback: SavedAssessment) {
    this.assessmentService.deleteFeedback(feedback.id).subscribe(
      () => {
        const index = this.feedbacks.indexOf(feedback);
        if (index !== -1) {
          this.feedbacks.splice(index, 1);
        }
        this.snackBarService.message('Deleted');
        this.setupPage();
      },
      error => {
        this.snackBarService.error(error);
      }
    );
  }

  deleteConfidentialFeedback(feedback: SavedFbConfidential) {
    this.assessmentService.deleteConfidentialFeedback(feedback.id).subscribe(
      () => {
        const index = this.confidentialFeedbacks.indexOf(feedback);
        if (index !== -1) {
          this.confidentialFeedbacks.splice(index, 1);
        }
        this.snackBarService.message('Deleted');
        this.setupPage();
      },
      error => {
        this.snackBarService.error(error);
      }
    );
  }

  private isFeedbackRequested(feedback: SavedAssessment) {
    return (
      feedback.feedbackTypeId === FeedbackTypeEnum.ClientFeedback ||
      (feedback.feedbackTypeId === FeedbackTypeEnum.AdhocFeedback &&
        feedback.requestedDate !== null)
    );
  }

  private setupPage() {
    this.isLoading = true;

    if (this.isFeedbackLead) {
      forkJoin([
        this.feedbackService.getDrafts(),
        this.feedbackService.getConfidentialDrafts(),
      ]).subscribe(
        ([feedbacks, confidentialFeedbacks]) => {
          this.feedbacks = feedbacks;
          this.confidentialFeedbacks = confidentialFeedbacks;
          this.filterFeedbacks();
          this.selectedFeedbacks = this.feedbacks;
          this.selectedType = this.feedbackTypeDetails.find(
            ft => ft.name === 'All'
          );
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
          this.snackBarService.error(error);
        }
      );
    } else {
      this.feedbackService.getDrafts().subscribe(
        feedbacks => {
          this.feedbacks = feedbacks;
          this.filterFeedbacks();
          this.selectedFeedbacks = this.feedbacks;
          this.selectedType = this.feedbackTypeDetails.find(
            ft => ft.name === 'All'
          );
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
          this.snackBarService.error(error);
        }
      );
    }
  }

  private setupFeedbackTypes() {
    this.feedbackTypeDetails.push({ name: 'All', size: 0 });
    this.feedbackTypeDetails.push({ name: 'CM Feedback', size: 0 });

    if (this.isCfrAdmin) {
      this.feedbackTypeDetails.push({ name: 'Culture In Action', size: 0 });
    }

    this.feedbackTypeDetails.push({ name: 'Requested', size: 0 });
    this.feedbackTypeDetails.push({ name: 'Unsolicited', size: 0 });
    // this.feedbackTypeDetails.push({ name: 'Weekly Report', size: 0 });
  }

  private filterFeedbacks() {
    if (this.feedbacks) {
      this.unsolicitedFeedbacks = [];
      this.requestedFeedbacks = [];
      this.ciaFeedbacks = [];
      this.cmFeedbacks = [];
      // this.weeklyReports = [];

      for (const feedback of this.feedbacks) {
        if (
          feedback.feedbackTypeId === FeedbackTypeEnum.AdhocFeedback &&
          feedback.requestedById === null
        ) {
          this.unsolicitedFeedbacks.push(feedback);
        } else if (
          (feedback.feedbackTypeId === FeedbackTypeEnum.AdhocFeedback ||
            feedback.feedbackTypeId ===
              FeedbackTypeEnum.CareerMentorFeedback) &&
          feedback.requestedById !== null
        ) {
          this.requestedFeedbacks.push(feedback);
        } else if (
          feedback.feedbackTypeId === FeedbackTypeEnum.CareerMentorFeedback &&
          feedback.requestedById === null
        ) {
          this.cmFeedbacks.push(feedback);
        } else if (
          feedback.feedbackTypeId === FeedbackTypeEnum.CultureInAction
        ) {
          this.ciaFeedbacks.push(feedback);
        }
        // else if (feedback.feedbackTypeId == FeedbackTypeEnum.WeeklyHealthDelivery) {
        //   this.weeklyReports.push(feedback);
        // }
      }

      if (this.feedbackTypeDetails) {
        const all = this.feedbackTypeDetails.find(ft => ft.name === 'All');
        if (all) {
          all.size = this.feedbacks?.length;
        }
        const unsolicited = this.feedbackTypeDetails.find(
          ft => ft.name === 'Unsolicited'
        );
        if (unsolicited) {
          unsolicited.size = this.unsolicitedFeedbacks?.length;
        }
        const requested = this.feedbackTypeDetails.find(
          ft => ft.name === 'Requested'
        );
        if (requested) {
          requested.size = this.requestedFeedbacks?.length;
        }
        const cmFeedback = this.feedbackTypeDetails.find(
          ft => ft.name === 'CM Feedback'
        );
        if (cmFeedback) {
          cmFeedback.size = this.cmFeedbacks?.length;
        }
        // this.feedbackTypeDetails.find(ft => ft.name === 'Weekly Report').size = this.weeklyReports.length;
        if (this.isCfrAdmin) {
          const cia = this.feedbackTypeDetails.find(
            ft => ft.name === 'Culture In Action'
          );
          if (cia) {
            cia.size = this.ciaFeedbacks?.length;
          }
        }
      }
    }
  }
}

export class feedbackTypeDetail {
  name!: string;
  size!: number;
}
