import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AccountInfo } from '@azure/msal-browser';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  FeedbackService,
  SelfAssessmentDto,
} from 'src/app/core/services/feedback.service';
import { SelfAssessmentPdfBuilderService } from 'src/app/core/services/self-assessement-pdf-builder.service';
import {
  FbSelfAssessmentReviewer,
  SelfAssessmentService,
} from 'src/app/core/services/self-assessment.service';
import { OnlinerPersonalInfo } from 'src/app/shared/models/onliner-personal-info.model';

@Component({
  selector: 'app-team-survey-list',
  templateUrl: './team-survey-list.component.html',
  styleUrls: ['./team-survey-list.component.scss'],
})
export class TeamSurveyListComponent implements OnInit, OnChanges {
  @ViewChild(MatSort, { static: true })
  sort!: MatSort;

  @Input() set feedbacks(feedbck: SelfAssessmentDto[]) {
    this.isLoading = true;
    this.dataSource = new MatTableDataSource<SelfAssessmentDto>(feedbck);
    this.selection = new SelectionModel<SelfAssessmentDto>(true, []);
    if (feedbck) {
      setTimeout(() => {
        this.isLoading = false;
      }, 200);
    } else {
      this.dataSource = new MatTableDataSource<SelfAssessmentDto>();
    }
  }

  isLoading = true;
  selected = [];
  dataSource!: MatTableDataSource<SelfAssessmentDto>;
  currentCm!: string;
  practice!: string;
  currentUserId!: string;
  selfAssessmentReviewers: FbSelfAssessmentReviewer[] = [];
  currentUserProfile!: AccountInfo | null;
  selection = new SelectionModel<SelfAssessmentDto>();
  isProcessingPdf = false;

  resultColumnHeaders: string[] = [
    'select',
    'practice',
    'competencyName',
    'onliner',
    'submittedDate',
    'careerMentorName',
    'assesmentAuditStatus',
    'actualReviewerName',
    'reviewDate',
  ];

  constructor(
    private router: Router,
    private authService: AuthService,
    private selfAssessmentService: SelfAssessmentService,
    private selfAssessmentPdfBuilderService: SelfAssessmentPdfBuilderService,
    private feedbackService: FeedbackService
  ) {}

  ngOnInit() {
    this.currentUserId = this.authService.getUserId().toLowerCase();
    this.currentUserProfile = this.authService.getUserProfile();
    this.selfAssessmentService.GetSelfAssessmentReviewers().subscribe({
      next: data => (this.selfAssessmentReviewers = data),
    });
  }

  ngOnChanges() {
    this.dataSource.sort = this.sort;
  }

  onSelect(selected: SelfAssessmentDto) {
    const isEditorReviewer = this.selfAssessmentReviewers.some(
      reviewer =>
        reviewer.reviewerId.toLowerCase() ===
          this.currentUserId.toLowerCase() && reviewer.isEditor
    );
    this.selfAssessmentService
      .getSchedule(selected.fiscalYear)
      .subscribe(schedule => {
        this.router.navigate(
          ['viewYearlyAssessmentReport/', schedule.id, selected.id],
          {
            queryParams: { isEditor: isEditorReviewer },
          }
        );
      });
  }

  async generatePdf() {
    this.isProcessingPdf = true;
    let generatedPdfCount = 0;
    this.selection.selected.map((selectedAssessment: SelfAssessmentDto) => {
      this.selfAssessmentService
        .getSchedule(selectedAssessment.fiscalYear)
        .subscribe(() => {
          this.feedbackService
            .getSubmitterDetails(selectedAssessment.submittedBy)
            .subscribe(async (data: OnlinerPersonalInfo) => {
              await this.selfAssessmentPdfBuilderService.download(
                data,
                selectedAssessment
              );

              generatedPdfCount = generatedPdfCount + 1;
              if (generatedPdfCount === this.selection.selected.length) {
                this.isProcessingPdf = false;
                generatedPdfCount = 0;
              }
            });
        });
    });
  }

  isRowClickable(row: SelfAssessmentDto): boolean {
    const isEditorReviewer = this.selfAssessmentReviewers.some(
      reviewer =>
        reviewer.reviewerId.toLowerCase() ===
          this.currentUserId.toLowerCase() && reviewer.isEditor
    );
    return (
      isEditorReviewer ||
      row.careerMentor === this.currentUserId.toLowerCase() ||
      row.actualReviewerId === '' ||
      (row.actualReviewerId !== '' &&
        row.actualReviewerId.toLowerCase() ===
          this.currentUserId.toLowerCase()) ||
      (row.assesmentAuditStatus !== '' &&
        (row.assesmentAuditStatus.toLowerCase() === 'reviewed' ||
          row.assesmentAuditStatus.toLowerCase() === 'reviewed_updated'))
    );
  }

  toggleSelectRows() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
}
