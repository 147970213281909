import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FbWeeklyReport } from 'src/app/core/services/weekly-report-service';
import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-view-weekly-health-delivery-detail',
  templateUrl: './view-weekly-health-delivery-detail.component.html',
  styleUrls: ['./view-weekly-health-delivery-detail.component.scss'],
})
export class ViewWeeklyHealthDeliveryDetailComponent {
  @Input() feedback!: FbWeeklyReport;
  // @Input() feedbackTypeIdParam: number;
  @Input() operationInProgress!: string | null;
  // @Input() isLegacyFeedback: boolean;

  cmComments!: string;
  mismatchedIDs = false;

  constructor(
    private location: Location,
    public dialog: MatDialog
  ) {}

  targetWeekMonday(targetWeek: Date) {
    const endDate = new Date(targetWeek);
    const prevMonday = new Date(targetWeek).setDate(endDate.getDate() - 4);
    return prevMonday;
  }

  goBack() {
    this.location.back();
  }

  htmlDecode(input: string) {
    const txt = document.createElement('textarea');
    txt.innerHTML = input;
    return txt.value;
  }

  private showConfirmationDialog() {
    const confirmDialog = new ConfirmDialog();
    confirmDialog.title = 'Access Error';
    confirmDialog.message =
      "<p> You don't have access to this feedback using this section of the app.</p>";
    confirmDialog.okButtonTitle = 'Continue';
    // confirmDialog.cancelButtonTitle = "Cancel";
    this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: confirmDialog,
      disableClose: true,
    });
  }

  private getCmComments() {
    this.feedback.log.sort((a, b) =>
      a.changedDate < b.changedDate ? 1 : b.changedDate < a.changedDate ? -1 : 0
    );
    this.cmComments = '';
  }
}
