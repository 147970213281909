import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionExpiredDialogComponent } from 'src/app/shared/components/session-expired-dialog/session-expired-dialog.component';

@Injectable()
export class UtilityService {
  public sessionExpiredDialogIsOpened = false;

  public constructor(private dialog: MatDialog) {}

  public showSessionExpiredDialog() {
    if (!this.sessionExpiredDialogIsOpened) {
      this.sessionExpiredDialogIsOpened = true;
      const dialogRef = this.dialog.open(SessionExpiredDialogComponent, {
        width: '400px',
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(() => {
        this.sessionExpiredDialogIsOpened = false;
      });
    }
  }
}
