import { Component, OnInit } from '@angular/core';
import {
  CareerMentorService,
  SavedCMDelegate,
} from 'src/app/core/services/career-mentor.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-cm-delegation-report',
  templateUrl: './cm-delegation-report.component.html',
  styleUrls: ['./cm-delegation-report.component.scss'],
})
export class CmDelegationReportComponent implements OnInit {
  delegations!: SavedCMDelegate[];
  delegationViewModel!: CMDelegateVM[];
  isLoading = true;

  constructor(
    private cmservice: CareerMentorService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit() {
    this.cmservice.getDelegates().subscribe(
      data => {
        this.delegations = data.sort((a, b) =>
          `${a.careerManagerName}${a.delegateName}`.localeCompare(
            `${b.careerManagerName}${b.delegateName}`
          )
        );
        this.delegationViewModel = this.vmFor(this.delegations);
        this.isLoading = false;
      },
      error => {
        this.snackBarService.error(error);
      }
    );
  }

  vmFor(data: SavedCMDelegate[]): CMDelegateVM[] {
    const vm = [];
    let lastCM = '';
    for (let i = 0; i < data.length; i++) {
      if (data[i].careerManagerId === lastCM) {
        vm.push(new CMDelegateVM('', data[i].delegateName, ''));
      } else {
        lastCM = data[i].careerManagerId;
        vm.push(
          new CMDelegateVM(
            data[i].careerManagerName,
            data[i].delegateName,
            'group-break'
          )
        );
      }
    }
    return vm;
  }
}

class CMDelegateVM {
  constructor(
    public visibleCareerMentor: string,
    public delegate: string,
    public cssClass: string
  ) {}
}
