import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { CookiePolicyComponent } from './cookie-policy.component';
import { CookiePolicyRoutingModule } from './cookie-policy.routing.module';

@NgModule({
  declarations: [CookiePolicyComponent],
  imports: [CommonModule, CookiePolicyRoutingModule, MatIconModule],
})
export class CookiePolicyModule {}
