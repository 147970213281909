import { Observable, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { ApiErrorData } from 'src/app/shared/interfaces/api-error.interface';
import { AuthorizedHttp } from './authorized-http.service';

@Injectable()
export class UserService {
  private apiEndpoint = `${environment.apiEndpoint}/api/user`;

  constructor(private http: AuthorizedHttp) {}

  public getMyProfilePhoto(): Observable<string> {
    return this.http
      .getText<string>(`${this.apiEndpoint}/myphoto`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }
}
