<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<form *ngIf="!isLoading" [formGroup]="requestFeedbackForm" class="mb-5" novalidate>
  <div *ngIf="isCMRequest" class="row form-group m-0 mt-2">
    <mat-form-field appearance="outline" class="onliner-width"
      [ngClass]="{'editable': true}">
      <mat-label>Choose CM *</mat-label>
      <input matInput name="careerMentor" placeholder="Choose CM"
        aria-label="Request Feedback From" [matAutocomplete]="careerMentorAuto"
        formControlName="careerMentor" />
      <mat-autocomplete #careerMentorAuto="matAutocomplete"
        [displayWith]="onlinerDisplay" (optionSelected)="cmSelected($event)">
        <mat-option class="options"
          *ngFor="let option of filteredCareerMentors | async" [value]="option">
          {{ onlinerDisplay(option) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="row form-group m-0 mt-2">
    <mat-form-field appearance="outline" class="onliner-width"
      [ngClass]="{'editable': cmAssigned}">
      <mat-label>Choose Onliner *</mat-label>
      <input matInput name="onliner" placeholder="Choose Onliner"
        aria-label="Request Feedback From" [matAutocomplete]="onlinerauto"
        formControlName="onliner" />
      <mat-autocomplete #onlinerauto="matAutocomplete"
        [displayWith]="onlinerDisplay">
        <mat-option class="options"
          *ngFor="let option of filteredOnliners | async" [value]="option">
          {{ onlinerDisplay(option) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div>
      <button type="button" mat-stroked-button
        color="basic"
        class="save-btn"
        (click)="addRequestFrom()"
        name="addRequestFromButton"
        [disabled]="!isOnlinerSelected() || isSubmitting">ADD</button>
    </div>
  </div>

  <div *ngIf="isOnlinerBoxNotEmpty()">
    <div class="mb-2 input-pad">Requesting Feedback From:</div>
    <mat-card appearance="outlined"
      class="col-md-11 col-sm-12 col-xs-12 request-box-width">
      <mat-card-content>
        <div class="col-md-10 p-0">
          <span *ngFor="let e of selectedOnliners">
            <div class="onlinersBox">{{e.firstName + " " + e.lastName}}<i
                (click)="removeOnlinerFromBox(e)" (keyup.enter)="removeOnlinerFromBox(e)" [tabindex]="0"
                class="far fa-times-circle fa-lg delete" title="Remove Onliner"></i></div>
          </span>
        </div>
      </mat-card-content>
    </mat-card>
    <div *ngIf="isOnlinerBoxNotEmpty()" style="height: 30px;"></div>
  </div>

  <div *ngIf="!isCMRequest" class="label-header">Choose "Other" if the
    client is not on the list.<br>Choose "Online Business Systems" if the
    Onliner is in a non-billable role.</div>
  <div *ngIf="!isCMRequest" class="row form-group col-md-12 col-sm-6 col-xs-12">
    <mat-form-field appearance="outline" class="client-group client-width"
      style="margin-bottom: -1.25em"
      [ngClass]="{'has-error': requestFeedbackForm.get('client')?.errors && requestFeedbackForm.get('client')?.touched, 'editable': true}">
      <mat-label>Choose Client *</mat-label>
      <input matInput type="text" placeholder="Choose Client"
        aria-label="Client"
        matTooltip="{{toolTip}}"
        matTooltipClass="test"
        [matAutocomplete]="clientauto" formControlName="client">

      <mat-autocomplete #clientauto="matAutocomplete"
        [displayWith]="clientDisplay">
        <mat-option class="options"
          *ngFor="let option of filteredClients | async" [value]="option">
          {{ clientDisplay(option) }}
        </mat-option>
      </mat-autocomplete>
      <mat-error
        *ngIf="requestFeedbackForm.get('client')?.errors && requestFeedbackForm.get('client')?.touched">Please
        enter the name of the Client.</mat-error>
    </mat-form-field>
  </div>

  <div class="row form-group col-md-12 col-sm-6 col-xs-12"
    *ngIf="requestFeedbackForm && requestFeedbackForm.value.client  && requestFeedbackForm.value.client.clientName === 'Other'">
    <mat-form-field appearance="outline" class="client-group client-width"
      style="margin-bottom: -1.25em"
      [ngClass]="{'has-error': requestFeedbackForm.get('otherClient')?.errors && requestFeedbackForm.get('otherClient')?.touched, 'editable': true}">
      <mat-label>Other Client *</mat-label>
      <div class="col-md-10 pl-0">
        <input matInput type="text" aria-label="Other Client" id="otherClient"
          formControlName="otherClient">
      </div>
      <mat-error
        *ngIf="requestFeedbackForm.get('otherClient')?.errors && requestFeedbackForm.get('otherClient')?.touched">Please
        enter the name of the Other Client.</mat-error>
    </mat-form-field>
  </div>

  <div class="label-header input-pad reduce-label-spacing">Request Message (can be customized)</div>
  <mat-card appearance="outlined"
    class="col-md-12 col-sm-12 col-xs-12 input-pad">
    <mat-card-content class="col-md-12 col-sm-12 col-xs-12">
      <mat-form-field appearance="outline"
        class="row form-group col-md-12 col-sm-12 col-xs-12"
        [ngClass]="{'has-error': requestFeedbackForm.get('message')?.errors && requestFeedbackForm.get('message')?.touched}">
        <textarea type="text"
          matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="5"
          style="overflow:hidden; width: 100%;"
          id="message"
          formControlName="message"> </textarea>
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <div style="height: 30px;"></div>
  <div [ngClass]="isCollapsed ? 'fixed-above-footer fixed-collapsed' : 'fixed-above-footer fixed-expanded'">
    <button type="button" mat-stroked-button
      class="submit-btn"
      color="primary"
      (click)="submitOnlinerFeedbackRequest()"
      [disabled]="!isRequestFormValid() || isSubmitting">
      <span *ngIf="isSubmitting"><i class="fa fa-spinner fa-spin"
          aria-hidden="true"></i></span> SUBMIT
    </button>
  </div>
</form>
<!--
<div *ngIf="isLoading" class="loading m-auto"></div> -->
