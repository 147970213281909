import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, forkJoin } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { AuthorizedHttp } from 'src/app/core/services/authorized-http.service';
import { AutoSuggestService } from 'src/app/core/services/auto-suggest.service';
import { CareerMentor } from 'src/app/core/services/career-mentor.service';
import { CommonService } from 'src/app/core/services/common.service';
import { Employee } from 'src/app/core/services/onliner.service';
import { RemoteWorkRequestFilterService } from 'src/app/core/services/remote-work-request-filter.service';
import {
  RemoteWorkRequestService,
  RemoteWorkRequestStatus,
  RemoteWorkRequestType,
} from 'src/app/core/services/remote-work-request.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-remote-work-request-criteria-form',
  templateUrl: './remote-work-request-criteria-form.component.html',
  styleUrls: ['./remote-work-request-criteria-form.component.scss'],
})
export class RemoteWorkRequestCriteriaFormComponent implements OnInit {
  @Input()
  searchCriteriaForm!: UntypedFormGroup;
  @Input()
  onlinerFilterMode!: string;
  @Input()
  isOnlinerFilterVisible!: boolean;
  @Input()
  isAuthorizedToReview!: boolean;
  @Input()
  isReview!: boolean;
  @Input() isSubmissionTypeFilterVisible = true;
  @Output() filterRemoteWorkRequestTriggered = new EventEmitter<boolean>();

  readonly OnlinerFilterModes_AllUsers = 'AllUsers';
  readonly OnlinerFilterModes_CurrentUser = 'CurrentUser';
  onliners!: Employee[];
  remoteWorkRequestTypes!: RemoteWorkRequestType[];
  remoteWorkRequestStatuses!: RemoteWorkRequestStatus[];
  onFilter = true;
  includedDefaultOnliner = false;
  get onlinerControl() {
    return this.searchCriteriaForm.controls['onliner'];
  }
  get remoteWorkRequestTypeControl() {
    return this.searchCriteriaForm.controls['remoteWorkRequestType'];
  }
  get remoteWorkRequestStatusControl() {
    return this.searchCriteriaForm.controls['remoteWorkRequestStatus'];
  }
  get anonymousControl() {
    return this.searchCriteriaForm.controls['anonymous'];
  }

  filteredOnliners!: Observable<Employee[]> | Observable<CareerMentor[]>;
  filteredRemoteWorkRequestTypes!: Observable<RemoteWorkRequestType[]>;
  filteredRemoteWorkRequestStatuses!: Observable<RemoteWorkRequestStatus[]>;

  minDate: Date = new Date(new Date().getFullYear() - 5, 1, 1);
  isLoading!: boolean;
  isSearching!: boolean;
  hideAsterisk!: boolean;
  isOnRequestsPage!: boolean;

  constructor(
    private commonService: CommonService,
    private remoteWorkRequestService: RemoteWorkRequestService,
    private snackBarService: SnackBarService,
    private autoSuggestService: AutoSuggestService,
    private authService: AuthService,
    private authHttp: AuthorizedHttp,
    private remoteWorkRequestFilterService: RemoteWorkRequestFilterService
  ) {}

  ngOnInit() {
    this.getDropdownsData();
  }

  resetForm() {
    this.remoteWorkRequestFilterService.resetRequestForm();
    this.filterRequests();
  }

  setOnlinerFilter() {
    this.onFilter = true;
    this.filteredOnliners = this.autoSuggestService.setOnlinerFilter(
      this.onlinerControl,
      this.onliners,
      this.includedDefaultOnliner,
      this.onFilter
    );
  }
  setRemoteWorkRequestStatusFilter = () =>
    (this.filteredRemoteWorkRequestStatuses =
      this.autoSuggestService.setRemoteWorkRequestStatusFilter(
        this.remoteWorkRequestStatusControl,
        this.remoteWorkRequestStatuses
      ));
  setRemoteWorkRequestTypesFilter = () =>
    (this.filteredRemoteWorkRequestTypes =
      this.autoSuggestService.setRemoteWorkRequestTypesFilter(
        this.remoteWorkRequestTypeControl,
        this.remoteWorkRequestTypes,
        false,
        true
      ));

  onlinerDisplay = (option?: Employee | CareerMentor): string =>
    this.autoSuggestService.onlinerDisplay(option);

  remoteWorkRequestTypeDisplay = (option?: RemoteWorkRequestType): string =>
    this.autoSuggestService.remoteWorkRequestTypeDisplay(option);

  remoteWorkRequestStatusDisplay = (option?: RemoteWorkRequestStatus): string =>
    this.autoSuggestService.remoteWorkRequestStatusDisplay(option);

  filterRequests() {
    if (this.searchCriteriaForm.valid) {
      this.filterRemoteWorkRequestTriggered.emit(true);
    }
  }

  private getDropdownsData() {
    this.isLoading = true;

    forkJoin([
      this.commonService.getEmployeesIncludingInactive(),
      this.commonService.getRemoteWorkRequestStatuses(),
      this.commonService.getRemoteWorkRequestTypes(),
    ]).subscribe(
      data => {
        this.setUpDropDownData(data);
      },
      error => {
        this.isLoading = false;
        this.snackBarService.error(error);
      }
    );
  }

  private setUpDropDownData(
    data: [Employee[], RemoteWorkRequestStatus[], RemoteWorkRequestType[]]
  ) {
    const ONLINER_IX = 0,
      REQUEST_STATUS_IX = 1,
      REQUEST_TYPE_IX = 2;
    if (this.isOnlinerFilterVisible) {
      switch (this.onlinerFilterMode) {
        case this.OnlinerFilterModes_AllUsers: {
          this.onliners = data[ONLINER_IX];
          break;
        }
        case this.OnlinerFilterModes_CurrentUser: {
          this.onliners = data[ONLINER_IX].filter(
            e => e.userId === this.authService.getUserId().toLowerCase()
          );
          break;
        }
        default:
          this.onliners = [];
      }
      this.includedDefaultOnliner =
        this.onlinerFilterMode !== this.OnlinerFilterModes_CurrentUser;
      this.setOnlinerFilter();
    }
    this.remoteWorkRequestStatuses = data[REQUEST_STATUS_IX];
    this.setRemoteWorkRequestStatusFilter();
    this.remoteWorkRequestTypes = data[REQUEST_TYPE_IX];
    this.setRemoteWorkRequestTypesFilter();

    this.isLoading = false;
  }
}
