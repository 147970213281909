/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CareerMentor } from './career-mentor.service';

export abstract class BaseFeedbackFilterService {
  resetForm(_careerMentor?: CareerMentor, _isOnMyTeamFeedbackPage?: boolean) {}
  resetRequestForm() {}
  resetSelfAssessmentForm(_careerMentor?: CareerMentor) {}
  resetClientFeedbackOnlineForm() {}
}
