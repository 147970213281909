import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientFeedbackOnlineListComponent } from './client-feedback-online-list.component';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';

@NgModule({
  declarations: [ClientFeedbackOnlineListComponent],
  imports: [CommonModule, AngularMaterialModule],
  exports: [ClientFeedbackOnlineListComponent],
})
export class ClientFeedbackOnlineListModule {}
