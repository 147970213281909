<form *ngIf="!isLoading && remoteWorkRequestForm"
  [formGroup]="remoteWorkRequestForm" novalidate>
  <div *ngFor="let question of questionsForThisForm">
    <p *ngIf="question.id === 64" class="required-label">Travel Destination</p>
    <div
      *ngIf="question.type.toString() !== 'Date_Input' && ((question.id === laptopQuestionId || question.id === clientApproveQuestionId) && !isClientAssignment) === false && ((question.id === projectNameQuestionId) && !isClientAssignment && !isBusinessTeam) === false">
      <span
        *ngIf="question.id !== 64 && question.id !== 65 && question.id !== 76"
        class="label-header reduce-label-spacing"
        [ngClass]="{'required-label' : !question.isOptional }">{{
        displayQuestion(question) }}</span>
      <mat-card appearance="outlined"
        *ngIf="question.id !== 65 && question.id !== 76 "
        class="col-md-12 col-sm-10 col-xs-12">
        <mat-card-content>
          <div *ngIf="question.type.toString() === 'Radio' && question.id !== 66">
            <div class="row form-group col-md-12 col-sm-10 col-xs-12"
              [ngClass]="{'has-error': hasErrorClass(question.id)}">
              <mat-radio-group formControlName="{{question.id}}">
                <mat-radio-button
                  *ngFor="let option of validQuestionOptions(question)"
                  [value]="option.id"
                  (change)="radioChange($event.value); validRequest()">
                  <span class="radio-rap">{{ optionDisplay(option) }}</span>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div *ngIf="question.type.toString() === 'Radio' && question.id === 66">
            <div class="row form-group col-md-12 col-sm-10 col-xs-12"
              [ngClass]="{'has-error': hasErrorClass(question.id)}">
              <mat-radio-group formControlName="{{question.id}}">
                <mat-radio-button
                  *ngFor="let option of validQuestionOptions(question)"
                  [value]="option.id" (change)="radioChange($event.value)">
                  <span class="radio-rap">{{ optionDisplay(option) }}</span>
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div *ngIf="isBusinessTeam||isClientAssignment">
              <span class="label-header"
                [ngClass]="{'required-label' : !question.isOptional }">Project
                Name</span>
              <mat-card appearance="outlined"
                class="col-md-12 col-sm-10 col-xs-12">
                <mat-card-content>
                  <input matInput id="76"
                    placeholder="What is the project?"
                    formControlName="76"
                    id="76"
                    style="overflow:hidden; outline: none; width: 100%; border: none" />
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <div *ngIf="question.type.toString() === 'Radio_Text'">
            <div class="row form-group col-md-12 col-sm-10 col-xs-12"
              [ngClass]="{'has-error': remoteWorkRequestForm.get(getID(question.id))?.errors && remoteWorkRequestForm.get(getID(question.id))?.touched}">
              <mat-radio-group formControlName="{{question.id}}">
                <mat-radio-button
                  *ngFor="let option of validQuestionOptions(question)"
                  [value]="option.id" (change)="radioTextChange($event.value)">
                  <span class="radio-rap">{{ optionDisplay(option) }}</span>
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <mat-form-field appearance="outline"
              class="row form-group col-md-12 col-sm-10 col-xs-12"
              [ngClass]="{'has-error': remoteWorkRequestForm.get(getRadioTextQuestionId(question.id))?.errors && remoteWorkRequestForm.get(getRadioTextQuestionId(question.id))?.touched }">
              <textarea type="text"
                matInput
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                style="overflow:hidden"
                id="{{getRadioTextQuestionId(question.id)}}"
                formControlName="{{getRadioTextQuestionId(question.id)}}"
                placeholder="Enter comments here..."></textarea>
            </mat-form-field>
          </div>
          <div *ngIf="question.type.toString() === 'Select'">
            <div class="row form-group col-md-12 col-sm-10 col-xs-12"
              [ngClass]="{'has-error': remoteWorkRequestForm.get(getID(question.id))?.errors && remoteWorkRequestForm.get(getID(question.id))?.touched}">
              <mat-select
                #selectInput
                id="{{question.id}}"
                formControlName="{{question.id}}"
                (input)="onSearchChange(selectInput.value)">
                <mat-option
                  *ngFor="let option of validQuestionOptions(question)"
                  [value]="option.id">
                  {{option.value}}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="remoteWorkRequestForm.get(getID(question.id))?.errors">
                Please enter an answer
              </mat-error>
            </div>
          </div>
          <div *ngIf="question.type.toString() === 'Text' && question.id === 64">
            <span class="label-header reduce-label-spacing"
              [ngClass]="{'required-label' : !question.isOptional }">{{
              displayQuestion(question) }}</span>
            <mat-card appearance="outlined"
              class="col-md-12 col-sm-12 col-xs-12 travel-destination-width">
              <mat-card-content>
                <input *ngIf="question.id === 64"
                  [ngClass]="{'has-error': hasErrorClass(64) }"
                  matInput
                  placeholder="Please enter a City"
                  formControlName="64"
                  id="64"
                  style="overflow:hidden; outline: none; width: 100%; border: none" />
              </mat-card-content>
            </mat-card>
            <span class="label-header reduce-label-spacing"
              [ngClass]="{'required-label' : !question.isOptional }">Country</span>
            <mat-card appearance="outlined"
              class="col-md-12 col-sm-12 col-xs-12 travel-destination-width">
              <mat-card-content>
                <input matInput id="65" [matAutocomplete]="auto"
                  style="overflow:hidden; outline: none; width: 100%; border: none"
                  formControlName="65" />
                <mat-autocomplete #auto="matAutocomplete"
                  [displayWith]="countryDisplay" autoActiveFirstOption
                  (optionSelected)="selectCountry($event)" panelWidth="auto">
                  <mat-option class="options"
                    *ngFor="let option of filteredCountries | async"
                    [value]="option">
                    {{ countryDisplay(option) }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="showError('65')">Please enter the Country name</mat-error>
              </mat-card-content>
            </mat-card>
          </div>

          <div *ngIf="question.type.toString() === 'Text' && question.id === 69">
            <mat-form-field appearance="outline"
              class="row form-group col-md-12 col-sm-12 col-xs-12"
              [ngClass]="{'has-error': hasErrorClass(question.id)}"
              style="padding-right:0;">
              <textarea type="text"
                matInput
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="5"
                style="overflow:hidden; border: 1px;"
                id="{{getID(question.id)}}"
                formControlName="{{getID(question.id)}}"
                placeholder="Enter comments here..."> </textarea>
            </mat-form-field>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <span
      *ngIf="question.type.toString() === 'Date_Input' && ((question.id === 63 && !isPermanentRequest) || (question.id === 62))"
      class="label-header reduce-label-spacing"
      [ngClass]="{'required-label' : !(question.isOptional || (isPermanentRequest && question.id === 63))}">{{
      displayQuestion(question) }}</span>
    <mat-card appearance="outlined" *ngIf="question.id === 62"
      class="col-md-3 col-sm-5 col-xs-12">
      <mat-card-content>
        <mat-form-field class="col-md-12 search">
          <!-- [hideRequiredMarker]="hideAsterisk" *ngIf="!isOnRequestsPage"> -->
          <div
            [ngClass]="{'has-error': remoteWorkRequestForm.get(getID(question.id))?.errors && (remoteWorkRequestForm.get(getID(question.id))?.touched || remoteWorkRequestForm.get(getID(question.id - 1))?.touched)}">
            <input
              matInput
              [readonly]="true"
              formControlName="{{question.id}}"
              [matDatepicker]="startDatePicker"
              placeholder="MM/DD/YYYY"
              maxDateRangeValidator="48"
              (dateInput)="validRequest()"
              (click)="startDatePicker.open()"
              (focus)="startDatePicker.open()"
              [min]="minDate">
            <mat-datepicker #startDatePicker></mat-datepicker>
          </div>
          <span class="error-message"
            *ngIf="remoteWorkRequestForm.get(getID(question.id))?.hasError('datePatternError') && remoteWorkRequestForm.get(getID(question.id))?.dirty">
            Must be in Date format
          </span>
          <span class="error-message"
            *ngIf="(remoteWorkRequestForm.get(getID(question.id))?.hasError('invalidStartDate') && (remoteWorkRequestForm.get(getID(question.id))?.touched || remoteWorkRequestForm.get(getID(question.id - 1))?.touched))">
            Start Date must be a date and before the End Date
          </span>
          <span class="error-message"
            *ngIf="(remoteWorkRequestForm.get(getID(question.id))?.hasError('overlappingStart') && remoteWorkRequestForm.get(getID(question.id))?.touched)">
            Start Date overlaps with existing request Dates
          </span>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <div *ngIf="!isPermanentRequest">
      <mat-card appearance="outlined" *ngIf="question.id === 63"
        class="col-md-3 col-sm-5 col-xs-12">
        <mat-card-content>
          <mat-form-field class="col-md-12 search">
            <!-- [hideRequiredMarker]="isPermanentRequest" *ngIf="!isOnRequestsPage"> -->
            <div
              [ngClass]="{'has-error': isPermanentRequest === false && remoteWorkRequestForm.get(getID(question.id))?.errors && remoteWorkRequestForm.get(getID(question.id))?.touched}">
              <input
                matInput
                [readonly]="true"
                formControlName="{{question.id}}"
                [matDatepicker]="endDatePicker"
                placeholder="MM/DD/YYYY"
                minDateRangeValidator="47"
                (dateInput)="validRequest()"
                (click)="endDatePicker.open()"
                (focus)="endDatePicker.open()"
                [min]="minDate"
                [attr.disabled]="isPermanentRequest">
              <mat-datepicker #endDatePicker></mat-datepicker>
            </div>
            <span class="error-message"
              *ngIf="remoteWorkRequestForm.get(getID(question.id))?.hasError('datePatternError') && remoteWorkRequestForm.get(getID(question.id))?.dirty">
              Must be in Date format
            </span>
            <span class="error-message"
              *ngIf="remoteWorkRequestForm.get(getID(question.id-1))?.hasError('invalidStartDate') && remoteWorkRequestForm.get(getID(question.id))?.touched">
              End Date must be a date and after the Start Date
            </span>
            <span class="error-message"
              *ngIf="remoteWorkRequestForm.get(getID(question.id))?.hasError('overlappingEnd') && remoteWorkRequestForm.get(getID(question.id))?.touched">
              End Date overlaps with existing request Dates
            </span>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div *ngIf="!isRequestApproval && !readOnly" class="row form-group">
    <button type="button" mat-stroked-button
      class="save-btn"
      color="basic"
      (click)="saveRemoteWorkRequest()"
      [disabled]="cannotSaveOrSubmit() || remoteWorkRequestForm.pristine">
      <span *ngIf="isSaving"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>
      {{ isSaved ? 'UPDATE' : 'SAVE' }}
    </button>
    <button type="button" mat-stroked-button
      class="submit-btn"
      color="primary"
      (click)="submissionConfirmation()"
      [disabled]="cannotSaveOrSubmit() || !requestValid">
      <span *ngIf="isSubmitting"><i class="fa fa-spinner fa-spin"
          aria-hidden="true"></i></span> SUBMIT
    </button>
  </div>
  <div *ngIf="activateChangeStatusButtons && userCanChangeStatus"
    class="row form-group">
    <button type="button" mat-stroked-button
      class="save-btn"
      color="primary"
      (click)="approveRemoteWorkRequestConfirmation()"
      [disabled]="cannotReview() || (isRequestExpired && !isPermanentRequest)">
      <span *ngIf="isChangingStatus"><i class="fa fa-spinner fa-spin"
          aria-hidden="true"></i></span> APPROVE
    </button>
    <button type="button" mat-stroked-button
      class="reject-btn"
      color="primary"
      (click)="rejectRemoteWorkRequestConfirmation()"
      [disabled]="cannotReview()">
      <span *ngIf="isChangingStatus"><i class="fa fa-spinner fa-spin"
          aria-hidden="true"></i></span> REJECT
    </button>
  </div>
</form>

<div *ngIf="isLoading" class="grid place-items-center inset-0">
  <mat-spinner></mat-spinner>
</div>

