import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { QuicklinkModule } from 'ngx-quicklink';
import { IfCountryDirective } from './directives/if-country.directive';
import { IfRoleOrReviewerDirective } from './directives/if-role-or-request-reviewer.directive';
import { IfRoleDirective } from './directives/if-role.directive';

@NgModule({
  imports: [RouterModule, QuicklinkModule],
  declarations: [
    IfCountryDirective,
    IfRoleDirective,
    IfRoleOrReviewerDirective,
  ],
  exports: [
    QuicklinkModule,

    IfCountryDirective,
    IfRoleDirective,
    IfRoleOrReviewerDirective,
  ],
  providers: [],
})
export class SharedModule {}

export class MatchType {
  public static MatchData<T extends object>(data: T, type: new () => T): T {
    const result = Object.assign<T, T>(new type(), data);
    return result;
  }
  public static MatchDataArray<T extends object>(
    dataArray: T[],
    type: new () => T
  ): T[] {
    const result: T[] = [];
    dataArray.forEach(data => {
      result.push(MatchType.MatchData<T>(data, type));
    });
    return result;
  }
}
