import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  AssessmentService,
  SavedAssessment,
} from 'src/app/core/services/assessment.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { FeedbackFilterService } from 'src/app/core/services/feedback-filter.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-feedback-provided',
  templateUrl: './feedback-provided.component.html',
  styleUrls: ['./feedback-provided.component.scss'],
})
export class FeedbackProvidedComponent implements OnInit {
  feedbacks!: SavedAssessment[];
  filteredFeedbacks!: SavedAssessment[];
  filterCriteriaForm!: UntypedFormGroup;
  filterDateDisplay!: string;
  isAdmin!: boolean;
  isCfrAdmin!: boolean;
  filterRecieverValue!: string;
  filterCMValue!: string;
  filterSubmissionTypeValue!: string;
  filterClientValue!: string;
  filterFeedbackStatusValue!: string;
  filtersValue!: string;
  isLoading = true;

  constructor(
    private assessmentService: AssessmentService,
    private snackBarService: SnackBarService,
    private feedbackFilterService: FeedbackFilterService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.filterCriteriaForm =
      this.feedbackFilterService.getFilterCriteriaForm();
    this.getMyProvidedFeedbacks();
    this.isAdmin = this.authService.doesUserHaveRole([
      environment.roles.FeedbackAdmin,
    ]);
    this.isCfrAdmin = this.authService.doesUserHaveRole([
      environment.roles.FeedbackCFRAdmin,
    ]);
  }

  filterFeedbacks() {
    const searchCriteria = this.feedbackFilterService.getFilterCriteriaModel();
    this.filterDateDisplay =
      this.feedbackFilterService.getFilterDateRangeDisplay(searchCriteria);
    this.filteredFeedbacks = this.feedbacks.filter(
      f =>
        this.feedbackFilterService.filterReceiver(f, searchCriteria) &&
        this.feedbackFilterService.filterCareerMentor(f, searchCriteria) &&
        this.feedbackFilterService.filterSubmissionType(f, searchCriteria) &&
        this.feedbackFilterService.filterClient(f, searchCriteria) &&
        this.feedbackFilterService.filterStartDate(f, searchCriteria) &&
        this.feedbackFilterService.filterEndDate(f, searchCriteria) &&
        this.feedbackFilterService.filterFeedbackType(f, searchCriteria) &&
        this.feedbackFilterService.filterFeedbackStatus(f, searchCriteria)
    );
    this.getFilterFormValues();
  }

  getFilterFormValues() {
    this.filterClientValue = `${this.filterCriteriaForm.controls.client.value['clientName']}`;
    this.filterCMValue = `${this.filterCriteriaForm.controls.careerMentor.value['firstName']} ${this.filterCriteriaForm.controls.careerMentor.value['lastName']}`;
    this.filterCMValue = this.filterCMValue.trim();
    this.filterRecieverValue = `${this.filterCriteriaForm.controls.onliner.value['firstName']} ${this.filterCriteriaForm.controls.onliner.value['lastName']}`;
    this.filterRecieverValue = this.filterRecieverValue.trim();
    this.filterFeedbackStatusValue = `${this.filterCriteriaForm.controls.status.value['statusDescription']}`;
    this.filterSubmissionTypeValue = `${this.filterCriteriaForm.controls.submissionType.value['name']}`;

    this.filtersValue = `${this.filterClientValue}, ${this.filterRecieverValue}, ${this.filterSubmissionTypeValue}, ${this.filterFeedbackStatusValue}, ${this.filterCMValue}`;
  }

  private getMyProvidedFeedbacks() {
    this.assessmentService.getMyProvidedFeedbacks().subscribe(
      data => {
        this.feedbacks = data;
        this.filterFeedbacks();
        this.isLoading = false;
      },
      error => {
        this.snackBarService.error(error);
      }
    );
  }
}
