import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FeedbackStatusEnum } from 'src/app/shared/enums/enums.model';
import * as formValidator from '../../shared/validators/form-validator';
import { SavedAssessment } from './assessment.service';
import { BaseFeedbackFilterService } from './base-feedback-filter.service';
import { CareerMentor } from './career-mentor.service';
import {
  defaultAnonymousOption,
  defaultFeedbackStatus,
  defaultPendingFeedbackStatus,
} from './feedback.service';
import { defaultOnliner } from './onliner.service';

@Injectable()
export class CmFeedbackFilterService implements BaseFeedbackFilterService {
  _filterCriteriaForm!: UntypedFormGroup;
  firstDayOfTheYear = new Date(new Date().getFullYear(), 0, 1);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe
  ) {}

  resetForm(careerMentor?: CareerMentor, isOnMyTeamFeedbackPage?: boolean) {
    this._filterCriteriaForm.reset({
      careerMentor:
        isOnMyTeamFeedbackPage && careerMentor ? careerMentor : defaultOnliner,
      submittedBy: defaultOnliner,
      startDate: this.firstDayOfTheYear,
      endDate: new Date(),
      status: defaultPendingFeedbackStatus,
      anonymous: defaultAnonymousOption,
    });
  }

  resetRequestForm() {
    console.error(
      'CmFeedbackFilterService.resetRequestForm() not supported function'
    );
  }

  resetSelfAssessmentForm() {
    console.error(
      'CmFeedbackFilterService.resetRequestForm() not supported function'
    );
  }

  resetClientFeedbackOnlineForm() {
    console.error(
      'CmFeedbackFilterService.resetClientFeedbackOnlineForm() not supported function'
    );
  }

  getFilterCriteriaForm(
    careerMentor?: CareerMentor,
    isOnMyTeamFeedbackPage?: boolean
  ): UntypedFormGroup {
    if (this._filterCriteriaForm) {
      if (isOnMyTeamFeedbackPage && careerMentor) {
        this._filterCriteriaForm.controls['careerMentor'].setValue(
          careerMentor
        );
      }
      if (!isOnMyTeamFeedbackPage) {
        if (
          this._filterCriteriaForm.controls['careerMentor'].value.userId !==
          defaultOnliner.userId
        ) {
          this._filterCriteriaForm.markAsDirty();
        }
      }
      return this._filterCriteriaForm;
    } else {
      return this.createFilterCriteriaForm(careerMentor);
    }
  }

  getFilterCriteriaModel(): FeedbackFilterCriteria {
    const filterCriteria = new FeedbackFilterCriteria();
    filterCriteria.careerMentorId = this._filterCriteriaForm.controls[
      'careerMentor'
    ].value
      ? this._filterCriteriaForm.controls['careerMentor'].value.userId
      : defaultOnliner.userId;
    filterCriteria.submitterId = this._filterCriteriaForm.controls[
      'submittedBy'
    ].value
      ? this._filterCriteriaForm.controls['submittedBy'].value.userId
      : defaultOnliner.userId;
    filterCriteria.startDate = this._filterCriteriaForm.controls['startDate']
      .value
      ? this._filterCriteriaForm.controls['startDate'].value
      : this.firstDayOfTheYear;
    filterCriteria.endDate = this._filterCriteriaForm.controls['endDate'].value
      ? this._filterCriteriaForm.controls['endDate'].value
      : new Date();
    filterCriteria.status = this._filterCriteriaForm.controls['status'].value
      ? this._filterCriteriaForm.controls['status'].value.statusDescription
      : defaultFeedbackStatus.statusDescription;
    filterCriteria.anonymous = this._filterCriteriaForm.controls['anonymous']
      .value
      ? this._filterCriteriaForm.controls['anonymous'].value.value
      : defaultAnonymousOption.value;
    // Set the endDate to the end of that day, otherwise the filter service will filter out any feedback
    // created after this service was created or created past this time on that date selected.
    filterCriteria.endDate.setHours(23);
    filterCriteria.endDate.setMinutes(59);
    filterCriteria.endDate.setSeconds(59);

    return filterCriteria;
  }

  getFilterDateRangeDisplay(model: FeedbackFilterCriteria): string {
    if (!model) {
      model = this.getFilterCriteriaModel();
    }
    return `(${this.datePipe.transform(
      model.startDate,
      'longDate'
    )} - ${this.datePipe.transform(model.endDate, 'longDate')})`;
  }

  filterSubmitter(
    feedback: SavedAssessment,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return (
      filterCriteria.submitterId === defaultOnliner.userId ||
      feedback.submittedById === filterCriteria.submitterId
    );
  }

  filterCareerMentor(
    feedback: SavedAssessment,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return (
      filterCriteria.careerMentorId === defaultOnliner.userId ||
      feedback.employeeId === filterCriteria.careerMentorId
    );
  }

  filterStartDate(
    feedback: SavedAssessment,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return filterCriteria.startDate <= new Date(feedback.submittedDate);
  }

  filterEndDate(
    feedback: SavedAssessment,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return filterCriteria.endDate >= new Date(feedback.submittedDate);
  }

  filterFeedbackStatus(
    feedback: SavedAssessment,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return (
      filterCriteria.status === defaultFeedbackStatus.statusDescription ||
      FeedbackStatusEnum[feedback.GetCurrentStatusId()] ===
        filterCriteria.status ||
      feedback.currentStatus === filterCriteria.status
    );
  }

  filterAnonymous(
    feedback: SavedAssessment,
    filterCriteria: FeedbackFilterCriteria
  ): boolean {
    return (
      filterCriteria.anonymous === defaultAnonymousOption.value ||
      feedback.isAnonymous === filterCriteria.anonymous
    );
  }

  private createFilterCriteriaForm(
    careerMentor?: CareerMentor
  ): UntypedFormGroup {
    return (this._filterCriteriaForm = this.formBuilder.group({
      careerMentor: careerMentor
        ? [careerMentor, formValidator.validateOnliner()]
        : [defaultOnliner, formValidator.validateOnliner()],
      submittedBy: [defaultOnliner, formValidator.validateOnliner()],
      startDate: [this.firstDayOfTheYear],
      endDate: [new Date()],
      status: [defaultFeedbackStatus, formValidator.validateFeedbackStatus()],
      anonymous: [
        defaultAnonymousOption,
        formValidator.validateAnonymousOption(),
      ],
    }));
  }
}

class FeedbackFilterCriteria {
  careerMentorId!: string;
  submitterId!: string;
  startDate!: Date;
  endDate!: Date;
  status!: string;
  anonymous!: boolean;
}
