import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RequestOnlinerFeedbackRoutingModule } from './request-onliner-feedback-routing.module';
import { RequestOnlinerFeedbackComponent } from './request-onliner-feedback.component';
import { RequestOnlinerFeedbackFormComponent } from './request-onliner-feedback-form/request-onliner-feedback-form.component';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';

@NgModule({
  declarations: [
    RequestOnlinerFeedbackComponent,
    RequestOnlinerFeedbackFormComponent,
  ],
  imports: [
    CommonModule,
    RequestOnlinerFeedbackRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,
    MatProgressSpinnerModule,

    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class RequestOnlinerFeedbackModule {}
