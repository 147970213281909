import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CmDelegationRoutingModule } from './cm-delegation-routing.module';
import { CmDelegationComponent } from './cm-delegation.component';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CmDelegationComponent],
  imports: [
    CommonModule,
    CmDelegationRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class CmDelegationModule {}
