import { HttpClient, HttpHeaders } from '@angular/common/http';

import { EMPTY, Observable, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { UtilityService } from '../services/utility.service';

@Injectable()
export class AuthorizedHttp {
  constructor(
    private http: HttpClient,
    private router: Router,
    private utilityService: UtilityService
  ) {}

  get<T>(url: string): Observable<T> {
    return this.protectSessionExpired<T>(() =>
      this.intercept<T>(this.http.get<T>(url, this.createAuthorizationHeader()))
    );
  }

  /// getText receives a base64 encoded string response from API. May be useful for images or binary files returned from API.
  getText<T>(url: string): Observable<T> {
    return this.protectSessionExpired<T>(() =>
      this.intercept<T>(
        this.http.get<T>(url, {
          headers: this.createAuthorizationHeader().headers,
          responseType: 'text' as 'json',
        })
      )
    );
  }

  post<T>(url: string, data: unknown): Observable<T> {
    return this.protectSessionExpired<T>(() =>
      this.intercept<T>(
        this.http.post<T>(url, data, this.createAuthorizationHeader())
      )
    );
  }

  postWithNoData<T>(url: string): Observable<T> {
    return this.protectSessionExpired<T>(() =>
      this.intercept<T>(
        this.http.post<T>(url, '', this.createAuthorizationHeader())
      )
    );
  }

  put<T>(url: string, data: unknown): Observable<T> {
    return this.protectSessionExpired<T>(() =>
      this.intercept<T>(
        this.http.put<T>(url, data, this.createAuthorizationHeader())
      )
    );
  }

  delete<T>(url: string, body = {}): Observable<T> {
    return this.protectSessionExpired<T>(() => {
      const deleteOptions = { ...this.createAuthorizationHeader(), body };
      return this.intercept<T>(this.http.delete<T>(url, deleteOptions));
    });
  }

  refresh(): Observable<never> {
    location.reload();
    return EMPTY;
  }

  notFound(): Observable<never> {
    this.router.navigate(['404']);
    return EMPTY;
  }

  forbidden(): Observable<never> {
    this.router.navigate(['/forbidden']);
    return EMPTY;
  }

  unauthorize(): Observable<never> {
    this.utilityService.showSessionExpiredDialog();
    return throwError(() => ({
      error: { exceptionMessage: 'Session Expired' },
    }));
  }

  clientNotAuthorized(): Observable<never> {
    this.router.navigate(['/formExpired']);
    return EMPTY;
  }

  private createAuthorizationHeader(): { headers: HttpHeaders } {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return httpOptions;
  }

  private protectSessionExpired<T>(
    restCall: () => Observable<T>
  ): Observable<T> {
    return restCall();
  }

  private intercept<T>(observable: Observable<T>) {
    return observable.pipe(
      catchError(err => {
        if (err.status === 401) {
          return this.unauthorize();
        } else if (err.status === 403) {
          return this.forbidden();
        } else if (err.status === 404) {
          return this.notFound();
        } else if (err.status === 450) {
          return this.clientNotAuthorized();
        } else {
          return throwError(() => err);
        }
      })
    );
  }
}
