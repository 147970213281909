import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  AssessmentService,
  MyFeedbackRequests,
} from 'src/app/core/services/assessment.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { FeedbackStatusEnum } from 'src/app/shared/enums/enums.model';

@Component({
  selector: 'app-my-feedback-requests-list',
  templateUrl: './my-feedback-requests-list.component.html',
  styleUrls: ['./my-feedback-requests-list.component.scss'],
})
export class MyFeedbackRequestsListComponent implements OnChanges {
  @Input() set feedbacks(feedbck: MyFeedbackRequests[]) {
    if (feedbck) {
      feedbck.sort((a, b) =>
        a.requestDate < b.requestDate
          ? 1
          : b.requestDate < a.requestDate
          ? -1
          : 0
      );
      this.evaluateSubmittedDate(feedbck);
    }
    this.dataSource = new MatTableDataSource<MyFeedbackRequests>(feedbck);
    if (feedbck) {
      this.isLoading = false;
    } else {
      this.dataSource = new MatTableDataSource<MyFeedbackRequests>();
    }
  }
  @Input()
  canReview!: boolean;

  @ViewChild(MatTable)
  table!: MatTable<MyFeedbackRequests>;

  @ViewChild(MatSort, { static: true })
  sort!: MatSort;

  dataSource!: MatTableDataSource<MyFeedbackRequests>;
  isLoading = true;
  selected = [];

  resultColumnHeaders: string[] = [
    'requestTo',
    'requestDate',
    'submittedDate',
    'daysToSubmission',
    'requestType',
    'status',
    'delete',
  ];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private assessmentService: AssessmentService,
    private snackBarService: SnackBarService
  ) {}

  ngOnChanges() {
    this.dataSource.sort = this.sort;
  }

  openDeleteConfirmation(feedbackRequest: MyFeedbackRequests) {
    const confirmDialog = new ConfirmDialog();

    confirmDialog.title = 'Remove Request?';
    confirmDialog.message =
      'Remove feedback request from ' +
      feedbackRequest.requestTo +
      '. ' +
      'You will also be removing the corresponding draft from ' +
      feedbackRequest.requestTo +
      '. ' +
      'Do you want to continue?';
    confirmDialog.okButtonTitle = 'Yes';
    confirmDialog.cancelButtonTitle = 'No';
    confirmDialog.theme = 'danger';

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: confirmDialog,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.deleteRequest(feedbackRequest);
      }
    });
  }

  isDeletable(feedbackRequest: MyFeedbackRequests): boolean {
    return (
      feedbackRequest.statusId === FeedbackStatusEnum.Draft &&
      feedbackRequest.requestType !== 'Client'
    );
  }

  private deleteRequest(feedbackRequest: MyFeedbackRequests) {
    if (feedbackRequest.statusId === FeedbackStatusEnum.Draft) {
      this.assessmentService
        .deleteFeedback(feedbackRequest.feedbackId)
        .subscribe(
          () => {
            const data = this.dataSource.data;
            const index = data.indexOf(feedbackRequest);
            if (index !== -1) {
              data.splice(index, 1);
            }
            this.dataSource.data = data;
            this.snackBarService.message('Request Deleted');
          },
          error => {
            this.snackBarService.error(error);
          }
        );
    }
  }

  private evaluateSubmittedDate(requests: MyFeedbackRequests[]) {
    for (const request of requests) {
      if (request.statusId === FeedbackStatusEnum.Draft) {
        request.submittedDate = null;
      }
    }
  }
}
