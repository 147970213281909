import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  message(message: string) {
    this.snackBar.open(message, 'Dismiss', {
      duration: 5000,
    });
  }

  clientMessage(message: string) {
    const snackBarRef = this.snackBar.open(message, 'OK');
    snackBarRef.onAction().subscribe(() => {
      window.location.href = environment.HomePageLink;
    });
  }

  error(message: string) {
    this.snackBar.open(`Error: ${message}`, 'Dismiss', {
      duration: 5000,
    });
  }
}
