<div *ngIf="question?.type?.toString() === 'Text'">
    <div class="label-container">
        <label [for]="$any(question)['id']"
            class="reduce-label-spacing text-label font-weight-bold"
            [ngClass]="{ 'required-label': !question.isOptional && (question.question || customLabel) }">
            {{customLabel ? customLabel : question.question ? question.question : '&nbsp;'}}
        </label>
    </div>
    <mat-form-field
        class="col-12"
        [appearance]="control.disabled ? 'fill' : 'outline'"
        [ngClass]="{
            'has-error': control.hasError('required') && control.touched
          }">
        <textarea
            matInput
            #textareafield
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="5"
            class="textarea-field"
            id="question.id"
            [formControl]="control"
            placeholder="{{control.disabled ? '' : 'Enter comments here...'}}"
            maxlength="{{question.charLimit}}">
        </textarea>
        <mat-hint *ngIf="!control.disabled && question.charLimit" align="end">{{question.charLimit - textareafield.value.length}} chars left</mat-hint>
    </mat-form-field>
</div>

<div *ngIf="question?.type?.toString() === 'Radio'">
    <mat-grid-list
        [cols]="validQuestionOptions(question).length"
        rowHeight="60px">
        <mat-grid-tile
            id="mat-grid-option-display"
            *ngFor="let option of validQuestionOptions(question)">
            <span class="font-weight-bold displaySpan centerText">{{option.value}}</span>
        </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list
        [cols]="validQuestionOptions(question).length"
        rowHeight="60px">
        <mat-radio-group [formControl]="control">
            <mat-grid-tile
                *ngFor="let option of validQuestionOptions(question)">
                <mat-radio-button
                    class="question-radio-button"
                    [value]="option.id">
                </mat-radio-button>
            </mat-grid-tile>
        </mat-radio-group>
    </mat-grid-list>
</div>
