import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import {
  FeedbackType,
  Question,
  QuestionGroup,
  QuestionOptionGroup,
} from 'src/app/core/services/feedback.service';
import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { FeedbackTypeDetailComponent } from './feedback-type-detail/feedback-type-detail.component';
import { OptionGroupDetailComponent } from './option-group-detail/option-group-detail.component';
import { QuestionDetailComponent } from './question-detail/question-detail.component';
import { QuestionGroupDetailComponent } from './question-group-detail/question-group-detail.component';

export enum QuestionConfigMode {
  FeedbackTypeConfig = 'FeedbackTypeConfig',
  QuestionGroupConfig = 'QuestionGroupConfig',
  QuestionConfig = 'QuestionConfig',
  OptionGroupConfig = 'OptionGroupConfig',
}

@Component({
  selector: 'app-questions-config',
  templateUrl: './questions-config.component.html',
  styleUrls: ['./questions-config.component.scss'],
})
export class QuestionsConfigComponent implements OnInit {
  @ViewChild(FeedbackTypeDetailComponent, { static: false })
  feedbackDetail!: FeedbackTypeDetailComponent;
  @ViewChild(QuestionGroupDetailComponent, { static: false })
  questionGroupDetail!: QuestionGroupDetailComponent;
  @ViewChild(QuestionDetailComponent, { static: false })
  questionDetail!: QuestionDetailComponent;
  @ViewChild(OptionGroupDetailComponent, { static: false })
  optionGroupDetail!: OptionGroupDetailComponent;

  isLoading = false;
  selectedForm!: FeedbackType;
  selectedQuestion!: Question | null;
  selectedQuestionGroup!: QuestionGroup | null;
  selectedOptionGroup!: QuestionOptionGroup | null;
  numQuestions!: number;
  questionGroupConfigTitle!: string | null;
  questionConfigTitle!: string | null;
  optionGroupConfigTitle!: string | null;
  QuestionConfigMode = QuestionConfigMode;
  configMode: QuestionConfigMode = QuestionConfigMode.FeedbackTypeConfig;
  dialogConfirmation = new EventEmitter<boolean>();

  constructor(private confirmDialog: MatDialog) {}

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return !this.hasUnsavedChanges();
  }

  ngOnInit() {
    this.configMode = QuestionConfigMode.FeedbackTypeConfig;
  }

  configQuestionGroup(event: {
    form: FeedbackType;
    title: string;
    questionGroup: QuestionGroup;
  }) {
    if (this.hasUnsavedChanges()) {
      this.showConfirmationDialog();
      this.dialogConfirmation.pipe(take(1)).subscribe((result: boolean) => {
        if (result) {
          this.selectedForm = event.form;
          this.selectedQuestionGroup = event.questionGroup;
          this.questionGroupConfigTitle = event.title;
          this.configMode = QuestionConfigMode.QuestionGroupConfig;
        }
      });
    } else {
      this.selectedForm = event.form;
      this.selectedQuestionGroup = event.questionGroup;
      this.questionGroupConfigTitle = event.title;
      this.configMode = QuestionConfigMode.QuestionGroupConfig;
    }
  }

  returnQuestionGroup() {
    if (this.hasUnsavedChanges()) {
      this.showConfirmationDialog();
      this.dialogConfirmation.pipe(take(1)).subscribe((result: boolean) => {
        if (result) {
          this.selectedQuestionGroup = null;
          this.questionGroupConfigTitle = null;
          this.configMode = QuestionConfigMode.FeedbackTypeConfig;
        }
      });
    } else {
      this.selectedQuestionGroup = null;
      this.questionGroupConfigTitle = null;
      this.configMode = QuestionConfigMode.FeedbackTypeConfig;
    }
  }

  configQuestion(event: {
    question: Question;
    questionGroup: QuestionGroup;
    title: string;
    numQuestions: number;
  }) {
    if (this.hasUnsavedChanges()) {
      this.showConfirmationDialog();
      this.dialogConfirmation.pipe(take(1)).subscribe((result: boolean) => {
        if (result) {
          this.selectedQuestionGroup = event.questionGroup;
          this.selectedQuestion = event.question;
          this.questionConfigTitle = event.title;
          this.numQuestions = event.numQuestions;
          this.configMode = QuestionConfigMode.QuestionConfig;
        }
      });
    } else {
      this.selectedQuestionGroup = event.questionGroup;
      this.selectedQuestion = event.question;
      this.questionConfigTitle = event.title;
      this.numQuestions = event.numQuestions;
      this.configMode = QuestionConfigMode.QuestionConfig;
    }
  }

  returnQuestion() {
    if (this.hasUnsavedChanges()) {
      this.showConfirmationDialog();
      this.dialogConfirmation.pipe(take(1)).subscribe((result: boolean) => {
        if (result) {
          this.selectedQuestion = null;
          this.questionConfigTitle = null;
          this.configMode = QuestionConfigMode.QuestionGroupConfig;
        }
      });
    } else {
      this.selectedQuestion = null;
      this.questionConfigTitle = null;
      this.configMode = QuestionConfigMode.QuestionGroupConfig;
    }
  }

  configOptionGroup(event: {
    optionGroup: QuestionOptionGroup;
    title: string;
  }) {
    if (this.hasUnsavedChanges()) {
      this.showConfirmationDialog();
      this.dialogConfirmation.pipe(take(1)).subscribe((result: boolean) => {
        if (result) {
          this.optionGroupConfigTitle = event.title;
          this.selectedOptionGroup = event.optionGroup;
          this.configMode = QuestionConfigMode.OptionGroupConfig;
        }
      });
    } else {
      this.optionGroupConfigTitle = event.title;
      this.selectedOptionGroup = event.optionGroup;
      this.configMode = QuestionConfigMode.OptionGroupConfig;
    }
  }

  returnOptionGroup(): void {
    if (this.hasUnsavedChanges()) {
      this.showConfirmationDialog();
      this.dialogConfirmation.pipe(take(1)).subscribe((result: boolean) => {
        if (result) {
          this.selectedOptionGroup = null;
          this.optionGroupConfigTitle = null;
          this.configMode = QuestionConfigMode.QuestionConfig;
        }
      });
    } else {
      this.selectedOptionGroup = null;
      this.optionGroupConfigTitle = null;
      this.configMode = QuestionConfigMode.QuestionConfig;
    }
  }

  private hasUnsavedChanges() {
    return (
      (this.feedbackDetail ? this.feedbackDetail.hasUnsavedChanges : false) ||
      (this.questionGroupDetail
        ? this.questionGroupDetail.hasUnsavedChanges
        : false) ||
      (this.questionDetail ? this.questionDetail.hasUnsavedChanges : false) ||
      (this.optionGroupDetail
        ? this.optionGroupDetail.hasUnsavedChanges
        : false)
    );
  }

  private showConfirmationDialog() {
    const confirmDialog = new ConfirmDialog();
    confirmDialog.title = 'Unsaved Changes';
    confirmDialog.message =
      '<p> There are currently unsaved changes that have not been committed. Are you sure you want to continue?</p>';
    confirmDialog.okButtonTitle = 'Continue';
    confirmDialog.cancelButtonTitle = 'Cancel';
    const dialogRef = this.confirmDialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: confirmDialog,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.dialogConfirmation.emit(result === 'ok');
    });
  }
}
