 <form [formGroup]="requestFeedbackForm" novalidate>
  <mat-card appearance="outlined" class="col-md-8 col-sm-10 col-xs-10">
    <div class="row form-group m-0 mt-2">
      <div class="delete-button-div">
        <button type="button" mat-icon-button *ngIf="showDelete"
          (click)="deleteForm()"> <i class="fas fa-lg fa-times-circle"></i></button>
      </div>
    </div>

    <div class="label-header">Choose "Other" if the client is not on the list.</div>
    <div class="row form-group col-md-12 col-sm-6 col-xs-12">
      <mat-form-field appearance="outline" class="client-group client-width"
        [ngClass]="{'has-error': requestFeedbackForm.get('client')?.errors && requestFeedbackForm.get('client')?.touched, 'editable': true}">
        <mat-label>Choose Client *</mat-label>
        <input matInput type="text" placeholder="Choose Client"
          aria-label="Client"
          [matAutocomplete]="clientauto" formControlName="client">

        <mat-autocomplete #clientauto="matAutocomplete"
          [displayWith]="clientDisplay">
          <mat-option class="options"
            *ngFor="let option of filteredClients | async" [value]="option">
            {{ clientDisplay(option) }}
          </mat-option>
        </mat-autocomplete>
        <mat-error
          *ngIf="requestFeedbackForm.get('client')?.errors && requestFeedbackForm.get('client')?.touched">Please
          enter the name of the Client.</mat-error>
      </mat-form-field>
    </div>
    <div class="row form-group col-md-12 col-sm-6 col-xs-12"
      *ngIf="requestFeedbackForm && requestFeedbackForm.value.client  && requestFeedbackForm.value.client.clientName === 'Other'">
      <mat-form-field appearance="outline" class="client-group client-width"
        [ngClass]="{'has-error': requestFeedbackForm.get('otherClient')?.errors && requestFeedbackForm.get('otherClient')?.touched, 'editable': true}">
        <mat-label>Other Client *</mat-label>
        <div class="col-md-12 pl-0">
          <input matInput type="text" placeholder="Other Client"
            aria-label="Other Client" id="otherClient"
            formControlName="otherClient">
        </div>
        <mat-error
          *ngIf="requestFeedbackForm.get('otherClient')?.errors && requestFeedbackForm.get('otherClient')?.touched">Please
          enter the name of the Other Client.</mat-error>
      </mat-form-field>
    </div>
    <div class="row col-md-12 col-sm-8 col-xs-6">
      <mat-form-field appearance="outline" class="client-group client-width"
        [ngClass]="{'has-error': requestFeedbackForm.get('contactName')?.errors && requestFeedbackForm.get('contactName')?.touched, 'editable': true}">
        <mat-label>Contact Name *</mat-label>
        <div class="col-md-10 col-sm-8 col-xs-8 pl-0">
          <input matInput type="text" placeholder="Contact Name *"
            aria-label="Contact Name" id="contactName"
            formControlName="contactName">
        </div>
        <mat-error
          *ngIf="requestFeedbackForm.get('contactName')?.errors && requestFeedbackForm.get('contactName')?.touched">Please
          enter the name of the Contact.</mat-error>
      </mat-form-field>
    </div>
    <div class="row col-md-12 col-sm-8 col-xs-6">
      <mat-form-field appearance="outline" class="client-group client-width"
        [ngClass]="{'has-error': requestFeedbackForm.get('contactRole')?.errors && requestFeedbackForm.get('contactRole')?.touched, 'editable': true}">
        <mat-label>Contact Role</mat-label>
        <div class="col-md-10 col-sm-8 col-xs-8 pl-0"
          [ngClass]="{'has-error': requestFeedbackForm.get('contactRole')?.errors && requestFeedbackForm.get('contactRole')?.touched}">
          <input matInput type="text" placeholder="Contact Role"
            aria-label="Contact Role" id="contactRole"
            formControlName="contactRole">
        </div>
        <mat-error
          *ngIf="requestFeedbackForm.get('contactRole')?.errors && requestFeedbackForm.get('contactRole')?.touched">Please
          enter the Role of the Contact.</mat-error>
      </mat-form-field>
    </div>
    <div class="row col-md-12 col-sm-8 col-xs-6">
      <mat-form-field appearance="outline" class="client-group client-width"
        [ngClass]="{'has-error': requestFeedbackForm.get('contactEmail')?.errors && requestFeedbackForm.get('contactEmail')?.touched, 'editable': true}">
        <mat-label>Contact Email *</mat-label>
        <div class="col-md-10 col-sm-8 col-xs-8 pl-0"
          [ngClass]="{'has-error': requestFeedbackForm.get('contactEmail')?.errors && requestFeedbackForm.get('contactEmail')?.touched}">
          <input matInput type="text" placeholder="Contact Email *"
            aria-label="Contact Email" id="contactEmail"
            formControlName="contactEmail">
        </div>
        <mat-error
          *ngIf="requestFeedbackForm.get('contactEmail')?.errors && requestFeedbackForm.get('contactEmail')?.touched">Please
          enter the Email of the Contact.</mat-error>
      </mat-form-field>
    </div>
  </mat-card>
</form>
