import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';

import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { WidgetQuickValueCenterModule } from 'src/app/features/home/dashboard/widget-quick-value-center/widget-quick-value-center.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,
    MatProgressSpinnerModule,
    WidgetQuickValueCenterModule,
    SharedModule,
  ],
})
export class DashboardModule {}
