import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackDetailComponent } from './feedback-detail.component';
import { LegacyFeedbackDetailComponent } from './legacy-feedback-detail/legacy-feedback-detail.component';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';

@NgModule({
  declarations: [FeedbackDetailComponent, LegacyFeedbackDetailComponent],
  imports: [CommonModule, AngularMaterialModule],
  exports: [FeedbackDetailComponent, LegacyFeedbackDetailComponent],
})
export class FeedbackDetailModule {}
