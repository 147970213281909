<vex-secondary-toolbar current="Review">
  <vex-breadcrumbs [crumbs]="['Review', 'CM Feedback']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="row m-0">
  <div class="component-container">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <i class="fas fa-filter fa-lg"></i>
          <span>Filter Submissions ({{filtersValue}})</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-feedback-filter-criteria-form
        [searchCriteriaForm]="filterCriteriaForm"
        [onlinerFilterMode]="'AllUsers'"
        [isOnlinerFilterVisible]="false"
        [isSubmittedByFilterVisible]="false"
        [isSubmissionTypeFilterVisible]="false"
        [isClientFilterVisible]="false"
        [isAnonymousFilterVisible]="true"
        (filterDatesTriggered)="filterCmFeedbacks()"
        (filterFeedbacksTriggered)="filterCmFeedbacks()">
      </app-feedback-filter-criteria-form>
    </mat-expansion-panel>
    <br>
    <h3 class="page-title">CM Feedback {{filterDateDisplay}}</h3>
    <app-cm-feedback-list [feedbacks]="filteredCmFeedbacks" [canReview]="true"></app-cm-feedback-list>
  </div>
</div>
