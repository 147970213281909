import {
  AbstractControl,
  FormGroupDirective,
  NgForm,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export function requiredAndNoWhiteSpaceValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value || control.value.toString().trim().length <= 0) {
    const temp: ValidationErrors = {};
    temp['required'] = true;
    return temp;
  }

  return null;
}

export function checkDatePattern(control: UntypedFormControl) {
  if (control.value) {
    if (control.value instanceof Date) {
      const data = control.value;
      const testData =
        data.getMonth() + '/' + data.getDate() + '/' + data.getFullYear();
      const dateRegEx = new RegExp(/^\d{1,2}\/\d{1,2}\/\d{4}$/);
      if (!dateRegEx.test(testData)) {
        return { datePatternError: true };
      }
    }
  }
  return null;
}

export function validateOnliner(): ValidatorFn {
  return (onlinerControl: AbstractControl) => {
    if (!onlinerControl.value || !onlinerControl.value.firstName) {
      const temp: ValidationErrors = {};
      temp['required'] = true;
      return temp;
    }
    return null;
  };
}

export function validateSecApprover(): ValidatorFn {
  return (onlinerControl: AbstractControl) => {
    if (
      (!onlinerControl.value || !onlinerControl.value.firstName) &&
      onlinerControl.value !== ' '
    ) {
      const temp: ValidationErrors = {};
      temp['required'] = false;
      return temp;
    }
    return null;
  };
}

export function validateSelectListItem(): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.value || !control.value.name) {
      const temp: ValidationErrors = {};
      temp['required'] = true;
      return temp;
    }
    return null;
  };
}

export function validateFeedbackType(): ValidatorFn {
  return (feedbackTypeControl: AbstractControl) => {
    if (!feedbackTypeControl.value || !feedbackTypeControl.value.typeDescr) {
      const temp: ValidationErrors = {};
      temp['required'] = true;
      return temp;
    }
    return null;
  };
}

export function validateFeedbackStatus(): ValidatorFn {
  return (feedbackStatusControl: AbstractControl) => {
    if (
      !feedbackStatusControl.value ||
      !feedbackStatusControl.value.statusDescription
    ) {
      const temp: ValidationErrors = { required: true };
      return temp;
    }
    return null;
  };
}

export function validateRemoteWorkRequestStatus(): ValidatorFn {
  return (requestStatusControl: AbstractControl) => {
    if (
      !requestStatusControl.value ||
      !requestStatusControl.value.description
    ) {
      const temp: ValidationErrors = { required: true };
      return temp;
    }
    return null;
  };
}

export function validateCountry(): ValidatorFn {
  return (requestCountryControl: AbstractControl) => {
    if (
      (!requestCountryControl.value ||
        !requestCountryControl.value.countryDesc) &&
      requestCountryControl.value !== ' '
    ) {
      const temp: ValidationErrors = { required: true };
      return temp;
    }
    return null;
  };
}

export function validateRemoteWorkRequestType(): ValidatorFn {
  return (requestStatusControl: AbstractControl) => {
    if (
      !requestStatusControl.value ||
      !requestStatusControl.value.description
    ) {
      const temp: ValidationErrors = { required: true };
      return temp;
    }
    return null;
  };
}

export function validateClient(): ValidatorFn {
  return (clientControl: AbstractControl) => {
    if (!clientControl.value || !clientControl.value.clientName) {
      const temp: ValidationErrors = {};
      temp['required'] = true;
      return temp;
    }
    return null;
  };
}

export function validateAnonymousOption(): ValidatorFn {
  return (anonymousControl: AbstractControl) => {
    if (!anonymousControl.value || !anonymousControl.value.anonymousDesc) {
      const temp: ValidationErrors = {};
      temp['required'] = true;
      return temp;
    }
    return null;
  };
}
export function validatePractice(): ValidatorFn {
  return (practiceControl: AbstractControl) => {
    if (!practiceControl.value || !practiceControl.value.practice) {
      const temp: ValidationErrors = {};
      temp['required'] = true;
      return temp;
    }
    return null;
  };
}

export class CompControlErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
