import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientFeedbackRoutingModule } from './client-feedback-routing.module';
import { ClientFeedbackComponent } from './client-feedback.component';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientFeedbackFormComponent } from './client-feedback-form/client-feedback-form.component';

@NgModule({
  declarations: [ClientFeedbackComponent, ClientFeedbackFormComponent],
  imports: [
    CommonModule,
    ClientFeedbackRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class ClientFeedbackModule {}
