
<div class="row col-md-8">
  <h3 class="page-title col-md-5 pl-0 ">Weekly PulseCheck</h3>
  <mat-form-field appearance="outline" class="col-md-3 pr-0">
    <mat-label>Year</mat-label>
    <mat-select [(ngModel)]="selectedYear" name="year" (selectionChange)="getMyWeeklyReportListByYear($event.value)">
      <mat-option *ngFor="let year of surveyYears" [value]="year">
        {{ year }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<br>

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" [hidden]="isLoading || !(dataSource && dataSource.data.length > 0)">
  <ng-container matColumnDef="submittedDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Submission Week</th>
      <td mat-cell *matCellDef="let value">
        {{targetWeekMonday(value.submittedDate) | date:'MM/dd/yyyy' }} to {{ value.submittedDate | date:'MM/dd/yyyy' }}{{ getIsSubmitted(value.id) }}
      </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="resultColumnHeaders"></tr>
  <!-- <tr mat-row class="mat-row-clickable enabled-row" *matRowDef="let row; columns: resultColumnHeaders" disabled='(row.id <= 0)' (click)="!clicked && onSelect(row)"></tr> -->
  <tr mat-row [ngClass]="{'mat-row-clickable':(row.id > 0), 'disabled-row':(row.id <= 0)}" *matRowDef="let row; columns: resultColumnHeaders" disabled='(row.id <= 0)' (click)="onSelect(row)"></tr>
</table>
<div *ngIf="dataSource && dataSource.data.length === 0 && !isLoading">No records found</div>

<div *ngIf="isLoading" class="grid place-items-center inset-0">
  <mat-spinner></mat-spinner>
</div>

