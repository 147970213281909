import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, forkJoin } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { AutoSuggestService } from 'src/app/core/services/auto-suggest.service';
import { BaseFeedbackFilterService } from 'src/app/core/services/base-feedback-filter.service';
import {
  CareerMentor,
  CareerMentorService,
} from 'src/app/core/services/career-mentor.service';
import { CommonService } from 'src/app/core/services/common.service';
import {
  CompetencyDto,
  FeedbackService,
} from 'src/app/core/services/feedback.service';
import { MyTeamFeedbackFilterService } from 'src/app/core/services/my-team-feedback-filter.service';
import {
  Employee,
  defaultOnliner,
} from 'src/app/core/services/onliner.service';
import {
  FbSelfAssessmentSchedule,
  PracticeType,
  SelfAssessmentService,
} from 'src/app/core/services/self-assessment.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-team-survey-filter-criteria-form',
  templateUrl: './team-survey-filter-criteria-form.component.html',
  styleUrls: ['./team-survey-filter-criteria-form.component.scss'],
  providers: [
    {
      provide: BaseFeedbackFilterService,
      useExisting: MyTeamFeedbackFilterService,
    },
  ],
})
export class TeamSurveyFilterCriteriaFormComponent implements OnChanges {
  @Input()
  searchCriteriaForm!: UntypedFormGroup;
  @Output() filterFeedbacksTriggered = new EventEmitter<boolean>();
  @Output() filterYearTriggered = new EventEmitter<FbSelfAssessmentSchedule>();

  submittedBy!: Employee[];
  careerMentors!: CareerMentor[];
  onFilter = true;
  currentCareerMentor?: CareerMentor;
  fiscalYear!: number[];
  selfAssessmentSchedules!: FbSelfAssessmentSchedule[];
  isCMDelegate = false;
  includedDefaultSubmittedBy = false;
  includedDefaultCareerMentor = false;
  includedDefaultCompetency = false;
  practices!: string[];
  practiceTypes: PracticeType[] = [];
  competencies!: CompetencyDto[];

  get practiceControl() {
    return this.searchCriteriaForm.controls['practice'];
  }
  get competencyControl() {
    return this.searchCriteriaForm.controls['competency'];
  }
  get submittedByControl() {
    return this.searchCriteriaForm.controls['submittedBy'];
  }
  get careerMentorControl() {
    return this.searchCriteriaForm.controls['careerMentor'];
  }
  get fiscalYearControl() {
    return this.searchCriteriaForm.controls['fiscalYear'];
  }

  filteredPractices!: Observable<PracticeType[]>;
  filteredSubmittedBy!: Observable<Employee[]> | Observable<CareerMentor[]>;
  filteredCareerMentors!: Observable<CareerMentor[]>;
  filteredfiscalYear!: Observable<number[]>;
  filteredCompetencies!: Observable<CompetencyDto[]>;

  minDate: Date = new Date(new Date().getFullYear() - 5, 1, 1);
  isLoading = true;
  isSearching!: boolean;

  constructor(
    private commonService: CommonService,
    private feedbackService: FeedbackService,
    private selfAssessmentService: SelfAssessmentService,
    private careerMentorService: CareerMentorService,
    private snackBarService: SnackBarService,
    private autoSuggestService: AutoSuggestService,
    private authService: AuthService,
    private feedbackFilterService: MyTeamFeedbackFilterService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchCriteriaForm && changes.searchCriteriaForm.currentValue) {
      this.getDropdownsData();
      this.yearDisplay();
    }
  }

  setCompetencyFilter() {
    this.onFilter = true;
    this.filteredCompetencies = this.autoSuggestService.setCompetencyFilter(
      this.competencyControl,
      this.competencies,
      this.includedDefaultCompetency,
      this.onFilter
    );
  }

  setOnlinerFilter() {
    this.onFilter = true;
    this.filteredSubmittedBy = this.autoSuggestService.setOnlinerFilter(
      this.submittedByControl,
      this.submittedBy,
      this.includedDefaultSubmittedBy,
      this.onFilter
    );
  }

  setCareerMentorFilter() {
    if (
      this.authService.doesUserHaveRole([environment.roles.FeedbackAdmin]) ||
      this.isCMDelegate ||
      this.authService.doesUserHaveRole([environment.roles.FeedbackLead]) ||
      this.authService.doesUserHaveRole([environment.roles.FeedbackReviewer])
    ) {
      this.onFilter = true;
    } else {
      this.onFilter = false;
    }

    this.filteredCareerMentors = this.autoSuggestService.setOnlinerFilter(
      this.careerMentorControl,
      this.careerMentors,
      this.includedDefaultCareerMentor,
      this.onFilter
    );
  }

  setPracticeTypeFilter() {
    this.onFilter = true;
    this.filteredPractices = this.autoSuggestService.setPracticeTypeFilter(
      this.practiceControl,
      this.practiceTypes,
      false,
      this.onFilter
    );
  }

  onlinerDisplay = (option?: Employee | CareerMentor): string =>
    this.autoSuggestService.onlinerDisplay(option);

  yearDisplay = (option?: FbSelfAssessmentSchedule): string =>
    (this.autoSuggestService.fiscalYearDisplay(option) ?? '').toString();

  competencyDisplay = (option?: CompetencyDto): string =>
    this.autoSuggestService.competencyDisplay(option);

  practiceTypeDisplay = (option?: PracticeType): string =>
    this.autoSuggestService.practiceTypeDisplay(option);

  careerMentorDisplay = (option?: CareerMentor): string =>
    this.autoSuggestService.careerMentorDisplay(option);

  filterFeedbacks() {
    if (this.searchCriteriaForm.valid) {
      sessionStorage.setItem(
        'cm',
        this.searchCriteriaForm.controls['careerMentor'].value.userId
      );
      this.filterFeedbacksTriggered.emit(true);
    }
  }

  filterYears(year: MatAutocompleteSelectedEvent) {
    if (this.searchCriteriaForm.valid) {
      this.filterYearTriggered.emit(year.option.value);
    }
  }

  resetForm() {
    this.feedbackFilterService.resetSelfAssessmentForm().then(() => {
      this.filterYearTriggered.emit(this.selfAssessmentSchedules[0]); // load the data for the current fiscal year
      this.filterFeedbacks();
    });
  }

  private getDropdownsData() {
    this.isLoading = true;

    forkJoin([
      this.commonService.getEmployeesIncludingInactive(),
      this.careerMentorService.getCareerMentorsIncludingInactive(),
      this.commonService.getFiscalYear(),
      this.commonService.getPractices(),
      this.feedbackService.getCompetencies(),
      this.careerMentorService.isDelegate(
        this.authService.getUserId().toLowerCase()
      ),
    ]).subscribe(
      data => {
        this.setUpDropDownData(data);
      },
      error => {
        this.isLoading = false;
        this.snackBarService.error(error);
      }
    );
    this.careerMentorService.getCareerMentors().subscribe(data => {
      this.currentCareerMentor = data.find(
        cm => cm.userId === this.authService.getUserId().toLowerCase()
      );
    });
  }

  private setUpDropDownData(
    data: [
      Employee[],
      CareerMentor[],
      FbSelfAssessmentSchedule[],
      string[],
      CompetencyDto[],
      boolean,
    ]
  ) {
    const ONLINER_IX = 0,
      FEEDBACK_CAREER_MENTOR_IX = 1,
      YEARS_IX = 2,
      PRACTICE = 3,
      COMPETENCY = 4,
      isCMDelegate = 5;

    this.submittedBy = data[ONLINER_IX];
    this.includedDefaultSubmittedBy =
      !this.submittedBy.includes(defaultOnliner);
    this.selfAssessmentSchedules = data[YEARS_IX];
    this.setOnlinerFilter();

    this.careerMentors = data[FEEDBACK_CAREER_MENTOR_IX].sort((a, b) =>
      `${a.firstName} ${a.lastName}`.localeCompare(
        `${b.firstName} ${b.lastName}`
      )
    );
    this.includedDefaultCareerMentor =
      !this.careerMentors.includes(defaultOnliner);

    data[PRACTICE].forEach(element => {
      this.practiceTypes.push({ practice: element });
    });

    this.competencies = data[COMPETENCY];
    this.isCMDelegate = data[isCMDelegate];
    this.setPracticeTypeFilter();
    this.setCareerMentorFilter();
    this.setCompetencyFilter();

    this.isLoading = false;
  }
}
