import {
  CdkDragDrop,
  CdkDragStart,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  QuestionOption,
  QuestionOptionGroup,
} from 'src/app/core/services/feedback.service';
import { QuestionsConfigService } from 'src/app/core/services/questions-config.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {
  OptionDialogComponent,
  OptionDialogData,
} from '../option-dialog/option-dialog.component';

@Component({
  selector: 'app-option-group-detail',
  templateUrl: './option-group-detail.component.html',
  styleUrls: [
    './option-group-detail.component.scss',
    '../questions-config.component.scss',
  ],
})
export class OptionGroupDetailComponent implements OnInit {
  @Input()
  selectedOptionGroup!: QuestionOptionGroup;
  @Input()
  title!: string;
  @Output() return = new EventEmitter<void>();

  dragDisabled = true;
  options!: QuestionOption[];
  isLoading = true;
  isSavingOptions = false;
  orderChanged = false;
  optionActiveForms!: FormGroup[];
  optionGroupForm!: FormGroup;
  previousIndex!: number;
  optionColumns: string[] = ['dragHandle', 'active', 'option', 'score'];
  addOptionTooltip = 'Please save the Option Group before adding an option';
  dragHandleTooltip = 'Drag to reorder';

  constructor(
    private questionsConfigService: QuestionsConfigService,
    private snackBarService: SnackBarService,
    private fb: FormBuilder,
    private optionDialog: MatDialog
  ) {}

  ngOnInit() {
    this.setUpComponent();
  }

  get isNewOptionGroup(): boolean {
    return this.selectedOptionGroup?.id === undefined;
  }

  get isSaveDisabled(): boolean {
    return (
      (this.optionGroupForm.pristine &&
        !this.orderChanged &&
        this.optionActiveForms.every(form => form.pristine)) ||
      this.optionGroupForm.invalid
    );
  }

  get hasUnsavedChanges(): boolean {
    return (
      !this.optionGroupForm.pristine ||
      this.optionActiveForms.some(form => !form.pristine) ||
      this.orderChanged
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    const updatedOptions = [...this.options];
    const updatedFormGroups = [...this.optionActiveForms];

    moveItemInArray(updatedOptions, this.previousIndex, event.currentIndex);
    moveItemInArray(updatedFormGroups, this.previousIndex, event.currentIndex);

    updatedOptions.forEach((question, index) => {
      question.sortOrder = index;
    });

    this.optionActiveForms = updatedFormGroups;
    this.options = updatedOptions;
    this.orderChanged = true;
  }

  start(event: CdkDragStart<string[]>, toIndex: number) {
    this.previousIndex = toIndex;
  }

  close() {
    this.return.emit();
  }

  toggleActive(whichOption: QuestionOption) {
    whichOption.isActive = !whichOption.isActive;
  }

  addOption() {
    const newOptionData = new OptionDialogData();
    newOptionData.title = 'Add Option';
    newOptionData.mode = 'add';
    newOptionData.data = new QuestionOption();

    const optionDialogRef = this.optionDialog.open(OptionDialogComponent, {
      width: '500px',
      height: '230px',
      data: newOptionData,
    });

    optionDialogRef.afterClosed().subscribe((data: QuestionOption) => {
      if (data) {
        const newOption = new QuestionOption();
        newOption.value = data.value;
        newOption.isActive = data.isActive;
        newOption.sortOrder = this.options.length;
        newOption.groupId = this.selectedOptionGroup.id;
        newOption.score = data.score;
        newOption.groupLabel = this.selectedOptionGroup.label;

        this.questionsConfigService.addQuestionOption(newOption).subscribe(
          (addedOption: QuestionOption) => {
            this.optionActiveForms.push(
              this.fb.group({
                activeControl: [data.isActive],
              })
            );
            this.options = [...this.options, addedOption];
            this.snackBarService.message('Option Added');
          },
          (error: string) => {
            this.snackBarService.error(error);
          }
        );
      }
    });
  }

  editOption(whichOption: QuestionOption) {
    const editOptionData = new OptionDialogData();
    editOptionData.title = 'Edit Option';
    editOptionData.mode = 'edit';
    editOptionData.data = whichOption;

    const optionDialogRef: MatDialogRef<OptionDialogComponent, QuestionOption> =
      this.optionDialog.open<OptionDialogComponent, OptionDialogData>(
        OptionDialogComponent,
        {
          width: '500px',
          height: '230px',
          data: editOptionData,
        }
      );

    optionDialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.questionsConfigService.updateQuestionOption(data).subscribe(
          (updatedOption: QuestionOption) => {
            const updatedOptions = this.options.map(answer =>
              answer.id === updatedOption.id ? updatedOption : answer
            );
            this.options = [...updatedOptions];

            const index = this.options.findIndex(
              answer => answer.id === updatedOption.id
            );
            if (index !== -1) {
              this.optionActiveForms[index]
                .get('activeControl')
                ?.setValue(updatedOption.isActive);
              this.optionActiveForms[index].markAsDirty();
            }
            this.snackBarService.message('Option Saved');
          },
          (error: string) => {
            this.snackBarService.error(error);
          }
        );
      }
    });
  }

  save() {
    this.isLoading = true;

    this.selectedOptionGroup.label =
      this.optionGroupForm.value.optionGroupControl;

    if (this.isNewOptionGroup) {
      this.questionsConfigService
        .addQuestionOptionGroup(this.selectedOptionGroup)
        .subscribe(
          (data: QuestionOptionGroup) => {
            this.selectedOptionGroup = data;
            this.isLoading = false;
            this.snackBarService.message('Option Group Added');
          },
          (error: string) => {
            this.isLoading = false;
            this.snackBarService.error(error);
          }
        );
    } else {
      this.questionsConfigService
        .updateQuestionOptionGroup(this.selectedOptionGroup)
        .subscribe(
          (data: QuestionOptionGroup) => {
            this.selectedOptionGroup = data;
            this.isLoading = false;
            this.optionGroupForm.markAsPristine();
            this.snackBarService.message('Option Group Saved');

            if (this.options.length > 0) {
              this.isSavingOptions = true;

              this.options.forEach(option => {
                option.groupLabel = data.label;
                this.questionsConfigService
                  .updateQuestionOption(option)
                  .subscribe(
                    () => {
                      this.optionActiveForms.forEach(formGroup => {
                        formGroup.markAsPristine();
                      });
                      this.orderChanged = false;
                      this.isSavingOptions = false;
                    },
                    (error: string) => {
                      this.snackBarService.error(error);
                      this.orderChanged = false;
                      this.isSavingOptions = false;
                    }
                  );
              });
            }
          },
          (error: string) => {
            this.isLoading = false;
            this.snackBarService.error(error);
          }
        );

      if (this.options.length > 0) {
        this.isSavingOptions = true;

        this.options.forEach(option => {
          this.questionsConfigService.updateQuestionOption(option).subscribe(
            () => {
              this.optionActiveForms.forEach(formGroup => {
                formGroup.markAsPristine();
              });
              this.orderChanged = false;
              this.isSavingOptions = false;
            },
            (error: string) => {
              this.snackBarService.error(error);
              this.orderChanged = false;
              this.isSavingOptions = false;
            }
          );
        });
      }
    }

    this.optionActiveForms.forEach((form, index) => {
      if (form.dirty) {
        const option = this.options[index];
        option.isActive = form.value.activeControl;
        this.questionsConfigService.updateQuestionOption(option).subscribe(
          (data: QuestionOption) => {
            this.options[index] = data;
            this.optionActiveForms[index].markAsPristine();
          },
          (error: string) => {
            this.snackBarService.error(error);
          }
        );
      }
    });
  }

  private setUpComponent() {
    this.isLoading = true;
    this.optionGroupForm = this.fb.group({
      optionGroupControl: [
        this.selectedOptionGroup?.label,
        Validators.required,
      ],
    });

    if (!this.isNewOptionGroup && this.selectedOptionGroup?.id) {
      this.questionsConfigService
        .getAllOptionsFromOptionGroup(this.selectedOptionGroup.id)
        .subscribe(
          options => {
            this.options = options;

            this.optionActiveForms = this.options.map(answer =>
              this.fb.group({
                activeControl: [answer.isActive],
              })
            );

            this.isLoading = false;
          },
          (error: string) => {
            this.snackBarService.error(error);
            this.isLoading = false;
          }
        );
    } else {
      this.options = [];
      this.optionActiveForms = [];
      this.isLoading = false;
    }
  }
}
