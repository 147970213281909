import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeedbackProvidedRoutingModule } from './feedback-provided-routing.module';
import { FeedbackProvidedComponent } from './feedback-provided.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { FeedbackListModule } from 'src/app/shared/modules/feedback-list/feedback-list.module';
import { FeedbackFilterCriteriaFormModule } from 'src/app/shared/modules/feedback-filter-criteria-form/feedback-filter-criteria-form.module';

@NgModule({
  declarations: [FeedbackProvidedComponent],
  imports: [
    CommonModule,
    FeedbackProvidedRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,
    MatProgressSpinnerModule,

    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FeedbackFilterCriteriaFormModule,
    FeedbackListModule,
  ],
  exports: [FeedbackFilterCriteriaFormModule, FeedbackListModule],
})
export class FeedbackProvidedModule {}
