import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuestionsConfigRoutingModule } from './questions-config-routing.module';
import { QuestionsConfigComponent } from './questions-config.component';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FeedbackTypeDetailComponent } from './feedback-type-detail/feedback-type-detail.component';
import { FeedbackTypeDialogComponent } from './feedback-type-dialog/feedback-type-dialog.component';
import { OptionDialogComponent } from './option-dialog/option-dialog.component';
import { OptionGroupDetailComponent } from './option-group-detail/option-group-detail.component';
import { QuestionDetailComponent } from './question-detail/question-detail.component';
import { QuestionGroupDetailComponent } from './question-group-detail/question-group-detail.component';

@NgModule({
  declarations: [
    QuestionsConfigComponent,
    FeedbackTypeDetailComponent,
    FeedbackTypeDialogComponent,
    OptionDialogComponent,
    OptionGroupDetailComponent,
    QuestionDetailComponent,
    QuestionGroupDetailComponent,
  ],
  imports: [
    CommonModule,
    QuestionsConfigRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
  ],
})
export class QuestionsConfigModule {}
