import { Component, HostListener, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { AuthorizedHttp } from 'src/app/core/services/authorized-http.service';
import { CommonService } from 'src/app/core/services/common.service';
import {
  Question,
  QuestionGroup,
  QuestionOption,
  QuestionOptionGroup,
} from 'src/app/core/services/feedback.service';
import {
  EmployeeDetails,
  RemoteWorkRequestService,
} from 'src/app/core/services/remote-work-request.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { FeedbackTypeEnum } from 'src/app/shared/enums/enums.model';
import * as formValidators from 'src/app/shared/validators/form-validator';

@Component({
  selector: 'app-request-remote-work-workflow',
  templateUrl: './request-remote-work-workflow.component.html',
  styleUrls: ['./request-remote-work-workflow.component.scss'],
})
export class RequestRemoteWorkWorkflowComponent implements OnInit {
  isLoading!: boolean;
  remoteWorkRequestForm!: UntypedFormGroup;
  remoteWorkRequestId = 0;
  remoteWorkRequestTypeId!: number;
  requestStatus!: string;
  questions!: Question[];
  questionGroups!: QuestionGroup[];
  questionOptions!: QuestionOption[];
  questionOptionGroups!: QuestionOptionGroup[];
  questionGroupIDs!: number[];
  questionOptionGroupIDs!: number[];
  user!: EmployeeDetails;
  name!: string;
  practice!: string;
  country!: string;
  status!: string;
  isRetreivingData = false;
  userCanChangeStatus?: boolean = undefined;
  countryQuestionId = 65;

  constructor(
    private route: ActivatedRoute,
    private authHttp: AuthorizedHttp,
    private commonService: CommonService,
    private remoteWorkRequestService: RemoteWorkRequestService,
    private authService: AuthService,
    private snackBarService: SnackBarService
  ) {}

  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    return !this.remoteWorkRequestForm.dirty;
  }

  ngOnInit() {
    this.isLoading = true;

    this.route.params.subscribe(param => {
      if (param['id']) {
        this.remoteWorkRequestId = param['id'];
      }
      this.remoteWorkRequestTypeId = FeedbackTypeEnum.RemoteWorkRequest;
      forkJoin([
        this.commonService.getQuestions(),
        this.commonService.getQuestionGroups(),
        this.commonService.getQuestionOptions(),
        this.commonService.getQuestionOptionGroups(),
      ]).subscribe(
        ([
          questions,
          questionGroups,
          questionOptions,
          questionOptionGroups,
        ]) => {
          this.questions = [...questions];
          this.questionGroups = [...questionGroups];
          this.questionOptions = [...questionOptions];
          this.questionOptionGroups = [...questionOptionGroups];

          this.remoteWorkRequestService
            .getUserDetailsForRequest(
              this.remoteWorkRequestId ? this.remoteWorkRequestId : 0
            )
            .subscribe(
              (data: EmployeeDetails) => {
                if (data) {
                  this.user = data;
                  this.name = this.user.name;
                  this.practice = this.user.practice;
                  this.country = this.user.country;
                  this.isRetreivingData = true;
                  const userId = this.authService.getUserId();
                  this.remoteWorkRequestService
                    .userCanChangeStatus(userId)
                    .subscribe(result => (this.userCanChangeStatus = result));
                }
              },
              error => {
                this.snackBarService.error(error);
                this.isLoading = false;
                this.authHttp.forbidden();
              }
            );

          const group = this.questionGroups.filter(
            qg => qg.type.valueOf() === this.remoteWorkRequestTypeId
          );
          if (group) {
            if (group.length === 1) {
              this.questionGroupIDs = [group[0].id];
              this.populateQuestionOptionGroupIDs(group[0]);
            } else if (group.length > 1) {
              if (!this.questionGroupIDs) {
                this.questionGroupIDs = [];
              }
              group.forEach(q => {
                this.questionGroupIDs.push(q.id);
                this.populateQuestionOptionGroupIDs(q);
              });
            }
          }

          this.createRequestRemoteWorkForm();
        }
      );
      this.isLoading = false;
    });
  }

  populateQuestionOptionGroupIDs(group: QuestionGroup) {
    this.questionOptionGroupIDs = [];
    this.questions.forEach(q => {
      if (q.optionGroupId && q.groupId === group.id) {
        if (this.questionOptionGroupIDs.length > 0) {
          if (!this.questionOptionGroupIDs.some(qo => qo === q.optionGroupId)) {
            this.questionOptionGroupIDs.push(q.optionGroupId);
          }
        } else {
          this.questionOptionGroupIDs.push(q.optionGroupId);
        }
      }
    });
  }

  private createRequestRemoteWorkForm() {
    const questionGroup = this.questionGroups.filter(
      qg => qg.type.valueOf() === this.remoteWorkRequestTypeId
    );
    if (questionGroup) {
      this.questionGroupIDs = [];

      questionGroup.forEach(q => {
        if (
          this.questionGroupIDs.findIndex(question => question === q.id) < 0
        ) {
          this.questionGroupIDs.push(q.id);
        }
      });
    }

    const group: {
      [key: string]: AbstractControl<unknown, unknown>;
    } = {};
    group['onliner'] = new UntypedFormControl('');
    this.questionGroupIDs.forEach(qg => {
      this.questions
        .filter(qu => qu.groupId === qg)
        .forEach(q => {
          if (q && q.isOptional) {
            group[`${q.id}`] = new UntypedFormControl('');
          } else {
            if (
              q.type.toString() === 'Text' &&
              q.id !== this.countryQuestionId
            ) {
              group[`${q.id}`] = new UntypedFormControl('', [
                Validators.required,
                formValidators.requiredAndNoWhiteSpaceValidator,
              ]);
            } else if (q.id === this.countryQuestionId) {
              group[`${q.id}`] = new UntypedFormControl(
                '',
                Validators.required
              );
            } else {
              group[`${q.id}`] = new UntypedFormControl(
                '',
                Validators.required
              );
            }
            if (q.type.toString() === 'Radio_Text') {
              group[`${q.id}_text`] = new UntypedFormControl('');
            }
          }
        });
    });
    this.remoteWorkRequestForm = new UntypedFormGroup(group);
    this.remoteWorkRequestForm.controls[this.countryQuestionId].setValidators(
      formValidators.validateCountry()
    );
  }
}
