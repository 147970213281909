<form *ngIf="!isLoading" [formGroup]="searchCriteriaForm" novalidate [style.fontSize.px]="18">
    <div class="row form-group" >
        <mat-form-field class="mr-3 col-md-2 search" [hideRequiredMarker]="hideAsterisk" *ngIf="!isOnRequestsPage">
          <mat-label for="client">Date From: </mat-label>
          <div [ngClass]="{'has-error': searchCriteriaForm.get('startDate')?.errors && searchCriteriaForm.get('startDate')?.touched}">
              <input
              matInput
              formControlName="startDate"
              [matDatepicker]="startDatePicker"
              placeholder="MM/DD/YYYY"
              name="dateStart"
              maxDateRangeValidator="endDate"
              (click)="startDatePicker.open()"
              (focus)="startDatePicker.open()"
              (dateChange) = "filterRequests()"
              [min]="minDate">
              <mat-datepicker #startDatePicker></mat-datepicker>
          </div>
          <span class="error-message" *ngIf="searchCriteriaForm.get('startDate')?.hasError('datePatternError') && searchCriteriaForm.get('startDate')?.dirty">
              Must be in Date format
          </span>
          <span class="error-message" *ngIf="(searchCriteriaForm.get('startDate')?.hasError('invalidStartDate') && searchCriteriaForm.get('startDate')?.touched)">
            Start Date must be a date and before the End Date.
          </span>
        </mat-form-field>

        <mat-form-field class="mr-3 col-md-2 search" [hideRequiredMarker]="hideAsterisk">
          <mat-label for="client">Date To: </mat-label>
          <div [ngClass]="{'has-error': searchCriteriaForm.get('endDate')?.errors && searchCriteriaForm.get('endDate')?.touched}">
              <input
              matInput
              formControlName="endDate"
              [matDatepicker]="endDatePicker"
              name="dateEnd"
              placeholder="MM/DD/YYYY"
              minDateRangeValidator="startDate"
              (click)="endDatePicker.open()"
              (focus)="endDatePicker.open()"
              (dateChange) = "filterRequests()">
              <mat-datepicker #endDatePicker></mat-datepicker>
          </div>
          <span class="error-message" *ngIf="searchCriteriaForm.get('endDate')?.hasError('datePatternError') && searchCriteriaForm.get('endDate')?.dirty">
              Must be in Date format
          </span>
          <span class="error-message" *ngIf="searchCriteriaForm.get('endDate')?.hasError('invalidEndDate') && searchCriteriaForm.get('endDate')?.touched">
              End Date must be a date and greater than Start Date.
          </span>
        </mat-form-field>
    </div>

      <div class="row form-group" >
        <mat-form-field class="mr-3 col-md-3 search" *ngIf="isOnlinerFilterVisible" [hideRequiredMarker]="hideAsterisk">
            <mat-label for="onliner">Onliner: </mat-label>
            <input matInput name="onliner" id="onliner" placeholder="Choose Onliner" [matAutocomplete]="onlinerauto"
              formControlName="onliner"/>
            <mat-error *ngIf="searchCriteriaForm.get('onliner')?.hasError('required') && searchCriteriaForm.get('onliner')?.touched">
                Please enter the name of the Onliner.</mat-error>
            <mat-autocomplete #onlinerauto="matAutocomplete" [displayWith]="onlinerDisplay" autoActiveFirstOption
              (optionSelected)="filterRequests()" panelWidth="280px">
              <mat-option class="options" *ngFor="let option of filteredOnliners | async" [value]="option">
                {{ onlinerDisplay(option) }}
              </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="mr-3 col-md-2 search" [hideRequiredMarker]="hideAsterisk">
            <mat-label for="remoteWorkRequestType">Request Type: </mat-label>
            <input matInput name="remoteWorkRequestType" id="remoteWorkRequestType" [matAutocomplete]="remoteWorkRequestTypeauto"
              formControlName="remoteWorkRequestType"/>
            <mat-autocomplete #remoteWorkRequestTypeauto="matAutocomplete" [displayWith]="remoteWorkRequestTypeDisplay" autoActiveFirstOption
              (optionSelected)="filterRequests()" panelWidth="280px">
              <mat-option class="options" *ngFor="let option of filteredRemoteWorkRequestTypes | async" [value]="option">
                {{ remoteWorkRequestTypeDisplay(option) }}
              </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="mr-3 col-md-2 search">
          <mat-label for="remoteWorkRequestStatus">Request Status: </mat-label>
          <input matInput name="remoteWorkRequestStatus" id="remoteWorkRequestStatus" [matAutocomplete]="remoteWorkRequestStatusAuto"
              formControlName="remoteWorkRequestStatus"/>
            <mat-autocomplete #remoteWorkRequestStatusAuto="matAutocomplete" [displayWith]="remoteWorkRequestStatusDisplay" autoActiveFirstOption
              (optionSelected)="filterRequests()" panelWidth="280px">
              <mat-option class="options" *ngFor="let option of filteredRemoteWorkRequestStatuses | async" [value]="option">
                {{ remoteWorkRequestStatusDisplay(option) }}
              </mat-option>
            </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="row form-group" >
        <div class="col-md-3 padding-left-15">
          <button type="button" mat-stroked-button color="warn" class="reject-btn" (click)="resetForm()" [disabled]="!searchCriteriaForm.dirty">RESET</button>
        </div>
      </div>

  </form>

  <div *ngIf="isLoading" class="grid place-items-center inset-0">
    <mat-spinner></mat-spinner>
  </div>


