<div *ngIf="!isLoading && !isSavingOptions">
    <div class="detail-title">{{title}}</div>

    <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 questions-config-body mat-elevation-z3">
        <mat-form-field class="text-input" appearance="outline" [formGroup]="optionGroupForm">
            <mat-label>Option Group</mat-label>
            <input matInput formControlName="optionGroupControl" placeholder="Option Group">
        </mat-form-field>

        <div class="table-header">Available Options</div>

        <div class="table-container mat-elevation-z3">

            <table cdkDropList (cdkDropListDropped)="drop($event)" mat-table [dataSource]="options">
                <ng-container matColumnDef="dragHandle">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell class="px-3 d-block" *matCellDef="let answer">
                        <mat-icon
                            class="fas fa-sort d-flex align-items-center"
                            cdkDragHandle
                            (touchstart)="dragDisabled = false"
                            (touchend)="dragDisabled = true"
                            (mousedown)="dragDisabled = false"
                            (mouseup)="dragDisabled = true"
                            matTooltip="{{dragHandleTooltip}}">
                        </mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="active">
                    <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
                    <mat-cell *matCellDef="let option; let i = index">
                        <form [formGroup]="optionActiveForms[i]">
                            <mat-slide-toggle formControlName="activeControl" (change)="toggleActive(option)"></mat-slide-toggle>
                        </form>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="option">
                    <mat-header-cell *matHeaderCellDef>Option</mat-header-cell>
                    <mat-cell *matCellDef="let option" (click)="editOption(option)">
                        {{option.value}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="score">
                    <mat-header-cell *matHeaderCellDef>Score</mat-header-cell>
                    <mat-cell *matCellDef="let option" (click)="editOption(option)">
                        {{option.score}}
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="optionColumns"></mat-header-row>
                <mat-row
                    class="mat-row-clickable"
                    *matRowDef="let row; let i = index; columns: optionColumns;"
                    style="cursor: pointer"
                    cdkDrag
                    (cdkDragStarted)="start($event,i)"
                    [cdkDragDisabled]="dragDisabled"
                    (cdkDragReleased)="dragDisabled = true">
                </mat-row>
            </table>
            <span [matTooltip]="isNewOptionGroup ? addOptionTooltip : ''">
            <button mat-stroked-button
                class="questions-add-button m-2"
                (click)="addOption()"
                [disabled]="isNewOptionGroup">ADD
            </button>
          </span>
        </div>
    </div>

    <div class="d-flex justify-content-start mt-4">
        <button mat-stroked-button class="cancel-btn" (click)="close()">CANCEL</button>
        <button mat-stroked-button class="save-btn ml-4" (click)="save()" [disabled]="isSaveDisabled">SAVE</button>
    </div>
</div>
