<vex-secondary-toolbar current="My Feedback">
  <vex-breadcrumbs [crumbs]="['My Feedback', 'Weekly Reports', (weeklyReport && weeklyReport.submittedDate | date: 'MM/dd/yyyy') ?? '']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="row m-0">
  <div class="component-container">
      <app-view-weekly-health-delivery-detail [feedback]="weeklyReport" [operationInProgress]="operationInProgress"></app-view-weekly-health-delivery-detail>
  </div>
</div>
