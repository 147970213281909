import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { first } from 'rxjs/operators';
import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard {
  dialogConfirmation = new EventEmitter<boolean>();

  constructor(private dialog: MatDialog) {}

  canDeactivate(
    component: ComponentCanDeactivate,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currentRoute: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currentState: RouterStateSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    nextState?: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    return component.canDeactivate()
      ? observableOf(true)
      : this.openConfirmationDialog().pipe(first());
    // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
    // when navigating away from your angular app, the browser will show a generic warning message
    // see http://stackoverflow.com/a/42207299/7307355
  }

  openConfirmationDialog(): Observable<boolean> {
    return new Observable(observer => {
      this.showConfirmationDialog();
      this.dialogConfirmation.subscribe(result => {
        observer.next(result);
        observer.complete();
      });
    });
  }

  private showConfirmationDialog() {
    const confirmDialog = new ConfirmDialog();
    confirmDialog.title = 'Unsaved Changes';
    confirmDialog.message =
      '<p> There are currently unsaved changes that have not been committed. Are you sure you want to continue?</p>';
    confirmDialog.okButtonTitle = 'Continue';
    confirmDialog.cancelButtonTitle = 'Cancel';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: confirmDialog,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.dialogConfirmation.emit(result === 'ok');
    });
  }
}
