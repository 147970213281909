<vex-secondary-toolbar current="My Feedback">
  <vex-breadcrumbs [crumbs]="['My Feedback', 'Weekly Reports']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="row m-0">
  <div class="component-container">
    <br>
    <app-weekly-health-delivery-history-list [feedbacks]="weeklyReports" [year]="year" [surveyYears]="surveyYears" ></app-weekly-health-delivery-history-list>
  </div>
</div>
