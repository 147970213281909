import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-error403',
  templateUrl: './error-403.component.html',
  styleUrls: ['./error-403.component.scss'],
})
export class Error403Component implements OnInit {
  timedOut = false;

  constructor(
    private authService: AuthService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  ngOnInit() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.timedOut = !this.authService.isAuthenticated();
      });
  }

  logout() {
    this.authService.logout();
  }
}
