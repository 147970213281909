import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeedbackReceivedRoutingModule } from './feedback-received-routing.module';
import { FeedbackReceivedComponent } from './feedback-received.component';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { FeedbackFilterCriteriaFormModule } from 'src/app/shared/modules/feedback-filter-criteria-form/feedback-filter-criteria-form.module';
import { FeedbackListModule } from 'src/app/shared/modules/feedback-list/feedback-list.module';

@NgModule({
  declarations: [FeedbackReceivedComponent],
  imports: [
    CommonModule,
    FeedbackReceivedRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,
    MatProgressSpinnerModule,

    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FeedbackFilterCriteriaFormModule,
    FeedbackListModule,
  ],
  exports: [FeedbackFilterCriteriaFormModule, FeedbackListModule],
})
export class FeedbackReceivedModule {}
