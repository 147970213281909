import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CmFeedbackReceivedRoutingModule } from './cm-feedback-received-routing.module';
import { CmFeedbackReceivedComponent } from './cm-feedback-received.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { AngularMaterialModule } from 'src/app/core/angular-material/angular-material.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [CmFeedbackReceivedComponent],
  imports: [
    CommonModule,
    CmFeedbackReceivedRoutingModule,

    SecondaryToolbarModule,
    BreadcrumbsModule,

    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
})
export class CmFeedbackReceivedModule {}
