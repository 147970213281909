import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormExpiredRoutingModule } from './form-expired.routing.module';
import { FormExpiredComponent } from './form-expired.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [FormExpiredComponent],
  imports: [CommonModule, FormExpiredRoutingModule, MatIconModule],
})
export class FormExpiredModule {}
