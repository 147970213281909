import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  AssessmentService,
  MyFeedbackRequests,
} from 'src/app/core/services/assessment.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { FeedbackFilterService } from 'src/app/core/services/feedback-filter.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-feedback-requests',
  templateUrl: './feedback-requests.component.html',
  styleUrls: ['./feedback-requests.component.scss'],
})
export class FeedbackRequestsComponent implements OnInit {
  feedbacks!: MyFeedbackRequests[];
  filteredFeedbacks!: MyFeedbackRequests[];
  filterCriteriaForm!: UntypedFormGroup;
  filterDateDisplay!: string;
  isAdmin!: boolean;

  filterSubmitterValue!: string;
  filterCMValue!: string;
  filterSubmissionTypeValue!: string;
  filterClientValue!: string;
  filterFeedbackStatusValue!: string;
  filtersValue!: string;
  isLoading = true;

  constructor(
    private assessmentService: AssessmentService,
    private snackBarService: SnackBarService,
    private feedbackFilterService: FeedbackFilterService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.filterCriteriaForm =
      this.feedbackFilterService.getRequestedFilterCriteriaForm();
    this.filterCriteriaForm.controls['status'].setValue({
      statusId: 3,
      statusDescription: 'Draft',
    });
    this.getMyFeedbackRequests();
    this.isAdmin = this.authService.doesUserHaveRole([
      environment.roles.FeedbackAdmin,
    ]);
  }

  filterFeedbacks() {
    const filterCriteriaModel =
      this.feedbackFilterService.getRequestedFilterCriteriaModel();
    this.filterDateDisplay =
      this.feedbackFilterService.getFilterDateRangeDisplay(filterCriteriaModel);
    this.filteredFeedbacks = this.feedbacks.filter(f =>
      this.feedbackFilterService.filterRequestedFeedbackStatus(
        f,
        filterCriteriaModel
      )
    );
    this.getFilterFormValues();
  }

  getFilterFormValues() {
    this.filterFeedbackStatusValue = `${this.filterCriteriaForm.controls.status.value['statusDescription']}`;
    this.filtersValue = `${this.filterFeedbackStatusValue}`;
  }

  private getMyFeedbackRequests() {
    this.assessmentService.getMyFeedbackRequests().subscribe(
      data => {
        this.feedbacks = data;
        this.filterFeedbacks();
        this.isLoading = false;
      },
      error => {
        this.snackBarService.error(error);
      }
    );
  }
}
