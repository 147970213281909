import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthorizedHttp } from './authorized-http.service';
import { CommonService } from './common.service';
import {
  FeedbackType,
  Question,
  QuestionGroup,
  QuestionOption,
  QuestionOptionGroup,
} from './feedback.service';

@Injectable({
  providedIn: 'root',
})
export class QuestionsConfigService {
  private apiEndpoint = `${environment.apiEndpoint}/api/questionsConfig`;

  constructor(
    private http: AuthorizedHttp,
    private commonService: CommonService
  ) {}

  public updateFeedbackType(
    feedbackType: FeedbackType
  ): Observable<FeedbackType> {
    return this.http
      .put<FeedbackType>(`${this.apiEndpoint}/updateFeedbackType`, feedbackType)
      .pipe(
        catchError(err =>
          throwError(
            err.error.exceptionMessage || 'Server error (feedback status)'
          )
        )
      );
  }

  public getAllQuestionGroupsFromFeedbackType(
    feedbackTypeId?: number
  ): Observable<QuestionGroup[]> {
    return this.http
      .get<QuestionGroup[]>(
        `${this.apiEndpoint}/getAllQuestionGroupsFromFeedbackType/${feedbackTypeId}`
      )
      .pipe(
        catchError(err =>
          throwError(
            err.error.exceptionMessage || 'Server error (feedback status)'
          )
        )
      );
  }

  public getAllQuestionsFromQuestionGroup(
    questionGroupId: number
  ): Observable<Question[]> {
    return this.http
      .get<Question[]>(
        `${this.apiEndpoint}/getAllQuestionsFromQuestionGroup/${questionGroupId}`
      )
      .pipe(
        catchError(err =>
          throwError(
            err.error.exceptionMessage || 'Server error (feedback status)'
          )
        )
      );
  }

  public updateQuestionGroup(
    questionGroup: QuestionGroup
  ): Observable<QuestionGroup> {
    return this.http
      .put<QuestionGroup>(
        `${this.apiEndpoint}/updateQuestionGroup`,
        questionGroup
      )
      .pipe(
        tap(() => this.commonService.clearQuestionsCache()),
        catchError(err =>
          throwError(
            err.error.exceptionMessage || 'Server error (feedback status)'
          )
        )
      );
  }

  public addQuestionGroup(
    questionGroup: QuestionGroup
  ): Observable<QuestionGroup> {
    return this.http
      .post<QuestionGroup>(
        `${this.apiEndpoint}/addQuestionGroup`,
        questionGroup
      )
      .pipe(
        tap(() => this.commonService.clearQuestionsCache()),
        catchError(err =>
          throwError(
            err.error.exceptionMessage || 'Server error (feedback status)'
          )
        )
      );
  }

  public updateQuestion(question: Question): Observable<Question> {
    return this.http
      .put<Question>(`${this.apiEndpoint}/updateQuestion`, question)
      .pipe(
        tap(() => this.commonService.clearQuestionsCache()),
        catchError(err =>
          throwError(
            err.error.exceptionMessage || 'Server error (feedback status)'
          )
        )
      );
  }

  public addQuestion(question: Question): Observable<Question> {
    return this.http
      .post<Question>(`${this.apiEndpoint}/addQuestion`, question)
      .pipe(
        tap(() => this.commonService.clearQuestionsCache()),
        catchError(err =>
          throwError(
            err.error.exceptionMessage || 'Server error (feedback status)'
          )
        )
      );
  }

  public getAllOptionsFromOptionGroup(
    optionGroupId?: number
  ): Observable<QuestionOption[]> {
    return this.http
      .get<QuestionOption[]>(
        `${this.apiEndpoint}/getAllOptionsFromOptionGroup/${optionGroupId}`
      )
      .pipe(
        catchError(err =>
          throwError(
            err.error.exceptionMessage || 'Server error (feedback status)'
          )
        )
      );
  }

  public updateQuestionOption(
    questionOption: QuestionOption
  ): Observable<QuestionOption> {
    return this.http
      .put<QuestionOption>(
        `${this.apiEndpoint}/updateQuestionOption`,
        questionOption
      )
      .pipe(
        tap(() => this.commonService.clearQuestionsCache()),
        catchError(err =>
          throwError(
            err.error.exceptionMessage || 'Server error (feedback status)'
          )
        )
      );
  }

  public addQuestionOption(
    questionOption: QuestionOption
  ): Observable<QuestionOption> {
    return this.http
      .post<QuestionOption>(
        `${this.apiEndpoint}/addQuestionOption`,
        questionOption
      )
      .pipe(
        tap(() => this.commonService.clearQuestionsCache()),
        catchError(err =>
          throwError(
            err.error.exceptionMessage || 'Server error (feedback status)'
          )
        )
      );
  }

  public getQuestionOptionGroup(): Observable<QuestionOptionGroup[]> {
    return this.http
      .get<QuestionOptionGroup[]>(`${this.apiEndpoint}/getQuestionOptionGroups`)
      .pipe(
        catchError(err =>
          throwError(
            err.error.exceptionMessage || 'Server error (feedback status)'
          )
        )
      );
  }

  public addQuestionOptionGroup(
    questionOptionGroup: QuestionOptionGroup
  ): Observable<QuestionOptionGroup> {
    return this.http
      .post<QuestionOptionGroup>(
        `${this.apiEndpoint}/addQuestionOptionGroup`,
        questionOptionGroup
      )
      .pipe(
        tap(() => this.commonService.clearQuestionsCache()),
        catchError(err =>
          throwError(
            err.error.exceptionMessage || 'Server error (feedback status)'
          )
        )
      );
  }

  public updateQuestionOptionGroup(
    questionOptionGroup: QuestionOptionGroup
  ): Observable<QuestionOptionGroup> {
    return this.http
      .put<QuestionOptionGroup>(
        `${this.apiEndpoint}/updateQuestionOptionGroup`,
        questionOptionGroup
      )
      .pipe(
        tap(() => this.commonService.clearQuestionsCache()),
        catchError(err =>
          throwError(
            err.error.exceptionMessage || 'Server error (feedback status)'
          )
        )
      );
  }
}
