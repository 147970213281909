import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuestionOption } from 'src/app/core/services/feedback.service';
import { QuestionTypeEnum } from 'src/app/shared/enums/enums.model';

export class OptionDialogData {
  title!: string;
  mode!: string;
  data!: QuestionOption;
}

@Component({
  selector: 'app-option-dialog',
  templateUrl: './option-dialog.component.html',
  styleUrls: ['./option-dialog.component.scss'],
})
export class OptionDialogComponent implements OnInit {
  dialogForm!: FormGroup;
  public QuestionTypeEnum = QuestionTypeEnum;
  public questionTypes = Object.keys(QuestionTypeEnum).filter(key =>
    isNaN(Number(key))
  );
  public config: OptionDialogData = inject(MAT_DIALOG_DATA);

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<OptionDialogComponent>
  ) {}

  ngOnInit() {
    this.dialogForm = this.fb.group({
      answerControl: [this.config.data.value],
      activeControl: [
        this.config.data.isActive !== null ? this.config.data.isActive : false,
      ],
      scoreControl: [this.config.data.score],
    });
  }

  save() {
    if (this.dialogForm.dirty) {
      const updatedData = {
        ...this.config.data,
        value: this.dialogForm.value.answerControl,
        isActive: this.dialogForm.value.activeControl,
        score: this.dialogForm.value.scoreControl,
      };
      this.dialogRef.close(updatedData);
    } else {
      this.dialogRef.close();
    }
  }

  close() {
    this.dialogRef.close();
  }
}
