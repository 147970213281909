import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SavedAssessment } from 'src/app/core/services/assessment.service';
import { FeedbackStatusEnum } from 'src/app/shared/enums/enums.model';

@Component({
  selector: 'app-cm-feedback-list',
  templateUrl: './cm-feedback-list.component.html',
  styleUrls: ['./cm-feedback-list.component.scss'],
})
export class CmFeedbackListComponent implements OnInit, OnChanges {
  @Input()
  canReview!: boolean;

  @Input() set feedbacks(feedbck: SavedAssessment[]) {
    this.dataSource = new MatTableDataSource<SavedAssessment>(feedbck);
    if (feedbck) {
      this.isLoading = false;
    } else {
      this.dataSource = new MatTableDataSource<SavedAssessment>();
    }
  }

  @ViewChild(MatSort, { static: true })
  sort!: MatSort;

  isLoading = true;
  selected = [];
  dataSource!: MatTableDataSource<SavedAssessment>;

  resultColumnHeaders: string[] = [
    'employeeName',
    'submittedByName',
    'submittedDate',
    'currentStatus',
    'isAnonymous',
  ];

  constructor(private router: Router) {}

  ngOnInit() {
    return;
  }

  ngOnChanges() {
    this.dataSource.sort = this.sort;
  }

  onSelect(selected: SavedAssessment) {
    const routeToNavigate = this.canReview
      ? 'reviewCmFeedback'
      : 'viewCmFeedback';
    this.router.navigate([routeToNavigate, selected.id]);
  }

  getFeedbackStatusEnum(enumCode: number): string {
    return FeedbackStatusEnum[enumCode];
  }
}
